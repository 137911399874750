import                                      "./index.css"
import React, { Component }                 from "react"
import {connect}                            from "react-redux"
import Avatar from '@mui/material/Avatar';
import InlineIcon                           from "../icon/inline-icon.js"
import Utils                                from "../../../modules/utils"
import quickStartSrc                        from "../../../assets/icons/quick-start.svg"
import goToSandboxSrc                       from "../../../assets/icons/goto-sandbox.svg"
import buildAction                          from "../../../helpers/buildAction"
import ON_BOARDING                          from "../../../action-types/on-boarding"
import USER                                 from "../../../action-types/user"
import ITEM_ASSEMBLY                        from "../../../action-types/item-assembly"
import SEARCH                               from "../../../action-types/search"
import OnBoardingList                       from "./on-boarding-list"
import Popover                              from "../popover-box/"
import notifications                        from "./env-notifications"
import Loading                              from "../../ui/inner-loading"
import {permissions as Permissions}         from "../../../modules/schemas"
import SandboxSrc                           from "../../../assets/icons/sandbox-icon.js"
import CompanySrc                           from "../../../assets/icons/company-icon.js"
import { DuroMenu } from "common/duroMenu";
import SettingsIcon                         from "../../../assets/icons/settings"
import SignOutIcon                          from "../../../assets/icons/sign-out"
import AvatarIcon                           from "../../../assets/icons/user-avatar"
import PolicyIcon                           from "../../../assets/icons/policy-icon"
import SwitchLibraryIcon                    from "../../../assets/icons/switch-library-icon"
import SubmitTicketIcon                     from "../../../assets/icons/submit-ticket-icon"
import HelpDocumentationIcon                from "../../../assets/icons/help-documentation-icon"
import InviteUsersIcon                      from "../../../assets/icons/invite-users-icon"
import IntegrationsIcon                     from "../../../assets/icons/plug"
import { privatePaths }                     from "../../../app/routes"
import { publicPaths }                      from "../../../app/routes"
import UserFormView                         from "../../../components/page/settings/users/user-modal-box/user-form-view"
import ModalBox                             from "../modal-box"

class OnBoarding extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.showHideOnBoarding                      = this.showHideOnBoarding.bind(this)
        this.onInputChange                           = this.onInputChange.bind(this)
        this.redirectToQuickStart                    = this.redirectToQuickStart.bind(this)
        this.next                                    = this.next.bind(this)
        this.gotoInviteUser                          = this.gotoInviteUser.bind(this)
        this.onOpenCloseVideoModal                   = this.onOpenCloseVideoModal.bind(this)
        this.onCloseHandler                          = this.onCloseHandler.bind(this)
        this.getAndSaveSampleProduct                 = this.getAndSaveSampleProduct.bind(this)
        this.onModalExternalClick                    = this.onModalExternalClick.bind(this)
        this.closeOnboardingModal                    = this.closeOnboardingModal.bind(this)
        this.redirectTo                              = this.redirectTo.bind(this)
        this.switchLibrary                           = this.switchLibrary.bind(this)
        this.renderEnvInfo                           = this.renderEnvInfo.bind(this)
        this.closeEnvInfoModal                       = this.closeEnvInfoModal.bind(this)
        this.processStep1                            = this.processStep1.bind(this)
        this.getSampleProduct                        = this.getSampleProduct.bind(this)
        this.displayOnBoardingNav                    = this.displayOnBoardingNav.bind(this)
        this.onInviteModalClose                      = this.onInviteModalClose.bind(this)
        this.onBoardingPopoverExternalClick          = this.onBoardingPopoverExternalClick.bind(this)
        this.showQuickGuide                          = this.showQuickGuide.bind(this)
        this.goThere                                 = this.goThere.bind(this)
        this.setNotShowAgainFlag                     = this.setNotShowAgainFlag.bind(this)
        this.quickStartGuideExternalHover            = this.quickStartGuideExternalHover.bind(this)

        this.createMenuOption                        = this.createMenuOption.bind(this)
        this.inviteUser = this.inviteUser.bind(this)
        this.hideFeaturePopOver                      = this.hideFeaturePopOver.bind(this)

        this.state                                   =
        {
            openOnBoardingNave: false,
            showFeaturePopup: true
        }
    }

    inviteUser()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ON_BOARDING.UPDATE_INVITE_MODAL_FLAG, true));
    }

    createMenuOption(label, icon, path, labelWapperClass='', border_bottom=false, disabled=false)
    {
        let option = {
            label   : label,
            icon    : icon,
            to      : path.pathname,
            state   : path.state,
            labelWapperClass,
            border_bottom,
            disabled
        }
        return option
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (this.props.on_boarding.currentEnv === "LIVE")
        {
            let element = document.getElementsByClassName("main-content")[0];
            if (!!element) element.style.marginRight = 0
        }
    }

    setNotShowAgainFlag()
    {
        const { dispatch }      = this.props;
        let payload = {user: this.props.user}
        dispatch(buildAction(USER.SET_QUICK_START_GUIDE_FLAG, payload));
        dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
    }

    goThere(e)
    {
        this.switchLibrary(null, true)
    }

    showQuickGuide(event)
    {
        let {isTutorialSkiped, currentEnv, displayOnBoardingNav}    = this.props.on_boarding
        let {quickStartGuideNotification}                           = this.props.user.data.onboarding
        if (!isTutorialSkiped && currentEnv === "LIVE" && quickStartGuideNotification)
        {

            let _this = this
            setTimeout(function()
            {
                const {dispatch} = _this.props;
                dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, true));
                document.body.addEventListener("mouseover", _this.quickStartGuideExternalHover)
            }, 300);
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.switchLibrary)
        {
            this.switchLibrary()
            this.props.setSwitchLibraryFlagAfterLibrarySwitch(false)
        }
    }

    componentWillUnmount()
    {
        const { dispatch } = this.props;
        dispatch(buildAction(ON_BOARDING.RESET_INITIAL_STATE))
    }

    hideFeaturePopOver()
    {
        this.state.showFeaturePopup = false
        this.setState(this.state)
    }

    getSampleProduct(name="drone")
    {
        const {dispatch} = this.props;
        let payload =
        {
            activeLibrary    : this.props.user.data.personalLibrary,
            user             : this.props.user,
            currentEnv       : "SANDBOX",
            history          : this.props.history,
            dummyProduct     : true,
            dummyProductType : name
        }
        dispatch(buildAction(ON_BOARDING.SWITCH_ENV_AND_CREATE_SAMPLE_PRODUCT, payload));
        dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
        document.body.addEventListener("click", this.onBoardingPopoverExternalClick)
    }

    showHideOnBoarding(event)
    {
        const {dispatch}             = this.props;
        let { displayOnBoardingNav } = this.props.on_boarding
        let { steps }                = this.props.on_boarding
        displayOnBoardingNav         = !displayOnBoardingNav
        let activeStepIndex          = this.props.on_boarding.activeStepIndex

        if(steps[activeStepIndex].showToolTip)
        {
            steps[activeStepIndex].showToolTip = false
        }
        dispatch(buildAction(ON_BOARDING.SHOW_HIDE_ON_BOARDING_NAV, displayOnBoardingNav));
        if (this.props.on_boarding.isOnBoardingNotification)
            dispatch(buildAction(ON_BOARDING.SET_ON_BOARDING_NOTIFICATION, false));
    }

    getAndSaveSampleProduct(redirectionPath = "")
    {
        const {dispatch} = this.props;
        let payload =
        {
            dummyProduct     : true,
            dummyProductType : this.props.user.data.onboarding.dummyProductType || this.props.on_boarding.dummyProductType,
            history          : this.props.history,
            redirectionPath  : redirectionPath
        }
        dispatch(buildAction(ON_BOARDING.GET_SAMPLE_PRODUCT, payload))
    }

    onInputChange(i, options)
    {
        if (i <= 5)
        {
            const {dispatch}                      = this.props;
            let steps                             = this.props.on_boarding.steps
            let activeStepIndex                   = this.props.on_boarding.activeStepIndex
            let showToolTip                       = steps[i].showToolTip
            steps[activeStepIndex].class          = ""
            steps[activeStepIndex].showVideoModal = false
            steps[activeStepIndex].showToolTip    = false

            if(typeof(options) === "object" && Object.keys(options).length > 0)
            {
                Object.keys(options).forEach( ( key ) =>
                {
                    if(steps[i].hasOwnProperty(key))
                    {
                        steps[i][key] = options[key]
                    }
                })
            }

            if(this.props.on_boarding.currentStep !== steps[i].key || !showToolTip)
            {
                let on_boarding = this.props.on_boarding
                on_boarding.steps = steps
                on_boarding.currentStep = steps[i].key
                on_boarding.activeStepIndex = i

                dispatch(buildAction(ON_BOARDING.UPDATE_STEPS_VALUE, on_boarding));
                let payload = {on_boarding: on_boarding, user: this.props.user}
                dispatch(buildAction(USER.SAVE_ON_BOARDING_INFO_ON_API, payload));
                this.redirectTo(steps[i])
            }

            let isProductView = this.props.history.location.pathname.match("/product/view/")
            if(isProductView)
            {
                dispatch(buildAction(ON_BOARDING.SET_LOADING, false));
            }

            if ((steps[i].key === "full_bom_assembly_tree" || steps[i].key === "export") && !isProductView)
            {
                this.getAndSaveSampleProduct(steps[i].defaultPath)
            }
        }
    }

    redirectTo(step)
    {
        if (step.key === "full_bom_assembly_tree" || step.key === "export") return
        let state = {query: step.queryType}
        if (this.props.location.pathname === "/search")
        {
            const {dispatch} = this.props;
            const payload = {state}
            dispatch(buildAction(SEARCH.CHANGE_SEARCH_TYPE, payload));

        }
        else
        {
            this.props.history.push({pathname: step.defaultPath, onBoarding: true, state: state})
        }
    }

    gotoInviteUser(event)
    {
        event.stopPropagation();
        if(document.getElementsByClassName('popover-tool-tip-container')[0])
        {
            document.getElementsByClassName('popover-tool-tip-container')[0].style.zIndex = "1"
        }
        const {dispatch} = this.props;
        dispatch(buildAction(ON_BOARDING.UPDATE_INVITE_MODAL_FLAG, true));
    }

    closeOnboardingModal()
    {
        const {dispatch} = this.props
        document.body.removeEventListener("click", this.onModalExternalClick)
        dispatch(buildAction(ON_BOARDING.HIDE_ON_BOARDING_MODALS))
    }

    onBoardingPopoverExternalClick(event)
    {
        event.stopPropagation();
        const {dispatch} = this.props
        if (this.props.on_boarding.showEnvInfoModal)
        {
            dispatch(buildAction(ON_BOARDING.SHOW_ENV_INFO, false));
        }
        else if (this.props.on_boarding.isTutorialSkiped)
        {
            dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
        }
        document.body.removeEventListener("click", this.onBoardingPopoverExternalClick)
    }

    quickStartGuideExternalHover(event)
    {
        event.stopPropagation();
        let _this = this
        if (_this.props.on_boarding.isTutorialSkiped && !event.target.closest(".help-menu-actions") && !event.target.closest(".env-info-popover"))
        {
            const {dispatch} = _this.props
            dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
            document.body.removeEventListener("mouseover", _this.quickStartGuideExternalHover)
        }
    }

    onModalExternalClick(event)
    {
        event.stopPropagation();
        let target            = event.target
        let parent            = this.refs.onBoardingModalNode
        let isExternal        = target !== parent && !Utils.isDescendant(parent, target)
        let tagName           = event.target && event.target.getAttribute('nameSvg')
        let loadingPage       = document.getElementsByClassName("ui-loading")[0]
        let clickOnBoarding   = event.target.closest(".tool-tip-modal")
        let clickOnEnvPopover = event.target.closest(".env-info-popover")
        let isVideoModalOpen  = false
        let activeStepIndex   = this.props.on_boarding.activeStepIndex
        let isToolTipModalOpen = true

        if(this.props.on_boarding.steps[activeStepIndex].showVideoModal)
        {
            isVideoModalOpen = true
        }

        if(this.props.on_boarding.steps[activeStepIndex].showToolTip)
        {
            isToolTipModalOpen = false
        }

        if
        (
            isExternal &&
            tagName !== "on-boarding-video-cross" &&
            !loadingPage &&
            !clickOnBoarding &&
            !isVideoModalOpen &&
            !clickOnEnvPopover &&
            !isToolTipModalOpen
        )
        {
            this.closeOnboardingModal()
        }
    }

    next(options={})
    {
        const {dispatch}   = this.props;
        let defaultOptions =
        {
            class       : "active",
            showToolTip : true,
            isCompleted : true
        }

        let next = 1
        if(options.hasOwnProperty("setCurrentStep") && options.setCurrentStep >= 0)
        {
            next = options.setCurrentStep
        }
        else
        {
            let activeStepIndex = this.props.on_boarding.activeStepIndex
            next = activeStepIndex + 1
        }

        if (next <= 5)
        {
            options = {...options, ...defaultOptions}
            if (next === 0 || next === 5)
            {
                dispatch(buildAction(ON_BOARDING.SET_LOADING, true));
            }

            this.onInputChange(next, options)
        }
        else
        {
            this.showHideOnBoarding()
            this.props.history.push("/dashboard")
            document.body.addEventListener("click", this.onBoardingPopoverExternalClick)
        }
    }

    onOpenCloseVideoModal(i)
    {
        const {dispatch} = this.props;
        let steps        = this.props.on_boarding.steps

        steps.forEach((step, i) => { step.showVideoModal = false})
        steps[i].showVideoModal = true
        let payload = {steps}
        dispatch(buildAction(ON_BOARDING.UPDATE_STEPS_VALUE, payload));

    }

    onCloseHandler(i)
    {
        const {dispatch}        = this.props;
        let steps               = this.props.on_boarding.steps
        steps[i].showVideoModal = false
        let payload             = {steps}
        dispatch(buildAction(ON_BOARDING.UPDATE_STEPS_VALUE, payload));
    }

    redirectToQuickStart()
    {
        const {dispatch}    = this.props;
        let steps           = this.props.on_boarding.steps
        let activeStepIndex = this.props.on_boarding.activeStepIndex

        steps[activeStepIndex].showToolTip    = false
        steps[activeStepIndex].showVideoModal = false

        let payload = {steps}
        dispatch(buildAction(ON_BOARDING.UPDATE_STEPS_VALUE, payload));
        window.open(privatePaths.docs.quickstart.pathname, '_blank');

        if(!Permissions.can("view", "sandbox", this.props.user.data))
        {
            dispatch(buildAction(ON_BOARDING.SHOW_HIDE_ON_BOARDING_NAV, false));
        }
    }

    switchLibrary(env=null, showQuickGuide=false)
    {
        if(!this.props.on_boarding.loading)
        {
            const {dispatch} = this.props;
            if (window.location.pathname === "/search")
            {
                dispatch(buildAction(SEARCH.RESET_STATE));
            }

            if(this.props.user.data.onboarding.displaySampleProduct)
            {
                this.getSampleProduct("drone")
                this.props.history.push('/dashboard')
            }
            else
            {
                let activeLibrary               = null
                let currentEnv                  = this.props.on_boarding.currentEnv
                if(!currentEnv) currentEnv      = this.props.user.data.currentEnv

                let companyLibraryNotification  = this.props.user.data.onboarding

                const {dispatch}           = this.props
                for(let lib of this.props.user.data.libraries)
                {
                    if((currentEnv === "SANDBOX" && lib.type === "GENERAL") || (currentEnv === "LIVE" && lib.type === "PERSONAL"))
                    {
                        if(lib.type === "GENERAL")
                        {
                            currentEnv = "LIVE"
                            if (companyLibraryNotification)
                            {
                                document.body.addEventListener("click", this.onBoardingPopoverExternalClick)
                            }
                        }
                        else if(lib.type === "PERSONAL")
                        {
                            currentEnv = "SANDBOX"
                            document.body.addEventListener("click", this.onBoardingPopoverExternalClick)
                        }

                        activeLibrary = lib
                        break
                    }
                }

                if(!activeLibrary) activeLibrary = this.props.user.data.activeLibrary
                let payload = {
                    activeLibrary: activeLibrary,
                    user: this.props.user,
                    currentEnv: currentEnv,
                    history: this.props.history,
                    showQuickGuide: showQuickGuide
                }

                if (currentEnv === "LIVE") this.showHideOnBoarding()
                dispatch(buildAction(USER.SWITCH_ENV, payload));
                dispatch(buildAction(ITEM_ASSEMBLY.RESET_ITEM_LIST, true));
                dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
            }
        }

    }

    closeEnvInfoModal(event)
    {
        event.stopPropagation();
        const { dispatch } = this.props
        if (this.props.on_boarding.isTutorialSkiped)
        {
            dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
        }
        else
        {
            if (this.props.on_boarding.currentEnv && this.props.on_boarding.currentEnv === "SANDBOX" && this.props.user.data.onboarding.completedSteps.length === 0)
            {
                    this.getAndSaveSampleProduct()
                    this.processStep1()
            }
            dispatch(buildAction(ON_BOARDING.SHOW_ENV_INFO, false));
        }
    }

    processStep1()
    {
        const {dispatch}     = this.props;
        let steps = [...this.props.on_boarding.steps]
        steps[0] = {
            ...steps[0],
            class: "active",
            showToolTip: true,
            isCompleted: true,
        }
        let payload = { steps, currentStep: steps[0].key }

        dispatch(buildAction(ON_BOARDING.SHOW_HIDE_ON_BOARDING_NAV, true));
        dispatch(buildAction(ON_BOARDING.UPDATE_STEPS_VALUE, payload));
    }

    renderEnvInfo()
    {
        let popoverClass = ''
        let markup      = null
        let bottomText = ''
        if(!this.props.ui.loading)
        {
            let popoverType         = this.props.on_boarding.currentEnv
            let status              = this.props.on_boarding.status
            let popoverHeading      = ""

            if(this.props.on_boarding.isTutorialSkiped)
            {
                popoverType = "SKIPPED"
            }

            else if(this.props.on_boarding.isOnBoardingNotification)
            {
                popoverType = "notify"
            }

            if(popoverType) popoverType = popoverType.toLowerCase()

            if(notifications[popoverType] && notifications[popoverType].type)
            {

                if (notifications[popoverType].type === "sandbox")
                {
                    popoverHeading = <div className="icon-holder"><InlineIcon><SandboxSrc/></InlineIcon><h1>{notifications[popoverType].h1}</h1></div>
                }
                else if (notifications[popoverType].type === "live")
                {
                    popoverHeading = <div className="icon-holder"><InlineIcon><CompanySrc/></InlineIcon><h1>{notifications[popoverType].h1}</h1></div>
                }
                else if (notifications[popoverType].type === "skipped" || notifications[popoverType].type === "completed")
                {
                    popoverHeading = <div className="icon-holder"><h1>{notifications[popoverType].h1}</h1></div>
                }
            }

            if(notifications[popoverType])
                {
                    popoverClass = notifications[popoverType].className || ''
                    bottomText = notifications[popoverType].bottomText
                }

            markup =
            <div className={popoverType}>
                {popoverHeading}
                <div className="body-container">
                    { (notifications[popoverType]) ? notifications[popoverType].p : ''}
                </div>
                { bottomText && <span className="do-not-show-agian" onClick={this.setNotShowAgainFlag}>{bottomText}</span>}

                {   popoverType !== "notify" ?
                    <button
                        className="got-it-btn"
                        onClick={ notifications[popoverType] && notifications[popoverType].type === "skipped" ? this.goThere : this.closeEnvInfoModal}
                    >
                    {notifications[popoverType] ? notifications[popoverType].buttonText : ''}
                    </button>: null
                }
            </div>
        }

        return {markup, popoverClass}
    }

    displayOnBoardingNav()
    {
        let { displayOnBoardingNav, currentEnv } = this.props.on_boarding
        currentEnv === "SANDBOX" ?
            document.body.addEventListener("click", this.onModalExternalClick)
        :
            document.body.removeEventListener("click", this.onModalExternalClick)
        return currentEnv === "SANDBOX"
    }

    onInviteModalClose()
    {
        if(document.getElementsByClassName('popover-tool-tip-container')[0])
        {
            document.getElementsByClassName('popover-tool-tip-container')[0].style.zIndex = "99"
        }
        const {dispatch} = this.props;
        dispatch(buildAction(ON_BOARDING.UPDATE_INVITE_MODAL_FLAG, false));
    }

    render()
    {
        if (this.props.on_boarding.innerLoading) return <Loading/>
        let { status }                                       = this.props.on_boarding
        let { steps }                                        = this.props.on_boarding
        let { currentEnv }                                   = this.props.on_boarding
        let showInviteModal                                  = this.props.on_boarding.showInviteModal
        let displayPopover                                   = this.props.on_boarding.showEnvInfoModal || this.props.on_boarding.isTutorialSkiped || this.props.on_boarding.isOnBoardingNotification
        displayPopover                                       = displayPopover && this.state.showFeaturePopup
        let {completedSteps}                                 = this.props.user.data.onboarding || []
        let {quickStartGuideNotification}                    = this.props.user.data.onboarding
        let hideFeaturePopOver                               = null
        if(this.props.on_boarding.isOnBoardingNotification)
        {
            hideFeaturePopOver = this.hideFeaturePopOver
        }
        if(!currentEnv) currentEnv = this.props.user.data.currentEnv

        let popoverType = currentEnv.toLowerCase()
        let canViewSandbox = Permissions.can("view", "sandbox", this.props.user.data)
        if (this.props.on_boarding.isOnBoardingNotification) popoverType = "notify"
        if (this.props.on_boarding.isTutorialSkiped) popoverType = "skipped"

        let envLabel = currentEnv === "LIVE" ? "Private Sandbox" : "Company Account"
        let actionsList =[
            {
                label: envLabel,
                icon: goToSandboxSrc,
                to: "#",
                onClick: this.switchLibrary
            },

            {
                label: "Quickstart Guide",
                icon: quickStartSrc,
                to: privatePaths.docs.quickstart,
                onClick: this.redirectToQuickStart
            }
        ]

        let shouldDisplayQuickGuide = quickStartGuideNotification && currentEnv === "LIVE"
        let envInfo = this.renderEnvInfo()
        let envInfoMarkup = envInfo.markup
        // let envInfoPopoverClass = envInfo.popoverClass


        let avatarSrc = this.props.user && this.props.user.data && this.props.user.data.avatar && this.props.user.data.avatar.src


        let avatarOptions = []

        let user_name = this.props.user.data.firstName + " " + this.props.user.data.lastName
        avatarOptions.push( this.createMenuOption([<div className="user_name_div" key="user_name">{user_name}</div>, <div className="user_email_div" key="user_email">{this.props.user.data.email}</div>], null, {pathname: '/settings/user'}, 'border-bottom', true, false))
        avatarOptions.push({ isDivider: true });
        if(this.props.user.data.currentEnv === "LIVE")
        {
            let settingOPtion = this.createMenuOption("Account settings", SettingsIcon, privatePaths.companySettingsUsers, 'border-bottom', true)
            avatarOptions.push(settingOPtion)
            avatarOptions.push({ isDivider: true });

            if (canViewSandbox)
            {
                let sandbox_option = { label: "Sandbox & tutorials", icon: SwitchLibraryIcon, to: '#', state: '#', onClick: this.switchLibrary }
                avatarOptions.push(sandbox_option)
            }
        }
        else
        {
            let inviteUserOption = { label: "Invite users", icon: InviteUsersIcon, to: '#', onClick: this.inviteUser }
            avatarOptions.push(inviteUserOption)
            avatarOptions.push({ isDivider: true });

            if (canViewSandbox)
            {
                let company_account_option = { label: "Company account", icon: SwitchLibraryIcon, to: '#', state: '#', onClick: this.switchLibrary }
                avatarOptions.push(company_account_option)
            }
        }

        let help_and_document_option = { label: "Help & documentation", icon: HelpDocumentationIcon, to: publicPaths.docs.documentation.pathname, target: "_blank" }
        avatarOptions.push(help_and_document_option)

        let submit_ticket_option = { label: "Submit a ticket", icon: SubmitTicketIcon, to: privatePaths.submit_ticket.pathname, target: "_blank" }
        avatarOptions.push(submit_ticket_option)
        avatarOptions.push({ isDivider: true });

        let integrationOption = { label: "Integrations", icon: IntegrationsIcon, to: privatePaths.integrationsSetting.pathname }
        avatarOptions.push(integrationOption)
        avatarOptions.push({ isDivider: true });

        let policy_option = { label: "Policy", icon: PolicyIcon, to: publicPaths.legal.policy.pathname, target: "_blank" }
        avatarOptions.push(policy_option)

        let signOutOptions = { label: "Sign out", icon: SignOutIcon, to: privatePaths.signOut.pathname, state: privatePaths.signOut.state, isSignOut: true }
        avatarOptions.push(signOutOptions)

        const avatar = avatarSrc
            ? <Avatar alt="User" src={avatarSrc} />
            : <InlineIcon><AvatarIcon /></InlineIcon>

        let markup =
            <div className="on-boarding-block" ref="onBoardingModalNode">
                <div className="icon-holder">
                    {
                        <div className="navigation">
                            <DuroMenu
                                anchorComponent={avatar}
                                name="onboarding"
                                options={avatarOptions}
                            />
                        </div>
                    }


                    {/*{
                        displayPopover && canViewSandbox ? <Popover contentData={envInfoMarkup} className={"env-info-popover " + envInfoPopoverClass} padding={0} position="bottom" arrowColor={notifications[popoverType].fill} arrowSize={10}/> : null
                    }*/}
                    {
                        displayPopover && canViewSandbox ? <Popover hideFeaturePopOver={hideFeaturePopOver} contentData={envInfoMarkup} className={"env-info-popover"} padding={0} position="bottom" arrowColor={notifications[popoverType].fill} arrowSize={10}/> : null
                    }
                </div>
                {
                    this.displayOnBoardingNav() ?
                        <OnBoardingList
                            steps={steps}
                            onInputChange={this.next}
                            gotoInviteUser={this.gotoInviteUser}
                            onOpenCloseVideoModal={this.onOpenCloseVideoModal}
                            redirectToQuickStart={this.redirectToQuickStart}
                            switchLibrary={this.switchLibrary}
                            currentEnv={currentEnv}
                            next={this.next}
                            onCloseHandler={this.onCloseHandler}
                            user={this.props.user}
                            onInviteModalClose={this.onInviteModalClose}
                            history={this.props.history}
                            showInviteModal={showInviteModal}
                            ui={this.props.ui}
                            canViewSandbox={canViewSandbox}
                            completedSteps={completedSteps}
                        />
                        :
                        null
                }

                {
                    showInviteModal ?
                    <div className="user-add-edit-modal">
                        <ModalBox
                            onClose={this.onInviteModalClose}
                            history={this.props.history}
                            className='new-user-modal'
                        >
                            <UserFormView
                                props={this.props.props}
                                history={this.props.history}
                                onCancel={this.onInviteModalClose}
                                mode={"new"}
                                fromSandbox={true}
                            />
                        </ModalBox>

                    </div> : ""
                }

            </div>
        return markup
    }
}

// export default connect((store) => store)(OnBoarding)


export default connect((store) => ({
    ui: store.ui,
    user: store.user,
    on_boarding: store.on_boarding
}))(OnBoarding)
