import base  from "./data/default.json"
import merge from "merge"

let loadData = (file) =>
{
    try
    {
        return require("./data/" + file + ".json");
    }
    catch(err)
    {
        return {};
    }
}

let ENV    = window.ENV || "local"
let config = merge.clone(base)
let data   = loadData(ENV)

config                = merge.recursive(true, config, data)
config.ENV            = ENV
config.VERSION        = process.env.npm_package_version
config.MAX_FILE_SIZE *= 1000000
window.config         = config

// Override the configurations of the React app if the those configurations exist
// in the window object. The host server injects the values.
// See /server/modules/config/index.js and /server/routes/index.js
config.API_ORIGIN = window.API_ORIGIN || config.API_ORIGIN;
config.BASE_URL = window.BASE_URL || config.BASE_URL;
config.ASSETS_ORIGIN = window.ASSETS_ORIGIN || config.ASSETS_ORIGIN;
config.RECORD_ERRORS = window.RECORD_ERRORS || config.RECORD_ERRORS;
config.REACT_APP_AUTH_URL = window.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_URL || config.REACT_APP_AUTH_URL
config.REACT_APP_GRAPHQL_URL = window.REACT_APP_GRAPHQL_URL || process.env.REACT_APP_GRAPHQL_URL || config.REACT_APP_GRAPHQL_URL
window.config = config

export default config
