import { ApolloClient, FetchPolicy, gql, NormalizedCacheObject, useQuery } from "@apollo/client";
import { Company } from "common/models/company";
import { CompanyFragment } from "graphql/fragment/companyFragment";
import { useMemo } from "react";
import { getSubErrors } from "v1/modules/utils/errors";

export const GET_COMPANY = gql`
  query($id: ID) {
    companyById(id: $id) {
      ...companyFragment
    }
  }
  ${CompanyFragment}
`;

export async function getCompany(
  client: ApolloClient<NormalizedCacheObject>,
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors, loading } = await client.query({
      query: GET_COMPANY,
      variables: { id },
      fetchPolicy,
    });
    const company = data?.companyById ?? {};
    return { data: company, errors, loading };
  }
  catch (e: any) {
    return {
      data: null,
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

export function useCompany(
  id: string | null = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading } = useQuery<{ companyById: Company }>(GET_COMPANY, {
    variables: { id },
    fetchPolicy,
    skip: !id,
  });

  const company = useMemo(() => data?.companyById, [data]);

  return { data: company, error, loading };
}
