import                                         "./index.css"
import React, {Component, lazy, Suspense} from "react"
import Icon                               from "../../../../../../ui/icon"
import Loading                            from "../../../../../../ui/inner-loading"
import Schemas                            from "../../../../../../../modules/schemas"
import API                                from "../../../../../../../modules/api"
import Utils                              from "../../../../../../../modules/utils"
import flagSrc                            from "../../../../../../../assets/icons/flag.svg"
import checkSrc                           from "../../../../../../../assets/icons/check-icon.svg"
import * as Constants                     from "../../../../../../../modules/constants"
import AssemblyScehma                     from "../../../../../../../../v1/modules/schemas/assembly"
import ModalBox                           from "../../../../../../ui/modal-box"
import ToggleBtn                          from "../../../../../../ui/toggle-btn"
import ProgressBar                        from "../../../../../../ui/progress-bar"
import validations, { validateField, schemas }     from "../../../../../../../modules/validations"
import ImportUtils                        from "../utils"
import Categories                         from "./modules/categories"
import UnitOfMeasures                     from "./modules/unit-of-measures"
import InputField                         from "./modules/input-field"
import { Prompt }                         from 'react-router'
import WarningModal                       from "../../../../../../ui/warning-modal"
import TableData                          from "./Table.js"
import Spinner                            from "../../../../../../../assets/icons/spinner-dark.js"
import InlineIcon                         from "../../../../../../ui/icon/inline-icon.js"
import $                                  from "jquery";
import TableIcons                         from "../../../../../common/extended-table/table-icons";
import SourcingUtils                      from "../../../../../common/sourcing/sourcing-utils"

class ReviewScreen extends Component
{
    constructor(props)
    {
        super(props)

        this.onInputChange                   = this.onInputChange.bind(this)
        this.getInputCount                   = this.getInputCount.bind(this)
        this.validate                        = this.validate.bind(this)
        this.updateBanner                    = this.updateBanner.bind(this)
        this.compareQuantityAndRefDes        = this.compareQuantityAndRefDes.bind(this)
        this.getValue                        = this.getValue.bind(this)
        this.getSpreadsheetRowValue          = this.getSpreadsheetRowValue.bind(this)
        this.proceed                         = this.proceed.bind(this)
        this.handleCreateAndUpdate           = this.handleCreateAndUpdate.bind(this)
        this.createAll                       = this.createAll.bind(this)
        this.updateAll                       = this.updateAll.bind(this)
        this.addToAssembly                   = this.addToAssembly.bind(this)
        this.convertChildren                 = this.convertChildren.bind(this)
        this.createComponent                 = this.createComponent.bind(this)
        this.parseSpecs                      = this.parseSpecs.bind(this)
        this.getSpecs                        = this.getSpecs.bind(this)
        this.parseImages                     = this.parseImages.bind(this)
        this.parseDocuments                  = this.parseDocuments.bind(this)
        this.parseChildren                   = this.parseChildren.bind(this)
        this.backButtonClick                 = this.backButtonClick.bind(this)
        this.onModalClose                    = this.onModalClose.bind(this)
        this.haveFieldName                   = this.haveFieldName.bind(this)
        this.getCategories                   = this.getCategories.bind(this)
        this.assignCategoryToInputs          = this.assignCategoryToInputs.bind(this)
        this.isRowDisabled                   = this.isRowDisabled.bind(this)
        this.toggleRowState                  = this.toggleRowState.bind(this)
        this.disableAllInputs                = this.disableAllInputs.bind(this)
        this.toggleButtonSelectedStateForAll = this.toggleButtonSelectedStateForAll.bind(this)
        this.getToggleClassForAll            = this.getToggleClassForAll.bind(this)
        this.checkThisRow                    = this.checkThisRow.bind(this)
        this.checkAllRows                    = this.checkAllRows.bind(this)
        this.checkAllState                   = this.checkAllState.bind(this)
        this.createCategoryTdMarkup          = this.createCategoryTdMarkup.bind(this)
        this.createUnitOfMeasureTdMarkup     = this.createUnitOfMeasureTdMarkup.bind(this)
        // this.getEnabledRowsLength            = this.getEnabledRowsLength.bind(this)
        this.getBulkSelectValue              = this.getBulkSelectValue.bind(this)
        this.isValidRow                      = this.isValidRow.bind(this)
        this.setScrollHight                  = this.setScrollHight.bind(this)
        this.addComponentsToAssembly         = this.addComponentsToAssembly.bind(this)
        this.haveQuotesInformation           = this.haveQuotesInformation.bind(this)
        this.haveDistributorInformation      = this.haveDistributorInformation.bind(this)
        this.haveManufacturerInformation     = this.haveManufacturerInformation.bind(this)
        this.onTableScroll                   = this.onTableScroll.bind(this)
        this.setPagination                   = this.setPagination.bind(this)
        this.validateMpn                     = this.validateMpn.bind(this)
        this.mergeData                       = this.mergeData.bind(this)
        this.createChildrens                 = this.createChildrens.bind(this)
        this.convertArrayToString            = this.convertArrayToString.bind(this)
        this.onMappAllToggleChange           = this.onMappAllToggleChange.bind(this)
        this.enableAllInputs                 = this.enableAllInputs.bind(this)
        this.initCategories                  = this.initCategories.bind(this)
        this.validateRefDesDuplication       = this.validateRefDesDuplication.bind(this)
        this.validateItemNumberDuplication   = this.validateItemNumberDuplication.bind(this)
        this.getMpnDuplicateCount            = this.getMpnDuplicateCount.bind(this)
        this.showModal                       = this.showModal.bind(this)
        this.closeModal                      = this.closeModal.bind(this)
        this.handleBlockedNavigation         = this.handleBlockedNavigation.bind(this)
        this.handleConfirmNavigationClick    = this.handleConfirmNavigationClick.bind(this)
        this.setMyState                      = this.setMyState.bind(this)
        this.selectFromLibrary               = this.selectFromLibrary.bind(this)
        this.onMouseDown                     = this.onMouseDown.bind(this)
        this.onThDoubleClick                 = this.onThDoubleClick.bind(this)
        this.onMouseLeave                    = this.onMouseLeave.bind(this)
        this.performNoEvent                  = this.performNoEvent.bind(this)
        this.setMaxWidthForColumns           = this.setMaxWidthForColumns.bind(this)
        this.autoFitColumnsWidth             = this.autoFitColumnsWidth.bind(this)
        this.setColumnWidth                  = this.setColumnWidth.bind(this)
        this.validateInputs                  = this.validateInputs.bind(this);
        this.validateMpnDuplication          = this.validateMpnDuplication.bind(this);
        this.validateSourcingTree            = this.validateSourcingTree.bind(this);
        this.setSourcingFields               = this.setSourcingFields.bind(this);

        let styles;
        try
        {
            this.tableSettings = window.__userStyles.styles.assemblyTable || {}
            // importTable
            // assemblyTable
        }
        catch(error){
            this.tableSettings = {}
        }

        this.state =
        {
            paginationSize      : 50,
            pagination          : 50,
            idz                 : [],
            newlyCreatedOrUpdatedComponents : [],
            useExistingComponents : [],
            percentage          : 0,
            progressBarText     : "PROCESSING DATA",
            showProgressBar     : false,
            hasValidated        : false,
            validating          : true,
            loading             : false,
            data                : Utils.clone(this.props.data),
            headings            : [],
            inputs              : [],
            cells               : 0,
            checkedRows         : [],
            page                : 0,
            chunkSizeforBulk    : 300,
            banner              :
            {
                class    : "",
                icon     : "",
                copy     : "",
                button   :
                {
                    copy     : "continue",
                    onClick  : null
                }
            },
            requredInputs: ["name"],
            requiredFields: [],
            enabledRowsLength: 0,
            timeOutID: 0,
            categories: null,
            unitOfMeasures: null,
            manufacturersArr :
            [
                "manufacturer",
                "mpn",
                "mfr description",
                "datasheet",
                "dpn",
                "distributor",
                "dist description",
                "package",
                "package quantity"
            ],
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            shouldBlockNavigation: true
        }
    }

    autoFitColumnsWidth()
    {
        let stateHeadings = this.state.data.headings
        let {inputs} = this.state
        this.setMaxWidthForColumns(inputs)
        stateHeadings.forEach((heading, index) =>
        {
            this.setColumnWidth(inputs[0], heading)
        })
    }

    setColumnWidth(row, heading)
    {
        let tableSelector = ".table-headings thead"
        let input = this.getSpreadsheetRowValue(row, heading)
        let colHeading = document.querySelector(tableSelector).querySelector("th[name='"+heading+"']")
        colHeading.style.width = input.width + "px"
        let colTds = document.querySelector(".table-content tbody").querySelector("td[name='"+heading+"']")
        colTds.style.width = input.width + "px"
    }

    onMouseDown(e, index) {
        let state = this.state
        window._currentTarget = e.target
        window.initial_pageX = e.pageX

        let th = e.target.closest('th')
        let cellIndex = th.cellIndex


        let tdSelector = ".review-screen .table-content-wrapper table tbody tr:first-child td:nth-child("+(cellIndex+1)+")"

        let td = document.querySelector(tdSelector)
        window._currentTd = td

        // window._currentCellIndex = cellIndex + 1
        // var cssMainContainer = $('#css-modifier-container');
        // if (cssMainContainer.length == 0) {
        //     var cssMainContainer = $('<div id="css-modifier-container"></div>');
        //     cssMainContainer.hide();
        //     cssMainContainer.appendTo($('body'));
        // }

        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e)
    {
        let th = window._currentTarget.closest('th')
        // let cellIndex = th.cellIndex
        // let tdSelector = ".review-screen .table-content-wrapper table tbody tr:first-child td:nth-child("+(cellIndex+1)+")"

        // let td = document.querySelector(tdSelector)

        let th_minWidth = parseInt(th.style.minWidth)
        let diff = Math.abs(window.initial_pageX - e.pageX)

        let currentWidth = parseInt(window._currentTarget.closest('th').style.width)
        if (window.initial_pageX > e.pageX)
        {
            currentWidth -= diff
        }
        else if (window.initial_pageX < e.pageX)
        {
            currentWidth += diff
        }

        if (currentWidth < th_minWidth)
        {
            currentWidth = th_minWidth
        }

        // window._currentCellIndex
        let appliedWidth = currentWidth + "px"

        // let style;
        // style  = " .review-screen .table-content-wrapper table tbody tr:first-child td:nth-child("+window._currentCellIndex+")"
        // style += "{ min-width: " + appliedWidth + " !important; width: " + appliedWidth + " !important;}"
        // style += " .review-screen table thead th:nth-child("+window._currentCellIndex+")"
        // style += "{ min-width: " + appliedWidth + " !important; width: " + appliedWidth + " !important;} "
        // $('#css-modifier-container').html('<style type="text/css">' + style + '</style>')

        window._currentTd.style.width = currentWidth + "px"
        th.style.width = appliedWidth
        window.initial_pageX = e.pageX

    }

    onMouseLeave(e)
    {
        let state = this.state
        state.hoveredColIndex = null
        state.hoveredColName = ""
        window.initial_pageX = null
        this.setMyState(state, this.saveStyles)
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        window._currentTarget = undefined
        e.stopPropagation()
        e.preventDefault()
    }


    onThDoubleClick(event)
    {
        event.preventDefault()
        event.stopPropagation()
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);

        let {inputs} = this.state
        this.setMaxWidthForColumns(inputs)

        let target      = event.target
        if ( target.tagName !== "TH" )
            target       = event.target.closest("th")
        let columnName   = target.getAttribute("name")
        this.setColumnWidth(inputs[0], columnName)
    }

    addExpandHoverClass(e)
    {
        let element = e.target
        element.closest("th").classList.add("expand-me")
    }

    removeExpandHoverClass(e)
    {
        let element = e.target
        element.closest("th").classList.remove("expand-me")
    }

    performNoEvent(e){
        e.stopPropagation()
        e.preventDefault()
    }

    setMyState(state, cb)
    {
        if (cb)
        {
            this.setState(state, cb)
        }
        else
        {
            this.setState(state)
        }
    }

    showModal(location)
    {
        this.setMyState({modalVisible: true, lastLocation: location})
    }

    closeModal(callback, isSkip=false)
    {
        if (isSkip)
        {
           this.setMyState({modalVisible: false})
        }
        else
        {
            this.setMyState({modalVisible: false}, callback)
        }
    }

    handleConfirmNavigationClick()
    {
        this.closeModal(() =>
        {
            const {lastLocation} = this.state
            if (lastLocation)
            {
                this.setMyState({confirmedNavigation: true, shouldBlockNavigation: false}, () => this.props.history.push({pathname: lastLocation.pathname, state: lastLocation.state}))
            }
        })
    }

    handleBlockedNavigation(nextLocation)
    {
        const {confirmedNavigation} = this.state
        const {shouldBlockNavigation} = this.state
        if (!confirmedNavigation && shouldBlockNavigation)
        {
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    onTableScroll(e)
    {
        let header_elmnt = document.querySelector(".table-headings > table");
        header_elmnt.style.marginLeft = "100px"
        let t = document.querySelector(".table-content");
        header_elmnt.style.marginLeft = "-" + t.scrollLeft + "px"
    }

    setPagination(e)
    {
        var el = e.target;
        let sub_height = el.scrollHeight - el.scrollTop;
        if (sub_height <= el.clientHeight + 20)
        {
            this.setMyState({ pagination: this.state.pagination + this.state.paginationSize });
        }
    }

    setScrollHight()
    {
      let height = (document.getElementsByClassName("modal")[0].clientHeight - 195) + 'px'
      document.getElementsByClassName("table-content-wrapper")[0].style.height = height
    }

    initCategories()
    {
        let type  = this.props.type
        let cats  = Schemas.component.category.list()
        let listA = []
        let listB = []

        cats.forEach((category) =>
        {
            // if (category.type !== Constants.mechanical &&
            //     category.type !== Constants.electrical &&
            //     category.type !== Constants.assembly &&
            //     category.type !== Constants.document &&
            //     category.type !== Constants.software)
            // {
            //     return
            // }
            category.type === type ? listA.push(category) : listB.push(category)
        })

        if(listA.length > 0)
        {
            listA.push(<option></option>)
        }

        return {options: Utils.toOptions([...listA, ...listB]), list: [...listA, ...listB]}
    }

    componentDidMount()
    {

        let runBulk = true
        let validateNameUsingBulk = runBulk
        let validateEidUsingBulk = runBulk
        if(!this.state.data.headings.includes("eid"))
        {
            validateEidUsingBulk = false
        }
        if(!this.state.data.headings.includes("name") || this.props.fileType === "update")
        {
            validateNameUsingBulk = false
        }
        // if(this.props.mpnImportDialog.flag && this.state.data.headings.includes("mpn"))
        if(this.state.data.headings.includes("mpn"))
        {
            this.validate(this.state, [], null, false, true, "required", validateNameUsingBulk, validateEidUsingBulk);
        }
        else
        {
            this.validate(this.state, [], null, false, false,  "required", validateNameUsingBulk, validateEidUsingBulk);
        }
        window.scrollTo(window.scrollX, 0)
        this.setScrollHight()
    }

    componentDidUpdate(prevProps, prevState)
    {
        // TODO: these are here for purpose to check that event.
        if(this.state !== prevState)
        {
            // TODO: these are here for purpose to check that event.
            if (!this.state.modalVisible) this.setScrollHight()
        }

        if (this.state.shouldBlockNavigation)
        {
            window.onbeforeunload = () => true
        }
        else
        {
            window.onbeforeunload = undefined
        }
    }

    componentWillUnmount()
    {
        window.onbeforeunload = undefined
    }

    validateMpn(row, skipOctopart, cb, isMpnField)
    {
        let cat = this.getSpreadsheetRowValue(row, "category").value
        let mpn = this.getSpreadsheetRowValue(row, "mpn")
        let cpn;
        if(!cat)
        {
            cpn = this.getSpreadsheetRowValue(row, "cpn").value
            if(cpn)
            {
                let category = Schemas.categories.findByCPN(cpn);
                if(category)
                {
                    cat = category.name
                }
            }
        }

        let cat_type = Schemas.component.category.getType(cat)
        let defaultData =
        {
          status : "",
          type : "validating",
          data : ""
        }
        let getDataFromVendor = this.props.mpnImportDialog.flag ? true : false
        if (cat_type && cat_type === "ELECTRICAL")
        {
          if (skipOctopart)
          {
            cb(defaultData)
          }
          else
          {
            let data = { data: [ {category: cat, mpn: mpn.value, rowInfo: mpn, cpn: cpn} ] };
            data.getDataFromVendor = getDataFromVendor

            Schemas.component.mpn.validateOctopart(data, (res) =>
            {
                mpn.cmpData = "";
                if(!data) {
                  cb(defaultData)
                }
                else
                {
                    let response = res.data[0];
                    if(response && response.type && response.type === 'Warning' && response.status === 'MPN not found')
                    {
                        mpn.prevSku = response.rowInfo && response.rowInfo.value;
                    }
                    cb(response);
                }
            })

          }
        }
        else
        {

          let data = {category: cat, mpn: mpn.value, dataFromMcmaster: mpn.cmpData, isMpnField, cpn: cpn}
          data.getDataFromVendor = getDataFromVendor
          Schemas.component.mpn.validate(data, (res) =>
          {
              cb(res.data)
          })
        }

    }

    mergeData(inputs, cmpFromWeb, cmpFromSheet, oldComponent=null)
    {
        return ImportUtils.mergeData(inputs, cmpFromWeb, cmpFromSheet, oldComponent, this.props.fileType == "update")
    }

    assignCategoryToInputs(e){
        let value = e.target.value
        let state = this.state
        let rows = state.inputs
        let item_index = 0
        let validateEidUsingBulk = true
        let validateNameUsingBulk = true
        for(let r = item_index; r < state.inputs.length; r++) {
          if (r < (0 + rows.length))
          {
              if (state.checkedRows.indexOf(r) >= 0)
              {
                  let inputIndex = rows[r].columns.indexOf("category")
                  let input = rows[r].data[inputIndex]

                  if(input)
                  {
                      let categoryObj = schemas.categories.findByName2(value, window.__categories);
                      if(Utils.isNonIntelligentCPNScheme())
                      {
                        input.displayName = value
                      }
                      else if(categoryObj)
                      {
                        input.displayName = categoryObj.displayNameWithPrefix
                      }
                      input.value = value
                      input.valid = input.value ? true : false
                      input.class = input.valid ? '' : 'invalid'
                  }
              }
          }
        }
        if(!this.state.data.headings.includes('eid'))
        {
            validateEidUsingBulk = false
        }
        if(!this.state.data.headings.includes('name') || this.props.fileType === "update")
        {
            validateNameUsingBulk = false
        }
        this.validate(state, ["category"], null, false, true, "required", validateNameUsingBulk, validateEidUsingBulk)
    }

    getCategories()
    {
        return {options: this.state.categories.options, list: this.state.categories.list}
    }

    componentWillMount()
    {
        let data              = this.state.data
        let inputs            = this.state.inputs
        let cells             = 0
        this.state.categories = this.initCategories()
        this.state.unitOfMeasures = Schemas.component.unitOfMeasure.list()

        data.rows.forEach((row, r) =>
        {
            let rowData = []
            let columns = []
            row.forEach((value, c) =>
            {
                cells++
                let input;
                value = (value === undefined || value === null) ? "" : value
                let inputValue = String(value)
                let originalValue = inputValue
                if ( data.headings[c].toLowerCase() === "category" ){
                    inputValue = Utils.capitalizeString(Utils.removeExtraSpaces(inputValue).replace(/-/g, " ").toLowerCase())
                    let selectOptionState = Utils.havePropertyWithValue(this.getCategories().list, "name", inputValue, {removeHyphen: true} )
                    let haveValue = selectOptionState.found
                    inputValue =  selectOptionState.selectedObj.name
                    inputValue = haveValue ? inputValue : originalValue
                    inputValue = Schemas.categories.normalizeCategory(inputValue)
                    input =
                    {
                        row     : r,
                        col     : c,
                        value   : inputValue,
                        defaultOption: (haveValue ? "" : originalValue),
                        class   : "",
                        disabled: false,
                        valid   : true,
                        message : "",
                        name    : data.headings[c],
                        minWidth: ImportUtils.getDefaultMinWidthOfColumn(data.headings[c])
                    }
                }
                else if ( data.headings[c].toLowerCase() === "unit of measure" ){
                    inputValue = Utils.capitalizeString(Utils.removeExtraSpaces(inputValue).toLowerCase())
                    let selectOptionState = Utils.havePropertyWithValue(this.state.unitOfMeasures, "displayName", inputValue )
                    let haveValue = selectOptionState.found
                    inputValue =  selectOptionState.selectedObj.displayName
                    inputValue = haveValue ? inputValue : originalValue
                    input =
                    {
                        row     : r,
                        col     : c,
                        value   : inputValue,
                        defaultOption: (haveValue ? "" : originalValue),
                        class   : "",
                        disabled: false,
                        valid   : true,
                        message : "",
                        name    : data.headings[c],
                        minWidth: ImportUtils.getDefaultMinWidthOfColumn(data.headings[c])
                    }
                }
                else
                {
                    input =
                    {
                        row     : r,
                        col     : c,
                        value   : inputValue,
                        class   : "",
                        disabled: false,
                        valid   : true,
                        message : "",
                        name    : data.headings[c],
                        minWidth: ImportUtils.getDefaultMinWidthOfColumn(data.headings[c])
                    }

                }

                if (input.name === 'ref des')
                {
                    // eslint-disable-next-line no-control-regex
                    input.value = input.value.replace(/[^\x00-\x7F]/g, ", ");
                }

                columns.push(input.name)
                rowData.push(input)
            })

            // if no category provided add new input with category type in rows
            if (["new", "new_assembly"].includes(this.props.fileType) && !this.state.data.headings.includes("category")){
                cells++
                let input =
                {
                    row     : r,
                    col     : row.count + 1,
                    value   : "",
                    class   : "",
                    disabled: false,
                    valid   : false,
                    message : "",
                    name    : "category",
                    minWidth: ImportUtils.getDefaultMinWidthOfColumn("category")
                }
                columns.push(input.name)
                rowData.push(input)
            }
            inputs.push({disabled: false, columns: columns, data: rowData})
        })

        let headings = []
        if(!["new", "new_assembly"].includes(this.props.fileType ) && data.headings.includes("cpn"))
        {
            headings.push("cpn")
        }
        else
        {
            headings.push("category")
            data.headings.forEach( (heading) =>
            {
                if(heading.toLowerCase() === "quantity")
                {
                    if(this.props.fileType === "new_assembly")
                    {
                        headings.push(heading.toLowerCase())
                    }
                }
                else if(heading.toLowerCase() === "item number" && Utils.isItemNumber(this.props.currentlyOpenedCategory) && !window.__isAllowedBlankItemNumber)
                {
                    if(this.props.fileType === "new_assembly")
                    {
                        headings.push(heading.toLowerCase())
                    }
                }
                else if(this.state.requredInputs.includes(heading.toLowerCase()))
                {
                    headings.push(heading.toLowerCase())
                }
            })
        }

        // if no category provided add new heading category name in headings
        if (["new", "new_assembly"].includes(this.props.fileType) && !this.state.data.headings.includes("category")){
            this.state.data.headings.push("category")
        }
        this.state.requiredFields = headings
        this.state.cells = cells
        this.state.enabledRowsLength = inputs.length

        this.setMaxWidthForColumns(inputs)
        this.setMyState(this.state)
    }

    setMaxWidthForColumns(inputs)
    {
        let rowsCount = inputs.length
        let columnsCount = inputs[0].data.length

        for(let i=0; i < columnsCount; i++)
        {
            let columnMaxWidth = 0
            for(let j=0; j < rowsCount; j++)
            {
                let input = inputs[j].data[i]
                let fontSize = Utils.getComputedStyleOfElem(`td[name="${input.name}"]`, "font-size")

                let inputTxtSpan = document.createElement("SPAN");
                if(fontSize) inputTxtSpan.style.fontSize = fontSize;

                if (input.name === "status")
                {
                    input.value = input.value.toUpperCase()
                }
                let displayedValue = input.displayName || input.value;
                let inputTxt = document.createTextNode(displayedValue)
                inputTxtSpan.setAttribute('class', 'span-text-content');
                inputTxtSpan.appendChild(inputTxt)
                document.body.appendChild(inputTxtSpan)
                if (columnMaxWidth < inputTxtSpan.offsetWidth)
                {
                    let finalWidth = inputTxtSpan.offsetWidth + 55
                    if (finalWidth > 650)
                    {
                        finalWidth = 650
                    }
                    columnMaxWidth = finalWidth
                }
                inputTxtSpan.remove()
            }

            for(let j=0; j < rowsCount; j++)
            {
                let input = inputs[j].data[i]
                if (columnMaxWidth > input.minWidth)
                {
                    input.width = columnMaxWidth
                }
                else
                {
                    input.width = input.minWidth
                }
            }
        }
    }

    haveFieldName(name){
        let haveField = false
        for(let i = 0; i < this.state.inputs.length; i++)
        {
            let input = this.state.inputs[i]
            if(input.value === name)
            {
                haveField = true
                break;
            }
        }
        return haveField
    }

    onInputChange(value, row, col)
    {
        let state   = this.state
        let input   = state.inputs[row].data[col]
        input.value = value
        let options = [input.name]

        if(input.name === "cpn")
        {
            options = ["cpn", "name", "eid", "status", "revision"]
        }

        if(input.name === "quantity" || input.name === "ref des")
        {
            options = ["quantity", "ref des"]
        }

        if(input.name === "status")
        {
            options = ["status", "revision"]
        }
        let validateNameUsingBulk = false
        let validateEidUsingBulk = false
        state.validating = true
        this.validate(state, options, row, state.manufacturersArr.includes(input.name), false, "required", validateNameUsingBulk, validateEidUsingBulk)
    }

    getBulkSelectValue(columnName){
        let state = this.state
        let rows = state.inputs
        let item_index = 0
        let selectedValues = []

        for(let r = item_index; r < state.inputs.length; r++){

            if (r < (0 + rows.length))
            {
                if (state.checkedRows.indexOf(r) >= 0)
                {
                    let inputs = state.inputs[r]

                    let inputIndex = inputs.columns.indexOf(columnName)
                    let input = inputs.data[inputIndex]
                    selectedValues.push( input.value )
                }
            }
        }
        let allEqual = selectedValues.every( v => v === selectedValues[0] )

        if (selectedValues.length === 0)
            return {value: "", class: "disabled select-disable" }
        else if (!allEqual && selectedValues.length > 0)
            return {value: "", class: "" }
        else if (allEqual && selectedValues.length > 0)
            return {value: selectedValues[0], class: "" }
        else
            return {value: "", class: "" }
    }

    toggleRowState(event, rowIndex)
    {
        let state = this.state
        let row   = state.inputs[rowIndex]
        let options = ["name", "eid", "ref des", "cpn"];


        if(row.disabled)
        {
            row.disabled = false
            state.enabledRowsLength = state.enabledRowsLength + 1
        }
        else
        {
            row.disabled = true
            state.enabledRowsLength = state.enabledRowsLength - 1
            if(state.checkedRows.includes(rowIndex))
            {
                state.checkedRows.splice(state.checkedRows.indexOf(rowIndex), 1)
            }
        }
        // this.setMyState(state)

        if(this.state.data.headings.includes("mpn"))
        {
            this.validate(state, options, row, state.manufacturersArr.includes("mpn"), false, "required", false, false)
        }
        else
        {
            this.validate(state, options, rowIndex, false, false, "not_required", false, false, true);
        }
    }

    selectFromLibrary(event, rowIndex, inputName, selectAll=false)
    {
        let state = this.state;
        let row   = state.inputs[rowIndex];
        let options = ["name", "eid"];
        let columnIndex = row.columns.indexOf(inputName);
        let inputs = state.inputs;
        let duplicateFields = ['name', 'eid', 'mpn'];
        if(window.__currentCompanyCpnType === "FREE-FORM")
            duplicateFields.push("cpn");

        if(row.selectedFromLibrary)
        {
            row.selectedFromLibrary = false;
        }
        else
        {
            row.selectedFromLibrary = true;
            if(selectAll && duplicateFields.includes(inputName)){
                inputs.forEach((row, rowIndex) =>
                {
                    if(!row.disabled)
                    {
                        let input = row.data[columnIndex];
                        if(input.name === inputName && input[inputName + 'DuplicateOf'])
                        {
                            row.selectedFromLibrary = true;
                            row.selectedCmpFromLibrary = this.getSpreadsheetRowValue(row, inputName)[inputName + 'DuplicateOf'];
                            if(state.checkedRows.includes(rowIndex))
                            {
                                state.checkedRows.splice(state.checkedRows.indexOf(rowIndex), 1);
                            }
                        }
                    }
                })
            }
            else
            {
                if (inputName && duplicateFields.includes(inputName))
                {
                    row.selectedCmpFromLibrary = this.getSpreadsheetRowValue(row, inputName)[inputName + 'DuplicateOf'];
                }
                if(state.checkedRows.includes(rowIndex))
                {
                    state.checkedRows.splice(state.checkedRows.indexOf(rowIndex), 1);
                }
            }
        }

        if(this.state.data.headings.includes("mpn"))
        {
            this.validate(state, options, row, state.manufacturersArr.includes("mpn"), false, "required", false, false)
        }
        else
        {
            this.validate(this.state, options, rowIndex, false, false, "not_required", false, false)
        }
    }

    getInputCount(value, inputName) {
        let inputs = this.state.inputs;
        const {fileType} = this.props;
        const message = `Duplicate ${inputName} found`;
        return inputs.reduce((count, current) => {
            if(!current.disabled) {
            let input = current.data[current.columns.indexOf(inputName)];
            const considerCount = fileType === 'update' && input.name === 'cpn';
                if(value && input.name === inputName && input.value === value) {
                    ++count;
                    input.message = message;
                    if (considerCount && count < 2) input.message = '';
                }
            }
            return count;
        }, 0);
    }

    getMpnDuplicateCount(value) {
        let cpn;
        let inputs = this.state.inputs;
        return inputs.reduce((count, current) => {
            if(!current.disabled) {
                let input = current.data[current.columns.indexOf('mpn')];
                let cpnValue = current.columns.includes('cpn')
                 ? current.data[current.columns.indexOf('cpn')].value
                 : null;
                if(value && input.value === value) {
                    if(!cpnValue || cpn != cpnValue){
                        cpn = cpnValue;
                        ++count;
                    }
                }
            }
            return count;
        }, 0);
    }

    validateRefDesDuplication()
    {

        let globalRefDesArray = []
        let inputs = this.state.inputs
        let refDesValues = []

        inputs.forEach((row) =>{
            if(!row.disabled)
            {
                let inputIndex = row.columns.indexOf("ref des")
                if (inputIndex >= 0)
                {
                    let input = row.data[inputIndex]
                    refDesValues = input.value.split(/,| |:|;/).filter(function(v){return v!==''})
                    globalRefDesArray = globalRefDesArray.concat(refDesValues)
                }
            }

        })

        let sorted_arr = globalRefDesArray.slice().sort();
        let duplicatedValues = [];
        for (var i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                duplicatedValues.push(sorted_arr[i]);
            }
        }

        inputs.forEach((row) =>
        {
            if (!row.disabled)
            {
                let quantityInput = this.getSpreadsheetRowValue(row, "quantity")
                let refDesInput = this.getSpreadsheetRowValue(row, "ref des")

                if (duplicatedValues.length > 0)
                {
                    let duplicateRefDesArr = []
                    let inputRefDesValues = []

                    inputRefDesValues = refDesInput.value.split(/,| |:|;/).filter(function(v){return v!==''})
                    duplicatedValues.forEach((dupVal) => {
                        if (inputRefDesValues.includes(dupVal))
                        {
                            duplicateRefDesArr.push(dupVal)
                        }
                    })

                    if (duplicateRefDesArr.length > 0)
                    {
                        duplicateRefDesArr = duplicateRefDesArr.filter(function(item, pos) {return duplicateRefDesArr.indexOf(item) === pos;})

                        let quantity = quantityInput.value
                        quantity = Number(quantity) ? Number(quantity) : quantity

                        validateField(refDesInput, validations.component.children.refDes, refDesInput.value, {quantity: quantity})

                        if (refDesInput.valid)
                        {
                            refDesInput.message = "Duplicate Ref Des value: " + duplicateRefDesArr.join()
                        }
                        refDesInput.valid = !refDesInput.message
                        refDesInput.class = refDesInput.valid ? "" : "invalid"
                    }
                }
                else
                {
                    let quantity = quantityInput.value
                    quantity = Number(quantity) ? Number(quantity) : quantity
                    validateField(refDesInput, validations.component.children.refDes, refDesInput.value, {quantity: quantity})
                }

            }
        })
    }

    validateItemNumberDuplication(value)
    {
        let inputs = this.state.inputs
        let globalItemNumberArray = []
        let duplicatedValues = []
        inputs.forEach((row) =>{
            if(!row.disabled)
            {
                let itemNumberInput = this.getSpreadsheetRowValue(row, "item number")
                if (itemNumberInput.value)
                {
                    globalItemNumberArray.push(itemNumberInput.value)
                }
            }
        })

        let sortedArr = globalItemNumberArray.slice().sort();
        for (var i = 0; i < sortedArr.length - 1; i++) {
            if (sortedArr[i + 1] === sortedArr[i]) {
                duplicatedValues.push(sortedArr[i]);
            }
        }

        let itemNumberCount = 0
        inputs.forEach((row) =>
        {
            if (!row.disabled)
            {
                let itemNumberInput = this.getSpreadsheetRowValue(row, "item number")

                validateField(itemNumberInput, validations.component.children.itemNumber, itemNumberInput.value, {isAllowedBlankItemNumber: window.__isAllowedBlankItemNumber})

                if (itemNumberInput.valid)
                {
                    if (duplicatedValues.includes(itemNumberInput.value))
                    {
                        itemNumberInput.message = `Value must be unique within an assembly. Value ${value} is already used in this assembly`
                        itemNumberInput.valid = !itemNumberInput.message
                        itemNumberInput.class = itemNumberInput.valid ? "" : "invalid"
                    }

                }
            }
        })
    }

    validate(state, options=[], r=null, validateManufacturers=false, skipOctopart=false, backendValidation="required", validateNameUsingBulk=true, validateEidUsingBulk=true, validateRefDesDuplication=false)
    {
        // TODO: eventually we should only validate all field when component is first mounted.
        // after first mount we should only validate individual cells onChange or onBlur
        state.validating = true
        this.setMyState(state)

        let inputs             = this.state.inputs
        let cells              = this.state.cells
        let validateApiArr     = ["name", "eid", "cpn", "mpn"]
        let errors             = 0
        let validated          = 0
        let currentlyOpenedCpn = this.props.currentlyOpenedCpn
        let manufacturersArr   = this.state.manufacturersArr

        let done = (err) =>
        {
            validated++
            let isMpnValidated = true;
            if(validated >= cells)
            {
                // NOTE: if the validation happens to fast there is a flicker on the banner
                // setting a minimum wait of 1 second to ease the transition after validation
                clearTimeout(this.state.timeOutID)

                this.state.timeOutID = setTimeout(() =>
                {
                    errors = 0
                    inputs.forEach((row) =>
                    {
                      if(!row.disabled && !row.selectedFromLibrary)
                      {
                        row.data.forEach((input) =>
                        {
                            if (input.name === 'mpn')
                            {
                                let mpnCount = this.getMpnDuplicateCount(input.value)
                                if(!['warning', 'validating'].includes(input.resStatus) && mpnCount > 1)
                                {
                                    input.resStatus = "error"
                                    input.resMsg = "Duplicate MPN found in file"
                                    input.valid = false
                                    input.class = "mpninvalid"
                                }
                                else if(this.props.mpnImportDialog.flag && input.resStatus === "validating") {
                                    isMpnValidated = false;
                                }
                            }

                            if (!input.valid) {
                                errors++
                            }
                        })
                      }
                    })
                    let state = this.state
                    state.validating = !isMpnValidated;
                    state.hasValidated = isMpnValidated;
                    this.updateBanner(errors, state)

                }, 1000)
            }
        }

        let getDataFromVendor = this.props.mpnImportDialog.flag ? true : false
        let validateOctoBulk = () =>
        {
          let mpnArr   = []
          inputs.forEach((row, rowIndex) =>
          {
            if(!row.disabled)
            {
              let cat = this.getSpreadsheetRowValue(row, "category").value
              let mpn = this.getSpreadsheetRowValue(row, "mpn")

              if(!cat)
              {
                let cpn = this.getSpreadsheetRowValue(row, "cpn").value
                if(cpn)
                {
                    let category = Schemas.categories.findByCPN(cpn);
                    if(category)
                    {
                        cat = category.name
                    }
                }
              }

              let cat_type = Schemas.component.category.getType(cat)

              if (cat_type && cat_type === "ELECTRICAL")
              {
                let data = {category: cat, mpn: mpn.value, rowInfo: mpn  }
                mpnArr.push(data)
              }
            }
          })
          let len = 20
          let i = 0
          let n = mpnArr.length;
          while (i < n) {
            let arr = mpnArr.slice(i, i += len)
            let reqData = { data: arr, getDataFromVendor: getDataFromVendor, bulk: true };
            if (i > 2) {
              setTimeout( () => {
                validateOcto(reqData, arr)
              }, 150*(i - 2))
            }
            else
            {
                validateOcto(reqData, arr)
            }
          }
        }
        if (skipOctopart) {
          validateOctoBulk()
        }

        let validateNameBulk = (chunk) =>
        {
            let names = []
            chunk.forEach((row, rowIndex) =>
            {
                if(!row.disabled)
                {
                    let name = this.getSpreadsheetRowValue(row, "name").value
                    names.push(name)
                }
            })
            API.components.bulkNameExists(names, (err, results) =>
            {
                validated = validated + chunk.length
                done()
                if(err)
                {
                    console.error(err)
                }
                inputs.forEach((row) =>
                {
                  if(!row.disabled)
                  {
                    row.data.forEach((input) =>
                    {
                        let existWithinFile = false
                        if (input.name === 'name' && input.valid)
                        {
                            existWithinFile = this.getInputCount(input.value, "name") > 1
                            let result = results.filter(function(item) { return item.name === input.value; })
                            if(result && result[0])
                            {
                                if(!existWithinFile)
                                {
                                    input.message = ""
                                    input.message = Schemas.component.name.validate(result[0].name, null)
                                }

                                input.nameDuplicateOf = result[0].duplicate_of
                                if(result[0].alreadyExist === true && !input.message)
                                {
                                    input.message = "This component name already exists in your library."
                                }

                                input.valid   = result[0].alreadyExist == true || existWithinFile || input.message ? false : true
                                input.class   = input.valid ? "" : "invalid"
                            }
                        }
                    })
                  }
                })
            })
        }

        let validateEidBulk = (chunk) =>
        {
            let eids = []
            let data = {isUseExisting: false};
            if(this.props && this.props.fileType === 'update')
            {
                data.isUseExisting = true;
            }
            chunk.forEach((row, rowIndex) =>
            {
                if(!row.disabled)
                {
                    let eid = this.getSpreadsheetRowValue(row, "eid").value;
                    if(eid === "")
                    {
                        return;
                    }
                    if(data.isUseExisting)
                    {
                        let cpn = this.getSpreadsheetRowValue(row, "cpn").value;
                        eids.push({eid, cpn});
                    }
                    else
                    {
                        eids.push(eid);
                    }
                }
            })
            data.eids = eids;
            API.components.bulkEidExists(data, (err, results) =>
            {
                validated = validated + chunk.length
                done()
                if(err)
                {
                    console.error(err)
                }
                inputs.forEach((row) =>
                {
                  if(!row.disabled)
                  {
                    row.data.forEach((input) =>
                    {
                        let existWithinFile = false
                        if (input.name === 'eid' && input.valid)
                        {
                            existWithinFile = this.getInputCount(input.value, "eid") > 1
                            let result = results.filter(function(item) { return item.eid === input.value; })
                            if(result && result[0])
                            {
                                if(!existWithinFile)
                                {
                                    input.message = ""
                                    validateField(input, validations.component.eid, input.value)
                                }
                                if(result[0].alreadyExist.exist === true)
                                {
                                    input.eidDuplicateOf = result[0].alreadyExist.duplicate_of
                                    input.message = "Component EID already exists in library."
                                }

                                input.valid   = result[0].alreadyExist.exist == true || existWithinFile || input.message ? false : true
                                input.class   = input.valid ? "" : "invalid"
                                if(!input.valid)
                                {
                                    done(!input.valid)
                                }
                            }
                        }
                    })
                  }
                })
            })
        }

        if(validateNameUsingBulk)
        {
            let chunkSizeforBulk = this.state.chunkSizeforBulk
            let total_chunks = Math.ceil(inputs.length/chunkSizeforBulk)
            let i = 0
            let j = 0
            while(i < total_chunks)
            {
                let chunk = inputs.slice(j, j += chunkSizeforBulk)
                setTimeout( () => {
                validateNameBulk(chunk)
                }, 150* 2)
                i++;
            }
        }

        if(validateEidUsingBulk)
        {
            let chunkSizeforBulk = this.state.chunkSizeforBulk
            let total_chunks = Math.ceil(inputs.length/chunkSizeforBulk)
            let i = 0
            let j = 0
            while(i < total_chunks)
            {
                let chunk = inputs.slice(j, j += chunkSizeforBulk)
                setTimeout( () => {
                validateEidBulk(chunk)
                }, 150* 2)
                i++;
            }
        }

        let validateOcto = (reqData, arr) =>
        {
          Schemas.component.mpn.validateOctopart(reqData, (res) =>
          {
            validated = validated + (arr.length - 1)
            done()
            res.data.forEach((dat) =>
            {
                let row = inputs[dat.rowInfo.row]
                let input = row.data[dat.rowInfo.col]
                let cpn = this.getSpreadsheetRowValue(row, "cpn").value;
                input.mpnDuplicateOf = dat.duplicate_of
                input.resStatus = dat.type
                input.resMsg = dat.status
                input.cmpData = dat.data
                if (input.mpnDuplicateOf) {
                    if(cpn !== Utils.getCpn(input.mpnDuplicateOf)) {
                        input.valid = false;
                        input.class = "mpninvalid";
                    }
                    else if(!this.validateSourcingTree(row, input.row)) {
                        input.resStatus = input.resMsg = "";
                        input.valid = !input.resStatus;
                    }
                }
                else this.validateSourcingTree(row, input.row)
            })
          })
        }

        inputs.forEach((row, rowIndex) =>
        {
            if(!row.disabled && !row.selectedFromLibrary)
            {
                let rowCpn = this.getSpreadsheetRowValue(row, "cpn");
                (this.props && this.props.fileType === "update" && rowCpn) ? API.components.findByCpn(rowCpn.value, (err, existingComponent) =>
                {
                    this.validateInputs(row, validated, options, done, validateManufacturers, manufacturersArr, rowIndex, validateApiArr, r, validateRefDesDuplication, backendValidation, skipOctopart, validateNameUsingBulk, validateEidUsingBulk, currentlyOpenedCpn, existingComponent);
                }) : this.validateInputs(row, validated, options, done, validateManufacturers, manufacturersArr, rowIndex, validateApiArr, r, validateRefDesDuplication, backendValidation, skipOctopart, validateNameUsingBulk, validateEidUsingBulk, currentlyOpenedCpn);
            }
            else
            {
                validated = validated + (row.data.length - 1)
                done(false)
            }
        })
    }

    validateMpnDuplication(options) {
        let { cb, backendValidation, row, skipOctopart, input } = options;
        if(this.state.data.headings.includes("mpn") && backendValidation === "required") {
            let mpnCell       = this.getSpreadsheetRowValue(row, "mpn")
            let cpnValue      = this.getSpreadsheetRowValue(row, "cpn").value
            mpnCell.resMsg    = ""
            mpnCell.resStatus = "validating"
            this.validateMpn(row, skipOctopart, (res) => {
                mpnCell.mpnDuplicateOf = res.duplicate_of;
                mpnCell.resMsg         = res.status;
                mpnCell.resStatus      = res.type;
                mpnCell.cmpData        = res.data;
                if (mpnCell.mpnDuplicateOf) {
                    if(cpnValue !== Utils.getCpn(mpnCell.mpnDuplicateOf)) {
                        mpnCell.valid = false;
                        mpnCell.class = "mpninvalid";
                    }
                    else if (!this.validateSourcingTree(row, input.row)) {
                        mpnCell.resStatus = mpnCell.resMsg = "";
                        mpnCell.valid = !mpnCell.resStatus;
                    }
                }
                else if(this.props.fileType === 'update'){
                        return this.validateSourcingTree(row, input.row);
                    }
                if (mpnCell.resStatus !== "validating") {
                    cb(!input.valid);
                    return true;
                }
            });
        }
        else{
            cb(!input.valid);
            return false;
        }
        if(this.props.fileType === 'update') return this.validateSourcingTree(row, input.row);
    }

    validateInputs(row, validated, options, done, validateManufacturers, manufacturersArr, rowIndex, validateApiArr, r, validateRefDesDuplication, backendValidation, skipOctopart, validateNameUsingBulk, validateEidUsingBulk, currentlyOpenedCpn, existingComponent=null)
    {
        row.data.forEach((input) =>
        {
            if((options.length > 0 && (options.includes(input.name))) || (validateManufacturers && manufacturersArr.includes(input.name)) || options.length === 0)
            {
                let skipValidation   = !input.value && this.props.fileType === "update"
                let validateApi = ((r === null) || (options.length > 0 && r !== null))

                if(validateApiArr.includes(input.name) || (options.length === 0 && r == null) || (r === rowIndex) || validateRefDesDuplication)
                {
                    switch(input.name)
                    {
                        case "category" :
                        {
                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                input.value   = Schemas.component.category.normalize(input.value)
                                input.message = Schemas.component.category.validate(input.value)
                                input.valid   = !input.message
                                input.class   = input.valid ? "" : "invalid"
                                this.validateMpnDuplication({backendValidation, row, skipOctopart, cb: done, input})
                                // if(this.props.mpnImportDialog.flag && this.state.data.headings.includes("mpn") && backendValidation === "required")
                            }
                            break
                        }

                        case "unit of measure" :
                        {
                            input.value   = !!window.__customUomLabels.length ? Utils.mapUOMValues(input.value) : input.value;
                            input.value   = Schemas.component.unitOfMeasure.normalize(input.value)
                            input.message = Schemas.component.unitOfMeasure.validate(input.value)
                            input.valid   = !input.message
                            input.class   = input.valid ? "" : "invalid"
                            done(!input.valid)
                            break
                        }

                        case "name" :
                        {
                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""

                                if (input.nameDuplicateOf)
                                {
                                    input.valid = false
                                    input.message = "Duplicate name from library"
                                    input.class   = input.valid ? "" : "invalid"

                                }

                                done(false)
                            }
                            else
                            {
                                if(validateApi)
                                {
                                    input.nameDuplicateOf = null
                                    validateField(input, validations.component.name, input.value)

                                    if (input.valid)
                                    {
                                        let obj = {}
                                        if(["update_assembly", "update"].includes(this.props.fileType))
                                        {
                                            obj.cpn = this.getSpreadsheetRowValue(row, "cpn").value
                                        }

                                        if (!validateNameUsingBulk)
                                        {
                                            Schemas.component.name.validate(input.value, (data) =>
                                            {
                                                if (data.exist)
                                                {
                                                    input.valid = false
                                                    input.nameDuplicateOf = data.duplicate_of
                                                    input.message = "Duplicate name from library"
                                                }
                                                else
                                                {
                                                    input.valid = this.getInputCount(input.value, "name") < 2
                                                }
                                                input.class   = input.valid ? "" : "invalid"
                                                done(!input.valid)
                                            }, obj)
                                        }
                                    }
                                    else
                                    {
                                        done(!input.valid)
                                    }
                                }
                                else
                                {
                                    input.valid   = (input.valid || input.message === "Duplicate name found" ) && this.getInputCount(input.value, "name") < 2
                                    input.class   = input.valid ? "" : "invalid"

                                    if (input.nameDuplicateOf)
                                    {
                                        input.valid = false
                                        input.message = "Duplicate name from library"
                                        input.class   = input.valid ? "" : "invalid"
                                    }
                                    done(!input.valid)
                                }
                            }

                            break
                        }

                        case "eid" :
                        {

                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                if(validateApi)
                                {
                                    input.eidDuplicateOf = null
                                    validateField(input, validations.component.eid, input.value)
                                    if (input.valid)
                                    {
                                        input.value   = Schemas.component.eid.normalize(input.value)

                                        let obj = {}
                                        if(["update_assembly", "update"].includes(this.props.fileType))
                                        {
                                            obj.cpn = this.getSpreadsheetRowValue(row, "cpn").value
                                        }
                                        if (!validateEidUsingBulk)
                                        {
                                            Schemas.component.eid.validate(input.value, (data) =>
                                            {
                                                if (data.exist)
                                                {
                                                    input.valid = false
                                                    input.eidDuplicateOf = data.duplicate_of
                                                    input.message = "Duplicate EID from library"
                                                }
                                                else
                                                {
                                                    input.valid = this.getInputCount(input.value, "eid") < 2
                                                }
                                                input.class   = input.valid ? "" : "invalid"

                                                done(!input.valid)
                                            }, obj)
                                        }
                                        else
                                        {
                                            done(!input.valid)
                                        }
                                    }
                                    else
                                    {
                                        done(!input.valid)
                                    }
                                }
                                else
                                {
                                    input.valid   = (input.valid || input.message === "Duplicate eid found" ) && this.getInputCount(input.value, "eid") < 2
                                    input.class   = input.valid ? "" : "invalid"

                                    if (input.eidDuplicateOf)
                                    {
                                        input.valid = false
                                        input.message = "Duplicate name from library"
                                        input.class   = input.valid ? "" : "invalid"
                                    }
                                    done(!input.valid)
                                }
                            }

                            break
                        }

                        case "description" :
                        {

                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                validateField(input, validations.component.description, input.value)
                                done(!input.valid)
                            }
                            break
                        }

                        case "mass" :
                        {
                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                validateField(input, validations.component.mass, input.value, {massPrecisionValue: window.__massPrecisionValue})
                                done(!input.valid)
                            }
                            break
                        }

                        case "revision" :
                        {

                            let statusInput = this.getSpreadsheetRowValue(row, "status");

                            if(window.__isNotRevisionManagedEnabled && existingComponent && !existingComponent.revisionManaged && input.value)
                            {
                                input.value = input.value.toUpperCase();
                                if(existingComponent.revision !== input.value)
                                {
                                    input.valid = false;
                                    input.class = "invalid not-revision-managed";
                                    input.message = `This Component is not Revision controlled.\nIt's Revision should be equal to ${existingComponent.revision}.`;
                                }
                                else
                                {
                                    input.valid = true;
                                    input.class = "";
                                    input.message = "";
                                }
                                done(!input.valid);
                            }
                            else if (skipValidation || (!input.value && !statusInput.value))
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                let val       = statusInput.value
                                val = val ? val.toUpperCase() : ""
                                let cpnInput = this.getSpreadsheetRowValue(row, "cpn")
                                let component = cpnInput.currentComponentClone

                                let revSchemeType = window.__revSchemeType ? window.__revSchemeType : "DEFAULT"
                                let libraryType = window.__libraryType ? window.__libraryType : "GENERAL"

                                let isCustomRevScheme = revSchemeType !== "DEFAULT" && libraryType === "GENERAL"
                                if (isCustomRevScheme && (this.props.fileType === "update_assembly" || this.props.fileType === "update"))
                                {
                                    if (component)
                                    {
                                        //Check revison greater than last saved revision
                                        let previousRevision = component.status === "DESIGN" ? component.revision : component.previousRevision
                                        validateField(input, validations.component.revision, input.value.toUpperCase(), {status: val, revSchemeType: revSchemeType, libraryType: libraryType, isClient: true, previousRevision: previousRevision})
                                    }
                                }
                                else
                                {
                                    validateField(input, validations.component.revision, input.value.toUpperCase(), {status: val, revSchemeType: revSchemeType, libraryType: libraryType, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})
                                }
                                done(!input.valid)
                            }
                            break
                        }

                        case "status" :
                        {
                            let revisionInput = this.getSpreadsheetRowValue(row, "revision");
                            let minQuantity = this.getSpreadsheetRowValue(row, "min quantity");

                            if(window.__isNotRevisionManagedEnabled && existingComponent && !existingComponent.revisionManaged && input.value)
                            {
                                input.value = input.value.toUpperCase();
                                if(existingComponent.status !== input.value)
                                {
                                    input.valid = false;
                                    input.class = "invalid not-revision-managed";
                                    input.message = `This Component is not Revision controlled.\nIt's Status should be equal to ${existingComponent.status}.`;
                                }
                                else
                                {
                                    input.valid = true;
                                    input.class = "";
                                    input.message = "";
                                }
                                done(!input.valid);
                            }
                            else if (skipValidation || (!input.value && !revisionInput.value) || (input.value && minQuantity && minQuantity.value))
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                let val     = revisionInput.value
                                val = val ? val.toUpperCase() : ""
                                validateField(input, validations.component.status, input.value.toUpperCase(), {revision: val})
                                if(minQuantity && minQuantity.hasOwnProperty("value"))
                                {
                                    let status = input.value ? input.value : "PRODUCTION";

                                    let val = validations.component.manufacturers.distributors.quotes.minQuantity.normalize(minQuantity.value)
                                    validateField(minQuantity, validations.component.manufacturers.distributors.quotes.minQuantity, val, {status: status})
                                }
                                done(!input.valid)
                            }

                            break
                        }

                        case "datasheet" :
                        {
                            if (!skipValidation && (input.value || this.haveManufacturerInformation(row.data) || this.haveQuotesInformation(row.data) || this.haveDistributorInformation(row.data)))

                            {
                                let schema  = Schemas.component.manufacturers.datasheet.src
                                input.value = schema.normalize(input.value).replace(/\s/g, '')
                                validateField(input, validations.component.manufacturers.datasheet.src, input.value)
                                done(false)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "cpn" :
                        {
                            if(validateApi && (["new", "new_assembly"].includes(this.props.fileType) && window.__currentCompanyCpnType === "FREE-FORM"))
                            {
                                input.value = input.value.trim();
                                validateField(input, validations.cpn.value, input.value, {cpnType: window.__currentCompanyCpnType, isIntelligentCpnScheme: window.__isIntelligentCpnScheme, nonIntelligent: window.__nonIntelligent});
                                input.cpnDuplicateOf = null;
                                if(input.valid)
                                {
                                    Schemas.component.cpn.validate(input.value, (data) =>
                                    {
                                        if(data.exist)
                                        {
                                            input.valid = false;
                                            input.cpnDuplicateOf = data.duplicate_of;
                                            input.message = "Duplicate cpn from library";
                                        }
                                        else
                                        {
                                            input.valid   =   this.getInputCount(input.value, "cpn") < 2;
                                        }
                                        input.class   = input.valid ? "" : "invalid";
                                        done(!input.valid);
                                    }, this.props.fileType);
                                }
                                else
                                    done(!input.valid);
                            }
                            else if(validateApi)
                            {
                                Schemas.component.cpn.validate(input.value, (message, data) =>
                                {
                                    if (data)
                                    {
                                        input.currentComponentClone = data
                                    }
                                    input.message = message
                                    input.value = input.value.trim()
                                    input.valid   = !message
                                    if (this.props.fileType === "update_assembly") {
                                        input.valid   = !message && this.getInputCount(input.value, "cpn") < 2;
                                        if (currentlyOpenedCpn === input.value && input.valid)
                                        {
                                            input.message = "Cannot add parent component as child assembly (Self reference not allowed)"
                                            input.valid   = !input.message
                                        }
                                    }
                                    else if(this.props.fileType === "update") {
                                        if(!this.validateMpnDuplication({backendValidation, row, skipOctopart, cb: done, input}))
                                        {
                                            input.valid   = !message;
                                            input.resStatus = input.message && input.message !== "Duplicate cpn found" ? "error" : this.getInputCount(input.value, "cpn") > 1 ? 'warning' : ''
                                            input.resMsg = input.valid && this.getInputCount(input.value, "cpn") > 1 ? "Duplicate cpn found" : !input.valid ? message : '';
                                            input.message = ""
                                        }
                                    }
                                    input.class   = input.valid ? "" : this.props.fileType === "update" ? "mpninvalid" : "invalid";

                                    let revSchemeType = window.__revSchemeType ? window.__revSchemeType : "DEFAULT"
                                    let libraryType = window.__libraryType ? window.__libraryType : "GENERAL"
                                    //Validate revision value if revSchemType !== DEFAULT
                                    let isCustomRevScheme = revSchemeType !== "DEFAULT" && libraryType === "GENERAL"
                                    if (isCustomRevScheme)
                                    {
                                        let component = input.currentComponentClone
                                        if (isCustomRevScheme && (this.props.fileType === "update_assembly" || this.props.fileType === "update"))
                                        {
                                            if (component)
                                            {
                                                let revInput = this.getSpreadsheetRowValue(row, "revision")
                                                let statusInput = this.getSpreadsheetRowValue(row, "status")

                                                //Check revison greater than last saved revision
                                                let previousRevision = component.status === "DESIGN" ? component.revision : component.previousRevision
                                                if (revInput && statusInput)
                                                {
                                                    validateField(revInput, validations.component.revision, revInput.value.toUpperCase(), {status: statusInput.value, revSchemeType: revSchemeType, libraryType: libraryType, isClient: true, previousRevision: previousRevision})
                                                }
                                            }
                                        }
                                    }

                                    done(!input.valid)
                                });
                            }
                            else
                            {
                                if (this.props.fileType === "update_assembly"){
                                    input.valid   = (input.valid || input.message === "Duplicate cpn found" ) && this.getInputCount(input.value, "cpn") < 2
                                    if (currentlyOpenedCpn === input.value && input.valid)
                                    {
                                        input.message = "Cannot add parent component as child assembly (Self reference not allowed)"
                                        input.valid   = !input.message
                                    }
                                }
                                else if((["new", "new_assembly"].includes(this.props.fileType) && window.__currentCompanyCpnType === "FREE-FORM" ) || this.props.fileType === "update")
                                {
                                    input.valid   = (input.valid || input.message === "Duplicate cpn found" ) && this.getInputCount(input.value, "cpn") < 2
                                }

                                input.class   = input.valid ? "" : "invalid"
                                done(!input.valid)
                            }
                            break
                        }

                        case "parent" :
                        {
                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                Schemas.component.parent.validate(input.value, (message) =>
                                {
                                    input.message = message
                                    input.valid   = !message
                                    input.class   = input.valid ? "" : "invalid"
                                    done(!input.valid)
                                })
                            }

                            break
                        }

                        case "specs" :
                        {
                            done(false)
                            break
                        }

                        case "procurement":
                        {
                            if (skipValidation)
                            {
                                input.message = "";
                                input.valid   = true;
                                input.class   = "";
                                done(false);
                            }
                            else
                            {
                                validateField(input, validations.component.procurement, input.value);
                                done(!input.valid);
                            }
                            break
                        }

                        case "images" :
                        {
                            if (input.value)
                            {
                                Schemas.component.images.url.validate(input.value, (message) =>
                                {
                                    input.message = message
                                    input.valid   = !message
                                    input.class   = input.valid ? "" : "invalid"
                                    done(!input.valid)
                                })
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(!input.valid)
                            }
                            break
                        }

                        case "documents" :
                        {
                            if(input.value)
                            {
                                Schemas.component.documents.url.validate(input.value, (message) =>
                                {
                                    input.message = message
                                    input.valid   = !message
                                    input.class   = input.valid ? "" : "invalid"
                                    done(!input.valid)
                                })
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(!input.valid)
                            }
                            break
                        }

                        case "assembly" :
                        {
                            done(false)
                            break
                        }

                        case "manufacturer" :
                        {
                            if (!skipValidation && (input.value || this.haveManufacturerInformation(row.data, this.props.mpnImportDialog.flag) || this.haveQuotesInformation(row.data) || this.haveDistributorInformation(row.data)))
                            {
                                let status = "PRODUCTION"//this.getSpreadsheetRowValue(row, "status").value || "DESIGN"
                                validateField(input, validations.component.manufacturers.name, input.value, {status: status})
                                done(!input.valid)
                            }
                            else{
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }

                            break
                        }

                        case "distributor" :
                        {
                            if (!skipValidation && (input.value || this.haveDistributorInformation(row.data) || this.haveQuotesInformation(row.data)))

                            {
                                let status = "PRODUCTION"//this.getSpreadsheetRowValue(row, "status").value || "DESIGN"
                                validateField(input, validations.component.manufacturers.distributors.name, input.value, {status: status})
                                done(!input.valid)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "mpn" :
                        {
                            let allowMpnDuplicate = true;
                            let cpn = this.getSpreadsheetRowValue(row, "cpn").value
                            if(this.props.fileType === 'update' || this.props.fileType === 'update_assembly')
                            {
                                allowMpnDuplicate = false;
                            }

                            if (!skipValidation && (input.value || this.haveManufacturerInformation(row.data) || this.haveQuotesInformation(row.data) || this.haveDistributorInformation(row.data)))
                            {
                                let status = "PRODUCTION"//this.getSpreadsheetRowValue(row, "status").value || "DESIGN"
                                validateField(input, validations.component.manufacturers.mpn.key, input.value, {status: status})

                                // if(this.props.mpnImportDialog.flag && backendValidation === "required")
                                if(backendValidation === "required")
                                {
                                    input.resMsg  = ""
                                    input.resStatus = "validating"
                                    this.validateMpn(row, skipOctopart, (res) =>
                                    {
                                        if(res.type)
                                        {
                                            input.mpnDuplicateOf = res.duplicate_of
                                            input.resMsg = res.status
                                            input.resStatus = res.type
                                            input.cmpData = res.data
                                            if (input.mpnDuplicateOf) {
                                                if(cpn !== Utils.getCpn(input.mpnDuplicateOf)) {
                                                    input.valid = false;
                                                    input.class = "mpninvalid";
                                                }
                                                else if(!this.validateSourcingTree(row, input.row)) {
                                                        input.resStatus = input.resMsg = "";
                                                        input.valid = !input.resStatus;
                                                }
                                            }
                                            else if(this.props.fileType === 'update'){
                                                    this.validateSourcingTree(row, input.row)
                                                }
                                        }
                                        done(!input.valid)
                                    }, allowMpnDuplicate)
                                    break
                                }
                                else
                                {
                                    done(!input.valid)
                                }
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                // let status = ""
                                // validateField(input, validations.component.manufacturers.mpn.key, input.value, {status: status})
                                // if(this.props.mpnImportDialog.flag && backendValidation === "required")
                                if(input.value && backendValidation === "required")
                                {
                                    input.resMsg  = ""
                                    input.resStatus = "validating"
                                    this.validateMpn(row, skipOctopart, (res) =>
                                    {
                                        if(res.type)
                                        {
                                            input.mpnDuplicateOf = res.duplicate_of
                                            input.resMsg = res.status
                                            input.resStatus = res.type
                                            input.cmpData = res.data

                                            if (input.mpnDuplicateOf)
                                            {
                                                input.valid   = false
                                                input.class   = input.valid ? "" : "mpninvalid"
                                            }

                                        }
                                        done(false)
                                    })
                                    break
                                }
                                else
                                {
                                    done(false)
                                }
                            }
                            break
                        }

                        case "dpn" :
                        {
                            if(!this.validateSourcingTree(row, input.row) && !skipValidation && (input.value || this.haveDistributorInformation(row.data) || this.haveQuotesInformation(row.data))) {
                                let status = "PRODUCTION"//this.getSpreadsheetRowValue(row, "status").value || "DESIGN"
                                validateField(input, validations.component.manufacturers.distributors.dpn.key, input.value, {status: status})
                                done(!input.valid)
                            }
                            else {
                                input.message = ""
                                input.class   = ""
                                input.valid   = true
                                done(false)
                            }
                            break
                        }

                        case "price" :
                        {
                            let statusColumn = this.getSpreadsheetRowValue(row, "status");
                            if (!skipValidation && (input.value || this.haveQuotesInformation(row.data)))
                            {
                                const priceWithoutCurrency = Number(input.value?.toString().replace("$", ""));
                                const [unitPriceValue, validatorValue] = isNaN(priceWithoutCurrency) ? [input.value, input.value] 
                                    : [SourcingUtils.precisePriceValue(priceWithoutCurrency), priceWithoutCurrency];

                                let status = statusColumn ? statusColumn.value : "DESIGN";
                                validateField(input, validations.component.manufacturers.distributors.quotes.unitPrice, validatorValue, {status});
                                input.value = unitPriceValue;
                                done(!input.valid)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "min quantity" :
                        {
                            let statusColumn = this.getSpreadsheetRowValue(row, "status");
                            if( !this.validateSourcingTree(row, input.row) && !skipValidation && ((input.value || this.haveQuotesInformation(row.data)) || (statusColumn ? statusColumn.value !== "DESIGN" : false))) {
                                let status = statusColumn ? statusColumn.value : "PRODUCTION";

                                let inputValue = input.value
                                let val = validations.component.manufacturers.distributors.quotes.minQuantity.normalize(input.value)
                                validateField(input, validations.component.manufacturers.distributors.quotes.minQuantity, val, {status: status})

                                input.value = inputValue
                                done(!input.valid)
                            }
                            else {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "quote lead time" :
                        {
                            if (!skipValidation && (input.value || this.haveQuotesInformation(row.data)))
                            {
                                if (input.value){
                                    let val = parseInt(input.value,10)
                                    input.value = val ? val : ""
                                    let validator = validations.component.manufacturers.distributors.quotes.leadTime.value
                                    validateField(input, validator, input.value)
                                    done(!input.valid)
                                }
                                else
                                {
                                    input.message = ""
                                    input.valid   = true
                                    input.class   = ""
                                    done(false)
                                }
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }

                            break
                        }

                        case "quote lead time unit" :
                        {
                            if (!skipValidation && (input.value || this.haveQuotesInformation(row.data)))
                            {
                                if (input.value){
                                    let quoteLeadTime = this.getSpreadsheetRowValue(row, "quote lead time").value
                                    quoteLeadTime = parseInt(quoteLeadTime) || ''

                                    let validator   = validations.component.manufacturers.distributors.quotes.leadTime.units
                                    input.value = validator.normalize({}, input.value).units

                                    done(!input.valid)
                                }
                                else
                                {
                                    input.message = ""
                                    input.valid   = true
                                    input.class   = ""
                                    done(false)
                                }

                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }

                            break
                        }

                        case "package" :
                        {
                            if (!skipValidation && (input.value || this.haveDistributorInformation(row.data) || this.haveQuotesInformation(row.data)))
                            {
                                input.value = input.value.toUpperCase()
                                let inputValue = input.value
                                input.value = validations.component.manufacturers.distributors.package.type.normalize(input.value)
                                validateField(input, validations.component.manufacturers.distributors.package.type, input.value)
                                input.value = inputValue
                                done(!input.valid)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }

                            break
                        }

                        case "package quantity" :
                        {
                            if (!skipValidation && (input.value || this.haveDistributorInformation(row.data) || this.haveQuotesInformation(row.data)))
                            {
                                let val = parseInt(input.value,10)
                                input.value = val ? val : ""
                                validateField(input, validations.component.manufacturers.distributors.package.quantity, input.value)
                                done(!input.valid)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "quantity" :
                        {
                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                let refDes = this.getSpreadsheetRowValue(row, "ref des").value
                                let inputValue = input.value
                                validateField(input, validations.component.children.quantity, Number(input.value) ? Number(input.value) : input.value, {refDes: refDes})
                                input.value = inputValue
                                done(!input.valid)
                            }
                            break
                        }

                        case "ref des" :
                        {
                            let quantity = this.getSpreadsheetRowValue(row, "quantity").value
                            quantity = Number(quantity) ? Number(quantity) : quantity
                            validateField(input, validations.component.children.refDes, input.value, {quantity: quantity})



                            this.validateRefDesDuplication()
                            done(!input.valid)
                            break
                        }

                        case "item number" :
                        {
                            let isInteger = Utils.isIntegerValue(input.value)
                            validateField(input, validations.component.children.itemNumber, isInteger ? Number(input.value) : input.value, {isAllowedBlankItemNumber: window.__isAllowedBlankItemNumber})

                            this.validateItemNumberDuplication(input.value)
                            done(!input.valid)
                            break
                        }

                        case "mfr description" :
                        {
                            if (!skipValidation && (input.value || this.haveManufacturerInformation(row.data) || this.haveQuotesInformation(row.data) || this.haveDistributorInformation(row.data)))

                            {
                                validateField(input, validations.component.manufacturers.description, input.value)
                                done(!input.valid)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "dist description" :
                        {
                            if (!skipValidation && (input.value || this.haveDistributorInformation(row.data) || this.haveQuotesInformation(row.data)))

                            {
                                validateField(input, validations.component.manufacturers.distributors.description, input.value)
                                done(!input.valid)
                            }
                            else
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            break
                        }

                        case "notes" :
                        {
                            if (skipValidation)
                            {
                                input.message = ""
                                input.valid   = true
                                input.class   = ""
                                done(false)
                            }
                            else
                            {
                                validateField(input, validations.component.children.notes, input.value)
                                done(!input.valid)
                            }
                            break

                        }

                        default :
                        {
                            console.error("Unhandled input name:", input.name)
                            done(false)
                        }
                    }

                }
                else
                {
                    done(false)
                }
            }
            else
            {
                done(false)
            }
        })
    }

    updateBanner(errors, state)
    {
        let banner = state.banner
        if (state.enabledRowsLength === 0)
        {
            banner.class = "warning"
            banner.icon  = flagSrc
            banner.copy  = "Enable one or more rows to import. "
            banner.button.copy    = "continue"
            banner.button.class    = "disabled"
        }
        else
        {
            if(errors < 1)
            {
                banner.class          = ""
                banner.icon           = checkSrc
                banner.copy           = "No errors found."
                banner.button.copy    = "continue"
                banner.button.class  = "active"
                banner.button.onClick = this.proceed
                return this.setMyState(state)
            }

            banner.class = "errors"
            banner.icon  = flagSrc
            banner.button.class  = "disabled"
            banner.copy  = "We found "
            + errors
            + " error" + (errors > 1 ? "s" : "")
            + ". Fix "
            + (errors > 1 ? "or remove them" : "or remove it")
            + " to proceed."
        }
        this.setMyState(state)
    }

    haveQuotesInformation(row){
        let haveValue = false
        for(let i = 0; i < row.length; i++)
        {
            let input = row[i]

            let quoteInput = {}
            switch(input.name)
            {
                case "price" :
                case "min quantity" :
                case "quote lead time" :
                case "quote lead time unit" :
                    quoteInput = input
                    break;
                default:
                    break;
            }
            if (Object.keys(quoteInput).length && quoteInput.value) {
                haveValue = true
                break;
            }
            if (haveValue){
                break;
            }
        }
        return haveValue;
    }

    haveDistributorInformation(row){
        let haveValue = false
        for(let i = 0; i < row.length; i++)
        {
            let input = row[i]

            let distributorInput = {}
            switch(input.name)
            {
                case "dpn" :
                case "distributor" :
                case "dist description" :
                case "package" :
                case "package quantity" :
                    distributorInput = input
                    break;
                default:
                    break;
            }
            if (Object.keys(distributorInput).length && distributorInput.value) {
                haveValue = true
                break;
            }
            if (haveValue){
                break;
            }
        }
        return haveValue;
    }

    haveManufacturerInformation(row, mpnFlag=false){
        let haveValue = false
        let manufacturerInputs = ["manufacturer", "mpn", "mfr description", "datasheet"]
        for(let i = 0; i < row.length; i++)
        {
            let input = row[i]
            if(manufacturerInputs.includes(input.name) && input.value)
            {
              if(!(mpnFlag && input.name === "mpn"))
              {
                haveValue = true
                break
              }
            }
        }

        return haveValue;
    }

    compareQuantityAndRefDes(input, row)
    {
        let refDesValue   = this.getValue(row, "ref des")
        refDesValue = refDesValue ? refDesValue.value : ""
        AssemblyScehma.compareQuantityAndRefDes(refDesValue, input.value, function(err, data){
            input.message = data
            input.valid   = !input.message
            input.class   = input.valid ? "" : "invalid"
        })

        return !input.valid
    }

    getValue(row, column)
    {
        let value = null

        for (var i = 0; i < row.length; ++i)
        {
            if(row[i].name === column)
            {
                value = row[i]
                return value
            }
        }

        return ""
    }

    getSpreadsheetRowValue(row, column)
    {
        let value = null
        value = row.data[row.columns.indexOf(column)]
        return value ? value : ""
    }

    proceed()
    {
        let state = this.state
        state.showProgressBar = true
        state.shouldBlockNavigation = false
        state.percentage = 0.1
        this.setMyState(state)
        let inputs       = state.inputs

        let complete     = 0
        let list         = []
        let updateList   = []
        let assemblyList = []
        let assemblyListComponent = []

        let _this = this

        let checkCompletion = () =>
        {
            let completeCount = ++complete
            if(completeCount === inputs.length || completeCount === state.enabledRowsLength)
            {
                this.handleCreateAndUpdate(list, updateList, assemblyList, assemblyListComponent)
            }
        }

        inputs.forEach((row) =>
        {

            if (_this.isRowDisabled(row))
            {
                this.createComponent(row, (err, cmp) =>
                {
                    if(err)
                    {
                        // TODO: handle server error
                        return console.error(err)
                    }

                    if(cmp.parent)
                    {
                        assemblyList.push(cmp)
                        checkCompletion()
                        return
                    }

                    if (cmp.cpn && this.props.fileType === "update_assembly" && cmp.currentComponentClone)
                    {
                        cmp.currentComponentClone.quantity = cmp.quantity
                        cmp.currentComponentClone.itemNumber = cmp.itemNumber
                        cmp.currentComponentClone.refDes = cmp.refDes
                        cmp.currentComponentClone.notes = cmp.notes
                        assemblyListComponent.push(cmp.currentComponentClone)
                        checkCompletion()
                        return
                    }

                    if(cmp.cpn && this.props.fileType === "update")
                    {
                        let component = {}
                        API.components.findByCpn(cmp.cpn, (err, componentData) =>
                        {

                            if(err)
                            {
                                return console.error(err)
                            }
                            let dataOriginal = componentData
                            let updatedLastModified = dataOriginal.lastModified;
                            component        = Object.assign(dataOriginal, cmp)
                            component.lastModified = updatedLastModified;
                            updateList.push(component)
                            checkCompletion()
                        })

                        return
                    }

                    if(cmp.category || cmp.selectedFromLibrary)
                    {
                        list.push(cmp)
                        checkCompletion()
                        return
                    }

                    // NOTE: used to look to see if name exists here
                    // if you need to do that in the future you can use the API.components.nameExists method

                    // TODO: if they made it this far there should be an error shown
                    console.error("Something went wrong on the review screen")
                })
            }
        })
    }

    handleCreateAndUpdate(createList, updateList, assemblyList, assemblyListComponent)
    {
        let creating   = createList.length   > 0
        let updating   = updateList.length   > 0
        let assembling = assemblyList.length > 0
        let assemblingComponent = assemblyListComponent.length > 0


        let onComplete = () =>
        {
            if(!creating && !updating && !assembling)
            {
                let _that = this
                setTimeout(function(){
                    if (_that.props.assemblyFlag)
                    {
                        let componentsList = [..._that.state.newlyCreatedOrUpdatedComponents, ..._that.state.useExistingComponents]
                         componentsList = ImportUtils.filterUniqueComponents(componentsList)
                        _that.props.onDone(componentsList, _that.props.fileType)
                    }
                    else
                    {
                        let sortBy = _that.props.fileType === "update" ? "lastModified" : "created"
                        let records_length = createList.length + updateList.length + assemblyList.length
                        let components_text = records_length === 1 ? " component." : " components."
                        let success_message = "Success. You’ve imported " + records_length + components_text
                        _that.props.history.push("/search", {query: "type:cmp", success_message, total_records: records_length, imported_ids: _that.state.idz, sortBy})
                        _that.props.onDoneFromCmpHeaderNav("displayFileImport");
                    }
                }, 1000);
            }
        }

        if(creating)
        {
            let state = this.state
            let totalRecords = createList.length
            let listWithUseExisting = ImportUtils.filterUseExisting(createList)
            let listWithoutUseExisting = ImportUtils.filterUseWithoutExisting(createList)
            this.state.useExistingComponents = listWithUseExisting

            if (listWithoutUseExisting.length === 0)
            {
                state.percentage = 1
                this.setMyState(state)
                creating = false
                onComplete()
            }
            else
            {
                let sentList = listWithoutUseExisting
                let cmpWithCategory = ImportUtils.splitComponentsBasedOnCategoryType(sentList)

                let totalCmpTypeCategory = Object.keys(cmpWithCategory).length
                let totalProcessedBatches = 0
                let processedKeys = []

                let sendRequestInParallelCount = 3
                //limit parallel requests to 1 for company with non-intellgent cpn scheme
                if(Utils.isNonIntelligentCPNScheme())
                {
                    sendRequestInParallelCount = 1
                }
                let processedBatchesCount = 0

                let options = {totalCmps: sentList.length, createdCmps: 0}

                let sendBatch = (key) =>
                {
                    this.createAll(cmpWithCategory[key], options, () =>
                    {
                        totalProcessedBatches = totalProcessedBatches + 1
                        processedBatchesCount = processedBatchesCount + 1

                        if (totalProcessedBatches === totalCmpTypeCategory)
                        {
                            state.percentage = 1
                            this.setMyState(state)
                            creating = false
                            onComplete()
                        }
                        else if (processedBatchesCount === sendRequestInParallelCount)
                        {
                            processedBatchesCount = 0
                            let sendBatchesCount = 0

                            for(let key in cmpWithCategory)
                            {
                                if (!processedKeys.includes(key))
                                {
                                    if (sendBatchesCount < sendRequestInParallelCount)
                                    {
                                        sendBatchesCount++
                                        processedKeys.push(key)
                                        sendBatch(key)
                                    }
                                }
                            }
                        }

                    })
                }

                let sendBatchesCount = 0
                for(let key in cmpWithCategory)
                {
                    if (!processedKeys.includes(key))
                    {
                        if (sendBatchesCount < sendRequestInParallelCount)
                        {
                            sendBatchesCount++
                            processedKeys.push(key)
                            sendBatch(key)
                        }
                    }
                }
            }
        }

        if(updating)
        {
            this.updateAll(updateList, () =>
            {
                updating = false
                onComplete()
            })
        }

        if(assembling)
        {
            this.addToAssembly(assemblyList, () =>
            {
                assembling = false
                onComplete()
            })
        }

        if(assemblingComponent)
        {
            this.addComponentsToAssembly(assemblyListComponent, () =>
            {
                assemblingComponent = false
                onComplete()
            })
        }

    }

    createAll(list, options, cb)
    {
        let index = 0
        let state = this.state

        let cmpBatches = ImportUtils.createComponentBatches(list)


        let onComplete = (err, data) =>
        {
            data.forEach((cmp, i) => {
                if (cmp._id)
                {
                    state.idz.push(cmp._id)
                    cmp.quantity = cmpBatches[index][i].quantity
                    cmp.refDes = cmpBatches[index][i].refDes
                    cmp.notes = cmpBatches[index][i].notes
                    cmp.primarySource = {
                      leadTime: {
                        value: null,
                        units: null
                      },
                      unitPrice: null,
                      minQuantity: null
                    }
                    state.newlyCreatedOrUpdatedComponents.push(cmp)
                }
            })

            if(++index === cmpBatches.length)
            {
                this.setMyState(state)
                return cb()
            }
            options.createdCmps = options.createdCmps + data.length
            state.progressBarText = "IMPORTING FILE"
            state.percentage = 0.1 + options.createdCmps/options.totalCmps
            this.setMyState(state)
            create()
        }

        let create = () =>
        {
            let payload = {components: cmpBatches[index], responseKeys: ["_id", "cpn", "cpnVariant"]}
            API.components.createWithData(payload, onComplete)
        }

        create()
    }

    updateAll(list, cb)
    {
        let index = 0
        let state = this.state
        let newlyUpdatedComponents = []
        let idz = []

        let totalCmps = list.length
        let updatedCmps = 0
        if(state.data.headings.includes("manufacturer")) ImportUtils.mergeSourcing(list);
        let cmpBatches = ImportUtils.createComponentBatches(list)
        let sendNumberOfBatchInParallel = 5
        let processedBatches = 0
        let batchNo = 0
        let onComplete = (err, data) =>
        {

            data.forEach((cmp, i) => {
                idz.push(cmp._id)
            })

            if(++index === cmpBatches.length)
            {
                state.percentage = 1
                state.idz = idz
                this.setMyState(state)
                API.components.updateParent(state.idz)
                return cb()
            }

            updatedCmps = updatedCmps + data.length
            state.progressBarText = "IMPORTING FILE"
            state.percentage = 0.1 + updatedCmps/totalCmps
            processedBatches = processedBatches + 1
            this.setMyState(state)

            if (processedBatches === sendNumberOfBatchInParallel)
            {
                processedBatches = 0
                for (let i=0; i < sendNumberOfBatchInParallel; i++)
                {
                    if (batchNo < cmpBatches.length)
                    {
                        update(batchNo)
                        batchNo++
                    }
                }
            }

        }

        let update = (i) =>
        {
            cmpBatches[i].forEach((cmp) => {
                cmp.modified = true
                delete cmp.currentComponentClone
            })

            let payload = {components: cmpBatches[i], responseKeys: ["_id", "cpn", "cpnVariant"]}
            API.components.updateWithData(payload, onComplete)
        }

        for (let i=0; i < sendNumberOfBatchInParallel; i++)
        {
            if (batchNo < cmpBatches.length)
            {
                update(batchNo)
                batchNo++
            }
        }
    }


    addComponentsToAssembly(list, cb)
    {
        let index = 0
        let state = this.state
        state.showProgressBar = true
        let newlyUpdatedComponents = []
        this.setMyState(state)

        list.forEach((cmp) => {
            newlyUpdatedComponents.push(cmp)
            if(++index === list.length)
            {
                state.percentage = 1
                state.newlyCreatedOrUpdatedComponents = newlyUpdatedComponents
                this.setMyState(state)
                return cb()
            }
            state.percentage = index/list.length
            this.setMyState(state)
        })
    }

    addToAssembly(componentList, cb)
    {

        let complete = 0
        let assembly = {}
        let childrenArray = []
        let onComplete = () =>
        {
            if(complete === componentList.length)
            {
                assembly.children = childrenArray

                if(assembly.children && assembly.children.length > 0)
                {
                     assembly.modified = true
                }

                API.components.update(assembly._id, assembly, (err) =>
                {
                    if(err)
                    {
                        // TODO: handle server error
                        console.error(err)
                    }
                })

                return cb()
            }

            // eslint-disable-next-line
            run()
        }

        let update = (component) =>
        {
            API.components.findByCpn(component.parent, (err, parent) =>
            {
                if(err)
                {
                    // TODO: handle server error
                    console.error(err)
                    return onComplete()
                }

                if(!parent)
                {
                    // no parent cpn in sheet
                    return onComplete()
                }

                // get current component by cpn
                API.components.findByCpn(component.cpn, (err, child) =>
                {
                    if(err)
                    {
                        // TODO: handle server error
                        console.error(err)
                        return onComplete()
                    }

                    if(!child)
                    {
                        // invalid cpn in sheet
                        return onComplete()
                    }

                    // add to parents list of children
                    childrenArray.push(
                    {
                        quantity  : component.quantity,
                        refDes    : component.refDes,
                        component : child._id
                    })


                    assembly = parent

                    onComplete()

                })
            })
        }

        let run = () =>
        {
            let component = componentList.pop()
            update(component)
        }

        run()
    }

    convertChildren(list, cb)
    {
        if(list.length < 1)
        {
            return cb(null, list)
        }

        let children = []
        let complete = 0

        list.forEach((child) =>
        {
            API.search("type:cmp cpn:" + child.component, (err, results) =>
            {
                let result = results && results[0]

                if(result)
                {
                    child.component = result._id
                    children.push(child)
                }

                if(++complete === list.length)
                {
                    cb(null, children)
                }
            })
        })
    }

    createComponent(row, cb)
    {
        let isUpdate = this.props.fileType === "update"
        let data =
        {
            revision    : Constants.default_revision,
            status      : Constants.default_status,
            eid         : "",
            description : "",
            images      : [],
            documents   : [],
            manufacturers: [],
            specs       : [],
            children    : []
        }
        if (isUpdate)
        {
            data = {}
            data.lastModified = new Date().getTime();
        }

        let specsValue = ""
        let manufacturerObj =
        {
            mpn: {},
            distributors: [],
            datasheet: {}
        }
        let distributorObj =
        {
            dpn: {},
            quotes: [],
            package: {}
        }
        let quoteObj = {}

        data.fileImport = true
        row.data.forEach((input) =>
        {
            let valid = (!isUpdate || input.value) ? true : false

            if (valid)
            {
                switch(input.name)
                {
                    case "category" :
                    {
                        data.category = (input.value)//.replace(/ /g, "-")
                        break
                    }

                    case "unit of measure" :
                    {
                        data.unitOfMeasure = input.value
                        break
                    }

                    case "name" :
                    {
                        data.name = input.value
                        break
                    }

                    case "eid" :
                    {
                        data.eid = input.value
                        break
                    }

                    case "description" :
                    {
                        data.description = input.value
                        break
                    }

                    case "mass" :
                    {
                        data.mass = input.value
                        break
                    }

                    case "revision" :
                    {
                        if (input.value.length !== 0)
                        {
                            data.revision = input.value
                        }
                        break
                    }

                    case "status" :
                    {
                        if (input.value.length!==0)
                        {
                            data.status = input.value
                        }
                        break
                    }

                    case "images" :
                    {
                        data.images = []
                        data.images = this.parseImages(input.value, data.images)
                        break
                    }

                    case "documents" :
                    {
                        data.documents = []
                        data.documents = this.parseDocuments(input.value, data.documents)
                        break
                    }

                    case "assembly" :
                    {
                        data.children = []
                        data.children = this.parseChildren(input.value, data.children)
                        break
                    }

                    case "specs" :
                    {
                        data.specs = []
                        specsValue = input.value
                        break
                    }
                    case "procurement":
                    {
                        data.procurement = input.value;
                        break;
                    }
                    case "manufacturer" :
                    {
                        data.manufacturers = []
                        manufacturerObj.name = input.value
                        break
                    }
                    case "mpn" :
                    {
                        manufacturerObj.mpn.src = ""
                        manufacturerObj.mpn.key = input.value
                        data.eventId            = input.cmpData.eventId;
                        break
                    }
                    case "dpn" :
                    {
                        distributorObj.dpn.src = ""
                        distributorObj.dpn.key = input.value
                        break
                    }
                    case "distributor" :
                    {
                        distributorObj.name = input.value
                        break
                    }
                    case "price" :
                    {
                        quoteObj.unitPrice = input.value ? input.value : 1
                        break
                    }
                    case "min quantity" :
                    {
                        quoteObj.minQuantity = parseInt(input.value,10)
                        break
                    }

                    case "datasheet" :
                    {
                        manufacturerObj.datasheet.src = input.value
                        break
                    }
                    case "quote lead time" :
                    {
                        if (!quoteObj.leadTime)
                            quoteObj.leadTime = {}
                        quoteObj.leadTime.value = parseInt(input.value,10) ? parseInt(input.value,10) : 0
                        break
                    }

                    case "quote lead time unit" :
                    {
                        if (!quoteObj.leadTime)
                            quoteObj.leadTime = {}
                        quoteObj.leadTime.units = input.value ? input.value : "DAYS"
                        break
                    }

                    case "package" :
                    {

                        distributorObj.package.type = input.value
                        break
                    }
                    case "package quantity" :
                    {
                        distributorObj.package.quantity = input.value
                        break
                    }
                    case "cpn" :
                    {
                        data.cpn = input.value
                        if ((this.props.fileType === "update" || this.props.fileType === "update_assembly") && !data.category)
                        {
                            data.category = input.currentComponentClone.category
                            data.currentComponentClone = input.currentComponentClone
                        }
                        break
                    }
                    case "parent" :
                    {
                        data.parent = input.value
                        break
                    }
                    case "quantity" :
                    {
                        data.quantity = input.value
                        break
                    }
                    case "ref des" :
                    {
                        data.refDes = input.value
                        break
                    }
                    case "item number" :
                    {
                        data.itemNumber = input.value
                        break
                    }
                    case "mfr description" :
                    {
                        manufacturerObj.description = input.value
                        break
                    }
                    case "dist description" :
                    {
                        distributorObj.description = input.value
                        break
                    }
                    case "notes" :
                    {
                        data.notes = input.value
                        break
                    }

                    default :
                    {
                        // noop
                    }
                }

            }

        })

        if (row.selectedFromLibrary)
        {
            data.selectedFromLibrary = true
            data.selectedCmpFromLibrary = row.selectedCmpFromLibrary
            return cb(null, data)
        }

        if (!(Object.keys(quoteObj).length === 0 && quoteObj.constructor === Object))
        {
            distributorObj.quotes.push(quoteObj)
        }

        if (distributorObj.name && distributorObj.dpn && distributorObj.dpn.key)
        {
            manufacturerObj.distributors.push(distributorObj)
        }

        if (manufacturerObj.name && manufacturerObj.mpn && manufacturerObj.mpn.key)
        {
            data.manufacturers.push(manufacturerObj)
        }

        // wait to parse specs until after we have the category
        if (data.category)
        {
            data.specs = this.getSpecs(data.category)
            data.specs = this.parseSpecs(specsValue, data.specs)
        }

        let mpn = this.getSpreadsheetRowValue(row, "mpn")


        let cmpFromWeb = {}
        if(mpn.hasOwnProperty("cmpData") && Object.keys(mpn.cmpData).length > 0)
        {
            cmpFromWeb = mpn.cmpData
        }

        if(isUpdate && data.cpn)
        {
            let query     = "type:cmp cpn:" + (data.cpn)
            let component = {}
            let _this = this
            API.components.findByCpn(data.cpn, (err, componentData) =>
            {
                if(err)
                {
                    return console.error(err)
                }
                component = componentData
                data = _this.mergeData(row.data, cmpFromWeb, data, component)
                _this.createChildrens(data, cb)
            })

        }
        else
        {
            if(this.props.mpnImportDialog.flag && this.state.data.headings.includes("mpn"))
            {
                data = this.mergeData(row.data, cmpFromWeb, data)
            }
            this.createChildrens(data, cb)
        }
    }

    createChildrens(data, cb)
    {
        if (data.children)
        {
            this.convertChildren(data.children, (err, children) =>
            {
                if(err)
                {
                    console.error(err)
                    children = []
                }

                data.children = children
                cb(null, data)
            })
        }
        else
        {
            cb(null, data)
        }
    }

    parseSpecs(value, list)
    {
        let specsArr = value.split(",");
        specsArr.forEach((specStr) =>
        {
            // Split "key:value" by only FIRST occurence of colon (:)
            // so if value contains colon in it, then it will be included also.
            let colonIndex = specStr.indexOf(":")
            let specKeyValue;
            if (colonIndex == -1) {
              specKeyValue = [ specStr ];
            } else {
              specKeyValue = [ specStr.substr(0, colonIndex), specStr.substr(colonIndex+1) ];
            }

            let key   = (typeof specKeyValue[0] === "string") ? specKeyValue[0].trim() : specKeyValue[0];
            let value = (typeof specKeyValue[1] === "string") ? specKeyValue[1].trim() : '';

            list.forEach((spec) =>
            {
                if(spec.key.toLowerCase() === key.toLowerCase())
                {
                    spec.value = value;
                }
            })
        })

        return list;
    }

    getSpecs(category)
    {
        let list = Schemas.component.specs.list(category)
        if ( list && list.names )
        {
            return Schemas.component.specs.list(category).names.map((name) =>
            {
                let data =
                {
                    key   : name,
                    value : ""
                }

                return data
            })
        }
        else
            return []
    }

    parseImages(value, list)
    {
        let a = value.split(",")

        a.forEach((src) =>
        {
            let data =
            {
                src : src
            }

            list.push(data)
        })

        return list
    }

    parseDocuments(value, list)
    {
        let a = value.split(",")

        a.forEach((src) =>
        {
            let data =
            {
                name  : src,
                size  : "?",
                src   : src,
                specs :
                {
                    revision : 1,
                    status   : "design",
                    type     : "generic"
                }
            }

            list.push(data)
        })

        return list
    }

    parseChildren(value, list)
    {
        let a = value.split(",")

        a.forEach((cpn) =>
        {
            let data =
            {
                quantity  : 1,
                refDes    : "",
                component : cpn
            }

            list.push(data)
        })

        return list
    }

    backButtonClick(e)
    {
        let event = Utils.getEvent(this, this.props.mappedData)
        event.target.name = "upload-screen"
        event.fileType = this.props.fileType
        this.props.onBackClick(this.state.data.headings)
        this.props.next(event)
    }

    isRowDisabled(row)
    {
        return !row.disabled
    }

    isRowSelectedFromLibrary(row)
    {
        return !!row.selectedFromLibrary
    }

    isValidRow(row)
    {
        let clazz = ""
        for(let i = 0; i < row.length; i++)
        {
            let input = row[i]
            if(!input.valid)
            {
                clazz = "invalid-row"
                break;
            }
        }
        return clazz
    }

    onMappAllToggleChange(e)
    {
        if(!e.target.props.selected)
        {
            this.enableAllInputs()
        }
        else
        {
            this.disableAllInputs()
        }
    }

    disableAllInputs(){
        let state  = this.state
        let rows   = state.inputs
        let errors = 0
        for(let i = 0; i < rows.length; i++)
        {
            rows[i].disabled = true
        }

        state.enabledRowsLength = 0
        this.updateBanner(errors, state)
    }

    enableAllInputs()
    {
        let rows   = this.state.inputs
        for(let i = 0; i < rows.length; i++)
        {
             rows[i].disabled = false
        }
        this.state.enabledRowsLength = rows.length
        let validateNameUsingBulk = true
        let validateEidUsingBulk = true
        let validateMpnUsingBulk = false
        let required = "not_required"

        if(!this.state.data.headings.includes("eid"))
        {
            validateEidUsingBulk = false
        }
        if(!this.state.data.headings.includes("name") || this.props.fileType === "update")
        {
            validateNameUsingBulk = false
        }

        if(this.state.data.headings.includes("name"))
        {
            validateMpnUsingBulk = true
            required = "required"
        }
        this.validate(this.state, [], null, false, validateMpnUsingBulk, required, validateNameUsingBulk, validateEidUsingBulk)
    }

    onModalClose(e)
    {
        e && e.preventDefault();
        this.props.next(undefined);
    }

    toggleButtonSelectedStateForAll(){
        let state = this.state
        return state.enabledRowsLength === state.inputs.length
    }

    checkThisRow(event, rowIndex){
        let state = this.state
        state.checkedRows = Array.from(new Set(state.checkedRows)).sort()

        var index = state.checkedRows.indexOf(rowIndex);
        let shiftKeyPressed = event.shiftKey
        state.checkedRows.includes(rowIndex) ? state.checkedRows.splice(index, 1) : state.checkedRows.push(rowIndex)

        let checkedstate = state.checkedRows.includes(rowIndex)

        if (shiftKeyPressed)
        {
            let start = -1;
            let end  = -1;
            if(state.lastchecked < rowIndex && state.lastchecked !== -1)
            {
                start = state.lastchecked
                end = rowIndex
            }
            if(state.lastchecked > rowIndex && state.lastchecked !== -1)
            {
                start = rowIndex
                end = state.lastchecked
            }
            if(start >= 0 && end >= 0)
            {
                for(let j = start; j <= end; j++)
                {
                    let foundIndex = state.checkedRows.indexOf(j);
                    if (checkedstate)
                    {
                        state.checkedRows.push(j)
                    }
                    else
                    {
                        if (foundIndex !== -1 )state.checkedRows.splice(foundIndex, 1)
                    }
                }
                if(checkedstate)
                {
                    state.checkedRows.push(rowIndex)
                }
            }
        }
        // else
        // {
        //     let foundIndex = state.checkedRows.indexOf(rowIndex);
        //     checkedstate ? state.checkedRows.push(rowIndex) : state.checkedRows.splice(foundIndex, 1)
        // }

        state.lastchecked = rowIndex
        state.checkedRows = Array.from(new Set(state.checkedRows)).sort()
        console.log(state.checkedRows)
        console.log(state.lastchecked)
        this.setMyState(state)
    }

    checkAllRows(e){
        let state = this.state
        let rows = state.inputs

        let checked = state.checkedRows.length
        let enabled = state.enabledRowsLength;

        if (checked > 0 && checked < enabled)
        {
            state.checkedRows = []
        }
        else
        {
            state.checkedRows = e.target.checked ? Array.from({length: rows.length}, (v, i) => i) : []
        }
        this.setMyState(state)
    }

    checkAllState(e){
        let state = this.state
        let enabled = state.enabledRowsLength;
        let rows = state.inputs

        let checked = state.checkedRows.length

        if (checked === 0)
        {
            return {class: "", checked: false}
        }
        else if (checked > 0 && checked < enabled)
        {
            return {class: "middle", checked: false}
        }
        else if (checked === enabled)
        {
            return {class: "", checked: true}
        }
        else{
            return {class: "", checked: false}
        }
    }

    getToggleClassForAll(){
        let enabled = this.state.enabledRowsLength
        let rows   = this.state.inputs
        if (enabled > 0 && enabled < rows.length){
            return "middle"
        }
    }

    // getEnabledRowsLength(all = false){
    //     let state = this.state
    //     let rows   = state.inputs//.slice(this.current_page_index() , this.curren_page_limit())
    //     // if (all)
    //     //     rows = state.inputs

    //     let enabled = 0
    //     for(let i = 0; i < rows.length; i++)
    //     {
    //         if (this.isRowDisabled(rows[i]))
    //             enabled++
    //     }

    //     state.enabledRowsLength = enabled
    //     this.setMyState(state)
    // }

    createCategoryTdMarkup(row, r)
    {
        return <Categories tableSettings={this.tableSettings} parentComponent={this} rowIndex={r} row={row} fileType={this.props.fileType}/>
    }

    createUnitOfMeasureTdMarkup(row, r)
    {
        return <UnitOfMeasures tableSettings={this.tableSettings} parentComponent={this} rowIndex={r} row={row} fileType={this.props.fileType}/>
    }

    convertArrayToString()
    {
        let requiredFields = ""
        this.state.requiredFields.forEach( (field, index) =>
        {
            let preFix = " "
            let postFix = ""

            if(index + 1 < this.state.requiredFields.length)
            {
                postFix = ","
            }
            requiredFields = requiredFields + preFix + field.charAt(0).toUpperCase() + field.slice(1) + postFix
        })

        return requiredFields
    }

    validateSourcingTree(row, selfIndex) {
        let _this = this;
        let {inputs} = _this.state;
        let list = {cpn: 'cpn', mpn: 'mpn', dpn: 'dpn', minQuantity: 'min quantity'};
        const lookUp = Object.freeze({mpn: 'manufacturers', dpn: 'distributors', minQuantity: 'quotes'});
        let keysToCompare = Object.keys(list);


        for(let key of keysToCompare) {
            if(!row.columns.includes(list[key])) return false;
        }

        keysToCompare.forEach(key => list[key] = row.columns.indexOf(list[key]));

        for (const element of inputs) {
            if (element.disabled || selfIndex == element.data[0].row) continue;
            if (keysToCompare.reduce((count, current) => {
                let index = list[current];
                if (row.data[index].value == element.data[index].value) ++count;
                return count;
            }, 0) == keysToCompare.length) return _this.setSourcingFields(list, [selfIndex, element.data[0].row], 'error');
        }

        keysToCompare.shift();
        const findRequiredValues = (field, check) => {
            if (check == 'minQuantity')
                return !!field[lookUp[check]].find((item) => item[check] == row.data[list[check]].value);
            return field[lookUp[check]].find((item) => item[check].key == row.data[list[check]].value);
        }
        for (let element of inputs) {
            if (element.disabled) continue;
            let mpnField = element.data[list['mpn']].mpnDuplicateOf;
            if (!!mpnField) {
                if (row.data[list['cpn']].value == mpnField.cpn) {
                    keysToCompare.forEach(element => mpnField = mpnField && findRequiredValues(mpnField, element));
                    if (mpnField) return _this.setSourcingFields(list, [selfIndex], 'warning', element.data[list['mpn']].mpnDuplicateOf);
                }
            }
        }
        this.setSourcingFields(list, [selfIndex]);
    }

    setSourcingFields(list, rowIndexes, message='', duplicate_of=null) {
        let {inputs} = this.state;
        const tooltipText = Object.freeze({
            'error': 'Duplicate data exists in file.',
            'warning': 'This sourcing combination already exists in this component.',
            'duplicate': 'Duplicate cpn found'
        });
        let valuesToSet = {
            resMsg: tooltipText[message] || message,
            message: tooltipText[message] || message,
            resStatus: message,
            valid: message !== 'error',
            class: message === 'error' ? 'mpninvalid' : '',
            duplicate_of: duplicate_of ? duplicate_of : null,
        }

        if (!message) {
            const cpn = inputs[rowIndexes[0]].data[list['cpn']].value;
            let indexes = inputs.reduce((indexes, current) => {
                let cpnObject = current.data[list['cpn']];
                if (current.disabled) return indexes;
                if (Object.values(tooltipText).includes(cpnObject.resMsg) && cpn === cpnObject.value)
                    indexes.push(current.data[0].row);
                return indexes;
            }, []);
            if (indexes.length <= 2)
                rowIndexes = indexes;
        }

        const setValuesInEachField = (data) => Object.keys(valuesToSet).forEach(element => data[element] = valuesToSet[element]);
        const setData = (data, selfIndex) => {
            let listClone = {...list}
            let cpnCell = data[list["cpn"]];
            if(cpnCell.message === "Invalid CPN") {
                cpnCell.resMsg = cpnCell.message;
                delete listClone["cpn"]
            }
            Object.keys(listClone).forEach(key => setValuesInEachField(data[listClone[key]]));
            if (!cpnCell.message && !message) {
                this.validateSourcingTree(inputs[selfIndex], selfIndex);
                cpnCell.resStatus = this.getInputCount(cpnCell.value, "cpn") > 1 ? "warning" : "";
                cpnCell.message = cpnCell.resMsg =  cpnCell.resStatus ? "Duplicate cpn found" : "";
            }
        }
        rowIndexes.forEach(index => {
            if(inputs[index].disabled) return;
            setData(inputs[index].data, index)
        });
        return true;
    }


    render()
    {
        const state = this.state
        if(state.loading)
        {
            return <Loading />
        }

        let data              = state.data
        let inputs            = state.inputs

        inputs = inputs.slice(0, state.pagination)

        let banner            = state.banner

        let bannerClazz =
              "banner " + banner.class
            + (state.validating ? " validating" : "")
            + (state.hasValidated ? "" : " first-time")

        if (!state.validating)
            if (banner.button.class && banner.button.class === "active")
                bannerClazz = bannerClazz + " no-error"
        let expandEl = <span
                        className="col-move"
                        onMouseDown={(e) => this.onMouseDown(e)}
                        onMouseEnter={(e) => this.addExpandHoverClass(e)}
                        onMouseLeave={(e) => this.removeExpandHoverClass(e)}
                        onClick={this.performNoEvent}
                        onDoubleClick={this.onThDoubleClick}
                    />

        let tableSettings = this.tableSettings

        let markup =
            <div className="large-modal review-screen-modal">
                <div className="unsaved-warning-modal-holder">
                    {
                        state.shouldBlockNavigation &&
                        <Prompt
                            when={state.shouldBlockNavigation}
                            message={this.handleBlockedNavigation}
                        />
                    }

                    {
                        state.modalVisible &&
                        <WarningModal
                            onCancel={(e) => this.closeModal(e, true)}
                            onConfirm={this.handleConfirmNavigationClick}
                            title={"Changes will be lost"}
                            description={"You have unsaved changes. If you navigate away without first saving, the changes will be lost."}
                        />
                    }
                </div>

                <ModalBox onClose={this.props.onClose}>
                    <div className={state.showProgressBar ? "" : "hidden"}>
                        <div className="review-screen">
                            <header className="modal-header no-error">
                                <div className="header-heading">
                                    <Icon src={banner.icon} key={new Date().getTime()}/>
                                    <h3>Importing. Please wait.</h3>
                                </div>
                            </header>
                        </div>
                    </div>

                    <div className={state.showProgressBar ? "progress-modal" : "hidden progress-modal"}>
                        <ProgressBar percentage={state.percentage} text={state.progressBarText}/>
                    </div>

                    <div className={state.showProgressBar ? "hidden review-screen" : "review-screen"}>
                        <header className={bannerClazz + " modal-header"}>
                            <div className="header-heading">
                                {state.validating ? null : <Icon src={banner.icon} key={new Date().getTime()}/>}

                                {
                                    state.validating ?

                                    [<div key="h-spinner-dark" className="spinner">
                                        <InlineIcon>
                                            <Spinner/>
                                        </InlineIcon>
                                    </div>,
                                    <h3>Checking for errors...</h3>
                                    ]
                                    :
                                    <h3>{banner.copy}</h3>
                                }
                            </div>
                            <div className="btn-holder">
                                {
                                    state.validating ?
                                    null
                                    :
                                    <div>
                                        <button className="back btn-header" onClick={this.backButtonClick}>
                                            Back
                                        </button>
                                        <button onClick={banner.button.onClick} className={banner.button.class + " btn-header"}>
                                            {banner.button.copy}
                                        </button>
                                    </div>
                                }
                            </div>
                        </header>

                        <div className="app-row app-row-block">
                            <div className="app-row-heading">
                                <h1>Review your import</h1>
                                <p className="required-field-text">
                                {
                                    "* Required  fields:" + this.convertArrayToString()
                                }
                                </p>
                            </div>

                            <div className="bulk-action-block">
                                {
                                    ["new", "new_assembly"].includes(this.props.fileType) ?
                                    <select
                                        className={"bulk-category " + this.getBulkSelectValue("category").class}
                                        value={this.getBulkSelectValue("category").value}
                                        onChange={(e) => this.assignCategoryToInputs(e)}
                                        >
                                        <option value="">Assign Category</option>
                                        {this.getCategories().options}
                                    </select> : null
                                }
                            </div>
                            <TableIcons
                                autoFitColumnsWidth={this.autoFitColumnsWidth}
                                actionsList={[]}
                                allowEnlarge={false}
                                displaySettings={false}
                                className={(["new", "new_assembly"].includes(this.props.fileType) ? 'import-table-icons' : 'import-table-icons-with-use-existing')}
                            />
                            <div
                                    className={"outer app-row-table extended-table table-holder"}>
                                    <div className={"inner table-headings" + (["new", "new_assembly"].includes(this.props.fileType) ? '' : ' no-checkbox') }>
                                    <table>
                                        <thead>
                                            <tr>
                                            <th className="first-child">
                                                <ToggleBtn
                                                    name="mapAll"
                                                    class={this.getToggleClassForAll()}
                                                    selected={this.toggleButtonSelectedStateForAll()}
                                                    onChange={(e) => this.onMappAllToggleChange(e)}
                                                />
                                            </th>
                                            {
                                                ["new", "new_assembly"].includes(this.props.fileType) ?
                                                <th className="second-child">
                                                    <div className="check-holder">
                                                    <input
                                                        type="checkbox"
                                                        name="category-check-all"
                                                        checked={this.checkAllState().checked}
                                                        onChange={(e) => this.checkAllRows(e)}
                                                    />
                                                    <label htmlFor="category-check-all"
                                                        className={this.checkAllState().class}
                                                    />
                                                   </div>

                                                </th>  : null
                                            }
                                           {
                                                ["new", "new_assembly"].includes(this.props.fileType) ?
                                                <th
                                                    style={{minWidth: '100px', width: ( Utils.getStyleValue(tableSettings, "category", "width", 176, {onErrorDefault: true}) + 'px') }}
                                                    name="category"
                                                >
                                                    Category *
                                                    {expandEl}
                                                </th> : null
                                           }

                                            {
                                                data.headings.map((heading, i) =>
                                                {
                                                    let input = this.getSpreadsheetRowValue(inputs[0], heading)

                                                    if (heading !== "category")
                                                    {
                                                        let postFix = ""

                                                        if(state.requiredFields.includes(heading.toLowerCase()))
                                                        {
                                                            postFix = " *"
                                                        }

                                                        let value = Schemas.component.keys.getDisplayName(heading, this.props.currentlyOpenedCategory, this.props.displayRefDesAndItemNumber)
                                                        return <th
                                                                    key={i}
                                                                    style={{minWidth: (input.minWidth + 'px'), width: (input.width + 'px') }}
                                                                    name={heading}
                                                                >
                                                                    {value + postFix}
                                                                    {expandEl}
                                                                </th>
                                                    }
                                                })
                                            }
                                            </tr>
                                        </thead>
                                    </table>
                                    </div>
                                    <div className="table-content-wrapper" onScroll={this.setPagination}>
                                    <div className={"inner table-content" + (["new", "new_assembly"].includes(this.props.fileType) ? '' : ' no-checkbox') } onScroll={this.onTableScroll}>
                                    <table>
                                        <TableData
                                            state={state}
                                            inputs={inputs}
                                            isValidRow={this.isValidRow}
                                            isRowDisabled={this.isRowDisabled}
                                            toggleRowState={this.toggleRowState}
                                            checkThisRow={this.checkThisRow}
                                            createCategoryTdMarkup={this.createCategoryTdMarkup}
                                            createUnitOfMeasureTdMarkup={this.createUnitOfMeasureTdMarkup}
                                            parentComponent={this}
                                            onInputChange={this.onInputChange}
                                            mpnImportDialog={this.props.mpnImportDialog.flag}
                                            selectFromLibrary={this.selectFromLibrary}
                                            isRowSelectedFromLibrary={this.isRowSelectedFromLibrary}
                                            fileType={this.props.fileType}
                                            tableSettings={tableSettings}
                                        />
                                    </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            </div>

        return markup
    }
}

export default ReviewScreen
