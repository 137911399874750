import                    "./index.css";
import                    "../index-generic.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {Component} from "react";
import Utils              from "../../../../../modules/utils";
import API                from "../../../../../modules/api";
import SimpleTable        from "../../../common/simple-table";
import PerfectScrollbar   from 'react-perfect-scrollbar'
import InlineIcon         from "../../../../ui/icon/inline-icon.js";
import VariantIcon        from "../../../../../assets/icons/variant-icon";
import CompareRevIcon     from "../../../../../assets/icons/compare-rev.js";
import Loading            from "../../../../ui/inner-loading"
import InlineImageViewer  from "../../../common/inline-image-viewer/view";
import Config             from "../../../../../modules/config";
import DeleteSrc          from "../../../../../assets/icons/cross-icon.js";
import GlobalSearch       from "../../../../ui/search";
import TimeStampColumn    from "../../../common/timestamp-column";
import Spinner            from "../../../../../assets/icons/spinner-green.js";
import PrimaryVariantIcon from "../../../../../assets/icons/checkbox-with-center-dot";
import PrimarySourceIcon  from "../../../../../assets/icons/checkbox-with-center-dot";
import DeleteIcon         from "../../../../../assets/icons/delete-cross"
import DisableIcon        from "../../../../../assets/icons/disable"
import $                  from "jquery";
import Tooltip            from 'rc-tooltip'
import coAlertSrc         from "../../../../../assets/icons/co-alert.svg";
import RevisionField      from "../../../common/revision-field";
import CpnField           from "../../../common/cpn-field";
import StatusField        from "../../../common/status-field";
import Link               from "../../../../ui/link";
import LinkIcon           from "../../../../../assets/icons/open-link-icon.js";

export class VariantModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            showLoading: false,
            showInnerLoading: false,
            disableButtons: true,
            showToolTip: false,
            updateSearchResults: false,
            selectedVariant: this.props.objectData._id,
            primaryVariant : this.props.objectData._id,
            stateChanged : false,
            permittedVariants : this.props.permittedVariants || [],
            variantGroup: this.props.objectData.variantGroup || null,
            primaryVariantStatus: this.props.objectData.status,
            variantModal: {
                open : false
            },
            compareIconHidden: false,

            list : this.props.objectData.variants && this.props.objectData.variants.items ? this.props.objectData.variants.items : this.props.objectData.variants || [],
            columns:
            [
                {
                    key         : "checkbox",
                    displayName :this.props.fromAssemblyTab ? "Active" :
                                 <div>
                                    <InlineIcon className="variant-heading-icon">
                                        <VariantIcon/>
                                    </InlineIcon>
                                 </div>,
                    tooltip     : "",
                    sortable    : this.props.fromAssemblyTab ? true : false,
                    headingClass: this.props.fromLibraryTable ? "disable-element" : "checkbox-col"
                },
                {
                    key         : "img",
                    displayName : "",
                    tooltip     : "",
                    sortable    : false,
                    headingClass: 'img-col'
                },
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "CPN",
                    sortable    : true,
                    headingClass: 'cpn-col'
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    headingClass: 'name-col'
                },
                {
                    key         : "revision",
                    displayName : "Rev",
                    tooltip     : "Revision",
                    sortable    : true,
                    headingClass: 'revision-col'
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    headingClass: 'status-cell'

                },
                {
                    key         : "lastUpdated",
                    displayName : "Last Updated",
                    tooltip     : "Last Updated",
                    sortable    : true,
                    headingClass: 'last-update-cell'
                },
                {
                    key         : "removeCol",
                    displayName : "",
                    tooltip     : "",
                    sortable    : false,
                    headingClass: this.props.fromLibraryTable ? 'disable-element' : 'remove-cell'
                }

            ]
        }
        this.baseState                      = JSON.parse(JSON.stringify(this.state));
        this.forceModifiedFlag              = false;
        this.openVariantModal               = this.openVariantModal.bind(this);
        this.closeVariantModal              = this.closeVariantModal.bind(this);
        this.onVariantModalExternalClick    = this.onVariantModalExternalClick.bind(this);
        this.setMyState                     = this.setMyState;
        this.createVariant                  = this.createVariant.bind(this);
        this.addToVariantGroup              = this.addToVariantGroup.bind(this);
        this.addToVariantModal              = this.addToVariantModal.bind(this);
        this.setcompareIconHidden           = this.setcompareIconHidden.bind(this);
        this.removeVariant                  = this.removeVariant.bind(this);
        this.resetState                     = this.resetState.bind(this);
        this.updateSearchResultsFlag        = this.updateSearchResultsFlag.bind(this);
        this.setVariantGroupOfNewlyAdded    = this.setVariantGroupOfNewlyAdded.bind(this);
        this.onCheckBoxChange               = this.onCheckBoxChange.bind(this);
        this.setVariantPermitted            = this.setVariantPermitted.bind(this);
        this.setPrimaryVariant              = this.setPrimaryVariant.bind(this);
        this.onActionClick                  = this.onActionClick.bind(this);
        this.setModalPosition               = this.setModalPosition.bind(this);
        this.setStatusValueInChildrenVariantsArray = this.setStatusValueInChildrenVariantsArray.bind(this);
        // this.setVariantsPermittedList       = this.setVariantsPermittedList.bind(this);
        this.imagesWithSrc                  = [];
    }

    createVariant(productId)
    {
        this.props.createVariant(productId);
        this.closeVariantModal();
    }

    componentWillMount()
    {
        let state = this.props.fromAssemblyTab ? Utils.clone(this.state) : this.state;
        let product = null;
        let component = null;
        if(this.props.objectData && this.props.objectData.alias === "prd")
        {
            product = this.props.objectData;
        }
        else
        {
            component = this.props.objectData;
        }
        if (this.props.getDataFromApi && product && product.variantGroup)
        {
            state.showLoading = true;
            if (state.showLoading)
            {
                this.openVariantModal();
            }
            this.setState(state)
            API.products.getProductVariants(product.variantGroup, {} , (err, data) =>
            {
                if(data)
                {
                    state.list = data.items;
                    this.baseState.list  = JSON.parse(JSON.stringify(data.items));
                    state.selectedVariant = data.primaryVariant;
                    state.showLoading = false;
                    this.setState(state, () =>
                    {
                        this.openVariantModal();
                    });
                }
            })
        }
        else if (this.props.getDataFromApi && component && component.variantGroup)
        {
            state.showLoading = true;
            if (state.showLoading)
            {
                this.openVariantModal();
            }
            this.setState(state);
            API.components.getComponentVariants(component.variantGroup, {} , (err, data) =>
            {
                if(data)
                {
                    state.list = data.items;
                    this.baseState.list  = JSON.parse(JSON.stringify(data.items));
                    state.selectedVariant = data.primaryVariant;
                    state.showLoading = false;

                    if (this.props.fromAssemblyTab)
                    {
                        this.setStatusValueInChildrenVariantsArray(state)
                        this.baseState.permittedVariants = JSON.parse(JSON.stringify(state.permittedVariants));
                    }
                    this.setState(state, () =>
                    {
                        this.openVariantModal();
                    })
                }
            })
        }
        else
        {
            this.openVariantModal();
        }
    }

    setStatusValueInChildrenVariantsArray(state)
    {
        state.permittedVariants.forEach((childVariant) => {
            state.list.forEach((item) => {
                if (item._id === childVariant.variant)
                {
                    childVariant.status = item.status
                }
            })
        });

        state.permittedVariants.forEach((item) =>
        {
            if(Utils.getStatusRank(item.status) < Utils.getStatusRank(state.primaryVariantStatus))
            {
                item.isPermitted = false;
            }
        });
    }

    componentDidUpdate()
    {
        if (this.props.variantModalPosition && this.props.variantModalPosition.top)
        {
            this.setModalPosition()
        }
    }

    setVariantGroupOfNewlyAdded(variantGroupId, list)
    {
        list.forEach((item) =>
        {
            if(!item.variantGroup)
            {
                item.variantGroup = variantGroupId;
            }
        });
    }

    setModalPosition()
    {
        let VariantModalPosition  = this.props.variantModalPosition
        let VariantModalElement = document.getElementsByClassName("custom-duro-modal variant-modal")[0]

        if (VariantModalElement)
        {
            let offsetHeight = VariantModalElement.offsetHeight
            VariantModalElement.style.top = ( VariantModalPosition.top - ( offsetHeight/2 ) ) + 'px'
            VariantModalElement.style.left = ( VariantModalPosition.left ) + 'px'
        }
    }

    addToVariantGroup()
    {
        let state = this.state;
        state.showInnerLoading = true;
        this.setState(state);

        let addedVariants = [];
        let removedVariants = [];
        if(!state.variantGroup)
        {
            addedVariants.push(this.props.objectData._id);
        }

        state.list.forEach((item, i) =>
        {
            addedVariants.push(item._id);
        });

        let existingVariants =  JSON.parse(JSON.stringify(this.baseState.list));
        existingVariants.forEach((item, i) =>
        {
            if(!addedVariants.includes(item._id))
            {
                removedVariants.push(item._id);
            }
        });

        if(this.props.objectData.alias === 'cmp')
        {
            API.components.addComponentToVariantGroup(this.props.objectData._id, addedVariants, removedVariants, (err, data) =>
            {
                //Refresh product view routes state if variant group is deleted
                if (!data && this.props.refreshItemViewRoute)
                {
                    return this.props.refreshItemViewRoute()
                }

                if(data)
                {
                    if (!state.variantGroup)
                    {
                        this.props.updateObjectData();
                    }
                    this.setVariantGroupOfNewlyAdded(data, state.list);
                    this.baseState.list  = JSON.parse(JSON.stringify(state.list));
                }

                if(this.props.updateVariantsTab)
                {
                    this.props.updateVariantsTab(true);
                }

                state.showInnerLoading = false;
                state.disableButtons   = true;
                state.stateChanged     = false;
                state.updateSearchResults = true;
                this.forceModifiedFlag = true;
                this.setState(state);
            })
        }
        else if(this.props.objectData.alias === 'prd')
        {
            API.products.addProductToVariantGroup(this.props.objectData._id, addedVariants, removedVariants, (err, data) =>
            {
                //Refresh product view routes state if variant group is deleted
                if (!data && this.props.refreshItemViewRoute)
                {
                    return this.props.refreshItemViewRoute()
                }
                else if (!data && this.props.fromDashboard) //Note: Refresh dashabod route if variant group is deleted
                {
                    return this.props.updateObjectData(this.props.objectData._id);
                }

                if(data)
                {
                    if (!state.variantGroup || this.props.fromDashboard)
                    {
                        this.props.updateObjectData(this.props.objectData._id);
                    }
                    this.setVariantGroupOfNewlyAdded(data, state.list);
                    this.baseState.list  = JSON.parse(JSON.stringify(state.list));
                }

                if(this.props.updateVariantsTab)
                {
                    this.props.updateVariantsTab(true);
                }

                state.showInnerLoading = false;
                state.disableButtons   = true;
                state.stateChanged     = false;
                state.updateSearchResults = true;
                this.forceModifiedFlag = true;
                this.setState(state);
            })
        }
    }

    addToVariantModal(item)
    {
        let state = this.state;
        item.displayType = null;
        state.list.push(item);
        state.disableButtons = false;
        state.stateChanged   = true;
        this.setState(state);
    }

    componentDidMount(){
        this._ismounted = true;
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    setMyState(state = this.state, cb){
        if (this._ismounted){
            this.setState(state, cb);
        }
    }

    openVariantModal(event)
    {
        document.body.addEventListener("click", this.onVariantModalExternalClick);
        let variantModal  = this.state.variantModal;
        variantModal.open = true;
/*        if(this.props.fromAssemblyTab)
        {
            this.setVariantsPermittedList();
        }*/
        this.setMyState(this.state);
    }

    closeVariantModal()
    {
        document.body.removeEventListener("click", this.onVariantModalExternalClick);
        let variantModal  = this.state.variantModal;
        variantModal.open = false;
        this.forceModifiedFlag = false;
        this.setMyState(this.state, this.props.closeVariantModal);
    }

    onVariantModalExternalClick(event)
    {
        let target     = event.target;
        let parent     = this.refs.variantModalNode;
        let isExternal = target !== parent && !Utils.isDescendant(parent, target);
        let InlineImageViewer = document.getElementsByClassName('inline-image-viewer-tooltip');
        if(InlineImageViewer && InlineImageViewer[0] && !InlineImageViewer[0].classList.contains('rc-tooltip-hidden'))
        {
            isExternal = false;
        }
        if(isExternal) this.closeVariantModal()
    }

    selectVariant(e, result)
    {
        let state = this.state;
        if (state.selectedVariant && state.selectedVariant === result._id)
        {
            state.selectedVariant = null;
        }
        else if (result._id !== state.selectedVariant)
        {
            state.selectedVariant = result._id;
        }
        state.stateChanged = true;
        this.setState(state);
    }

    setVariantPermitted(e, result)
    {
        let state = this.state;
        state.showInnerLoading = true;
        this.setState(state);
        let parent = this.props.objectData.parent;
        if(parent.alias === 'cmp')
        {
            API.components.setVariantPermitted(parent._id, this.props.objectData._id, state.permittedVariants, (err, data) =>
            {
                if(data)
                {
                    this.baseState.permittedVariants = JSON.parse(JSON.stringify(state.permittedVariants));
                    this.baseState.primaryVariant = JSON.parse(JSON.stringify(state.primaryVariant));
                    this.props.updatePrimaryVariant(this.props.objectData._id, data, parent._id, state.permittedVariants)

                    if(data._id !== this.props.objectData._id)
                    {
                        state.primaryVariantStatus = data.status;
                    }
                    else
                    {
                        Utils.setStore("lastAssemblyParent", null);
                    }
                    this.props.closeVariantModal();
                }
                //Todo: handle the error in case of unique children at specicfic level
                state.disableButtons   = true;
                state.showInnerLoading = false;
                state.stateChanged     = false;
                this.setState(state);
            });
        }
        else if(parent.alias === 'prd')
        {
            API.products.setVariantPermitted(parent._id, this.props.objectData._id, state.permittedVariants, (err, data) =>
            {
                if(data)
                {
                    this.baseState.permittedVariants = JSON.parse(JSON.stringify(state.permittedVariants));
                    this.baseState.primaryVariant = JSON.parse(JSON.stringify(state.primaryVariant));
                    this.props.updatePrimaryVariant(this.props.objectData._id, data, parent._id, state.permittedVariants)

                    if(data._id !== this.props.objectData._id)
                    {
                        state.primaryVariantStatus = data.status;
                    }
                    else
                    {
                        Utils.setStore("lastAssemblyParent", null);
                    }
                    this.props.closeVariantModal();
                }

                //Todo: handle the error in case of unique children at specicfic level
                state.disableButtons   = true;
                state.showInnerLoading = false;
                state.stateChanged     = false;
                this.setState(state);
            });
        }
    }

    setPrimaryVariant(e, result)
    {
        let state = this.state;
        let primaryVariantStatus = "DESIGN"
        state.permittedVariants.forEach((item) =>
        {
            if(item.variant === result._id)
            {
                item.isPrimary   = true;
                item.isPermitted = true;
                primaryVariantStatus = item.status
            }
            else if(item.variant === state.primaryVariant)
            {
                item.isPrimary = false;
            }
        });
        //Note: Set isPermitted false if primary vairant's status is heigher
        state.permittedVariants.forEach((item) =>
        {
            if(Utils.getStatusRank(item.status) < Utils.getStatusRank(primaryVariantStatus))
            {
                item.isPermitted = false;
            }
        });
        state.primaryVariantStatus = primaryVariantStatus;
        state.primaryVariant = result._id;
        state.disableButtons = false;
        state.stateChanged   = true;
        this.setState(state);
    }

    onCheckBoxChange(event, result)
    {
        let value = event.target.checked;
        let state = this.state;
        state.disableButtons = false;
        state.stateChanged   = true;
        if(value)
        {
            state.permittedVariants.forEach((item) =>
            {
                if(item.variant === result._id)
                {
                    item.isPermitted = true;
                }
            });
        }
        else
        {
            state.permittedVariants.forEach((item) =>
            {
                if(item.variant === result._id)
                {
                    item.isPermitted = false;
                }
            });
        }
        this.setState(state);
    }

    setcompareIconHidden(hide)
    {
        let state = this.state;
        state.compareIconHidden = hide;
        this.setState(state);
    }

    removeVariant(variantId)
    {
        let state = this.state;
        state.list.forEach((variant, i) =>
        {
          if(variant._id === variantId)
          {
            state.list.splice(i, 1);
          }
        });
        state.disableButtons = false;
        state.stateChanged   = true;
        this.setState(state);
    }

    resetState()
    {
        let baseState = JSON.parse(JSON.stringify(this.baseState))
        let state     = this.state;
        state.list    = baseState.list;
        state.selectedVariant = baseState.selectedVariant;
        state.permittedVariants = baseState.permittedVariants;
        state.primaryVariant = baseState.primaryVariant;
        state.primaryVariantStatus = baseState.primaryVariantStatus;
        state.disableButtons = true;
        state.stateChanged   = false;
        this.setState(state);
    }

    updateSearchResultsFlag()
    {
        let state = this.state;
        state.updateSearchResults = false;
        this.setState(state);
    }

    onActionClick(event, result)
    {
        event.stopPropagation()
        $("#action-item-" + result._id).removeClass('hidden');

        let element = event.target
        let parentTableEl = document.getElementsByClassName("custom-duro-modal  open")[0]

        var elementPos = element.getBoundingClientRect();
        var parentPos = parentTableEl.getBoundingClientRect();

        let top  = elementPos.top - parentPos.top
        let right = elementPos.right - parentPos.right
        top = top - 51;
        right = 39
        let $el =  $("#action-item-" + result._id)
        $el.css({
            top: top,
            right: right,
            'z-index': 999
        })
        $el.removeClass('hidden');

        document.body.addEventListener("click", this.outsideClickListener)

        $(".scrollbar-container").scroll(function() {
            $('.action-pop-item').addClass("hidden")
        })
    }

    outsideClickListener(event){
        let $target = $(event.target);

        if(!$target.closest('.action-pop-item').length)
        {
            $('.action-pop-item').addClass("hidden")
            document.body.removeEventListener("click", this.outsideClickListener)
        }
    }

/*    setVariantsPermittedList()
    {
        let state = this.state;
        if(state.permittedVariants.length < state.list.length)
        {
            let isExist = false;
            state.list.forEach((variant) =>
            {
                isExist = false
                state.permittedVariants.forEach((item) =>
                {
                    if(item.variant === variant._id)
                    {
                        isExist = true;
                    }
                });
                if(!isExist)
                {
                    let isPrimary = variant._id === state.primaryVariant ? true : false;
                    state.permittedVariants.push({isPrimary: isPrimary, isPermitted: isPrimary ? true : false, variant: variant._id});
                }
            });
            this.baseState.permittedVariants = JSON.parse(JSON.stringify(state.permittedVariants));
            this.setState(state);
        }
    }*/

    render()
    {
        let markup = null;
        let variantModal = this.state.variantModal;
        let objectData = this.props.objectData;
        let state = this.state;
        let headings = this.state.columns;
        let totalPermittedVariants = 0;
        let compareVariantsLink = `/component/view/${this.props.objectData._id}?tab=${'variants'}`;
        if (state.showLoading && (this.props.fromAssemblyTab || this.props.fromLibraryTable))
        {
            return <Loading />
        }
        let rows = state.list.length > 0 ? state.list.map((result, i) =>
        {
            let dateTime = Utils.dateTimeWithLongFormat(result.lastModified);
            let isPermitted = false;
            if(this.props.fromAssemblyTab)
            {
                totalPermittedVariants = 0
                state.permittedVariants.forEach((item) =>
                {
                    if(item.variant === result._id)
                    {
                        isPermitted = item.isPermitted;
                    }
                    if(item.isPermitted)
                    {
                        totalPermittedVariants++;
                    }
                });
            }
            let disableCheckBox = false;
            let disabledFromVendor = this.props.objectData.fixedPrimaryByVendor;
            let existInAssembly = false;
            if(this.props.fromAssemblyTab && objectData.chilIdsOfthisLevel && objectData.chilIdsOfthisLevel.includes(result._id) && this.props.objectData._id !== result._id)
            {
                existInAssembly = true;
            }
            if(Utils.getStatusRank(result.status) < Utils.getStatusRank(state.primaryVariantStatus))
            {
                disableCheckBox = true;
            }
            let cells =
            {
                "checkbox":
                {
                    notLink     : true,
                    cellClass   : this.props.fromLibraryTable ? 'disable-element' : '',
                    displayValue: result.variantGroup && !this.props.fromAssemblyTab?
                                    <div className="rounded-checkbox">
                                        <input
                                            className="checked-input"
                                            type="checkbox"
                                            checked={result._id === state.selectedVariant ? true : false}
                                            onClick={(e) => this.selectVariant(e, result)}
                                        />
                                        {<label className={`checked-label`}/>}
                                    </div> :
                                    result.variantGroup && this.props.fromAssemblyTab && result._id === this.state.primaryVariant ?
                                    <span className="center-state checkbox active" ></span>
                                    :
                                    result.variantGroup && this.props.fromAssemblyTab ?
                                    <div className={`tooltip-checkbox ${disableCheckBox || disabledFromVendor || existInAssembly ? "disable-checkbox" : ""}`}>
                                    <Tooltip
                                      overlayClassName={`rc-bottom-custom-arrow ${disableCheckBox || disabledFromVendor || existInAssembly? "" : "hidden"}`}
                                      placement={"top"}
                                      overlay={
                                          <div className={`messeage-tip`}>
                                            <span>{disabledFromVendor ? Utils.vendorTooltip(Utils.getVendor(this.props.objectData)) : existInAssembly ? "Component already included in this assembly" : "Component must have an equal or greater Status value as the Primary Component"}</span>
                                          </div>
                                      }
                                    >
                                    <span>
                                        <input
                                        className="checked-input"
                                        type="checkbox"
                                        checked={isPermitted ? true : false}
                                        onChange={(event) => !disableCheckBox && !disabledFromVendor && !existInAssembly ? this.onCheckBoxChange(event, result) : null}
                                        />
                                        {<label className={`checked-label`}/>}
                                    </span>
                                    </Tooltip>
                                    </div> : null
                },
                "img":
                {
                    value        : "",
                    tooltip      : "",
                    displayValue :
                                    <div className={"variant-img" + (result.images && result.images.length === 0 ? "default-img variant-inline-image-viewer" : "")}>
                                    {
                                        <InlineImageViewer
                                            imagesWithSrc={this.imagesWithSrc}
                                            key={Utils.generateUniqueId()}
                                            defaultResolution={Config.defaultResolutions.featureImage}
                                            images={result.images}
                                        />
                                    }
                                    </div>,
                    cellClass    : 'inline-image-viewer',
                    notLink      : true
                },
                "cpn":
                {
                    value        : result.cpn,
                    tooltip      : result.cpn,
                    displayValue : <span className="link"><CpnField item={result} cpn={Utils.getCpn(result)}/></span>,
                    cellClass    : 'cpn-col'
                },
                "name":
                {
                    value       : result.name,
                    tooltip     : result.name,
                    displayValue: result.name,
                    cellClass   : 'name-col'
                },
                "revision":
                {
                    value       : result.revision,
                    tooltip     : result.revision,
                    displayValue: <RevisionField item={result} revision={result.revision} showIcon={((result.revision && result.modified) || (this.forceModifiedFlag && result.status !== "DESIGN"))}/>,
                    cellClass   : 'revision-col'
                },
                "status":
                {
                    value       : result.status,
                    tooltip     : result.status,
                    displayValue: <StatusField item={result} status={result.status}/>,
                    cellClass   : 'status-cell'

                },
                "lastUpdated":
                {
                    value       : result.lastModified ? result.lastModified : '',
                    tooltip     : `${dateTime.dateValue} ${dateTime.timeValue}`,
                    displayValue: <TimeStampColumn
                                    key={Utils.generateUniqueId()}
                                    format='date-time-with-long-format'
                                    value={result.lastModified}/>,
                    cellClass   : 'status-cell'

                },

                "removeCol":
                {
                    notLink      : true,
                    displayValue :  this.props.fromAssemblyTab ?
                                    <Tooltip
                                      overlayClassName={`rc-bottom-custom-arrow ${disableCheckBox || disabledFromVendor || existInAssembly ? "" : "hidden"}`}
                                      placement={"top"}
                                      overlay={
                                          <div className={`messeage-tip`}>
                                            <span>{disabledFromVendor ? Utils.vendorTooltip(Utils.getVendor(this.props.objectData)) : existInAssembly ? "Component already included in this assembly" : "Component must have an equal or greater Status value as the Primary Component"}</span>
                                          </div>
                                      }
                                    >
                                    <div onClick={(e) => state.primaryVariant !== result._id && !disableCheckBox && !disabledFromVendor && !existInAssembly ? this.onActionClick(e, result) : null} className={`action-popover-trigger ${state.primaryVariant === result._id || disableCheckBox || disabledFromVendor || existInAssembly ? "disabled" : ''}`}>
                                        <span className="dotted-icon">...</span>
                                    </div>
                                    </Tooltip>
                                    : this.props.objectData._id !== result._id ?
                                    <div className="remove-variant">
                                        <InlineIcon onClick={(e) => this.removeVariant(result._id)}>
                                            <DeleteSrc />
                                        </InlineIcon>
                                    </div> : null,
                    cellClass   : this.props.fromLibraryTable ? 'disable-element' : 'remove-cell',
                    tooltip     : ""
                },

                rowLink         : this.props.objectData.alias==='prd' ? `/product/view/${result._id}` : `/component/view/${result._id}`,
                rowClassName    : this.props.fromAssemblyTab && result._id === this.state.primaryVariant ? 'success' : null,
            }
            return cells

        }) : []

        markup =
            <div
                ref="variantModalNode"
                id="variant-table"
                className={`custom-duro-modal variant-modal${(variantModal.open ? " open" : "")}`}
                // This is needed to keep the dialog from closing when something inside of the dialog is selected.
                onClick={(e) => e.stopPropagation()}
            >
                 <div>
                    <div className="header-block flex-h">
                        <div className="flex-v header-block-left-col">
                            <div className="product-variant-title">
                                {objectData.alias === "prd" ? "Product Variants" : "Component Variants"}
                            </div>
                            {!this.props.fromAssemblyTab && !this.props.fromLibraryTable ?
                                <div className="creat-new-variant-link">
                                    <div className={`variant-popup-header-btn flex`}
                                         onClick={(e) => this.createVariant(state.selectedVariant? state.selectedVariant : objectData._id)}
                                        >
                                        <InlineIcon>
                                            <VariantIcon/>
                                        </InlineIcon>
                                        Create New Variant
                                    </div>
                                </div> : null
                            }
                            {!this.props.fromAssemblyTab && !this.props.fromLibraryTable?
                                <div className="search-row">
                                    <GlobalSearch
                                        search={this.props.search}
                                        location={this.props.location}
                                        history={this.props.history}
                                        placeholder="Add from Library"
                                        category={this.props.objectData.category}
                                        alias={this.props.objectData.alias}
                                        AddToVariantGroup={this.addToVariantModal}
                                        isVariantModal={true}
                                        variants={state.list}
                                        hideCompareIcon={this.setcompareIconHidden}
                                        currentObjectId={this.props.objectData._id}
                                        updateSearchResults={state.updateSearchResults}
                                        updateSearchResultsFlag={this.updateSearchResultsFlag}
                                    />
                                </div> : this.props.fromAssemblyTab ?
                                <div className="compare-variants-link">
                                    <Link
                                            to={compareVariantsLink}
                                            target="_blank"
                                            className="compare-var-btn flex"
                                            >
                                            <InlineIcon>
                                                    <CompareRevIcon/>
                                            </InlineIcon>
                                            <h4 className="compare-icon-label">Compare Variants</h4>
                                            <InlineIcon className="link-icon"><LinkIcon/></InlineIcon>
                                        </Link>
                                </div> : null
                            }
                        </div>
                        <div className={`flex-v header-block-right-col ${state.compareIconHidden || this.props.fromLibraryTable ? "hidden" : null}`}>
                            <div className="total-variants">
                                {this.props.fromAssemblyTab ? `${totalPermittedVariants} of ${state.list.length} Variants` : `${state.list.length} Variants`}
                            </div>
                        </div>

                    </div>
                    <div className="content-block">
                        {
                            state.showLoading ? <Loading /> :
                            <PerfectScrollbar className="custom-table-scroll">
                                <SimpleTable
                                    headings={headings}
                                    rows={rows}
                                    currentSortItem={"cpn"}
                                    currentSortItemAscending={true}
                                    footerElForNoEmptyData="No Variants"
                                />
                            </PerfectScrollbar>
                        }
                    </div>
                </div>
                {
                    state.list.length > 0 ?
                    <div className="bottom-section">
                        <div className="button-sections flex-v">
                          <button
                              type="button"
                              onClick={this.state.stateChanged ? this.resetState : this.closeVariantModal}
                              className={this.props.fromLibraryTable ? 'save-btn filled' : 'dark'}
                              >
                            {this.props.fromLibraryTable ? 'Ok' : 'Cancel'}
                          </button>
                        { !this.props.fromLibraryTable ?
                          <button
                              type="button"
                              onClick={this.props.fromAssemblyTab ? this.setVariantPermitted : this.addToVariantGroup}
                              className={`save-btn filled ${state.disableButtons ? "disabled" : ''}`}>
                            <span>Save</span>
                            {
                                state.showInnerLoading ?
                                <div className="spin-block">
                                    <div className="spinner">
                                        <InlineIcon>
                                            <Spinner/>
                                        </InlineIcon>
                                    </div>
                                </div>
                                : null
                            }
                          </button> : null
                        }
                        </div>
                    </div> : null
                }
                {
                    state.list.map((result, i) =>
                    {
                        let disableClazz = state.primaryVariant !== result._id ? true : false;
                        let actionsList = []
                        let disableAction = {label: "Set As Primary", icon: <PrimarySourceIcon />,  to: "#", state: this.state, onClick: (event)=>this.setPrimaryVariant(event, result) }
                        if (disableClazz)
                        {
                            actionsList.push(disableAction)
                        }

                        let options = []
                        actionsList.forEach((option, index) =>
                        {
                            let block =
                                <span className={option.disabled ? "disabled" : ""} key={index + 'u-' + i} onClick={(e) => option.onClick(e, option)}>
                                    <div className="action-option">
                                        <InlineIcon>{option.icon}</InlineIcon>
                                        <div className="label">{
                                            option.label
                                        }</div>
                                    </div>
                                </span>

                            options.push(block)
                        })

                        return disableClazz ? <div key={"action-item-" + result._id} id={"action-item-" + result._id} className={"action-pop-item hidden action-popover"}> {options} </div>:null
                    })
                }
            </div>
        return markup
    }
}

export default VariantModal
