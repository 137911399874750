import React, { FC, FormEventHandler, ReactNode, useMemo } from "react";
import { Button } from "@mui/material";
import {
  BaseModal,
  HeaderSection,
  ActionSection,
  Content,
} from "../base-modal";
import { ButtonVariants } from "common/constants";

type ActionBtnCallBack = (e: MouseEvent | React.MouseEvent) => void;
export interface IFormPropTypes {
  cancelLabel?: string,
  children: ReactNode,
  disableSubmit: boolean,
  modalProps?: {
    width?: string,
  }
  onCancel?: ActionBtnCallBack,
  onClose: ActionBtnCallBack,
  onSubmit: FormEventHandler<HTMLFormElement>,
  open: boolean,
  submitLabel?: string,
  title: string | JSX.Element,
}

export interface IMemoTypes {
  _cancelCallback: ActionBtnCallBack,
  _cancelLabel: string,
  _submitLabel: string,
}

export const FormModal: FC<IFormPropTypes> = ({
  cancelLabel,
  children,
  disableSubmit,
  modalProps,
  onCancel,
  onClose,
  onSubmit,
  open,
  submitLabel,
  title,
}) => {
  const { _cancelCallback, _cancelLabel, _submitLabel } = useMemo((): IMemoTypes => ({
    _cancelCallback: onCancel ?? onClose,
    _cancelLabel: cancelLabel ?? "Cancel",
    _submitLabel: submitLabel ?? "Save",
  }), [cancelLabel, submitLabel, onCancel, onClose]);

  return (
    <BaseModal
      modalProps={modalProps}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={onSubmit}>
        <HeaderSection label={title} onClose={onClose} />
        <Content>
          {children}
        </Content>
        <ActionSection>
          <Button variant={ButtonVariants.OUTLINED} color="info" onClick={_cancelCallback}>{_cancelLabel}</Button>
          <Button
            color="primary"
            disabled={disableSubmit}
            type="submit"
            variant={ButtonVariants.CONTAINED}
          >
            {_submitLabel}
          </Button>
        </ActionSection>
      </form>
    </BaseModal>
  );
};
