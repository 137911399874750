import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Divider, IconButton, InputAdornment, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RemoveIcon from "@mui/icons-material/Remove";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputFieldVariants } from "common/constants";
import { DateTimePicker } from "common/date-time-picker";
import { FieldTitle } from "common/field-title";
import { FormModal } from "common/form-modal";
import { InputField } from "common/input-field";
import { Switch } from "common/switch";
import { TextArea } from "common/text-area";
import { AliasType, ModelType } from "graphql/query/itemQueries";
import { useGetItem } from "../../hooks/useGetItem";
import { INode, Table } from "./table";
import { colorPalette } from "theme";

type ActionBtnCallBack = (e: MouseEvent | React.MouseEvent) => void;
export interface ISerializableItemProps {
  _id: string,
  alias: AliasType,
  childrenCount: number,
  parent?: string,
}

export interface ICpn {
  __typename: string,
  counter: number,
  displayValue: string,
  id: string,
  prefix: string,
  variant: string,
}
export interface IChildrenItemProps {
  component: IParentItemProps,
  quantity?: number,
}

export type IParentItemProps = INode & {
  children?: IChildrenItemProps[],
  cpn: ICpn,
  lastModified: number,
}

export interface ISerilizationModalPropTypes {
  itemToBeSerialized: ISerializableItemProps,
  onCancel?: ActionBtnCallBack,
  onClose: ActionBtnCallBack,
  onSubmit: (e: object) => void,
  open: boolean,
  title: string | JSX.Element,
}

export interface IMemoTypes {
  _buildDashboardToggleLabel: string,
  _childrenToggleLabel: string,
}

const initialCurrentItem: IParentItemProps = {
  alias: AliasType.CMP,
  category: "",
  children: [],
  cpn: {
    counter: 0,
    displayValue: "",
    id: "",
    prefix: "",
    __typename: "",
    variant: "",
  },
  id: "",
  lastModified: 0,
  name: "",
  parent: "",
  status: "",
  version: "",
};

const cpnLabelStyle = { color: colorPalette.taupeGray };
const datePickerProps = {
  calendarStyle: { placement: "bottom-start" },
  customStyle: { display: "block", alignItems: "flex-start" },
  inputWrapperProps: {
    style: {
      backgroundColor: colorPalette.gray,
    },
  },
  renderInputProps: {
    placeholder: "Enter Date",
    style: { height: "1.25rem" },
    variant: InputFieldVariants.FILLED,
  },
};

const formModalStyle = { width: "94.5rem" };
const notesStyle = { borderBottom: `0.063rem solid ${colorPalette.doveGray}` };
const toggleLabelStyle = {
  fontSize: "0.75rem",
  marginBottom: "1rem",
};

export const SerializationModal: FC<ISerilizationModalPropTypes> = (({
  itemToBeSerialized,
  onClose,
  onSubmit,
  open,
  title,
}) => {
  const [buildDashboardToggle, setBuildDashboardToggle] = useState<boolean>(false);
  const [childrenToggle, setChildrenToggle] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<IParentItemProps>(initialCurrentItem);
  const [dateValue, setDateValue] = useState<string | null>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [lotLabels, setLotLabels] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [productionRun, setProductionRun] = useState<number>(1);
  const { alias, _id, parent } = itemToBeSerialized;
  const {
    childItems,
    childrenLoading,
    fetchChildren,
    fetchParent,
    parentError,
    parentItem,
    parentLoading,
  } = useGetItem(alias, "cache-first", parent ? ModelType.REVISION : ModelType.WORKING);

  useEffect(() => {
    fetchParent(_id);
  }, [_id, fetchParent]);

  useEffect(() => {
    if (!parentLoading && Object.keys(parentItem).length > 0) {
      setCurrentItem(parentItem[0]);
    }
    else if (parentError) {
      setCurrentItem(initialCurrentItem);
    }
  }, [parentError, parentItem, parentLoading]);

  useEffect(() => {
    const isChildrenLoaded: boolean = !childrenLoading && !!Object.keys(childItems)?.length
      && childItems[0]?.children?.length !== currentItem?.children?.length;
    if (isChildrenLoaded) {
      setCurrentItem({ ...currentItem, children: childItems[0].children });
    }
  }, [childItems, childrenLoading, currentItem]);

  const { _buildDashboardToggleLabel, _childrenToggleLabel } = useMemo((): IMemoTypes => ({
    _childrenToggleLabel: childrenToggle ? "Enabled" : "Disabled",
    _buildDashboardToggleLabel: buildDashboardToggle ? "Enabled" : "Disabled",
  }), [buildDashboardToggle, childrenToggle]);

  const onChangeLotLabels = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLotLabels(e.target.value);
  }, []);

  const onChangeNotes = useCallback((value: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(value);
  }, []);

  const onChangeDatePicker = useCallback((value: string | null) => {
    setDateValue(value);
    setDisableSubmit(value === "Invalid date");
  }, []);

  const handleOnSubmit = useCallback(() => {
    const data = {
      currentItem,
      dateValue,
      lotLabels,
      notes,
      productionRun,
    };
    onSubmit(data);
  }, [currentItem, dateValue, lotLabels, notes, onSubmit, productionRun]);

  const handleOnIncrement = useCallback(() => setProductionRun(productionRun + 1), [productionRun]);
  const handleOnDecrement = useCallback(() => setProductionRun(productionRun - 1), [productionRun]);

  const handleBuildDashboardToggle = useCallback(() => {
    setBuildDashboardToggle(!buildDashboardToggle);
  }, [buildDashboardToggle]);

  const handlePopulateChildrenToggle = useCallback(() => {
    setChildrenToggle(!childrenToggle);
    fetchChildren(_id);
  }, [_id, childrenToggle, fetchChildren]);

  const { calendarStyle, customStyle, inputWrapperProps, renderInputProps } = datePickerProps;

  if (Object.keys(parentItem).length === 0) return null;
  return (
    <FormModal
      disableSubmit={disableSubmit}
      modalProps={formModalStyle}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      open={open}
      title={title}
    >
      <FieldsWrapper>
        <CpnField>
          <InputField
            disabled={true}
            isRequired={false}
            label={"CPN"}
            labelStyling={cpnLabelStyle}
            value={currentItem.cpn?.displayValue}
            variant={InputFieldVariants.FILLED}
          />
        </CpnField>
        <ProductionRunField>
          <InputField
            inputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Decrement"
                    disabled={productionRun === 1}
                    edge="end"
                    onClick={handleOnDecrement}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                  <CustomDivider orientation="vertical" variant="middle" />
                  <IconButton
                    aria-label="Increment"
                    edge="end"
                    onClick={handleOnIncrement}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>,
            }}
            isRequired={true}
            label={"Production Run"}
            value={productionRun}
            variant={InputFieldVariants.FILLED}
          />
        </ProductionRunField>
        <DatePickerField>
          <DateTimePicker
            calendarStyle={calendarStyle}
            customStyle={customStyle}
            EnclosingTag={MuiDatePicker}
            inputFormat={"MMM D, YYYY"}
            inputWrapperProps={inputWrapperProps}
            isRequired={true}
            label={"Production Date"}
            onChange={onChangeDatePicker}
            openPickerIcon={CalendarMonthIcon}
            renderInputProps={renderInputProps}
          />
        </DatePickerField>
        <LotLabels>
          <InputField
            isRequired={false}
            label={"Lot Label"}
            onChange={onChangeLotLabels}
            placeholder={"Enter Lot Labels (separate by commas)"}
            variant={InputFieldVariants.FILLED}
          />
        </LotLabels>
        <Notes>
          <TextArea
            characterLimit={500}
            isRequired={false}
            label={"Lot Notes"}
            onChange={onChangeNotes}
            placeholder={"Enter Lot Notes"}
            styleProps={notesStyle}
          />
        </Notes>
        <ToggleWrapper>
          <ToggleButton>
            <FieldTitle
              isRequired={false}
              label={"Include Children"}
              sx={toggleLabelStyle}
            />
            <Switch label={_childrenToggleLabel} onChange={handlePopulateChildrenToggle} />
          </ToggleButton>
          <ToggleButton>
            <FieldTitle
              isRequired={false}
              label={"Add to Build Dashboard"}
              sx={toggleLabelStyle}
            />
            <Switch label={_buildDashboardToggleLabel} onChange={handleBuildDashboardToggle} />
          </ToggleButton>
        </ToggleWrapper>
      </FieldsWrapper>
      <TableWrapper>
        <Table
          childrenCount={itemToBeSerialized.childrenCount}
          childrenToggle={childrenToggle}
          currentItem={currentItem}
          loading={childrenLoading}
          productionRun={productionRun}
        />
      </TableWrapper>
    </FormModal>
  );
});

const CpnField = styled(Box)({
  paddingRight: "0.5rem",
  width: "12.5%",
});

const CustomDivider = styled(Divider)({
  padding: "0.438rem",
});

const DatePickerField = styled(Box)({
  padding: "0 0.5rem",
  width: "12.5%",
});

const FieldsWrapper = styled(Box)({
  alignItems: "flex-start",
  display: "flex",
  marginBottom: "1.563rem",
  position: "relative",
});

const LotLabels = styled(Box)({
  padding: "0 0.5rem",
  width: "40%",
});

const Notes = styled(Box)({
  paddingLeft: "0.5rem",
  width: "22%",
});

const ProductionRunField = styled(Box)({
  padding: "0 0.5rem",
  width: "13%",
});

const ToggleButton = styled(Box)({
  marginRight: "0.938rem",
  minWidth: "10.938rem",
});

const ToggleWrapper = styled(Box)({
  bottom: "0",
  display: "flex",
  justifyContent: "space-around",
  left: "0",
  position: "absolute",
});

const TableWrapper = styled(Box)({
  display: "flex",
  maxWidth: "92.5rem",
});
