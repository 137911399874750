// TODO: make all image source files uppercase
// TODO: create component navigation block
import                                          "./index.css"
import React, { Component, Fragment }           from "react"
import { getLegacyCpnConfig } from "utils/cpn"
import Link                                     from "../../../ui/link"
import Loading                                  from "../../../ui/inner-loading"
import ConfirmBox                               from "../../../ui/alert/confirm.js"
import { connect } from 'react-redux';
import Schemas                                  from "../../../../modules/schemas"
import Utils                                    from "../../../../modules/utils"
import COMPONENT                                from "../../../../action-types/component"
import ITEM_ASSEMBLY                            from "../../../../action-types/item-assembly"
import UI                                       from "../../../../action-types/ui"
import buildAction                              from "../../../../helpers/buildAction"
import EditIcon                                 from "../../../../assets/icons/edit"
import Tabs                                     from "../../common/tabs/edit"
import SourcingUtils                            from "../../common/sourcing/sourcing-utils"
import FileImportModule                         from "../new/file/file-import-module"
import CategorySelect                           from "../new/modules/category-select"
import AssemblySearchMenu                       from "../../common/assembly/edit/modules/assembly-search-menu"
import validations, { validateField } from "../../../../modules/validations"
import ReactTooltip                             from 'react-tooltip'
import $                                        from "jquery";
import AlertErrorIcon                           from "../../../../assets/icons/alert-error"
import WarningTriangleIcon                      from "../../../../assets/icons/warning-triangle"
import { Prompt }                               from 'react-router'
import WarningModal                             from "../../../ui/warning-modal"
import {Helmet}                                 from "react-helmet"
import LazyInput                                from "../../../ui/lazy-input/input.js"
import TextArea                                 from "../../../ui/lazy-input/textarea.js"
import Actions                                  from "./actions.js"
import DISPLAYSIDEBAR                           from "../../../../action-types/display-sidebar"
import Tooltip                                  from 'rc-tooltip'
import InlineIcon                               from "../../../ui/icon/inline-icon.js"
import LinkIcon                                 from "../../../../assets/icons/open-link-icon.js"
import UpdateStatusModal  			            from "../../common/update-status-modal"
import URLSearchParams                          from "url-search-params"
import UpdateRevisionModal                      from "../../common/update-revision-modal"
import ImportFromVendor                         from "../new/web"
import ImportFromManual                         from "../new/manual"
import StatusUpdateModalForNonAssembly          from "../../common/update-status-modal-for-non-assembly";
import Config                                   from "../../../../modules/config";
import NumberFormat                             from 'react-number-format';
import RevisionField                            from "../../common/revision-field";
import LastUpdatedField                         from "../../common/last-modified-field";
import ProcurementEdit                          from "../../common/procurement/edit";
import ErrorNotification from "../../common/error-notification";
import TimeStampColumn                          from "../../common/timestamp-column"
import VendorPillbox                            from "../components/vendor"
import ItemCustomProperties                     from "../../common/specifications/item-custom-properties"
import PerfectScrollbar                         from "react-perfect-scrollbar";
import TileItem                                 from "../../common/tile-item";
import ImageViewerTile                          from "../../../ui/image-viewer-tile";
import ItemDetailsIcon                          from "../../../../assets/icons/item-details-icon";
import ItemSpecsIcon                            from "../../../../assets/icons/item-specs-icon";
import WebSrcIcon                               from "../../../../assets/icons/newcomp-web";
import ExtendedRolledUpCost                     from "../../common/extended-rolled-up-cost";
import RevisionManaged                          from "../../common/revision-managed";
import UnitPrice                                from "../../common/unit-price";
import CpnEditField                             from "../../common/cpn-edit-field";
import UpdateRevisionNonAssembly                from "../../common/update-revision-non-assembly-modal"
import ItemERP                                  from "../../common/specifications/item-erp-view"
import SpecInputField                           from "../../common/components/spec-input"

export class ComponentEdit extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.uploadImage                            = this.uploadImage.bind(this)
        this.onInputChange                          = this.onInputChange.bind(this)
        this.componentSearch                        = this.componentSearch.bind(this)
        this.clearComponentSearch                   = this.clearComponentSearch.bind(this)
        this.getData                                = this.getData.bind(this)
        this.onSubmit                               = this.onSubmit.bind(this)
        this.handleCancelEvent                      = this.handleCancelEvent.bind(this)
        this.onDone                                 = this.onDone.bind(this)
        this.onKeyPress                             = this.onKeyPress.bind(this)
        this.onAcceptCategoryChange                 = this.onAcceptCategoryChange.bind(this)
        this.onAcceptStatusChange                   = this.onAcceptStatusChange.bind(this)
        this.onRejectStatusChange                   = this.onRejectStatusChange.bind(this)
        this.onRejectFieldChanged                   = this.onRejectFieldChanged.bind(this)
        this.clearNewlyCreatedComponentsFromState   = this.clearNewlyCreatedComponentsFromState.bind(this)
        this.hideAssemblyModals                     = this.hideAssemblyModals.bind(this)
        this.addAssemblyFromLibrary                 = this.addAssemblyFromLibrary.bind(this)
        this.clearNewlyAddedComponentsFromState     = this.clearNewlyAddedComponentsFromState.bind(this)
        this.dispalyErrorModal                      = this.dispalyErrorModal.bind(this)
        this.onPrimarySourceChange                  = this.onPrimarySourceChange.bind(this)
        this.onPrimarySourceDeselect                = this.onPrimarySourceDeselect.bind(this)
        this.setPrimarySourceInObject               = this.setPrimarySourceInObject.bind(this)
        this.syncPrimaryQuoteInputWithData          = this.syncPrimaryQuoteInputWithData.bind(this)
        this.onRolledUpSelect                       = this.onRolledUpSelect.bind(this)
        this.onRolledUpInputChanged                 = this.onRolledUpInputChanged.bind(this)
        this.syncRolledUpCostTable                  = this.syncRolledUpCostTable.bind(this)
        this.afterSyncWithRolledUpCost              = this.afterSyncWithRolledUpCost.bind(this)
        this.onModalExternalClick                   = this.onModalExternalClick.bind(this)
        this.showModal                              = this.showModal.bind(this)
        this.closeModal                             = this.closeModal.bind(this)
        this.handleBlockedNavigation                = this.handleBlockedNavigation.bind(this)
        this.handleConfirmNavigationClick           = this.handleConfirmNavigationClick.bind(this)
        this.showUiAlert                            = this.showUiAlert.bind(this)
        this.blockNavigation                        = this.blockNavigation.bind(this)
        this.showHideAssemblySearch                 = this.showHideAssemblySearch.bind(this)
        this.hideUpdateStatusModal                  = this.hideUpdateStatusModal.bind(this)
        this.refreshAfterBulkUpdate                 = this.refreshAfterBulkUpdate.bind(this)
        this.getComponentFromAPI                    = this.getComponentFromAPI.bind(this)
        this.showHideSaveAsRevisionModal            = this.showHideSaveAsRevisionModal.bind(this)
        this.toggleModal                            = this.toggleModal.bind(this);
        this.handleSelectDisplay                    = this.handleSelectDisplay.bind(this)
        this.disablePrimarySourceBtn                = this.disablePrimarySourceBtn.bind(this);
        this.enablePrimarySourceBtn                 = this.enablePrimarySourceBtn.bind(this);
        this.handleProcurement = this.handleProcurement.bind(this);
        this.toggleErrorCount = this.toggleErrorCount.bind(this);
        this.setVariantFlags = this.setVariantFlags.bind(this);
        this.lastClickedTarget                      = null;
        this.getTotalErrorsCount                    = this.getTotalErrorsCount.bind(this);
        this.getErrorsWarningMsg                    = this.getErrorsWarningMsg.bind(this);
        this.handleRevisionManaged                  = this.handleRevisionManaged.bind(this);
        this.showRevisionWarningModal               = this.showRevisionWarningModal.bind(this);
        this.checkRevisionManaged                   = this.checkRevisionManaged.bind(this);
        this.setStateFromNonAssemblyComponent       = this.setStateFromNonAssemblyComponent.bind(this);
        this.revisionInputChange                    = this.revisionInputChange.bind(this)
        this.getSpecsValidated                      = this.getSpecsValidated.bind(this)
        this.allowedElementsForClick = ["sign out", "private sandbox", "company account", "exit sandbox"]
        this.state = {
            isAddedClickListener                : false,
            showFileImportModal                 : false,
            newlyCreatedComponent               : null,
            addAssemblyFromLibrary              : null,
            actionType                          : "new",
            primarySource                       : {},
            componentPrimarySource              : {},
            revisionInput:
            {
                valid: false
            },
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            showTrackStatusModal: true,
            showErrormodal: true,
            showErrorModalAfterBulk: true,
            showSaveAsRevisionModal: false,
            revisionComment: "",
            displayVendorModal: false,
            displayManualModal: false,
            displayFileImport: false,
            cmpId:null,
            showMassWarningIcon: false,
            showMassErrorIcon: true,
            procurement: "N/A",
            errorsCount: {
                assemblyTab: 0,
                sourcingTab: 0,
                documentTab: 0
            },
            isValidated: false,
            cpnType: "", // cpnType may be overridden by Sandbox mode, hence a separate state var
            currentCompanyCpnType: window.__currentCompanyCpnType,
            isCpnVariantEditable: false,
            isCpnVariantScheme: false,
        }
    }

    toggleModal(modalName, modalValue)
    {
        if (modalValue === true)
        {
            this.setState({[modalName]: modalValue, cmpId: null});
        }
        else
        {
            this.setState({[modalName]: modalValue});
        }
    }

    showModal(location)
    {
        this.setState({modalVisible: true, lastLocation: location})
    }

    showHideSaveAsRevisionModal(value)
    {
        let revisionValue = this.props.component && this.props.component.nextCoRevision || ""
        this.setState({showSaveAsRevisionModal: value, revisionComment: "", revisionInput:{value: revisionValue, valid: true}})
    }

    closeModal(callback, isSkip=false)
    {
        if (isSkip)
        {
           this.setState({modalVisible: false})
           this.lastClickedTarget = null
        }
        else
        {
            this.setState({modalVisible: false}, callback)
        }
    }

    handleConfirmNavigationClick()
    {
        this.closeModal(() =>
        {
            const {lastLocation} = this.state
            const lastClickedTarget = this.lastClickedTarget

            if (lastLocation)
            {
                this.setState({confirmedNavigation: true}, () => this.props.history.push({pathname: lastLocation.pathname, state: lastLocation.state}))
            }
            else if (lastClickedTarget)
            {
                let targetElementText = lastClickedTarget.innerText && lastClickedTarget.innerText.toLowerCase()
                if (this.allowedElementsForClick.includes(targetElementText))
                {
                    lastClickedTarget.click()
                }
                else if (!!lastClickedTarget.closest(".onboarding-setp-item"))
                {
                    lastClickedTarget.closest(".onboarding-setp-item").click()
                }

                else if (!!lastClickedTarget.closest(".exit-sandbox-btn"))
                {
                    lastClickedTarget.closest(".exit-sandbox-btn").click()
                }

            }

        })
    }

    handleBlockedNavigation(nextLocation)
    {
        const {confirmedNavigation} = this.state
        const {shouldBlockNavigation} = this.state
        const lastClickedTarget = this.lastClickedTarget

        if (!confirmedNavigation && shouldBlockNavigation && !lastClickedTarget)
        {
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    onModalExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.componentForm
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        let state = this.state
        let targetElementText = event.target.innerText && event.target.innerText.toLowerCase()

        if  (
                !isExternal && event.target.nodeName === "A" && event.target.innerText === "CANCEL" ||
                event.target.classList.contains("delete_btn") && event.target.value === "CANCEL"
            )
        {
            state.shouldBlockNavigation = false
            this.setState(state)
        }

        if  (
                isExternal && !this.lastClickedTarget &&
                (
                    (!!event.target.closest(".navigation") && this.allowedElementsForClick.includes(targetElementText)) ||
                    (!!event.target.closest(".onboarding-setp-item")) ||
                    (!!event.target.closest(".exit-sandbox-btn")) ||
                    (!!event.target.closest(".help-menu-actions") && (this.allowedElementsForClick.includes(targetElementText) || this.allowedElementsForClick.includes(targetElementText)))
                )
            )
        {
            event.preventDefault();
            event.stopPropagation();
            this.lastClickedTarget = event.target
            this.showModal()
        }
    }

    blockNavigation()
    {
        let state = this.state

        if (!state.isAddedClickListener)
        {
            document.body.addEventListener("click", this.onModalExternalClick)
            state.shouldBlockNavigation = true
            state.isAddedClickListener = true
            this.setState(state)
        }
    }

    componentWillReceiveProps(nextProps)
    {
        let { childrenModified, component, dispatch, modified } = nextProps;
        let state = { ...this.state };

        let urlParams = new URLSearchParams(window.location.search)
        let status    = urlParams.has('status') ? urlParams.get('status') : null
        let revision  = urlParams.has('revision') ? urlParams.get('revision') : null
        let refer  = urlParams.has('refer') ? urlParams.get('refer') : null;
        if(state.showTrackStatusModal)
        {
            if (status && component)
            {
                state.trackStatusChanged     = true
                state.newStatusValue         = status
                state.statusFieldPreviousValue = component.status
                state.revisionInput.valid     = true
            }
            if (revision && component)
            {
                state.trackStatusChanged      = true
                state.revisionInput.value     = revision

                const { revisions } = component
                validateField(state.revisionInput, validations.component.revision, revision.toUpperCase(), {status: state.newStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true})
            }
            if (refer === "co" && component)
            {
                state.trackStatusChanged = false;
                dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE,
                {
                    value: state.newStatusValue,
                    name: "status"
                }))

                dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE,
                {
                    value: state.revisionInput.value,
                    name: "revision"
                }))
            }
            if ((status || revision) && component)
            {
                state.showTrackStatusModal    = false
                this.setState(state)
            }
        }

        if (modified || childrenModified)
        {
            this.blockNavigation()
        }

    }

    componentWillUpdate(nextProps, nextState) {
        let updateCpnType;
        if (nextState.currentCompanyCpnType !== window.__currentCompanyCpnType) {
            this.setState({ currentCompanyCpnType: window.__currentCompanyCpnType });
            updateCpnType = true;
        }
        const { company, isNewObject } = this.props;
        if (company !== nextProps.company || isNewObject !== nextProps.isNewObject || updateCpnType) {
            this.setVariantFlags(nextProps);
        }
    }

    componentDidUpdate()
    {
        const state = this.state;
        const { dispatch, inputs, isNewObject, component } = this.props;
        const needValidationOnSpecs = !state.isValidated && component && window.__isSpecValidationEnabled;
        if (state.shouldBlockNavigation) window.onbeforeunload = () => true
        else  window.onbeforeunload = undefined    
        if (Boolean(needValidationOnSpecs)) {
            const specs = component && component.specs;
            inputs.specs = this.getSpecsValidated(specs);
            dispatch(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs));
            this.setState({isValidated: true});
        }
    }

    getSpecsValidated(specs) {
        const { category } = this.props.component;
        const categoryObject = window.__categories.find(categoryObj => categoryObj.name === category); 
        const categorySpecs = categoryObject.specs;
        const isSpecValidationEnabled = window.__isSpecValidationEnabled;
        const inputs = specs && specs.map((spec) => {
            const { allowedValues, isDropDown} = categorySpecs.specSettings.find(specName => Object.keys(specName) == spec.key)[spec.key];
            let input = {
                value   : spec.value,
                key     : spec.key,
                schema  : { allowedValues, isDropDown }
            };
            validateField(input, validations.component.specs.value, input.value, {categoryObject: categoryObject, key: spec.key, isSpecValidationEnabled});
            return input;
        });
        return inputs;
    }

    componentWillUnmount()
    {
        window.onbeforeunload = undefined;
        document.body.removeEventListener("click", this.onModalExternalClick);
        this.state.shouldBlockNavigation = undefined;
        const { dispatch } = this.props;
        dispatch(buildAction(COMPONENT.RESET_COMPONENT_EDIT_FORM));
        let app_row = document.getElementsByClassName('banner-block');
        if(app_row && app_row[0])
        {
            app_row[0].classList.remove("open-right-nav");
        }
        this.clearComponentSearch();
        dispatch(buildAction(DISPLAYSIDEBAR.HIDE_SIDE_BARS));
    }


    componentWillMount()
    {
        this.setVariantFlags(this.props);
        const { dispatch, history, isNewObject, location, match } = this.props;
        let id = match.params.id;
        Utils.setLocalStorageForAssemblyTree(id);

        if (location && location.showAll) {
            id = `${id}?showAll=true&include=children,documents,creator,images,revisions`;
        }
        else{
            id=`${id}?include=children,documents,creator,images,revisions`;
        }
        let payload = { id, history: history, editMode: true };
        dispatch(buildAction(COMPONENT.GET_COMPONENT_AND_SET_IN_EDIT_PAGE, payload));
        dispatch(buildAction(ITEM_ASSEMBLY.UPDATE_DISPLAY_ASSEBLY_SEARCH, false));

        if (location?.state?.isNewObject)
        {
            document.body.addEventListener("click", this.onModalExternalClick);
            this.setState({ shouldBlockNavigation: true });

            dispatch(buildAction(COMPONENT.SET_EDIT_PAGE_MODIFIED, true ));
            dispatch(buildAction(COMPONENT.SET_IS_NEW_OBJECT_FLAG, true ));
        }
    }

    refreshAfterBulkUpdate(bulkRevisionValue='', bulkStatusValue='', showErrorModalAfterBulk=true)
    {
        window.localStorage.removeItem('lastAssemblyTree')
        window.localStorage.removeItem('lastAssemblyParent')
        this.onAcceptStatusChange(showErrorModalAfterBulk)
        this.getComponentFromAPI(bulkRevisionValue, bulkStatusValue)
    }

    getComponentFromAPI(bulkRevisionValue, bulkStatusValue)
    {
        const {dispatch}  = this.props;
        let id = this.props.match.params.id
        if (this.props.location && this.props.location.showAll){
            id = `${id}?showAll=true&include=children,documents,creator,images,revisions`;
        }
        else{
            id=`${id}?include=children,documents,creator,images,revisions`;
        }
        let payload = { id, history: this.props.history, editMode: true, bulkRevisionValue, bulkStatusValue }
        dispatch(buildAction(COMPONENT.GET_COMPONENT_AND_SET_IN_EDIT_PAGE, payload))
    }

    syncPrimaryQuoteInputWithData(quote){
        this.state.componentPrimarySource = quote
        this.setState(this.state)
    }

    disablePrimarySourceBtn() {
        let state = this.state
        this.setState({allowPrimarySourceIcon: false});
    }

    enablePrimarySourceBtn() {
        let state = this.state
        this.setState({allowPrimarySourceIcon: true});
    }

    onPrimarySourceChange(isChecked, quote) {
        if (isChecked) {
            this.setState({
                allowPrimarySourceIcon: true,
                primarySource: {
                    ...quote,
                    allAreValid: true,
                },
                rolledUpCostAsPrimary: false,
            });
        }
        else {
            this.setState({
                allowPrimarySourceIcon: false,
                primarySource: {
                    leadTime: "",
                    leadTimeUnit: "",
                    minQuantity: "",
                    uniqueId: "dummy_id",
                    unitPrice: "",
                },
            });
        }
    }

    onRolledUpInputChanged(data)
    {
        let { component }      = this.props
        const {dispatch}       = this.props;
        component.rolledUpCost = {mpn: data.mpn, manufacturer: data.manufacturer}
        dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE))
        this.syncRolledUpCostTable()
    }

    onRolledUpSelect(event, rolledUpCost){
        let state = this.state
        let { component, company } = this.props
        if (Object.keys(rolledUpCost).length > 0 && event.target.checked)
        {
            state.allowPrimarySourceIcon = true
            state.rolledUpCostAsPrimary  = true
            state.primarySource =
            {
                minQuantity     : rolledUpCost.minQuantity,
                unitPrice       : rolledUpCost.totalPrice,
                leadTime        : rolledUpCost.leadTimeValue,
                leadTimeUnit    : rolledUpCost.leadTimeUnit,
                allAreValid     : rolledUpCost.allAreValid,

                leadTime     : {
                    value : rolledUpCost.leadTimeValue,
                    units : rolledUpCost.leadTimeUnit
                },
                primarySourceId : "dummy",
                rolledUpCost    : true,
                manufacturer    : (component.rolledUpCost && component.rolledUpCost.manufacturer) ? component.rolledUpCost.manufacturer : company.name,
                mpn             : (component.rolledUpCost && component.rolledUpCost.mpn) ? component.rolledUpCost.mpn : Utils.getCpn(component),
            }
        }

        else
        {
            state.allowPrimarySourceIcon = false
            state.rolledUpCostAsPrimary = false

            state.primarySource = {
                minQuantity  : "",
                unitPrice    : "",
                leadTime     : "",
                leadTimeUnit : "",
                uniqueId     : "dummy_id",
                rolledUpCost    : false
            }
        }

        this.setState(state)
    }


    onPrimarySourceDeselect()
    {
        let { component } = this.props
        let inputs        = this.props.inputs
        let state = this.state
        $('.quotes .primary-source-checkbox input:checkbox').prop('checked', false);

        state.allowPrimarySourceIcon = false
        state.componentPrimarySource = {}
        state.rolledUpCostAsPrimary  = false
        component.rolledUpCostAsPrimary = false

        component.primarySource = {
                minQuantity  : "",
                unitPrice    : "",
                leadTime     : {
                    value : '',
                    units : ''
                },
                leadTimeUnit : "",
                uniqueId     : "dummy_id",
                manufacturer : "",
                mpn          : "",
            }


        let manufacturers = inputs.manufacturers.value

        manufacturers.forEach((man, i) =>
        {
            man.isPrimary = false
            man.distributors.forEach((dist, i) =>
            {
                dist.isPrimary = false
                dist.quotes.forEach((quote, i) =>
                {
                    // quote.isPrimary = false
                    man.isPrimary = false
                    dist.isPrimary = false
                    quote.isPrimary = false
                    if(quote.inputs) quote.inputs.isChecked.value = false;

                }) // end dist.quotes.forEach((quote, i) =>
            }) // end man.distributors.forEach((dist, i) =>
        }) // end manufacturers.forEach((man, i) =>
        // return data

        const {dispatch} = this.props;
        dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE))
        this.setState(state, this.syncRolledUpCostTable)
    }

    showHideAssemblySearch(event){
        const {dispatch} = this.props;
        dispatch(buildAction(DISPLAYSIDEBAR.SET_LIBRARY_FLAG))
    }

    setPrimarySourceInObject()
    {

        let { component } = this.props
        let inputs        = this.props.inputs
        let state         = this.state

        // $('.quotes .primary-source-checkbox input:checkbox').prop('checked', false);

        state.allowPrimarySourceIcon = false
        state.componentPrimarySource = Utils.clone(state.primarySource)
        component.primarySource           = state.componentPrimarySource
        component.rolledUpCostAsPrimary   = this.state.rolledUpCostAsPrimary

        let quoteUniqueId = state.primarySource.uniqueId

        let manufacturers = inputs.manufacturers.value
        manufacturers.forEach((man, i) =>
        {
            man.isPrimary = false
            if (man.distributors.length > 0)
            {
                man.distributors.forEach((dist, i) =>
                {
                    dist.isPrimary = false
                    if (dist.quotes.length > 0)
                    {
                        dist.quotes.forEach((quote, i) =>
                        {
                            quote.isPrimary = false

                            if (quoteUniqueId === quote.uniqueId && state.rolledUpCostAsPrimary !== true) {
                                man.isPrimary = true
                                dist.isPrimary = true
                                quote.isPrimary = true
                                component.primarySource.manufacturer = man.name;
                                component.primarySource.mpn = man.mpn.key;
                            }
                        }) // end dist.quotes.forEach((quote, i) =>
                    } // if (dist.quotes.length > 0)
                }) // end man.distributors.forEach((dist, i) =>
            } // end if (man.distributors.length > 0)
        }) // end manufacturers.forEach((man, i) =>
        // return data

        const {dispatch} = this.props;
        dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE))
        this.setState(state, this.syncRolledUpCostTable)
    }

    syncRolledUpCostTable()
    {
        let state = this.state
        state.syncWithRolledUpCost = true
        this.setState(state)
    }

    afterSyncWithRolledUpCost()
    {
        let state = this.state
        state.syncWithRolledUpCost = false
        this.setState(state)
    }

    componentSearch(query)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(COMPONENT.SEARCH_COMPONENTS_IN_EDIT_PAGE,
        {
            query: query
        }))
    }

    handleCancelEvent(event)
    {
        this.props.history.goBack()
    }

    clearComponentSearch()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.CLEAR_ASSEMBLY_SEARCH_STATE, {children: []}))
        dispatch(buildAction(COMPONENT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, []))
    }

    onAcceptCategoryChange(showErrorModalAfterBulk=true){
        const {dispatch} = this.props;
        let state = this.state
        dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE,
            {
                value: state.changedFieldValue,
                name: state.changedFieldName,
                cpnType: state.cpnType,
            }))

        state.showCategoryChangeAlert = false
        state.showErrorModalAfterBulk = showErrorModalAfterBulk
        state.categoryChanged = true
        state.changedFieldValue = ""
        state.changedFieldName = ""
        this.setState(state)
    }

    onRejectFieldChanged(){
        let state = this.state
        state.showCategoryChangeAlert = false
        state.trackStatusChanged = false
        state.changedFieldValue = ""
        state.changedFieldName = ""
        this.setState(state)
    }

    onAcceptStatusChange(){
        const {dispatch} = this.props;
        let state = this.state

        dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE,
            {
                value: state.newStatusValue,
                name: "status"
            }))

        dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE,
            {
                value: state.revisionInput.value,
                name: "revision"
            }))

        state.trackStatusChanged = false
        this.setState(state)
    }

    onRejectStatusChange() {
        this.setState({ trackStatusChanged: false });
    }

    onInputChange(event, i) {
        const { categoryChanged, cpnType, isAddedClickListener, revisionInput } = this.state

        if (!isAddedClickListener) {
            document.body.addEventListener("click", this.onModalExternalClick)
            this.setState({
                isAddedClickListener: true,
                shouldBlockNavigation: true,
            });
        }

        const { name, value } = event.target;
        const { component, dispatch, inputs } = this.props

        if (event.target.name === "category" && event.target.value !== component.category && !categoryChanged) {
            this.setState({
                changedFieldName: name,
                changedFieldValue: value,
                showCategoryChangeAlert: true,
            });

        }

        if (event.target.name === "status") {
            let trackStatusChanged = true;
            if (event.target.value == 'DESIGN') {
                trackStatusChanged = false;
                dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE, {
                    name,
                    value,
                }))
                dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE, {
                    name: "revision",
                    value: (component.status === 'DESIGN' ? component.originalRevision : component.revision),
                }))
            }

            const newStatusValue = event.target.value;
            const previousRevision = Utils.getPreviousRevision(component);
            const validationPayload = {
                status: newStatusValue,
                revSchemeType: window.__revSchemeType,
                libraryType: window.__libraryType,
                isClient: true,
                revisions: this.props.component,
                revActionType: null,
                previousRevision,
                isClient: true,
                defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions,
            };

            let revValue = validations.component.revision.normalize(validationPayload, inputs.revision.value).revision;
            validateField(revisionInput, validations.component.revision, revValue.toUpperCase(), validationPayload);
            this.setState({
                newStatusValue,
                statusFieldPreviousValue: inputs.status.value,
                trackStatusChanged,
            });
        }
        else {
            dispatch(buildAction(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE, {
                value,
                name,
                index: i,
                cpnType,
            }));
        }

        if (event.target.name === "mass") {
            inputs.mass.value = validations.component.mass.normalize(inputs.mass.value);
            this.setState({
                showMassErrorIcon: false,
                showMassWarningIcon: true,
            });
        }
    }

    setStateFromNonAssemblyComponent(revisionObject, revisionComment){
        let state = this.state;
        this.showHideSaveAsRevisionModal(false)
        state.revisionInput = revisionObject;
        state.revisionComment = revisionComment;
        this.setState(state);
        this.onSubmit()
    }

    revisionInputChange(event){
        let value = event.target.value
        let { component } = this.props;
        let name = event.target.name
        let state = this.state
        let {revisions} = this.props.component
        let status = state.newStatusValue || "DESIGN"
        let libraryType = window.__libraryType
        let revSchemeType = window.__revSchemeType
        let isClient = true
        let isSaveAsRev = true
        let revValue = value.toUpperCase()
        let previousRevision = Utils.getPreviousRevision(component);
        let validationPayload = {
            status,
            revisions,
            libraryType,
            revSchemeType,
            isClient,
            isSaveAsRev,
            previousRevision,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }
        validateField(state.revisionInput, validations.component.revision, revValue, validationPayload)
        this.setState(state)
    }

    async setVariantFlags(props) {
        const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = await getLegacyCpnConfig({
            // ToDo: avoid storing data in the global window object :/
            currentCompanyCpnType: window.__currentCompanyCpnType,
            isCreating: props.isNewObject,
            modelType: "COMPONENT",
        });
        this.setState({ cpnType, isCpnVariantEditable, isCpnVariantScheme });
    }

    getData()
    {
        let { component, inputs, children} = this.props
        let isAssembly          = Schemas.component.category.getType(inputs.category.value).toLowerCase() === "assembly"
        let newModifiedValue    = true

        component.name          = inputs.name.value
        component.category      = inputs.category.value
        component.revision      = inputs.revision.value
        component.status        = inputs.status.value
        component.eid           = inputs.eid.value.trim()
        component.description   = inputs.description.value
        component.images        = inputs.images.value

        if(isAssembly)
        {
            Utils.updatePullRequestProperty(children)
        }
        component.children      = isAssembly ? children : []
        component.manufacturers = inputs.manufacturers.value
        component.documents     = inputs.documents.value
        component.specs         = inputs.specs
        component.customProperties = inputs.customProperties
        component.procurement   = inputs.procurement;
        component.mass          = inputs.mass.value
        component.unitOfMeasure = inputs.unitOfMeasure.value
        component.revisionManaged = inputs.revisionManaged;

        const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = this.state;
        if (cpnType === "FREE-FORM") {
            component.cpn = inputs.cpn.value;
        }
        if (isCpnVariantScheme && cpnType !== "CPN_RULES") {
            const cpnSplit = component.cpn.split("-");
            component.cpn = cpnSplit[0] + "-" + cpnSplit[1];
            if (cpnType === "CUSTOM-CODE-WITH-11-DIGIT") {
                component.cpn += "-" + cpnSplit[2];
            }
        }

        if (!inputs.inputChanged)
        {
            newModifiedValue = false
        }

        if (this.props.childrenModified)
        {
            newModifiedValue = true
        }

        if (isCpnVariantEditable)
        {
            component.cpnVariant    = inputs.cpnVariant.value
        }

        const isNewObject = this.props?.isNewObject;
        if (component.status === 'DESIGN' || isNewObject)
        {
            newModifiedValue = false
            component.modified = false
        }

        if ( isNewObject )
        {
            component.archived = false
        }

        component.specs.forEach((spec, i) =>
        {
            spec.value = inputs.specs[i].value
        })

        if (!component.modified)
        {
            component.modified = newModifiedValue
        }

        let {revisionComment, showSaveAsRevisionModal, revisionInput} = this.state
        if (showSaveAsRevisionModal)
        {
            component.revisionComment = revisionComment
            component.saveAsRevision  = true
            component.revision        = revisionInput.value
        }
        return component
    }

    onKeyPress(event) {
        if (event.target.type != 'textarea' && event.which === 13 /* Enter */) {
          event.preventDefault();
        }
    }

    onSubmit(event, options = null)
    {
        event?.preventDefault()
        const { dispatch, children, history, inputs, location, user } = this.props
        let validated = Utils.isValidated(inputs) && Utils.isValidated(children)

        if(!validated)
        {
            return
        }

        const data = this.getData();
        const payload =
        {
            data,
            history,
            user: user?.data ?? user,
            showAll: location.showAll,
        }

        if (options)
        {
            payload.data.revisionComment = options.revisionComment
            payload.data.saveAsRevision  = true
            payload.data.revision        = options.revision
        }

        if(this.props.location && this.props.location.isVariant)
        {
            payload.data.originalObjectId = this.props.originalComponentId;
            payload.data.isVariant = this.props.location.isVariant;
        }
        dispatch(buildAction(COMPONENT.SUBMIT_COMPONENT_EDIT_FORM, payload))
    }

    uploadImage(images, errors, isUploadedThumbnails)
    {
        let state = this.state
        if (!state.isAddedClickListener)
        {
            document.body.addEventListener("click", this.onModalExternalClick)
            state.shouldBlockNavigation = true
            state.isAddedClickListener = true
            this.setState(state)
        }

        const {dispatch} = this.props;
        dispatch(buildAction(COMPONENT.UPLOAD_IMAGES_ON_EDIT_PAGE,
        {
            images: images,
            thumbnailUploaderrors: errors,
            isUploadedThumbnails: isUploadedThumbnails
        }))
    }

    onDone(newlyCreatedComponents, actionType)
    {
        let state = this.state
        let { component } = this.props;
        for(const newlyCreatedComponent of newlyCreatedComponents) {
            const dataToCompare = component.children.find(element => element.component._id == newlyCreatedComponent._id);
            newlyCreatedComponent.newlyAdded = dataToCompare ? dataToCompare.isAddedAfterPullRequest : true;
        }
        state.showFileImportModal = false
        state.newlyCreatedComponent = newlyCreatedComponents
        state.actionType = actionType === "update_assembly" ? "update" : "new"
        state.displayFileImport = false
        this.setState(state)
    }

    addAssemblyFromLibrary(newlyAddedComponents, cmpId=null)
    {
        let state = this.state;
        state.addedAssemblyFromLibrary = newlyAddedComponents;
        state.cmpId = cmpId;
        this.setState(state);
    }

    clearNewlyCreatedComponentsFromState() {
        this.setState({ newlyCreatedComponent: null })
    }

    clearNewlyAddedComponentsFromState() {
        this.setState({ addedAssemblyFromLibrary: null })
    }

    hideAssemblyModals()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.UPDATE_DISPLAY_ASSEBLY_SEARCH, false))
        dispatch(buildAction(DISPLAYSIDEBAR.HIDE_SIDE_BARS))
    }

    dispalyErrorModal()
    {
        let _this = this
        setTimeout(function()
        {
            const {dispatch} = _this.props;
            let urlParams = new URLSearchParams(window.location.search)
            let showModal  = urlParams.has('showModal') ? urlParams.get('showModal') : null

            let payload =
            {
                closeCb: () => {},
                errorType: "custom",
                type: "errors",
                errors: [{message: "By switching to revision control Duro validation rules are now active. Any errors found must be corrected before you can continue. If you do not wish to correct these errors now, switch back to design mode before saving."}]
            }
            if(_this.state.showErrormodal && (showModal && showModal === 'false'))
            {
                _this.state.showErrormodal = false
                _this.setState(_this.state)
            }
            else if(!_this.state.showErrorModalAfterBulk)
            {
                _this.state.showErrorModalAfterBulk = true
                _this.setState(_this.state)
            }
            else
            {
                dispatch(buildAction(UI.SHOW_ALERT, payload))
            }
        }, 500);
    }

    showUiAlert(payload)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(UI.SHOW_ALERT, payload))
    }

    hideUpdateStatusModal()
    {
        this.state.trackStatusChanged   = false
   		this.setState(this.state)
    }


    handleSelectDisplay(isVendorCmp, inputs, isNewObject, component)
    {
        let revisionManaged = window.__isNotRevisionManagedEnabled ? inputs.revisionManaged : true;
        let inputField = "";
        let currentStatus = Utils.getPreviousStatus(component);
        if(isVendorCmp)
        {
            inputField = (
                <LazyInput
                    type="text"
                    name="status"
                    value={inputs.status.value}
                />
            )
        }
        else
        {
            inputField = (
                <select
                    name="status"
                    value={inputs.status.value}
                    onChange={this.onInputChange}
                    disabled={!revisionManaged}
                    >
                    {Utils.toOptions(Schemas.component.status.list(!isNewObject ? currentStatus : ''))}
                </select>
            )
        }
        return inputField;
    }

    handleProcurement(event)
    {
        let inputs = this.props.inputs;
        let state  = this.state;
        // state.procurement = inputs.procurement;
        inputs.procurement = event.target.value;
        // if(inputs.procurement === "Buy" && inputs.status.value !== "PRODUCTION")
        // {
        //     state.trackStatusChanged = true;
        //     state.statusFieldPreviousValue = inputs.status.value;
        //     state.newStatusValue     = "PRODUCTION";
        //     let currentRevision = this.props.component.revision;
        //     let {revisions} = this.props.component;
        //     let validationPayload = {status: state.newStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, revisions, revActionType: null, currentRevision};

        //     let revValue = validations.component.revision.normalize(validationPayload, inputs.revision.value).revision;
        //     validateField(state.revisionInput, validations.component.revision, revValue.toUpperCase(), validationPayload);
        //     this.setState(state);
        // }
        this.onInputChange(event);
    }

    toggleErrorCount(valid, tabName, amount = 1) {
        this.setState(({ errorsCount }) => {
            let count = errorsCount[tabName];

            if (!valid) {
                count += amount;
            }
            else {
                count -= amount;
            }

            if (count < 0) {
                count = 0;
            }

            return { errorsCount: { ...errorsCount, [tabName]: count } };
        });
    }

    getErrorsWarningMsg(){
        let count = this.getTotalErrorsCount();
        return `${count} ${count > 1 ? 'errors' : 'error'} found.<br>All errors must be resolved before saving.`;

    }

    getTotalErrorsCount()
    {
        let count = 0;
        let _this = this;
        Object.keys(this.state.errorsCount).forEach(function(key){
            count += _this.state.errorsCount[key];
        })

        if(!this.props.inputs.name.valid) count++;
        if(!this.props.inputs.eid.valid) count++;
        if(!this.props.inputs.revision.valid) count++;

        return count;
    }

    showRevisionWarningModal()
    {
        let { revisionWarningModalVisible } = this.state;
        this.setState({revisionWarningModalVisible: !revisionWarningModalVisible})
    }

    handleRevisionManaged(event)
    {
        let state = this.state;
        let { inputs, component } = this.props;
        inputs.revisionManaged = !inputs.revisionManaged;
        if(!inputs.revisionManaged && component.status !== "PRODUCTION" && component.status !== "OBSOLETE")
        {
            inputs.revision.value = "A";
            inputs.status.value = "PRODUCTION";
            this.setState({previousStatus: component.status, previousRevision: component.revision});
        }
        this.onInputChange(event);
        this.showRevisionWarningModal();
    }

    checkRevisionManaged(event)
    {
        let state = this.state;
        let { inputs, component } = this.props;
        inputs.revisionManaged = !inputs.revisionManaged;
        inputs.revision.value = state.previousRevision ? state.previousRevision : component.revision;
        inputs.status.value = state.previousStatus ? state.previousStatus : component.status;
        this.onInputChange(event);
    }

    render()
    {
        let { revisionWarningModalVisible, modalVisible } = this.state;
        let { component, company, inputs, modified, children, isNewObject } = this.props
        let validated    = Utils.isValidated(inputs)
        let childrenValidated    = Utils.isValidated(children)
        let proceed      = modified && validated
        let urlParams       = new URLSearchParams(window.location.search)
        let showModal       = urlParams.has('showModal') ? urlParams.get('showModal') : null
        let parentNewStatus = this.state.showErrormodal && (showModal && showModal === 'false')  &&  urlParams.has('status') ? urlParams.get('status') : this.state.newStatusValue
        let urlHasParams    = urlParams.has('status') || urlParams.has('revision') || urlParams.has('showModal') ? true : false
        let isAssemblyType  = component && component.category && Schemas.component.category.getType(component.category).toLowerCase() === "assembly"

        let state = this.state

        if (!component || !(company && company.data && company.data.cpnScheme)){
            return <Loading />
        }

        let isAssembly = children.length > 0 ? true : false

        component.primarySource = SourcingUtils.setPrimarySourceIfRolledUpSourceIsPrimary(component)
        let primarySource =
            Object.keys(this.state.componentPrimarySource).length !== 0 ?
            this.state.componentPrimarySource : component.primarySource

        let primarySourceLeadTimeValue = primarySource && primarySource.leadTime ? primarySource.leadTime.value === null ? '' : primarySource.leadTime.value : ''
        let primarySourceLeadTimeUnits = primarySource && primarySource.leadTime ? primarySource.leadTime.units === null ? '' : primarySource.leadTime.units: ''

        let componentViewLink = "/component/view/" + component._id
        let categoryCPN = component.categoryCPN
        let categoryRevision = inputs.revision.value

        let isNotRevisionManagedEnabled = window.__isNotRevisionManagedEnabled;

        const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = this.state;
        if (isCpnVariantScheme)
        {
            let cpnSplit = categoryCPN.split("-");
            if (cpnType === "CPN_RULES") {
                categoryCPN = component.cpn;
            }
            else if (cpnType === "CUSTOM-CODE-WITH-11-DIGIT")
            {
                categoryCPN = cpnSplit[0] + "-" + cpnSplit[1] + "-" + cpnSplit[2]
            }
            else if (cpnType === "WITH-6-DIGIT-PREFIX-AND-COUNTER" || window.__nonIntelligentCPN())
            {
                categoryCPN = cpnSplit[0]
            }
            else
            {
                categoryCPN = cpnSplit[0] + "-" + cpnSplit[1]
            }
        }

        let { nameDuplicateOf, eidDuplicateOf, cpnDuplicateOf} = inputs

        let topSectionErrors = 0;
        if(nameDuplicateOf)
        {
            topSectionErrors++;
        }
        if(eidDuplicateOf)
        {
            topSectionErrors++;
        }
        if(cpnDuplicateOf)
            topSectionErrors++;

        let eidDuplicateTooltip  = Utils.makeDuplicateInputTooltip(eidDuplicateOf, "EID")
        let nameDuplicateTooltip = Utils.makeDuplicateInputTooltip(nameDuplicateOf, "Name")
        let cpnDuplicateTooltip = cpnType === "FREE-FORM" ? Utils.makeDuplicateInputTooltip(cpnDuplicateOf, "CPN") : null;

        let tooltipText = Utils.vendorTooltip(Utils.getVendor(component));
        let { isVendorCmp, vendorLabel, isOriginallyVendorCmp } = Utils.getVendorLabel(component);
        const showCustomProperties = component.customProperties && !!component.customProperties.length && (isVendorCmp || isOriginallyVendorCmp) && vendorLabel !== "VALISPACE";
        let cpn = Utils.getCpn(component);
        let vendorsForEditableFields = ["ONSHAPE", "SOLIDWORKS PDM", "SOLIDWORKS CAD"];
        let componentEditClass = `edit-component-route${isVendorCmp ? Utils.vendorComponentEditClass(vendorLabel) : ''}`;
        let displayRefDesAndItemNumber = false;

        const lastModifiedBy = Utils.getLastModifiedBy(component);

        if (this.props.company && this.props.company.data && this.props.company.data.settings && this.props.company.data.settings.displayRefDesAndItemNumber)
        {
           displayRefDesAndItemNumber = true;
        }

        let warningTitle = revisionWarningModalVisible ? "Warning" : "Changes will be lost";
        let warningMessage = revisionWarningModalVisible ? "The Status and Revision values for this Component will no longer be revisioned." : "You have unsaved changes. If you navigate away without first saving, the changes will be lost.";

        let markup =
            <div className={componentEditClass} ref="componentForm">
                <ReactTooltip id={"vendor-disable-input"}
                    effect="solid"
                    className="disable-input-tooltip"
                >
                </ReactTooltip>
                <Helmet>
                    <title>{Utils.makeTitle("["+ cpn + "] (EDIT) "+component.name)}</title>
                </Helmet>
                {
                    this.state.shouldBlockNavigation &&
                    <Prompt
                        when={state.shouldBlockNavigation}
                        message={this.handleBlockedNavigation}
                    />
                }

                {
                    (modalVisible || revisionWarningModalVisible) &&
                    <WarningModal
                        onCancel={revisionWarningModalVisible ? this.showRevisionWarningModal : (e) => this.closeModal(e, true)}
                        onConfirm={revisionWarningModalVisible ? this.handleRevisionManaged : this.handleConfirmNavigationClick}
                        title={warningTitle}
                        description={warningMessage}
                        isWarningIcon={revisionWarningModalVisible ? true : false}
                    />
                }

                {
                    <AssemblySearchMenu
                        dispatch={this.props.dispatch}
                        parentType="Component"
                        component={component}
                        newlyAddedComponentsFromRightSearch={this.addAssemblyFromLibrary}
                        addedChildList={children}
                        // addedChildList={[]}
                        validations={validations.component.children}
                        // item_assembly={this.props.item_assembly}
                        changeorders={this.props.changeorders}
                        showHideAssemblySearch={this.showHideAssemblySearch}
                    />
                }
                {
                    this.state.displayFileImport &&
                    <FileImportModule
                        showUiAlert={this.showUiAlert}
                        displayModal={true}
                        history={this.props.history}
                        fileType={"new_assembly"}
                        onDone={this.onDone}
                        assemblyFlag={true}
                        currentlyOpenedCpn={component.cpn}
                        currentlyOpenedCategory={inputs.category.value}
                        displayImportFileFlag={true}
                        onClose={this.toggleModal}
                        displayRefDesAndItemNumber={displayRefDesAndItemNumber}
                    />
                }

                {
                    (state.showCategoryChangeAlert && !state.categoryChanged) &&
                        <ConfirmBox
                            confirm={this.onAcceptCategoryChange}
                            reject={this.onRejectFieldChanged}
                            heading='Category change may result in loss of data.'
                            confirmButtonText='Continue'
                            text='You will lose category specific data by changing the
                            category. Are you sure you want to continue?'
                        >
                            <div>
                                <h1>Category Change may result in loss of data. </h1>
                                <p>
                                    You will lose Category Specific data by changing the
                                    category. Are you sure you want to continue?
                                </p>
                            </div>
                        </ConfirmBox>
                }

                {
                    this.state.trackStatusChanged &&
                    (   !isAssembly ?
                        <StatusUpdateModalForNonAssembly
                            statusFieldPreviousValue={state.statusFieldPreviousValue}
                            newStatusValue={state.newStatusValue}
                            revisionInput={state.revisionInput}
                            revisionInputChange={this.revisionInputChange}
                            onRejectStatusChange={this.onRejectStatusChange}
                            onAcceptStatusChange={this.onAcceptStatusChange}
                            item={"component"}
                        />
                    :
                    <UpdateStatusModal
                        editPage={true}
                        parent={component}
                        hideUpdateStatusModal={this.hideUpdateStatusModal}
                        parentNewStatus={parentNewStatus}
                        parentNewRevision={state.revisionInput.value}
                        refreshAfterBulkUpdate={this.refreshAfterBulkUpdate}
                    />
                    )
                }

                {
                    !isAssembly && state.showSaveAsRevisionModal ?
                    <UpdateRevisionNonAssembly
                    revisionInput={state.revisionInput}
                    onContinue={this.setStateFromNonAssemblyComponent}
                    component={component}
                    toogleSaveAsRevisionModal={this.showHideSaveAsRevisionModal}
                    newStatusValue={state.newStatusValue}
                    />
                    : isAssembly && state.showSaveAsRevisionModal ?
                    <UpdateRevisionModal
                        editPage={true}
                        parent={component}
                        children={children}
                        hideUpdateStatusModal={this.hideUpdateStatusModal}
                        parentNewStatus={parentNewStatus}
                        refreshAfterBulkUpdate={this.refreshAfterBulkUpdate}
                        onSubmit={this.onSubmit}
                        showHideSaveAsRevisionModal={this.showHideSaveAsRevisionModal}
                        nextCoRevision={this.nextCoRevision}
                    />
                    : null
                }

                {
                    this.state.displayVendorModal &&
                    <ImportFromVendor
                        onClose={this.toggleModal}
                        history={this.props.history}
                        newlyAddedComponentsFromRightSearch={this.addAssemblyFromLibrary}
                        isEditRoute={true}
                    />
                }

                {
                    this.state.displayManualModal &&
                    <ImportFromManual
                        onClose={this.toggleModal}
                        history={this.props.history}
                        newlyAddedComponentsFromRightSearch={this.addAssemblyFromLibrary}
                        isEditRoute={true}
                    />
                }

                <form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>
                    <div className={"banner-block " + (this.state.showFileImportModal || this.props.item_assembly.displayAsseblySearch ? "open-right-nav" : "")}>
                        <div className="app-row">
                            <div>
                                <InlineIcon><EditIcon /></InlineIcon>

                                <div>You are currently in edit mode.</div>
                            </div>
                            <div>
                                {
                                    isNewObject === false ? <Link to={componentViewLink}>CANCEL</Link>
                                    :
                                    <input className="delete_btn" type="button" value="CANCEL" onClick={this.handleCancelEvent}/>
                                }
                                <Actions
                                    proceed={proceed}
                                    isVendorCmp={isVendorCmp}
                                    showHideSaveAsRevisionModal={this.showHideSaveAsRevisionModal}
                                    assemblyErrors={state.errorsCount.assemblyTab}
                                    onSubmit={this.onSubmit}
                                    isNewObject={this.props.isNewObject} />
                            </div>
                            {
                                validated && childrenValidated ? "" :
                                <ErrorNotification getErrorsWarningMsg={this.getErrorsWarningMsg}/>
                            }
                        </div>
                    </div>
                    <div className={`top-block app-row ${isVendorCmp ? 'managed-by-vendor': ''}`}>
                        <VendorPillbox isVendorCmp={Utils.getOriginalVendor(component)} label={vendorLabel} isComponentLinked={Utils.isComponentLinked(component.vendorInfo)} />
                        <div className="name-block" data-tip={tooltipText} data-for="vendor-disable-input" data-place="left">
                            <Tooltip
                                placement={"right"}
                                visible={nameDuplicateTooltip}
                                overlayClassName={"simple-rc-tip error"}
                                getTooltipContainer={() => document.querySelector(".routes .tiles-wrapper")}
                                overlay={ nameDuplicateTooltip ?
                                    <div>
                                        <p>
                                            <span className="link-text">{nameDuplicateTooltip.errorMessage}</span>
                                            <br/>
                                            <Link
                                                to={nameDuplicateTooltip.viewLink}
                                                target="_blank"
                                                className="open-link-holder white"
                                                >
                                                <span className="link-text">{nameDuplicateTooltip.linkMessage}
                                                    <InlineIcon >
                                                        <LinkIcon/>
                                                    </InlineIcon>
                                                </span>
                                            </Link>
                                        </p>
                                    </div>
                                    : ""
                                }
                            >
                                <LazyInput
                                    timeout={600}
                                    name="name"
                                    autoFocus={isVendorCmp ? false : true}
                                    value={inputs.name.value}
                                    onChange={this.onInputChange}
                                    className={inputs.name.class}
                                    data-place="right"
                                    data-type="error"
                                    data-tip={inputs.name.message}
                                />
                            </Tooltip>
                        </div>

                        <div className="tiles-wrapper edit">
                            <PerfectScrollbar className="tiles-section-scroll">
                                <TileItem title="Component overview">
                                    <ImageViewerTile
                                        name="images"
                                        isVendorCmp={isVendorCmp}
                                        vendorTooltipText={tooltipText}
                                        images={component.images}
                                        defaultResolution={Config.defaultResolutions.featureImage}
                                        onChange={this.uploadImage}
                                        edit={true}
                                    />
                                    <div className="inner-info pov diff-category-section">
                                        <span className="inner-attribute">category</span>
                                        <div className="inner-value have-input" data-tip={tooltipText} data-for="vendor-disable-input">
                                            {
                                                isVendorCmp ?
                                                <LazyInput
                                                    type="text"
                                                    name="category"
                                                    className={inputs.category.class}
                                                    value={inputs.category.value}
                                                    data-place="right"
                                                />
                                                :
                                                component.status.toLowerCase() === "design" ?
                                                <CategorySelect
                                                    name="category"
                                                    onChange={this.onInputChange}
                                                    value={inputs.category.value}
                                                    className={`category-drop-down ${inputs.category.class}`}
                                                    data-place="right"
                                                    data-type="error"
                                                    data-tip={inputs.category.message}
                                                />
                                                :
                                                Schemas.component.category.getDisplayName(component.category)
                                            }
                                        </div>
                                    </div>
                                    <CpnEditField
                                        cpnDuplicateTooltip={cpnDuplicateTooltip}
                                        inputs={inputs}
                                        categoryCPN={categoryCPN}
                                        cpnVariantScheme={isCpnVariantScheme}
                                        onInputChange={this.onInputChange}
                                        cpnVariant_disabled={!isCpnVariantEditable}
                                        user={this.props.user}
                                    />
                                    <div className="inner-info pov diff-eid-section">
                                        <span className="inner-attribute">eid</span>
                                        <div data-tip={vendorsForEditableFields.includes(vendorLabel) ? "" : tooltipText} data-for="vendor-disable-input" className={`inner-value have-input ${vendorsForEditableFields.includes(vendorLabel) ? "editable-vendor-fields" : ""}`}>
                                            <Tooltip
                                                placement={"right"}
                                                visible={eidDuplicateTooltip}
                                                overlayClassName={"simple-rc-tip error"}
                                                getTooltipContainer={() => document.querySelector(".routes .tiles-wrapper")}
                                                overlay={ eidDuplicateTooltip ?
                                                        <div>
                                                            <p>
                                                                <span className="link-text">{eidDuplicateTooltip.errorMessage}</span>
                                                                <br/>
                                                                <Link
                                                                    to={eidDuplicateTooltip.viewLink}
                                                                    target="_blank"
                                                                    className="open-link-holder white"
                                                                    >
                                                                    <span className="link-text">{eidDuplicateTooltip.linkMessage}
                                                                        <InlineIcon >
                                                                            <LinkIcon/>
                                                                        </InlineIcon>
                                                                    </span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                        : ""
                                                    }
                                                >
                                                <LazyInput
                                                    type="text"
                                                    name="eid"
                                                    className={inputs.eid.class}
                                                    value={inputs.eid.value}
                                                    data-place="right"
                                                    data-type="error"
                                                    data-tip={inputs.eid.message}
                                                    onChange={this.onInputChange}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="inner-info pov diff-revision-section">
                                        <span className="inner-attribute">revision</span>
                                        <div className="inner-value">
                                            {
                                                isNewObject && (isNotRevisionManagedEnabled ? inputs.revisionManaged : true) ?
                                                <LazyInput
                                                    type="text"
                                                    name="revision"
                                                    className={inputs.revision.class}
                                                    value={inputs.revision.value}
                                                    data-place="right"
                                                    data-type="error"
                                                    data-tip={inputs.revision.message}
                                                    onChange={this.onInputChange}
                                                />
                                                :
                                                <RevisionField
                                                    item={component}
                                                    revision={categoryRevision}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="inner-info pov diff-status-section">
                                        <span className="inner-attribute">status</span>
                                        <div className="inner-value have-input width100" data-tip={tooltipText} data-for="vendor-disable-input">
                                            {
                                                this.handleSelectDisplay(isVendorCmp, inputs, isNewObject, component)
                                            }
                                        </div>
                                    </div>
                                    {
                                        isVendorCmp &&
                                        <div className="inner-info pov addIn-only-fields">
                                            <span className="inner-attribute">workflow state</span>
                                            <div className="inner-value">
                                                { component.cmpState ? component.cmpState : '' }
                                            </div>
                                        </div>
                                    }
                                </TileItem>

                                <TileItem title="Details" icon={ <ItemDetailsIcon /> }>
                                    {
                                        isVendorCmp && inputs.description.value !== '' &&
                                        <p class="description">{inputs.description.value}</p>
                                    }
                                    {
                                        !isVendorCmp &&
                                        <div class="description">
                                            <TextArea
                                                name="description"
                                                placeholder="Enter a component description"
                                                onChange={this.onInputChange}
                                                value={inputs.description.value}
                                                data-place="right"
                                                data-type="error"
                                                className={`description diff-description-section ${inputs.description.class}`}
                                                data-tip={inputs.description.message}
                                            />
                                        </div>
                                    }
                                    <InlineIcon className="primary-source">
                                        <WebSrcIcon />
                                        <span>primary source</span>
                                    </InlineIcon>
                                    <div className="inner-info values-to-right diff-mpn-section">
                                        <span className="inner-attribute">mpn</span>
                                        <span className="inner-value" data-tip={tooltipText} data-for="vendor-disable-input">
                                            {primarySource.mpn}
                                        </span>
                                    </div>
                                    <div className="inner-info values-to-right diff-mfr-section">
                                        <span className="inner-attribute">mfr</span>
                                        <span className="inner-value" data-tip={tooltipText} data-for="vendor-disable-input">
                                            {primarySource.manufacturer}
                                        </span>
                                    </div>
                                    <div className="inner-info values-to-right diff-min-qty-section">
                                        <span className="inner-attribute">min qty</span>
                                        <span className="inner-value" data-tip={tooltipText} data-for="vendor-disable-input">
                                            {primarySource.minQuantity === null ? '' : primarySource.minQuantity}
                                        </span>
                                    </div>
                                    <UnitPrice
                                        primarySource={primarySource}
                                        item={component}
                                        tooltipText={tooltipText}
                                        mode="EDIT"
                                    />
                                    <ExtendedRolledUpCost
                                        extendedCost={primarySource.extendedCost}
                                        object={component}
                                        mode="EDIT"
                                        alias="cmp"
                                    />
                                    <div className="inner-info last-info values-to-right diff-lead-time-section">
                                        <span className="inner-attribute">lead time</span>
                                        <span className="inner-value">
                                            { primarySourceLeadTimeValue +' '+ primarySourceLeadTimeUnits }
                                        </span>
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">created</span>
                                        <TimeStampColumn format='date-time-with-long-format' value={component.created} />
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">modified</span>
                                        <LastUpdatedField item={ component }/>
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">modified by</span>
                                        {lastModifiedBy}
                                    </div>
                                </TileItem>

                                <TileItem title="Specs" icon={ <ItemSpecsIcon /> }>
                                    {
                                        isNotRevisionManagedEnabled &&
                                        <RevisionManaged
                                            tooltipText={tooltipText}
                                            vendor={Utils.getVendor(component)}
                                            revisionManaged={inputs.revisionManaged}
                                            onChange={
                                                inputs.revisionManaged ? this.showRevisionWarningModal : (e) => this.checkRevisionManaged(e)
                                            }
                                        />
                                    }
                                    {
                                        inputs.specs.map((spec, i) => {
                                            const allowedValues = []
                                            spec.schema && spec.schema.allowedValues.map(allowedValue => {
                                                allowedValues.push({ value: allowedValue, displayName: allowedValue })
                                            });
                                            const isDropDown = spec.schema && spec.schema.isDropDown;
                                            return( Schemas.component.specs.isSpecAllowedForCompany(spec.key) && 
                                            <div key={`specs-info-${i}`} className={`inner-info values-to-right ${`diff-${spec.key.replace(/[^a-zA-Z ]/g, "").split(' ').join('-').toLowerCase()}-section`}`}>
                                                <span className="inner-attribute specs">
                                                    {Schemas.component.specs.getKey(spec.key)}
                                                </span>
                                                <div className={`inner-value${isVendorCmp ? ' have-input' : ''} ${(vendorsForEditableFields.includes(vendorLabel) && spec.key !== 'Material') ? "editable-vendor-fields" : ""}`} data-tip={isVendorCmp && spec.key === "Material"  ? tooltipText : null} data-for={isVendorCmp ? 'vendor-disable-input' : null}>
                                                    <SpecInputField
                                                        input={spec}
                                                        onInputChange={this.onInputChange}
                                                        allowedValues={allowedValues}
                                                        isDropDown={isDropDown}
                                                        index={i}
                                                    />
                                                </div>
                                            </div>
                                        )})
                                    }
                                    <div className="inner-info values-to-right diff-mass-section">
                                        <span className="inner-attribute specs no-cl">{`MASS (${Utils.getCompanyMassUnit()})`}</span>
                                        <div className="inner-value">
                                            {
                                                isAssemblyType &&
                                                <Fragment>
                                                    {
                                                        (this.state.showMassErrorIcon === true && component.massStatus && component.massStatus.toUpperCase() === "ERROR") &&
                                                        <InlineIcon
                                                            tooltip="Missing mass values for one or more children"
                                                            tooltipPlace="top"
                                                            className="inside-icon"
                                                        >
                                                            <AlertErrorIcon />
                                                        </InlineIcon>
                                                    }
                                                    {
                                                        ((this.state.showMassWarningIcon === true) || (component.massStatus && component.massStatus.toUpperCase() === "WARNING")) &&
                                                        <InlineIcon
                                                            tooltip={`${inputs.mass.value ? "Manually entered value" : "Auto calculate value from children"} `}
                                                            tooltipPlace="top"
                                                            className="inside-icon"
                                                        >
                                                            <WarningTriangleIcon />
                                                        </InlineIcon>
                                                    }
                                                </Fragment>
                                            }
                                            <NumberFormat
                                                type="text"
                                                name="mass"
                                                className={`${inputs.mass.class}${isAssemblyType ? ' icon-in-input': ''}`}
                                                value={inputs.mass.value}
                                                onChange={this.onInputChange}
                                                data-place="right"
                                                data-type="error"
                                                data-tip={inputs.mass.message}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                    <ProcurementEdit
                                        onInputChange={this.handleProcurement}
                                        inputs={inputs}
                                        isVendorCmp={isVendorCmp}
                                        vendorLabel={vendorLabel}
                                    />
                                    {/* <div className="inner-info values-to-right diff-procurement-section">
                                        <span className="inner-attribute specs">procurement</span>
                                        <select
                                            className="inner-value"
                                            name="procurement"
                                            value={inputs.procurement}
                                            onChange={(e) => this.handleProcurement(e)}
                                        >
                                            {Utils.toOptions(Schemas.component.procurement.list())}
                                        </select>
                                    </div> */}
                                    <div className={`${vendorsForEditableFields.includes(vendorLabel) ? "editable-vendor-fields" : ""} inner-info values-to-right diff-unit-of-measure-section`}>
                                        <span className="inner-attribute specs">unit of measure</span>
                                        <select
                                            className="inner-value uom-input"
                                            data-tip={tooltipText}
                                            data-for="unit-of-measure-input"
                                            name="unitOfMeasure"
                                            value={inputs.unitOfMeasure.value}
                                            onChange={this.onInputChange}
                                        >
                                            {Utils.toOptions(Schemas.component.unitOfMeasure.list())}
                                        </select>
                                    </div>
                                </TileItem>
                                { showCustomProperties && 
                                <ItemCustomProperties 
                                    item={component}
                                    isVendorCmp={isVendorCmp}
                                    mode="edit"
                                    tooltipText={tooltipText}
                                    onInputChange={(e, i)=>this.onInputChange(e, i)}
                                />}
                                <ItemERP item={component}/>
                            </PerfectScrollbar>
                        </div>
                    </div>
                    <Tabs
                        tabsType="cmp"
                        errorsCount={this.state.errorsCount}
                        toggleErrorCount={this.toggleErrorCount}
                        component={component}
                        onChange={this.onInputChange}
                        componentSearch={this.componentSearch}
                        clearComponentSearch={this.clearComponentSearch}
                        inputs={inputs}
                        showFileImportModal={this.state.showFileImportModal}
                        hideAssemblyModals={this.hideAssemblyModals}
                        newlyCreatedComponent={this.state.newlyCreatedComponent}
                        clearNewlyCreatedComponentsFromState={this.clearNewlyCreatedComponentsFromState}
                        actionType={this.state.actionType}
                        newlyAddedComponentsFromRightSearch={this.state.addedAssemblyFromLibrary}
                        clearNewlyAddedComponentsFromState={this.clearNewlyAddedComponentsFromState}
                        dispalyErrorModal={this.dispalyErrorModal}
                        history={this.props.history}
                        onPrimarySourceChange={this.onPrimarySourceChange}
                        disablePrimarySourceBtn={this.disablePrimarySourceBtn}
                        enablePrimarySourceBtn={this.enablePrimarySourceBtn}
                        onPrimarySourceDeselect={this.onPrimarySourceDeselect}
                        onRolledUpSelect={this.onRolledUpSelect}
                        onRolledUpInputChanged={this.onRolledUpInputChanged}
                        allowPrimarySourceIcon={this.state.allowPrimarySourceIcon}
                        setPrimarySourceInObject={this.setPrimarySourceInObject}
                        syncPrimaryQuoteInputWithData={this.syncPrimaryQuoteInputWithData}
                        primarySource={primarySource}
                        userStyles={this.props.user.preferences.styles}
                        dispatch={this.props.dispatch}
                        item_assembly={this.props.item_assembly}
                        syncWithRolledUpCost={this.state.syncWithRolledUpCost}
                        afterSyncWithRolledUpCost={this.afterSyncWithRolledUpCost}
                        showHideAssemblySearch={this.showHideAssemblySearch}
                        toggleModal={this.toggleModal}
                        cmpId={this.state.cmpId}
                        defaultCurrency={component.defaultCurrency}
                    />
                </form>

            </div>

        return markup
    }
}

export default connect((store) => ({
    user: store.user,
    company: store.company,
    inputs: store.components.editPage.inputs,
    item_assembly: store.item_assembly,
    component: store.components.editPage.component,
    modified: store.components.editPage.modified,
    isNewObject: store.components.editPage.isNewObject,
    children: store.assembly_edit.children,
    childrenModified: store.assembly_edit.modified,
    originalComponentId: store.components.editPage.originalComponentId
}))(ComponentEdit);
