import { FunctionComponent, useMemo, useCallback, useState, MutableRefObject } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  DataGridPro, DataGridProProps, GridColDef, GridRenderCellParams, GridEventListener,
  GridCallbackDetails, GridCellCheckboxRenderer, GridSelectionModel, GridRowClassNameParams, GridSortModel,
  GRID_CHECKBOX_SELECTION_COL_DEF, LicenseInfo, GridRowId, GridColumnVisibilityModel, GridInitialState,
  GridFilterModel, GridRowIdGetter, GridValidRowModel, GridCellParams, useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridToolbar, ToolBarItem } from "./gridToolbar";
import { TableFilterPanel } from "./gridFilterPanel";
import { TreeCollapseIcon } from "assets/icons/treeCollapseIcon";
import { TreeExpandIcon } from "assets/icons/treeExpandIcon";
import { DuroCheckbox } from "../checkbox";

LicenseInfo.setLicenseKey(
  "21c77a7e549bf40b7d5c7e307d97d640Tz01NTUwNixFPTE3MDE2MjM3OTUwOTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

export const enum CellContentAlignment {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
}

export const enum SortMode {
  SERVER = "server",
  CLIENT = "client",
}

export const ROW_HEIGHT = 34;

interface IGridProps<RowData extends GridValidRowModel = GridValidRowModel> {
  apiRef?: MutableRefObject<GridApiPro>,
  autoHeight?: boolean;
  columnDefinition: Array<GridColDef>;
  columnVisibilityModel?: Record<GridRowId, boolean>,
  customFooter?: () => JSX.Element;
  data: RowData[];
  defaultGroupingExpansionDepth?: number,
  deselectedRowClassName?: string,
  filters?: GridFilterModel;
  filtersEnabled?: boolean;
  getCellClassName?: (params: GridCellParams) => string,
  getRowId?: GridRowIdGetter<RowData>;
  getTreeDataPath?: (row: RowData) => string[];
  initialColumnOrder?: string[]
  isCellEditable?: (params: GridCellParams<number>) => boolean,
  leftPinnedColumns?: Array<string>;
  loading?: boolean;
  loadingOverlay?: () => JSX.Element,
  onColumnsReordered?: GridEventListener<"columnOrderChange">;
  onColumnsResized?: GridEventListener<"columnWidthChange">;
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void;
  onFiltersChange?: (model: GridFilterModel, details: GridCallbackDetails<"filter">) => void;
  onRowScrollEnd?: GridEventListener<"rowsScrollEnd">;
  onSelectionChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
  onSortChange?: (sortModel: GridSortModel, details: GridCallbackDetails) => void;
  scrollEndThreshold?: number;
  selectedRowClassName?: string,
  selectionModel?: string[];
  sortMode?: SortMode;
  toolbarItems?: Array<ToolBarItem>;
  treeColumnDefinition?: DataGridProProps["groupingColDef"];
  treeData?: boolean;
}

function defaultGetTreeDataPath(row: { _path: string[] }) {
  return row._path;
}

export function getRowIdForApiData(row: { _id: string }) {
  return row._id;
}

const GridCheckbox = (props: GridRenderCellParams & { index: number }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOver = () => setIsHovered(true);
  const onMouseOut = () => setIsHovered(false);

  const { value: checked, index, ...checkboxProps }: any = props;
  if (checked || isHovered) {
    return (
      <GridCellCheckboxRenderer
        {...checkboxProps}
        onMouseOut={onMouseOut}
        value={checked}
      />
    );
  }
  return (
    <Typography onMouseOver={onMouseOver} >
      {(index + 1)}
    </Typography>
  );
};

export const Grid: FunctionComponent<IGridProps> = (props: IGridProps) => {
  const {
    apiRef,
    autoHeight = false,
    columnDefinition,
    columnVisibilityModel = {},
    customFooter,
    data,
    defaultGroupingExpansionDepth,
    deselectedRowClassName = "",
    filters,
    filtersEnabled,
    getCellClassName,
    getRowId,
    getTreeDataPath = defaultGetTreeDataPath,
    initialColumnOrder,
    isCellEditable,
    leftPinnedColumns = [],
    loading,
    loadingOverlay,
    onColumnsReordered,
    onColumnsResized,
    onColumnVisibilityModelChange,
    onFiltersChange,
    onRowScrollEnd,
    onSelectionChange,
    onSortChange,
    scrollEndThreshold = 80,
    selectedRowClassName = "",
    selectionModel,
    sortMode = SortMode.SERVER,
    toolbarItems = [],
    treeColumnDefinition,
    treeData,
  } = props;

  const backupApiRef = useGridApiRef();
  const internalApiRef = apiRef ?? backupApiRef;
  const columns: Array<GridColDef> = useMemo(() => (
    [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (params: GridRenderCellParams) => {
          const index = internalApiRef?.current?.getRowIndex(params.id);
          return (
            <GridCheckbox {...params} index={index} />
          );
        },
        disableReorder: true,
      },
      ...columnDefinition,
    ]
  ), [columnDefinition, internalApiRef]);

  // Creating the initial state for the grid. Using a useState here it only needs to be done once.
  const [initialState] = useState<GridInitialState>(() => {
    // if we pass in a specific field order, then make sure the checkbox column is always first.
    const orderedFields = initialColumnOrder ? [...initialColumnOrder] : initialColumnOrder;
    if (orderedFields) {
      orderedFields.unshift(GRID_CHECKBOX_SELECTION_COL_DEF.field);
    }

    return {
      columns: { columnVisibilityModel, orderedFields },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...leftPinnedColumns] },
      filter: { filterModel: filters },
    };
  });

  const getRowClassName = useCallback((params: GridRowClassNameParams) => {
    const isRowSelected = internalApiRef?.current?.isRowSelected(params.id);
    const rowClass = params.indexRelativeToCurrentPage % 2 ? "odd" : "even";
    return isRowSelected ? `${rowClass} ${selectedRowClassName}` : `${rowClass} ${deselectedRowClassName}`;
  }, [deselectedRowClassName, internalApiRef, selectedRowClassName]);

  const components = useMemo(() => ({
    BaseCheckbox: DuroCheckbox,
    ColumnSelectorIcon: SettingsOutlinedIcon,
    ColumnSortedAscendingIcon: ArrowDropUpIcon,
    ColumnSortedDescendingIcon: ArrowDropDownIcon,
    FilterPanel: TableFilterPanel,
    Footer: customFooter,
    LoadingOverlay: loadingOverlay ?? LinearProgress,
    Toolbar: GridToolbar,
    TreeDataCollapseIcon: TreeCollapseIcon,
    TreeDataExpandIcon: TreeExpandIcon,
  }), [customFooter, loadingOverlay]);
  const localeText = useMemo(() => ({
    toolbarColumns: "Settings",
    toolbarFilters: "Filter",
  }), []);

  const componentsProps = useMemo(() => ({
    toolbar: {
      filters,
      internalApiRef,
      items: toolbarItems,
    },
  }), [filters, internalApiRef, toolbarItems]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <DataGridPro
        apiRef={internalApiRef}
        autoHeight={autoHeight}
        autoPageSize
        checkboxSelection
        columns={columns}
        components={components}
        componentsProps={componentsProps}
        defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
        disableChildrenSorting
        disableColumnFilter={!filtersEnabled}
        disableColumnMenu
        disableSelectionOnClick
        getCellClassName={getCellClassName}
        getRowClassName={getRowClassName}
        getRowId={getRowId}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={treeColumnDefinition}
        headerHeight={ROW_HEIGHT}
        hideFooter={!customFooter}
        initialState={initialState}
        isCellEditable={isCellEditable}
        loading={loading}
        localeText={localeText}
        onColumnOrderChange={onColumnsReordered}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onColumnWidthChange={onColumnsResized}
        onFilterModelChange={onFiltersChange}
        onRowsScrollEnd={onRowScrollEnd}
        onSelectionModelChange={onSelectionChange}
        onSortModelChange={onSortChange}
        rowHeight={ROW_HEIGHT}
        rows={data}
        scrollEndThreshold={scrollEndThreshold}
        selectionModel={selectionModel}
        sortingMode={sortMode}
        treeData={treeData}
      />
    </Box>
  );
};
