import React, { Component } from "react";
import {connect}            from "react-redux";
import {Helmet}             from "react-helmet";
import { Divider } from "@mui/material";
import SettingSideNav       from '../setting-side-nav';
import Utils                from "../../../../modules/utils";
import IntegrationsTable    from "./integrations-table";
import { ApiToken } from "settings/apiToken";

export class Integrations extends Component
{
    constructor(props, context)
    {
        super(props, context)
    }

    render()
    {
        let markup =

            <div className="groups-settings-route">
                <Helmet>
                  <title>{Utils.makeTitle("Settings - Integrations")}</title>
                </Helmet>
                <SettingSideNav location={this.props.location} user={this.props.user.data}/>
                <div className="main-block">
                  <div className="heading-block">
                    <h1>Integrations</h1>
                  </div>
                    <div className="content-block">
                      <IntegrationsTable
                        company={this.props.company}
                        dispatch={this.props.dispatch}
                        loading={this.props.loading}
                      />
                    </div>

                  <Divider style={dividerStyle} />
                  <ApiToken />
                </div>
            </div>

        return markup
    }
}

const dividerStyle = {
  margin: "3rem 0",
};

const mapStateToProps = state => ({
    user: state.user,
    company: state.company,
    loading: state.users.loading,
    dispatch: state.dispatch
})

export default connect(mapStateToProps)(Integrations)
