import PRODUCT from "../../../action-types/product"
import initialState, { getInitialState, newforecastInputs } from "./initial_state"
import { handleActions } from "redux-actions"
import Schemas    from "../../../modules/schemas"

function setProductAndPopulateInForm(state= initialState, action)
{
    state = resetFormInputs(state, action)
    let { inputs } = state
    let { product } = state

    product = action.payload

    inputs.name.value          = product.name
    inputs.revision.value      = product.revision
    inputs.status.value        = product.status
    inputs.eid.value           = product.eid
    inputs.description.value   = product.description
    inputs.images.value        = product.images
    inputs.documents.value     = product.documents
    inputs.children.value      = product.children
    inputs.manufacturers.value = product.manufacturers
    inputs.cpnVariant.value    = product.cpnVariant
    inputs.mass.value          = product.mass
    inputs.procurement         = product.procurement
    inputs.cpn.value           = product.cpn
    let list   = Schemas.product.team.list()
    list.forEach((schema) =>
    {
        inputs.team.push({key: schema.value, value: product.team[schema.value].email, displayName: schema.displayName})
    })

    inputs.forecasts = product.forecasts.map((item) =>
    {
        let input =
        {
            key :
            {
                value   : item.key,
                class   : "",
                valid   : true,
                message : ""
            },
            targetDate :
            {
                value   : item.targetDate,
                class   : "",
                valid   : true,
                message : ""
            },
            volume :
            {
                value   : item.volume,
                class   : "",
                valid   : true,
                message : ""
            },
            counter:
            {
                value: item.counter
            },
            isDisabled: item.counter === 1 && item.key !== "massProductionBuild" ? true : false
        }
        return input
    })

    state.inputs = inputs
    state.product = product
    return {...state}
}

function setFormFields(state= initialState, { payload })
{
    state.inputs  = payload
    return {...state}
}

function setComponentSearchResults(state=initialState, { payload })
{
    state.inputs.searchComponent.results = payload
    return {...state}
}

function addNewForecast(state=initialState, {payload})
{
    let forecasts = state.inputs.forecasts
    let existingForecastKeys = []
    for(let forecast of forecasts)
    {
        existingForecastKeys.push(forecast.key.value)
    }

    let keyWithCounter = {}
    if (payload.forecastType === "EXPERT")
    {
        keyWithCounter = Schemas.product.forecasts.getNextForecastLabel(existingForecastKeys, payload.forecastLabelType, payload.forecastType)
        let index = 0
        for(let i=0; i < forecasts.length; i++)
        {
            index = i
            if(forecasts[i].key.value === payload.forecastLabelType && forecasts[i+1].key.value !== payload.forecastLabelType)
            {
                index++
                break;
            }
        }
        forecasts.splice(index, 0, newforecastInputs(keyWithCounter.key, keyWithCounter.counter))
    }
    else
    {
        keyWithCounter = Schemas.product.forecasts.getNextForecastLabel(existingForecastKeys, "massProductionBuild")
        forecasts.push(newforecastInputs(keyWithCounter.key, keyWithCounter.counter))
    }

    // let keyWithCounter = Schemas.product.forecasts.getNextForecastLabel(existingForecastKeys, "massProductionBuild")
    // forecasts.push(newforecastInputs(keyWithCounter.key, keyWithCounter.counter))
    state.modified = true
    return {...state}
}

function removeForecast(state=initialState, {payload})
{
    let { inputs } = state
    let deletedForecast = inputs.forecasts.splice(payload.index, 1)
    deletedForecast = deletedForecast[0]

    inputs = resetForecastCounter(inputs, payload.forecastType, deletedForecast)
    state.modified = true
    return {...state}
}

function resetForecastCounter(inputs, forecastType, deletedForecast)
{
    if (forecastType === "EXPERT")
    {
        inputs.forecasts.forEach((input) =>
        {
            if (input.counter.value > deletedForecast.counter.value)
            {
                input.counter.value = input.counter.value - 1
            }
        })
    }
    else
    {
        let count = 0
        inputs.forecasts.forEach((input) =>
        {
            count++

            if (count > 2)
            {
                input.key.value = "massProductionBuild"
                input.counter.value = count - 2
            }
        })
    }

    return inputs
}


function resetForm(state, { payload })
{
    return {...getInitialState()}
}

function updateModifiedFlag(state, action)
{
    state = {...state, modified: action.payload}
    return {...state}
}

function setIsNewObjectFlag(state, action)
{
    state = {...state, isNewObject: action.payload}
    return {...state}
}

function setOrignalProductId(state, action)
{
    state = {...state, originalProductId: action.payload}
    return {...state}
}

function resetFormInputs(state = initialState, action)
{
    let {inputs} = {...getInitialState()}
    state = {...state, inputs: inputs, product: null}
    return {...state}
}

function updateImageStateOnEditPage(state, { payload })
{
    state.inputs.isUploadedThumbnails = payload.isUploadedThumbnails
    state.inputs.images.value = payload.images
    return {...state}
}

function updateAssemblyOnEditPage(state, { payload })
{
    state.inputs.children.value = payload.children
    return {...state}
}

export default handleActions({
  [PRODUCT.SET_PRODUCT_IN_EDIT_FORM]: setProductAndPopulateInForm,
  [PRODUCT.SET_EDIT_PAGE_MODIFIED]: updateModifiedFlag,
  [PRODUCT.SET_IS_NEW_OBJECT_FLAG]: setIsNewObjectFlag,
  [PRODUCT.SET_ORIGNAL_PRODUCT_ID]: setOrignalProductId,
  [PRODUCT.UPDATE_EDIT_PRODUCT_FORM_FIELDS]: setFormFields,
  [PRODUCT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS]: setComponentSearchResults,
  [PRODUCT.ADD_NEW_FORECAST_EDIT_PAGE]: addNewForecast,
  [PRODUCT.REMOVE_FORECAST_EDIT_PAGE]: removeForecast,
  [PRODUCT.RESET_PRODUCT_EDIT_FORM]: resetForm,
  [PRODUCT.RESET_PRODUCT_EDIT_FORM_INPUTS]: resetFormInputs,
  [PRODUCT.UPDATE_IMAGES_STATE_ON_EDIT_PAGE]: updateImageStateOnEditPage,
  [PRODUCT.UPDATE_ASSEMBLY_ON_EDIT_PAGE]: updateAssemblyOnEditPage,
}, initialState);
