import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import config from "../v1/modules/config";

const httpLink = new BatchHttpLink({
  uri: config.REACT_APP_GRAPHQL_URL,
  batchMax: 5,
  batchInterval: 50, // Wait no more than 50ms after first batched operation
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: { errorPolicy: "all" },
  },
});

export function setGraphqlHeaders(token: string) {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

  client.setLink(authLink.concat(httpLink));
}
