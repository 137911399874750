import { useCallback, useMemo } from "react";
import { DocumentNode, FetchPolicy, gql, useLazyQuery } from "@apollo/client";
import {
  ComponentChildrenFragment,
  ComponentRevisionChildrenFragment,
  ProductChildrenFragment,
  ProductRevisionChildrenFragment,
} from "graphql/fragment/itemChildrenFragment";
import {
  ComponentFragment,
  ComponentRevisionFragment,
  ProductFragment,
  ProductRevisionFragment,
} from "graphql/fragment/itemsFragment";

export const enum AliasType {
  CMP = "cmp",
  PRD = "prd",
}

export const enum ModelType {
  REVISION = "revision",
  WORKING = "working",
}

export const enum QueryType {
  CHILDREN = "children",
  ITEM = "item",
}

export const enum ResponseType {
  COMPONENT_REVISIONS_BY_IDS = "componentRevisionsByIds",
  COMPONENTS_BY_IDS = "componentsByIds",
  PRODUCT_REVISIONS_BY_IDS = "productRevisionsByIds",
  PRODUCTS_BY_IDS = "productsByIds",
}

export type QueryLookupType = {
  [key: string]: {
    [key: string]: {
      [key: string]: DocumentNode;
    }
  }
};

export type ResponseLookupType = {
  [key: string]: {
    [key: string]: ResponseType,
  }
};

export const GET_COMPONENT: DocumentNode = gql`
  query getComponent($ids: [ID]) {
    componentsByIds(ids: $ids) {
      ...componentFragment
    }
  }
  ${ComponentFragment}
`;

export const GET_COMPONENT_CHILDREN: DocumentNode = gql`
  query getComponentChildren($ids: [ID]) {
    componentsByIds(ids: $ids) {
      ...componentChildrenFragment
    }
  }
  ${ComponentChildrenFragment}
`;

export const GET_COMPONENT_REVISION: DocumentNode = gql`
  query getComponentRevision($ids: [ID]) {
    componentRevisionsByIds(ids: $ids) {
      ...componentRevisionFragment
    }
  }
  ${ComponentRevisionFragment}
`;

export const GET_COMPONENT_REVISION_CHILDREN: DocumentNode = gql`
  query getComponentRevisionChildren($ids: [ID]) {
    componentRevisionsByIds(ids: $ids) {
      ...componentRevisionChildrenFragment
    }
  }
  ${ComponentRevisionChildrenFragment}
`;

export const GET_PRODUCT: DocumentNode = gql`
  query getProduct($ids: [ID]) {
    productsByIds(ids: $ids) {
      ...productFragment
    }
  }
  ${ProductFragment}
`;

export const GET_PRODUCT_CHILDREN: DocumentNode = gql`
  query getProductChildren($ids: [ID]) {
    productsByIds(ids: $ids) {
      ...productChildrenFragment
    }
  }
  ${ProductChildrenFragment}
`;

export const GET_PRODUCT_REVISION: DocumentNode = gql`
  query getProductRevision($ids: [ID]) {
    productRevisionsByIds(ids: $ids) {
      ...productRevisionFragment
    }
  }
  ${ProductRevisionFragment}
`;

export const GET_PRODUCT_REVISION_CHILDREN: DocumentNode = gql`
  query productRevisionChildren($ids: [ID]) {
    productRevisionsByIds(ids: $ids) {
      ...productRevisionChildrenFragment
    }
  }
  ${ProductRevisionChildrenFragment}
`;

const queryLookup: QueryLookupType = {
  cmp: {
    revision: {
      children: GET_COMPONENT_REVISION_CHILDREN,
      item: GET_COMPONENT_REVISION,
    },
    working: {
      children: GET_COMPONENT_CHILDREN,
      item: GET_COMPONENT,
    },
  },
  prd: {
    revision: {
      children: GET_PRODUCT_REVISION_CHILDREN,
      item: GET_PRODUCT_REVISION,
    },
    working: {
      children: GET_PRODUCT_CHILDREN,
      item: GET_PRODUCT,
    },
  },
};

const responseLookup: ResponseLookupType = {
  cmp: {
    revision: ResponseType.COMPONENT_REVISIONS_BY_IDS,
    working: ResponseType.COMPONENTS_BY_IDS,
  },
  prd: {
    revision: ResponseType.PRODUCT_REVISIONS_BY_IDS,
    working: ResponseType.PRODUCTS_BY_IDS,
  },
};

export function useFetchItem(
  alias: AliasType = AliasType.CMP,
  fetchPolicy: FetchPolicy = "cache-first",
  model: ModelType = ModelType.WORKING,
  query: QueryType = QueryType.ITEM,
) {
  const QUERY = useMemo(() => queryLookup[alias][model][query], [alias, model, query]);

  const [loadItem, { data, error, loading }] = useLazyQuery(QUERY, { fetchPolicy });

  const fetchItem = useCallback(id => {
    loadItem({
      variables: {
        ids: [id],
      },
    });
  }, [loadItem]);
  const item = useMemo(() => data?.[responseLookup[alias][model]] ?? {}, [alias, data, model]);
  return { error, fetchItem, item, loading };
}
