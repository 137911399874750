import { FC, ReactNode, ReactElement } from "react";
import { DialogContent } from "@mui/material";

export interface IContent {
  children?: ReactNode | ReactElement,
}

export const Content: FC<IContent> = ({
  children,
  ...rest
}: IContent) => <DialogContent {...rest}>
    {children}
  </DialogContent>;
