import { useCallback, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { PaletteMode, SxProps } from "@mui/material";
import type { } from "@mui/x-data-grid-pro/themeAugmentation";
import { AppSection } from "context";
import { StatusValue } from "design/constants";

export const colorPalette = {
  black: "#000",
  blueNavy: "#262633",
  dark: "#2a2a36",
  darkGrey: "#353540",
  doveGray: "#6F6F6F",
  gray: "#262626",
  graySeventy: "#B3B3B3",
  green: "#3cd1b6",
  greenDark: "rgb(60,209,182,.6)",
  jaguar: "#18171D",
  lightGreen: "#3CD1BF",
  lightGrey: "#CBCBCB",
  purple: "#bf8aff",
  purpleDark: "#ac7ee6",
  spanishGray: "#979797",
  taupeGray: "#888888",
  taupeGrayShade: "#88888826",
  white: "#fff",
};

declare module "@mui/material/styles" {
  interface DuroTheme {
    complexTable: {
      backgroundColor: string;
      borderColor: string;
      toolbar: {
        disabledButtonColor: string;
      }
    }
    dialogModal: {
      backgroundColor: string;
    }
    eventModal: {
      attachementsSections: {
        borderColor: string;
        content: {
          color: string;
        }
      }
      sectionDivider: {
        borderColor: string;
      }
      selectOptions: {
        backgroundColor: string;
      }
      textArea: {
        backgroundColor: string;
        color: string;
      },
    },
    fields: {
      timestamp: {
        dateColor: string;
        timeColor: string;
      }
    }
    filledInput: {
      backgroundColor: string,
    },
    rightSideBar: {
      backgroundColor: string;
      borderColor: string;
    }
    status: Record<StatusValue, SxProps<Theme>>;
    tabs: {
      tabButton: {
        baseTextColor: string;
        error: {
          backgroundColor: string;
          badgeColor: string;
        }
      }
    }
  }

  interface Theme {
    duro: DuroTheme;
  }

  interface ThemeOptions {
    duro: DuroTheme;
  }
}

// Duro theme. App-wide styling should be defined here instead of individual components.
export function useDuroTheme(mode: PaletteMode, section: AppSection) {
  const modeOverrides = useMemo(() => {
    switch (mode) {
      case "light":
        return {
          background: {
            paper: "#ccc",
          },
          text: {
            primary: "#000",
            secondary: "#333",
          },
          action: {
            active: "#444",
          },
        };
      default:
        return {
          background: {
            paper: "#444",
          },
          text: {
            primary: "#fff",
            secondary: "#ddd",
          },
          action: {
            active: colorPalette.lightGrey,
          },
        };
    }
  }, [mode]);

  const overrides = useMemo(() => {
    const buttonCommon = {
      disabledFontColor: "rgba(42, 42, 54, .75)",
      error: {
        main: "#F54A50",
        hoverBackground: "#FF8388",
        disabledBackground: "rgba(245, 74, 80, .4)",
      },
      info: {
        disabledColor: "rgba(136, 136, 136, .4)",
        hoverBackground: "rgba(216, 216, 216, .1)",
        main: colorPalette.taupeGray,
      },
    };

    switch (section) {
      case AppSection.BUILD:
        return {
          palette: {
            ...modeOverrides,
            primary: {
              main: "#BF8AFF",
              dark: "#A06EDC",
            },
            secondary: {
              main: "#3CD1B6",
              dark: "#00C3A0",
            },
          },
          button: {
            ...buttonCommon,
            primary: {
              hoverBackground: "#D6B4FF",
              disabledBackground: "rgba(191, 138, 255, .4)",
            },
          },
          iconButton: {
            hoverColor: "#BF8AFF",
          },
          Switch: {
            switchBase: {
              color: "#CCC",
            },
            colorPrimary: {
              "&.Mui-checked": {
                color: "#BF8AFF",
              },
            },
            track: {
              backgroundColor: "#CCC",
              ".Mui-checked.Mui-checked + &": {
                backgroundColor: "#BF8AFF",
              },
            },
          },
          baseButtonSelectedState: {
            backgroundColor: `${colorPalette.purple} !important`,
          },
          filledInput: {
            borderColor: colorPalette.purple,
          },
          muiDataGrid: {
            row: {
              checkbox: {
                hoveredState: {
                  borderBottom: `solid 1px ${colorPalette.purple}`,
                },
              },
              hoveredState: {
                borderBottom: `solid 0.063rem ${colorPalette.purple}`,
                boxShadow: `1px 1px 0 ${colorPalette.purple} inset, -1px -1px 0 ${colorPalette.purple} inset`,
              },
            },
          },
          muiPaper: {
            "& button.Mui-selected": {
              backgroundColor: `${colorPalette.purple} !important`,
            },
          },
          textArea: {
            "&:focus": {
              borderBottom: `0.125rem solid ${colorPalette.purple} !important`,
            },
          },
        };
      default:
        return {
          palette: {
            ...modeOverrides,
            primary: {
              main: "#3CD1B6",
              dark: "#00C3A0",
            },
            secondary: {
              main: "#BF8AFF",
              dark: "#A06EDC",
            },
          },
          button: {
            ...buttonCommon,
            primary: {
              hoverBackground: "#00FFD1",
              disabledBackground: "rgba(60, 209, 182, .4)",
            },
          },
          iconButton: {
            hoverColor: "#3CD1B6",
          },
          Switch: {
            switchBase: {
              color: "#CCC",
            },
            colorPrimary: {
              "&.Mui-checked": {
                color: "##3CD1B6",
              },
            },
            track: {
              backgroundColor: "#CCC",
              ".Mui-checked.Mui-checked + &": {
                backgroundColor: "##3CD1B6",
              },
            },
          },
          baseButtonSelectedState: {
            backgroundColor: `${colorPalette.green} !important`,
          },
          filledInput: {
            borderColor: colorPalette.green,
          },
          muiDataGrid: {
            row: {
              checkbox: {
                hoveredState: {
                  borderBottom: `solid 1px ${colorPalette.green}`,
                },
              },
              hoveredState: {
                boxShadow: `1px 1px 0 ${colorPalette.green} inset, -1px -1px 0 ${colorPalette.green} inset`,
              },
            },
          },
          muiPaper: {
            "& button.Mui-selected": {
              backgroundColor: `${colorPalette.green} !important`,
            },
          },
          textArea: {
            "&:focus": {
              borderBottom: `0.125rem solid ${colorPalette.green} !important`,
            },
          },
        };
    }
  }, [modeOverrides, section]);

  const toggleButtonOverrides = useCallback((value, type) => {
    const lookup: any = {
      PASS: "green",
      FAIL: "red",
      DNF: "blue",
      "N/A": "orange",
    };
    return { [type]: lookup[value] };
  }, []);

  const duroTheme = useMemo(() => createTheme(({
    palette: {
      common: {
        ...colorPalette,
      },
      mode,
      error: {
        dark: red.A400,
        main: red.A100,
      },
      ...overrides.palette,
    },
    zIndex: {
      // Since for most of the existing modals, value of z-index is 10000
      modal: 10000,
    },
    duro: {
      complexTable: {
        backgroundColor: colorPalette.taupeGrayShade,
        borderColor: "#979797",
        toolbar: {
          disabledButtonColor: "rgba(255, 255, 255, 0.3)",
        },
      },
      dialogModal: {
        backgroundColor: "#18171D",
      },
      eventModal: {
        attachementsSections: {
          borderColor: "#8C8C8C",
          content: {
            color: "#C6C6C6",
          },
        },
        sectionDivider: {
          borderColor: "#979797",
        },
        selectOptions: {
          backgroundColor: colorPalette.gray,
        },
        textArea: {
          backgroundColor: colorPalette.gray,
          color: colorPalette.white,
        },
      },
      fields: {
        timestamp: {
          dateColor: colorPalette.white,
          timeColor: "#888888",
        },
      },
      filledInput: {
        backgroundColor: colorPalette.gray,
      },
      rightSideBar: {
        backgroundColor: colorPalette.darkGrey,
        borderColor: "#17171F",
      },
      status: {
        APPROVED: {
          border: "1px solid #7AE173",
          color: "#7AE173",
        },
        CLOSED: {
          backgroundColor: "unset",
          border: "1px solid $GREY_LIGHT3",
          color: "#888888",
        },
        DESIGN: {
          backgroundColor: "#9ACCFF",
          color: "#18171D",
        },
        DRAFT: {
          backgroundColor: "unset",
          border: "1px solid #FFFD91",
          color: "#FFFD91",
        },
        OBSOLETE: {
          backgroundColor: "#888888",
          color: "#18171D",
        },
        OPEN: {
          backgroundColor: "unset",
          border: "1px solid #9ACCFF",
          color: "#9ACCFF",
        },
        PRODUCTION: {
          backgroundColor: "#7AE173",
          color: "#18171D",
        },
        PROTOTYPE: {
          backgroundColor: "#FFFD91",
          color: "#18171D",
        },
        REJECTED: {
          border: "1px solid #F54A4F",
          color: "#F54A4F",
        },
        REVIEW: {
          backgroundColor: "unset",
          border: "1px solid #9ACCFF",
          color: "#9ACCFF",
        },
      },
      tabs: {
        tabButton: {
          baseTextColor: "#b3b3b3",
          error: {
            backgroundColor: "#a84148",
            badgeColor: "#f54a4f",
          },
        },
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            border: "0",
            "& > div:last-child": {
              backgroundColor: colorPalette.taupeGrayShade,
            },
            "& .MuiDataGrid-main": {
              border: "0.063rem solid #979797",
              borderBottomLeftRadius: 0,
              borderRadius: "4px",
              borderTopLeftRadius: 0,
              "& .MuiButtonBase-root": {
                minWidth: "auto",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: colorPalette.taupeGrayShade,
              },
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
              "&.Mui-selected": {
                backgroundColor: "inherit",
              },
              "&.odd, &.odd.Mui-selected": {
                backgroundColor: colorPalette.taupeGrayShade,
              },
              "&.even, &.even.Mui-selected": {
                backgroundColor: colorPalette.jaguar,
              },
              "&.Mui-hovered, &.Mui-selected.Mui-hovered": overrides.muiDataGrid.row.hoveredState,
              "&.Mui-hovered, &.Mui-selected.Mui-hovered, &.MuiDataGrid-cellCheckbox":
                overrides.muiDataGrid.row.checkbox.hoveredState,
              "& .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox)": {
                borderBottom: "none",
                "&:focus ": {
                  outline: "none",
                },
                "&.form-control-field": {
                  padding: "0 0.156rem",
                },
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "&.disabled": {
                opacity: 0.5,
                "& .MuiInputBase-formControl": {
                  pointerEvents: "none",
                },
              },
            },
            "& .MuiDataGrid-columnSeparator": {
              opacity: "0 !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns": {
              backgroundColor: colorPalette.taupeGrayShade,
              backgroundImage: "none",
            },
            "& .MuiDataGrid-columnHeader": {
              margin: "0 0.938rem 0 0",
              borderRight: "solid 0.063rem #979797",
              borderBottom: "solid 0.063rem #979797",
              marginRight: 0,
              "& .MuiDataGrid-columnHeaderTitle": {
                color: colorPalette.taupeGray,
                fontSize: "0.875rem",
                fontWeight: "bold",
                lineHeight: "0.93",
                textTransform: "uppercase",
              },
              "&.selectHeader": {
                padding: 5,
                borderBottom: "solid 2px #979797",
              },
              "&.MuiDataGrid-columnHeader--sorted, &.MuiDataGrid-columnHeader--sortable": {
                "& .MuiDataGrid-iconButtonContainer": {
                  visibility: "visible !important",
                  marginLeft: "auto",
                  width: "auto",
                  "& .MuiButtonBase-root": {
                    border: 0,
                    "& svg": {
                      fill: `${colorPalette.lightGrey} !important`,
                      fontSize: "1.8rem",
                    },
                    "&:hover, &:focus": {
                      backgroundColor: "transparent",
                    },
                  },
                },
              },
              "&.MuiDataGrid-columnHeader--sortable": {
                "&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
                  opacity: ".5",
                },
              },
              "&:focus, &:focus-within": {
                outline: "none",
              },
            },
            "& .MuiDataGrid-cellCheckbox": {
              backgroundColor: colorPalette.taupeGrayShade,
              borderBottom: `solid 0.063rem ${colorPalette.spanishGray}`,
              borderRight: `solid 0.063rem ${colorPalette.spanishGray}`,
              "& .MuiTypography-root": {
                color: colorPalette.taupeGray,
              },
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: overrides.palette.primary.dark,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            width: "17.5rem",
            display: "inline-block",
            margin: ".5rem",
            minHeight: "20.5rem",
            maxHeight: "20.5rem",
            height: "20.5rem",
            backgroundColor: colorPalette.darkGrey,
            borderRadius: "0.375rem",
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            backgroundColor: overrides.palette.primary.dark,
            height: "2rem",
            padding: ".5rem 0.813rem",
            textTransform: "uppercase",
            color: colorPalette.darkGrey,
            "& .MuiCardHeader-title": {
              fontSize: "0.875rem",
              fontWeight: "bold",
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            maxHeight: "18rem",
            overflow: "auto",
            paddingBottom: "0 !important",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            height: "1.25rem",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: "0.87rem",
          },
          textarea: {
            ...overrides.textArea,
            outline: "none",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: "#17171F",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            marginBottom: "1.5rem",
            padding: "0",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            marginBottom: "3rem",
            padding: "0",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "0",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "0.375rem",
            color: colorPalette.dark,
            height: "1.5rem",
            lineHeight: "1",
            padding: "0.25rem 0.906rem 0.25rem 0.969rem",
            textTransform: "none",
          },
        },
        variants: [
          {
            props: { variant: "contained", color: "primary" },
            style: {
              border: `1px solid ${overrides.palette.primary.main}`,
              "&:hover": {
                backgroundColor: overrides.button.primary.hoverBackground,
              },
              "&:disabled": {
                border: "1px solid transparent",
                backgroundColor: overrides.button.primary.disabledBackground,
                color: overrides.button.disabledFontColor,
              },
            },
          },
          {
            props: { variant: "outlined", color: "primary" },
            style: {
              border: `1px solid ${overrides.palette.primary.main}`,
              color: overrides.palette.primary.main,
              "&:hover": {
                backgroundColor: overrides.palette.primary.main,
                border: `1px solid ${overrides.palette.primary.main}`,
                color: colorPalette.taupeGray,
              },
              "&:disabled": {
                border: `1px solid ${overrides.button.primary.disabledBackground}`,
                color: overrides.button.primary.disabledBackground,
              },
            },
          },
          {
            props: { variant: "text", color: "primary" },
            style: {
              color: overrides.palette.primary.main,
              "&:hover": {
                color: overrides.button.primary.hoverBackground,
              },
              "&:disabled": {
                color: overrides.button.primary.disabledBackground,
              },
            },
          },
          {
            props: { variant: "outlined", color: "info" },
            style: {
              border: `0.125rem solid ${overrides.button.info.main}`,
              color: overrides.button.info.main,
              "&:hover": {
                backgroundColor: overrides.button.info.hoverBackground,
                border: `0.125rem solid ${overrides.button.info.main}`,
              },
              "&:disabled": {
                border: `0.125 solid ${overrides.button.info.disabledColor}`,
                color: overrides.button.info.disabledColor,
              },
            },
          },
          {
            props: { variant: "contained", color: "error" },
            style: {
              background: overrides.button.error.main,
              border: `1px solid ${overrides.button.error.main}`,
              "&:hover": {
                backgroundColor: overrides.button.error.hoverBackground,
              },
              "&:disabled": {
                border: "1px solid transparent",
                backgroundColor: overrides.button.error.disabledBackground,
                color: overrides.button.disabledFontColor,
              },
            },
          },
        ],
      },
      MuiIconButton:
      {
        styleOverrides: {
          root:
          {
            "&:hover": {
              backgroundColor: "transparent",
              color: overrides.iconButton.hoverColor,
            },
            "&:disabled": {
              color: "#FFFFFF61",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            width: "fit-content",
            padding: "0.5rem 1.375rem 0.5rem 1.5rem",
            borderRadius: "0.375rem",
            backgroundColor: "#353540",
            "@media (min-width: 37.5rem)":
            {
              minHeight: "2.5rem",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: ({ ownerState }) => {
            const { value } = ownerState;
            return ({
              "&.Mui-selected": {
                backgroundColor: "transparent",
                ...toggleButtonOverrides(value, "color"),
                "&:hover": {
                  backgroundColor: "transparent",
                },
                svg: {
                  path: toggleButtonOverrides(value, "fill"),
                },
              },
              "&:hover": {
                backgroundColor: "transparent",
              },
            });
          },
        },
      },
      MuiSwitch: {
        styleOverrides: overrides.Switch,
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&:after": {
              ...overrides.filledInput,
            },
            "&:before": {
              borderBottomStyle: "solid !important",
              borderColor: colorPalette.doveGray,
            },
            "&.Mui-disabled": {
              borderColor: colorPalette.taupeGray,
              "&:before": {
                borderColor: colorPalette.taupeGray,
              },
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.Mui-selected": overrides.baseButtonSelectedState,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: overrides.muiPaper,
        },
      },
    },
    typography: {
      // eslint-disable-next-line quotes
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
  })), [mode, overrides, toggleButtonOverrides]);

  return duroTheme;
}
