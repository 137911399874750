import { useState, FC, ElementType, useCallback, useMemo } from "react";
import { Container, styled, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InputFieldVariants } from "common/constants";
import { FieldTitle } from "../field-title";
import moment, { Moment } from "moment";
import { colorPalette } from "theme";

interface IDateTimePickerButtonProps {
  sx: {
    marginRight: string,
    padding: string,
  },
}

interface IRenderInputProps {
  placeholder?: string,
  readOnly?: boolean,
  style?: {
    background?: string,
    border?: string,
    fontSize?: string,
    height?: string,
    padding?: string,
    width?: string,
  },
  variant?: InputFieldVariants,
}

interface IInputWrapperProps {
  disableUnderline?: boolean,
  style?: {
    backgroundColor?: string,
  },
}
interface ICustomPickerStyle {
  alignItems?: string,
  display?: string,
  justifyContent?: string,
}

interface IDateTimePickerProps {
  calendarStyle?: {
    placement?: string,
  },
  customStyle?: ICustomPickerStyle,
  EnclosingTag: ElementType,
  inputFormat?: string,
  inputWrapperProps?: IInputWrapperProps,
  isRequired: boolean,
  label: string,
  onChange: (value: string | null) => void,
  openPickerButtonProps?: IDateTimePickerButtonProps,
  openPickerIcon?: ElementType,
  renderInputProps?: IRenderInputProps,
  setIntialValue?: boolean,
}

export const DateTimePicker: FC<IDateTimePickerProps> = ({
  calendarStyle,
  customStyle,
  EnclosingTag,
  inputFormat,
  inputWrapperProps,
  isRequired,
  label,
  onChange,
  openPickerButtonProps,
  openPickerIcon,
  renderInputProps,
  setIntialValue,
}) => {
  const [value, setValue] = useState<string | null>(setIntialValue ? (moment().format()) : null);

  const _inputFormat = useMemo(() => (
    inputFormat ?? "MMM D, YYYY hh:mmA"
  ), [inputFormat]);

  const handleChange = useCallback((newValue: Moment) => {
    const formattedValue: string = moment(newValue).format(_inputFormat);
    setValue(formattedValue);
    onChange(formattedValue);
  }, [_inputFormat, onChange]);

  const renderInput = useCallback(params => (
    <TextField  {...params}
      variant={renderInputProps?.variant}
      inputProps={{ ...params.inputProps, ...renderInputProps }}
    />
  ), [renderInputProps]);

  return (
    <PickerWrapper style={customStyle}>
      <FieldTitle
        isRequired={isRequired}
        label={label}
        sx={{
          fontSize: "0.75rem",
          marginBottom: "0.5rem",
        }}
      />
      <LocalizationProvider dateAdapter={AdapterMoment} >
        <EnclosingTag
          AutoComplete={false}
          components={{ OpenPickerIcon: openPickerIcon }}
          disableMaskedInput
          inputFormat={_inputFormat}
          InputProps={inputWrapperProps}
          onChange={handleChange}
          OpenPickerButtonProps={openPickerButtonProps}
          PaperProps={PopoverStyle}
          PopperProps={calendarStyle}
          renderInput={renderInput}
          value={value}
        />
      </LocalizationProvider>
    </PickerWrapper>
  );
};

const PickerWrapper = styled(Container, {
  shouldForwardProp: prop => prop !== "style",
})<ICustomPickerStyle>(({ style }) => ({
  ...style,
  marginBottom: "0.625rem",
  "@media (min-width: 37.5rem)": {
    padding: "0",
  },
}));

const PopoverStyle = {
  sx: {
    backgroundColor: colorPalette.gray,
    backgroundImage: "none",
    marginTop: "0.25rem",
  },
};
