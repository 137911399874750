import { FC, useCallback, useMemo } from "react";
import {
  Box,
  IconButton,
  IconButtonProps,
  styled,
  unstable_composeClasses as composeClasses,
} from "@mui/material";
import {
  DataGridProProps,
  getDataGridUtilityClass,
  GridRenderCellParams,
  useGridApiContext,
  useGridRootProps,
} from "@mui/x-data-grid-pro";
import { TreeCollapseIcon } from "assets/icons/treeCollapseIcon";
import { TreeExpandIcon } from "assets/icons/treeExpandIcon";

export interface IMemoTypes {
  _formattedValue: string,
  Icon: React.ElementType,
}

export interface IWrapperTypes {
  depth: number,
  hasChildren?: boolean,
}

const slots = {
  root: ["treeDataGroupingCell"],
  toggle: ["treeDataGroupingCellToggle"],
};

const useUtilityClasses = (ownerState: { classes: DataGridProProps["classes"] }) => {
  const { classes } = ownerState;
  return useMemo(() => composeClasses(slots, getDataGridUtilityClass, classes), [classes]);
};

export const GroupingColDef: FC<GridRenderCellParams> = ({ field, formattedValue, id, rowNode }) => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const classes = useUtilityClasses({ classes: rootProps?.classes });

  const { _formattedValue, Icon } = useMemo((): IMemoTypes => ({
    _formattedValue: formattedValue ?? rowNode.groupingKey,
    Icon: rowNode?.childrenExpanded ? TreeCollapseIcon : TreeExpandIcon,
  }), [formattedValue, rowNode?.childrenExpanded, rowNode.groupingKey]);

  const handleClick: IconButtonProps["onClick"] = useCallback(event => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  }, [apiRef, field, id, rowNode.childrenExpanded]);

  const iconButtonMarkup: React.ReactNode = useMemo(() => (rowNode?.children?.length && (
    <IconWrapper>
      <CustomIconButton onClick={handleClick}>
        <Icon />
      </CustomIconButton>
    </IconWrapper>
  )), [handleClick, Icon, rowNode]);

  return (
    <Wrapper className={classes.root} depth={rowNode.depth} hasChildren={!!rowNode?.children?.length}>
      {iconButtonMarkup}
      <span>
        {_formattedValue}
      </span>
    </Wrapper>
  );
};

const CustomIconButton = styled(IconButton)({
  padding: "0",
});

const IconWrapper = styled(Box)({
  display: "flex",
  flex: "0 0 1rem",
  marginRight: "0.313rem",
});

const WRAPPER_REMOVE_PROPS: string[] = ["rowNode", "hasChildren"];
const Wrapper = styled(Box, {
  shouldForwardProp: prop => !WRAPPER_REMOVE_PROPS.includes(prop as string),
})<IWrapperTypes>(({ depth, hasChildren = false }) => ({
  marginLeft: `${hasChildren ? depth * 1.125 : depth * 1.45}rem`,
}));
