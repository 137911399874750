export const mechanical = "MECHANICAL"
export const electrical = "ELECTRICAL"
export const mechanicalmts = "MECHANICAL OTS"
export const mechanicalots = "MECHANICAL MTS"
export const assembly = "ASSEMBLY"
export const document = "DOCUMENT"
export const software = "SOFTWARE"

export const default_status = "Design"
export const default_revision = ""
export const DEFAULT_USER_GROUPS = ["Engineering", "Manufacturer", "Management", "Procurement", "QA", "Reviewer", "Supplier"];
export const ALLOWED_VENDORS = ["SWX-CAD", "SWX-PDM", "SWX", "PDM", "ONSHAPE", "Valispace"];







