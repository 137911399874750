import { gql } from "@apollo/client";

// Base User fragment without any relations to prevent circular dependencies
export const UserFragment = gql`
  fragment userFragment on User {
    id
    activeLibrary {
      id
      cpnRules {
        base {
          counterDelimiter
          counterLength
          hideVariantStart
          prefix
          prefixDelimiter
          prefixLength
          useCategoryPrefix
          variantLength
          variantStart
        }
        component {
          counterDelimiter
          counterLength
          hideVariantStart
          prefix
          prefixDelimiter
          prefixLength
          useCategoryPrefix
          variantLength
          variantStart
        }
        product {
          prefix
          useCategoryPrefix
        }
      }
      type
    }
    archived
    avatar {
      id
      src
    }
    created
    email
    firstName
    groups
    id
    integrations
    invite {
      accepted
      from
    }
    isSetupCompleted
    lastLogin
    lastName
    logins {
      loginAt
    }
    onboarding {
      companyLibraryNotification
      completedSteps
      currentStep
      displaySampleProduct
      dummyProductType
      onBoardingNotification
      personalLibraryNotification
      quickStartGuideNotification
      status
    }
    preferences {
      favorite {
        components {
          id
          name
        }
      }
    }
    primaryCompany {
      id
      legacyCpnVariantScheme
      subdomain
    }
    role
    status
    styles
    timezoneOffset
    title
    verified
  }
`;

export const AllEnabledUsersFragment = gql`
  fragment allEnabledUsersFragment on User {
    id
    primaryCompany {
      users (status: ENABLED) {
        _id: id
        avatar {
          id
          name
          src
        }
        email
        firstName
        groups
        lastName
        role
        status
        styles
      } 
    }
  }
`;
