import { FC, useMemo } from "react";
import { styled, TextField } from "@mui/material";
import { InputFieldVariants } from "common/constants";
import { FieldTitle } from "../field-title";

export interface IComponentStyleProps {
  marginBottom?: string,
  width?: string,
}

export interface IInputFieldProps {
  componentStyles?: IComponentStyleProps,
  disabled?: boolean,
  inputProps?: IInputProps,
  inputStyles?: IInputStyleProps,
  isRequired: boolean,
  label?: string,
  labelStyling?: ILabelStyleProps,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  value?: string | number,
  variant?: InputFieldVariants,
}

export interface IInputProps {
  endAdornment?: React.ReactNode,
}

export interface IInputStyleProps {
  style?: {
    backgroundColor?: string,
    borderRadius?: string,
    padding?: string,
    paddingTop?: string,
  }
}

export interface ILabelStyleProps {
  color?: string,
}

export const InputField: FC<IInputFieldProps> = ({
  componentStyles,
  disabled,
  inputProps,
  inputStyles,
  isRequired,
  label,
  labelStyling,
  onChange,
  placeholder,
  value,
  variant,
}) => {
  const _fieldTitle = useMemo(() => (
    label && (
      <FieldTitle
      isRequired={isRequired}
      label={label}
      sx={{
        fontSize: "0.75rem",
        marginBottom: "0.5rem",
        ...labelStyling,
      }}
      />
    )
  ), [isRequired, label, labelStyling]);

  return (
    <>
      {_fieldTitle}
      <CustomInput
        componentStyles={componentStyles}
        disabled={disabled}
        InputProps={inputProps}
        inputStyles={inputStyles}
        onChange={onChange}
        placeholder={placeholder ?? ""}
        required={isRequired}
        value={value}
        variant={variant}
      />
    </>
  );
};

const propsToBeSkipped: string[] = ["componentStyles", "inputStyles"];
const CustomInput = styled(TextField, {
  shouldForwardProp: prop => !propsToBeSkipped.includes(prop as string),
})<Pick<IInputFieldProps, "componentStyles" | "inputStyles">>(({
  componentStyles,
  inputStyles,
  theme,
}) => ({
  "& .Mui-disabled": {
    backgroundColor: theme.duro.filledInput.backgroundColor,
  },
  "& .MuiFilledInput-input": {
    paddingTop: inputStyles?.style?.paddingTop ?? "0.313rem",
  },
  "& .MuiInputBase-root": {
    backgroundColor: inputStyles?.style?.backgroundColor ?? theme.duro.filledInput.backgroundColor,
  },
  marginBottom: componentStyles?.marginBottom ?? "0",
  width: componentStyles?.width ?? "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: inputStyles?.style?.borderRadius,
  },
  "& .MuiOutlinedInput-input": {
    padding: inputStyles?.style?.padding,
  },
}));
