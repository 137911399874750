import                                  "./index.css";
import React                            from "react";
import View                             from "../../../../ui/view";
import Utils                            from "../../../../../modules/utils";
import Schemas                          from "../../../../../modules/schemas";
import AssemblyTree                     from "../../../common/assembly/view/tree";
import AssemblyGrid                     from "../../../common/assembly/view/grid";
import SerializationTab                 from "../../../common/serialization-tab/view";
import Documents                        from "../../../common/documents/view";
import SourcingRowView                  from "../../../common/sourcing/view/row-mode";
import Variants                         from "../../../common/variants/view";
import InlineIcon                       from "../../../../ui/icon/inline-icon.js"
import TreeIcon                         from "../../../../../assets/icons/tree-icon";
import GridIcon                         from "../../../../../assets/icons/grid-icon";
import ExpandIcon                       from "../../../../../assets/icons/expand-icon";
import Spinner                          from "../../../../ui/spinner";
import URLSearchParams                  from "url-search-params";
import Reports                          from "../../../common/reports/view";
import API                              from "../../../../../modules/api";

export class Tabs extends View
{
    constructor(props)
    {
        super(props)

        let { tabsType, isProductionBuildExist } = this.props;
        let view;
        let assemblyView = Utils.getStore("assemblyView") || "tree";

        if (tabsType === 'productionInstance')
        {
            this.objectData = this.props.productionInstance;
            view = Utils.getStore("production-instance-tab") || "documents";
            assemblyView = "tree"; // As Assembly Tab in Production Instance Landing Page has only Tree View.
        }
        else if(tabsType === "prd")
        {
            view = Utils.getStore("product-tab") || "assembly";
            if (view === "serialization" && !isProductionBuildExist) // If no production instance exist then do not show Serialization Tab
            {
                view = "assembly"
            }
            this.objectData = this.props.product;
        }
        else
        {
            this.objectData = this.props.component;
            view = Utils.getStore("component-tab") || "documents";
        }

        let isAssembly = true;
        if (tabsType === "cmp")
        {
            isAssembly = Schemas.component.category.getType(this.props.componentCategory).toLowerCase() === "assembly"

            if((!isAssembly && view === "assembly"))
            {
                view = "documents"
            }
        }

        if (view === "variants" && !this.objectData.variantGroup)
        {
            view = "documents"
        }

        if(this.props.isDiffTool && (view === "reports" || view === "variants"))
        {
            view = "documents"
        }

        let urlParams  = new URLSearchParams(window.location.search);
        let tab        = urlParams.has('tab') ? urlParams.get('tab') : null;
        if(tab)
        {
            view = tab;
        }
        this.state =
        {
            variants   : [],
            reports    : [],
            view,
            isAssembly,
            assemblyView,
            syncSourcingRowTable: false,
            isAnyChildModified: false,
        }
        this.imagesWithSrc = [];
    }

    saveReportsInParent(reports){
        let state = this.state
        state.reports = reports;
        this.setState(state)
    }

    saveVariantsInParent(variants){
        let state = this.state
        state.variants = variants
        this.setState(state)
    }

    componentDidMount(){
        let { mode, tabsType, paramId, documents_loaded, children_loaded} = this.props;

        let current_view = this.state.view;
        let params = "documents";
        if(current_view == "sourcing" || current_view == "assembly"){
            params = "children";
        }
        if((params == "documents" && !documents_loaded) ||
            (params == "children" && !children_loaded))
        {
            if (mode === "revision" && params == "children")
            {
                params = `${params},assemblyRevision`
            }
            this.props.getNestedData(params);
        }

        if (tabsType !== 'productionInstance')
        {
            let model = tabsType === 'prd' ? 'products' : 'components';
            API[model].getChildrenInfo(paramId, {mode}, (err, res) =>
            {
                if (res && res.success && res.data)
                {
                    this.setState({isAnyChildModified: res.data.isAnyChildModified})
                }
            });
        }
    }

    show(event)
    {

        let { tabsType, documents_loaded, children_loaded, mode } = this.props;

        this.state.view = event.target.getAttribute("name")
        let params = "documents";
        if (this.state.view == "sourcing" || this.state.view == "assembly" || this.state.view == "reports" ){
            params="children";
        }
        if((params == "documents" && !documents_loaded) ||
            (params == "children" && !children_loaded))
        {
            if (mode === "revision" && params == "children")
            {
                params = `${params},assemblyRevision`
            }
            this.props.getNestedData(params);
        }

        let tab;
        if (tabsType === "prd")
        {
            tab = "product-tab";
        }
        else if (tabsType === "productionInstance")
        {
            tab = "production-instance-tab";
        }
        else
        {
            tab = "component-tab";
        }

        Utils.setStore(tab, this.state.view);
        this.setState(this.state)
    }

    onPrimaryRowSelection(event, item)
    {
        if (event.target.checked)
        {
            this.state.primarySource =
            {
                minQuantity     : item.quoteMinQuantity,
                unitPrice       : item.quoteUnitPrice,
                leadTime        : item.quoteLeadTimeValue,
                leadTimeUnit    : item.quoteLeadTimeUnits,
                primarySourceId : item.quote_id,
                rolledUpCost    : false
            }
        }
        else
            this.state.primarySource = undefined

        this.setState(this.state)
    }

    onRolledUpSelect(event, rolledUpCost)
    {
        if (Object.keys(rolledUpCost).length > 0 && event.target.checked)
        {
            this.state.primarySource =
            {
                minQuantity     : rolledUpCost.rolledUpCost,
                unitPrice       : rolledUpCost.totalPrice,
                leadTime        : rolledUpCost.leadTimeValue,
                leadTimeUnit    : rolledUpCost.leadTimeUnit,
                primarySourceId : "dummy",
                rolledUpCost    : true
            };
        }
        else
        {
            this.state.primarySource = undefined;
        }

        this.state.syncSourcingRowTable = true;
        this.setState(this.state);
    }

    onPrimaryRowDeselection(event)
    {
        this.state.primarySource =
        {
          minQuantity     : "",
          unitPrice       : "",
          leadTime        : "",
          leadTimeUnit    : "",
          primarySourceId : "dummy"
        }
        this.props.setPrimarySourceInApi(this.state.primarySource)
    }

    toggleAssemblyTableView()
    {
        let state = this.state
        state.assemblyView = state.assemblyView == 'grid' ? 'tree' : 'grid'
        Utils.setStore("assemblyView", state.assemblyView)
        this.setState(state, window.changeRoutesWidth)
    }

    getAssemblyActionList()
    {
        let { tabsType } = this.props;
        let state = this.state;
        let actionsList = [];
        let actionEntry;
        let isTreeView = state.assemblyView === 'tree';

        if( tabsType !== "productionInstance" )
        {
            actionsList.push({
                type    : 'customEl',
                element : <div className="table-view-toggler flex-v">
                            <span
                                className={'tree-view action-item flex-v' + (!isTreeView ? ' active' : ' disabled')}
                                data-tip={'Tree View'}
                                onClick={!isTreeView ? this.toggleAssemblyTableView : null}
                            >
                                <InlineIcon
                                  className={(!isTreeView ? '' : 'disabled ')}
                                >
                                  <TreeIcon />
                                </InlineIcon>
                                <span
                                    className="item-title"
                                    >
                                    {'Tree'}
                                </span>
                            </span>
                            <span
                                className={'grid-view action-item flex-v' + (isTreeView ? ' active' : ' disabled')}
                                data-tip={'Grid View'}
                                onClick={isTreeView ? this.toggleAssemblyTableView : null}
                            >
                                <InlineIcon
                                  className={(isTreeView ? 'flex-v ' : 'disabled flex-v')}
                                >
                                  <GridIcon />
                                </InlineIcon>
                                <span
                                    className="item-title"
                                    >
                                    {'Grid'}
                                </span>
                            </span>
                        </div>
                        });
            actionsList.push({ type: 'divider' });
        }
        actionsList.push({ type: 'autofit' });

        if (isTreeView || tabsType === "productionInstance")
        {
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <ExpandIcon />,
                toolTip       : 'Expand all',
                name          : 'Expand',
                onClick       : this.expandTreeViewLevel,
                onDoubleClick : this.expandTreeView,
                active        : true,
                iconClassName : "expand-icon",
                className     : "tree-expand-icon"
            };
            actionsList.push(actionEntry);
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <ExpandIcon />,
                toolTip       : 'Collapse all',
                name          : 'Collapse',
                onClick       : this.collapseTreeView,
                active        : true,
                iconClassName : "collapse-icon",
                className     : "tree-collapse-icon"
            };
            actionsList.push(actionEntry);
        }

        return actionsList;
    }

    expandTreeView() {
        this.setState({
            collapseTreeView: 'expand'
        })
    }

    expandTreeViewLevel() {
        this.setState({
            collapseTreeView: 'expandLevel'
        })
    }

    collapseTreeView() {
        this.setState({
            collapseTreeView: 'collapse'
        })
    }

    afterToggleTreeView() {
        this.setState({
            collapseTreeView: undefined
        })
    }

    setPrimarySource()
    {
        if (this.state.primarySource)
        {
            this.props.setPrimarySourceInApi(this.state.primarySource)
            let id = this.props.paramId;
            Utils.setLocalStorageForAssemblyTree(id);
        }
    }

    afterSyncSourcingRowTable()
    {
        let state = this.state
        state.syncSourcingRowTable = false
        this.setState(state)
    }

    render()
    {
        let { tabsType, documents_loaded, children_loaded, isProductionBuildExist, massPrecision, user } = this.props;
        let view         = this.state.view;
        let isAssembly   = this.state.isAssembly;
        let assemblyView = this.state.assemblyView;
        let isLoading    = ((!documents_loaded && view == "documents") || (!children_loaded && view == "assembly"));
        let isSerializationEnabled = window.__isSerializationEnabled && window.__libraryType === "GENERAL";
        let isRestrictedRole = window.__userRole !== "VENDOR";
        let showVariantTab = window.__userRole!=='VENDOR' && this.objectData && this.objectData.variantGroup && !this.props.isDiffTool;

        let markup =
            <div className="tabs-block">
                <div className={"nav-block app-row " + (['documents', 'assembly', 'variants', 'reports'].includes(view) ? "display-flex-sb" : '')}>
                    <div className="tabs-headings">
                        <div
                            className={`${view === "documents" ? "selected" : ""} ${this.props.isDiffTool ? "diff-document-section" : ""}`}
                            name="documents"
                            onClick={this.show}
                            >
                            Documents
                        </div>

                        {
                            (tabsType !== "productionInstance" && isRestrictedRole) &&
                                <div
                                    className={`${view === "sourcing" ? "selected" : ""} ${this.props.isDiffTool ? "diff-sourcing-section" : ""}`}
                                    name="sourcing"
                                    onClick={this.show}
                                    >
                                    Sourcing
                                </div>
                        }
                        {
                            isAssembly
                            ?
                            <div
                                className={`${view === "assembly" ? "selected" : ""} ${this.props.isDiffTool ? "diff-assembly-section" : ""}`}
                                name="assembly"
                                onClick={this.show}
                                >
                                Assembly
                            </div>
                            :
                            null
                        }
                        {
                            showVariantTab && tabsType !== "productionInstance" &&
                            <div
                                className={view === "variants" ? "selected" : ""}
                                name="variants"
                                onClick={this.show}
                                >
                                Variants
                            </div>
                        }
                        {
                            tabsType === "prd" && this.props.mode !== 'revision' && !this.props.isDiffTool &&
                            <div
                                className={view === "reports" ? "selected" : ""}
                                name="reports"
                                onClick={this.show}
                                >
                                Reports
                            </div>
                        }

                        {
                            isProductionBuildExist && tabsType === "prd" && this.props.mode !== 'revision' && !this.props.isDiffTool && isSerializationEnabled &&
                            <div
                                className={view === "serialization" ? "selected" : ""}
                                name="serialization"
                                onClick={this.show}
                                >
                                Serialization
                            </div>
                        }

                    </div>

                </div>
                {
                isLoading ? <Spinner /> :
                <div className="view-block">
                    {
                        view === "documents" &&
                        <div className="selected">
                            <Documents
                                documents={this.props.documents}
                                objectData={this.objectData}
                                location={this.props.location}
                                isDiffTool={this.props.isDiffTool}
                                targetRevDocuments={this.props.targetRevDocuments}
                                component={this.props.component}
                                initializePusher={true}
                                isProductionInstance={tabsType === 'productionInstance'}
                            />

                        </div>
                    }
                    {
                        (view === "sourcing" && isRestrictedRole) &&
                        <div className="selected">
                            <SourcingRowView
                                tabsType={tabsType}
                                setPrimarySource={this.setPrimarySource}
                                primarySource={this.state.primarySource}
                                component={tabsType === "cmp" ? this.props.component : this.props.product}
                                dispatch={this.props.dispatch}
                                objectData={this.objectData}
                                isAssembly={isAssembly}
                                children={this.props.assemblyData}
                                allowPrimarySourceSelection={true}
                                onPrimaryRowSelection={this.onPrimaryRowSelection}
                                onPrimaryRowDeselection={this.onPrimaryRowDeselection}
                                onRolledUpSelect={this.onRolledUpSelect}
                                manufacturers={this.props.manufacturers}
                                syncSourcingRowTable={this.state.syncSourcingRowTable}
                                afterSyncSourcingRowTable={this.afterSyncSourcingRowTable}
                                mode={this.props.mode}
                                isDiffTool={this.props.isDiffTool}
                                targetRevManufacturers={this.props.targetRevManufacturers}
                                targetRevChildren={this.props.targetRevChildren}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </div>
                    }
                    {
                        isAssembly && view === "assembly" && assemblyView === "grid" &&
                            <div className="selected">
                                <AssemblyGrid
                                    children={this.props.assemblyData}
                                    objectData={this.objectData}
                                    mode={this.props.mode}
                                    getIconsActionsList={this.getAssemblyActionList}
                                    location={this.props.location}
                                    tabsType={tabsType}
                                    imagesWithSrc={this.imagesWithSrc}
                                    isDiffTool={this.props.isDiffTool}
                                    targetRevChildren={this.props.targetRevChildren}
                                    defaultCurrency={this.props.defaultCurrency}
                                    isAnyChildModified={this.state.isAnyChildModified}
                                />
                            </div>
                    }

                    {
                        showVariantTab && view === "variants" &&
                        <div className="selected">
                            <Variants
                                massPrecision={massPrecision}
                                objectData={this.objectData}
                                getDataFromApi={this.props.getDataFromApi}
                                variants={this.state.variants}
                                saveVariantsInParent={this.saveVariantsInParent}
                                history={this.props.history}
                                updateVariantsTab={this.props.updateVariantsTab}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </div>
                    }
                    {
                        view === "reports" && tabsType === "prd" && !this.props.isDiffTool &&
                        <div className="selected">
                            <Reports
                                children={this.props.assemblyData}
                                product={this.objectData}
                                getDataFromApi={this.state.reports.length === 0 ? true : false}
                                reports={this.state.reports}
                                saveReportsInParent={this.saveReportsInParent}
                                history={this.props.history}
                                user={user}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </div>
                    }
                    {
                        isProductionBuildExist && view === "serialization" && tabsType === "prd" && !this.props.isDiffTool &&
                        <div className="selected">
                            <SerializationTab
                                objectData={this.objectData}
                                alias={"prd"}
                                user={user}
                                refreshSerializationTab={this.props.refreshSerializationTab}
                            />
                        </div>
                    }
                    {
                        isAssembly && view === "assembly" && assemblyView === "tree" &&
                        <div className={isAssembly && view === "assembly" && assemblyView === "tree" ? "selected": "hidden"}>
                            <AssemblyTree
                                children={this.props.assemblyData}
                                objectData={this.objectData}
                                mode={this.props.mode}
                                getIconsActionsList={this.getAssemblyActionList}
                                collapseTreeView={this.state.collapseTreeView}
                                afterToggleTreeView={this.afterToggleTreeView}
                                location={this.props.location}
                                tabsType={tabsType}
                                imagesWithSrc={this.imagesWithSrc}
                                isDiffTool={this.props.isDiffTool}
                                targetRevChildren={this.props.targetRevChildren}
                                setAssemblyDiffCount={this.props.setAssemblyDiffCount}
                                history={this.props.history}
                                defaultCurrency={this.props.defaultCurrency}
                                setAssemblyTree={this.props.setAssemblyTree}
                                assemblyTree={this.props.assemblyTree}
                                setAssemblyLoading={this.props.setAssemblyLoading}
                                isAssemblyLoaded={this.props.isAssemblyLoaded}
                                isAnyChildModified={this.state.isAnyChildModified}
                            />
                        </div>
                    }
                </div>
            }
            </div>
        return markup
    }
}

export default Tabs
