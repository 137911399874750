import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import {
  ActionSection,
  BaseModal,
  Content,
  HeaderSection,
} from "../../../common/base-modal";
import { ButtonVariants } from "common/constants";
import { AppSection } from "context";
import { DesignWorkspaceIcon } from "v1/assets/icons/designWorkspaceIcon";
import { FilledCheckbox } from "v1/assets/icons/filledCheckbox";
import { SwitchWorkspaceIcon } from "v1/assets/icons/switchWorkspaceIcon";
import { BuildWorkspaceIcon } from "v1/assets/icons/buildWorkspaceIcon";

type ActionEventType  = MouseEvent | React.MouseEvent;
type ActionBtnCallBack = (e: ActionEventType) => void;
type SpanTypographyType = React.ComponentType<TypographyProps<"span", { component: "span" }>>;

export interface ISwitchToBuildModalProps {
  onExternalClick: ActionBtnCallBack,
  onModalAction: (checkboxValue: boolean, event: ActionEventType) => ActionEventType,
  open: boolean,
}

export const SwitchToBuildModal: FC<ISwitchToBuildModalProps> = ({
  onExternalClick,
  onModalAction,
  open,
}) => {
  const [checked, setChecked] = useState(false);

  const handleCheckbox = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const onButtonClick = useCallback(event => {
    onModalAction(checked, event);
  }, [onModalAction, checked]);

  const header: JSX.Element = useMemo(() => (
    <Fragment>
      <HeaderWrapper>
        <CustomTitleSection>Switch To Build Workspace</CustomTitleSection>
      </HeaderWrapper>
    </Fragment>
  ), []);

  const content: React.ReactNode = useMemo(() => (
    <ContentWrapper>
      <IconsWrapperBox>
        <CustomIconsTypography component="span">
          <DesignWorkspaceIcon />
        </CustomIconsTypography >
        <CustomIconsTypography component="span">
          <SwitchWorkspaceIcon />
        </CustomIconsTypography >
        <CustomIconsTypography component="span">
          <BuildWorkspaceIcon />
        </CustomIconsTypography>
      </IconsWrapperBox>
      <DescriptionSection>
        View your serialized BOM in the Build Workspace
      </DescriptionSection>
    </ContentWrapper>
  ), []);

  const footer: React.ReactNode = useMemo(() => (
    <>
      <ActionButtonsWrapper>
        <CustomActionButton
          color={"primary"}
          onClick={onButtonClick}
          value={AppSection.DESIGN}
          variant={ButtonVariants.CONTAINED}
        >
          Return to Design
        </CustomActionButton>
        <CustomActionButton
          color={"secondary"}
          onClick={onButtonClick}
          value={AppSection.BUILD}
          variant={ButtonVariants.CONTAINED}
        >
          Continue to Build
        </CustomActionButton>
      </ActionButtonsWrapper>
      <CustomFormControl
        control={<Checkbox checked={checked} checkedIcon={<FilledCheckbox />} onChange={handleCheckbox} />}
        label={<CustomFormControlLabel>Don't show this message again</CustomFormControlLabel>}
      />
    </>
  ), [checked, handleCheckbox, onButtonClick]);

  return (
    <BaseModal
      onClose={onExternalClick}
      open={open}
    >
      <HeaderSection
        label={header}
        onClose={onExternalClick}
      />
      <CustomContentSection>
        {content}
      </CustomContentSection>
      <CustomActionSection>
        {footer}
      </CustomActionSection>
    </BaseModal>
  );
};

const commonStyles = {
  fontSize: "0.875rem",
};

export const ActionButtonsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "1.5rem",
});

export const ContentWrapper = styled(Box)({
  textAlign: "center",
});

export const CustomActionButton = styled(Button)({
  border: "0",
  padding: "0.75rem 0.938rem",
  width: "9rem",
});

export const CustomActionSection = styled(ActionSection)({
  display: "block",
  margin: "0 auto",
  maxWidth: "19.688rem",
  width: "100%",
});

export const CustomContentSection = styled(Content)({
  marginBottom: "2.5rem",
});

export const CustomFormControl = styled(FormControlLabel)(commonStyles);
export const CustomFormControlLabel = styled(Typography)(commonStyles);
export const CustomIconsTypography: SpanTypographyType = styled(Typography)({
  margin: "0 1.25rem",
});

export const CustomTitleSection = styled(Typography)({
  fontSize: "1.188rem",
  textAlign: "center",
  width: "100%",
});

const DescriptionSection = styled(Box)({
  ...commonStyles,
  margin: "1.5rem auto 0",
  width: "14.25rem",
});

export const FormControlCheckbox = styled(Checkbox)(commonStyles);

export const HeaderWrapper = styled(Box)({
  alignItem: "center",
  display: "flex",
  justifyContentL: "center",
});

export const IconsWrapperBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});
