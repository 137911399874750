import                          "./index.css"
import React, {Component}  from "react"
import Utils               from "../../../../modules/utils"
import Spinner             from "../../../ui/spinner"
import tableDragger        from 'table-dragger'
import $                   from "jquery";
import { connect }         from "react-redux"
import USER                from "../../../../action-types/user"
import buildAction         from "../../../../helpers/buildAction"
import PerfectScrollbar    from 'react-perfect-scrollbar'
import Tooltip             from 'rc-tooltip'
import { Link as RouterLink } from "react-router-dom"
import 'react-perfect-scrollbar/dist/css/styles.css';
import TableIcons          from "./table-icons"
import FileUploadRow       from "./file-upload-row";
import LazyInput           from "../../../ui/lazy-input/input";
import Pagination          from "./pagination"

let dragDropTable;
class DragAndDrop {
  constructor(options) {
    this.table = options.table;
    this.dragger = tableDragger($(options.table)[0], options.draggerConfig);
  }
}

class DndWithScroll extends DragAndDrop {
    constructor(options) {
        super(options);
        this.tableWrap          = options.tableWrap;
        this.handler            = options.draggerConfig.dragHandler;
        this.columnAvatar       = '.gu-mirror';
        // static columns settings.
        this.startStaticColumns = options.draggerConfig.startStaticColumns
        this.endStaticColumns   = options.draggerConfig.endStaticColumns
        this.ignoreClass        = 'sindu_static';

        this.tableScroll        = null;
        this.commitTableScroll  = this.commitTableScroll.bind(this);
        this.scrollOnDrag       = this.scrollOnDrag.bind(this);
        this.onDragStart        = this.onDragStart.bind(this);
        this.onDrop             = this.onDrop.bind(this);
        this.dropCb             = options.dropCb || function() {}
        this.dragCb             = options.dragCb || function() {}
    }

    commitTableScroll() {
        this.tableScroll = $(this.tableWrap).scrollLeft();
    }

    scrollOnDrag() {
      const coords = this.coords;
      if (coords.columnAvatar.right > coords.tableWrap.right) {
          $(this.tableWrap).scrollLeft($(this.tableWrap).scrollLeft() + (coords.columnAvatar.right - coords.tableWrap.right))
      } else if (coords.columnAvatar.left < coords.tableWrap.left) {
          $(this.tableWrap).scrollLeft($(this.tableWrap).scrollLeft() - (coords.tableWrap.left - coords.columnAvatar.left))
      }
    }

    onDragStart() {
        let ingoreElements = []
        if (this.startStaticColumns > 0)
        {
            for (let i = 1; i <= this.startStaticColumns; i++) {
                ingoreElements.push(".sindu_dragger li:nth-child(" + i + ")")
            }
        }

        if (this.endStaticColumns > 0)
        {
            for (let i = 1; i <= this.endStaticColumns; i++) {
                ingoreElements.push(".sindu_dragger li:nth-last-child(" + i + ")")
            }
        }

        ingoreElements = ingoreElements.join(", ")

        if (ingoreElements.length > 0)
            $(ingoreElements).addClass(this.ignoreClass);

        $(this.tableWrap).scrollLeft(this.tableScroll)
        $(document).on('mousemove', this.scrollOnDrag);
        this.dragCb();
    }

    onDrop() {
      $(document).off('mousemove', this.scrollOnDrag);
      this.dropCb();
    }

    get coords() {
        return {
            columnAvatar: {
              left: $(this.columnAvatar)[0].getBoundingClientRect().left,
              right: $(this.columnAvatar)[0].getBoundingClientRect().right
            },
            tableWrap: {
              left: $(this.tableWrap)[0].getBoundingClientRect().left,
              right: $(this.tableWrap)[0].getBoundingClientRect().right
            }
        }
    }

    bindEvents() {
        $(this.handler).on('mousedown', this.commitTableScroll);
        this.dragger.on('drag', this.onDragStart);
        this.dragger.on('drop', this.onDrop);
    }
}

class ExtendedTablePaginated extends Component
{
    constructor(props)
    {
        super(props)

        this.dataTableId = this.props.themeDataTableId || "extended-table-data"

        this.wrapperClassName = this.props.wrapperClassName
        if (!this.props.wrapperSelectorClass)
        {
            alert('wrapperSelectorClass is complusary to pass.')
        }
        this.wrapperSelectorClass = this.props.wrapperSelectorClass

        this.state =
        {
            column :
            {
                name      : this.props.currentSortItem,
                ascending : (this.props.currentSortItemAscending || false)
            },

            key                   : Math.random(),
            tableMaxWidth         : 1000,
            hoveredColIndex       : null,
            hoveredColName        : "",
            initial_pageX         : null,
            rows                  : this.props.rows,
            headings              : this.sortHeadingByPosition(this.props.headings),
            isDragable            : (this.props.isDragable || false),
            isResizeable          : (this.props.isDragable || false),
            allowRowSelect        : (this.props.allowRowSelect || false),
            allowRowSelectAsRadio : (this.props.allowRowSelectAsRadio || false),
            selectedRowsIds       : [],
            lastSelectedRowId     : null,
            allRowsChecked        : true,
            paginationSize        : this.props.paginationSize || 50,
            page                  : 1,
            totalPages            : 0,
            didInputValueChange   : false,
            displayColumnsSetting : false,
            loadingTable: false
        }

        this.addExpandHoverClass           = this.addExpandHoverClass.bind(this)
        this.removeExpandHoverClass        = this.removeExpandHoverClass.bind(this)
        this.onMouseDown                   = this.onMouseDown.bind(this)
        this.performNoEvent                = this.performNoEvent.bind(this)
        this.onMouseMove                   = this.onMouseMove.bind(this)
        this.saveStyles                    = this.saveStyles.bind(this)
        this.onMouseLeave                  = this.onMouseLeave.bind(this)
        this.handleLoad                    = this.handleLoad.bind(this)
        this.onRowSelect                   = this.onRowSelect.bind(this)
        this.selectAllRows                 = this.selectAllRows.bind(this)
        this.setHeadingsVisibility         = this.setHeadingsVisibility.bind(this)
        this.sort                          = this.sort.bind(this)
        this.onHeadingClick                = this.onHeadingClick.bind(this)
        this.onThDoubleClick               = this.onThDoubleClick.bind(this)
        this.setSelectAllCheckboxState     = this.setSelectAllCheckboxState.bind(this)
        this.autoFitColumnsWidthOfTable    = this.autoFitColumnsWidthOfTable.bind(this)
        this.setMyState                    = this.setMyState.bind(this)
        this.onDataRowMouseEnter           = this.onDataRowMouseEnter.bind(this)
        this.onDataRowMouseLeave           = this.onDataRowMouseLeave.bind(this)
        this.initDndTable                  = this.initDndTable.bind(this)
        this.onMouseLeaveOfDragEl          = this.onMouseLeaveOfDragEl.bind(this)
        this.onInputChange                 = this.onInputChange.bind(this)
        this.toggleColumnSettingVisibility = this.toggleColumnSettingVisibility.bind(this)
        this.goNextPage                    = this.goNextPage.bind(this)
        this.goPreviousPage                = this.goPreviousPage.bind(this)
        this.goToTop                       = this.goToTop.bind(this)
        this.setTotalPages                 = this.setTotalPages.bind(this)
        
    }

    initDndTable(){
        dragDropTable = new DndWithScroll({
            tableWrap: ('.' + this.wrapperSelectorClass + ' .extended-data-table-wrapper-scroll'),
            table: ('.' + this.wrapperSelectorClass +' #extended-data-table-wrapper table#' + this.dataTableId ),
            draggerConfig: {
                mode: 'column',
                dragHandler: '[data-row="handler"]',
                startStaticColumns: this.props.startStaticColumns || 0,
                endStaticColumns: this.props.endStaticColumns || 0,
                onlyBody: true,
                animation: 300,
            },
            dropCb: this.onMouseLeaveOfDragEl
        })

        dragDropTable.bindEvents();
        this.dragDropTable = dragDropTable
    }

    selectAllRows(checked)
    {
        let _$ = $
        let state = this.state
        let parentElSelector = ""
        if (this.props.concateWrapperSelectorClassMasterCheckbox)
        {
            parentElSelector = `.${this.wrapperSelectorClass}`
        }
        let element = document.querySelector(`${parentElSelector} .index-table thead .index-checkbox label`);
        let isMiddleState = element ? element.classList.contains("middle") : false;
        if (isMiddleState)
        {
            checked = false
        }
        state.allRowsChecked = !checked
        $(`${parentElSelector} .index-table thead .index-checkbox label`).removeClass("middle")
        $(`${parentElSelector} .index-table input:checkbox`).prop('checked', checked);
        if ( _$(`${parentElSelector} .index-table .index-checkbox`).length > 0 && _$(`${parentElSelector} .index-table .index-number`).length > 0)
        {
            if (checked) {
                state.selectedRowsIds   = []
                state.rows.forEach((_row) =>
                {
                    if(_row.hasOwnProperty('getSelectedInBulk'))
                    {
                        if(_row.getSelectedInBulk)
                        {
                            state.selectedRowsIds.push(_row.rowId)
                        }
                    }
                    else
                    {
                       state.selectedRowsIds.push(_row.rowId)
                    }
                })
                state.lastSelectedRowId = null

            } else {
                state.selectedRowsIds   = []
                state.lastSelectedRowId = null
                if (this.props.clearSelectedRowsStore)
                {
                    this.props.clearSelectedRowsStore()
                }
            }
        }
        if (this.props.onSelectAllRows && this.props.enableChildRowSelect)
        {
            this.props.onSelectAllRows(checked, state.selectedRowsIds);
        }
        else if(this.props.onSelectAllRows)
        {
            this.props.onSelectAllRows(checked);
        }

        this.setMyState(state)
    }

    onRowSelect(event, row, customValue)
    {
        let state           = this.state
        let checked         = customValue
        let shiftKeyPressed = event.shiftKey
        let selectedRows    = [row]

        if (shiftKeyPressed)
        {
            let last = document.querySelector("[data-row-id='" + state.lastSelectedRowId +"']").rowIndex

            let current = document.querySelector("[data-row-id='" + row.rowId +"']").rowIndex
            if (last >= current)
            {
                selectedRows = state.rows.slice(current-1, last)
            }
            else
            {
                selectedRows = state.rows.slice(last, current)
            }
        }

        let selectedRowsIds = state.selectedRowsIds

        if (state.allowRowSelectAsRadio === true)
        {
            this.selectAllRows(false)
        }
        if (checked) {
            selectedRows.forEach((_row) =>
            {
                var index = selectedRowsIds.indexOf(_row.rowId);
                if (!(index > -1)) {
                    if(this.props.enableChildRowSelect)
                    {
                        this.props.onRowSelect({target: {checked}}, _row.object, selectedRowsIds);
                    }
                    else
                    {
                        selectedRowsIds.push(_row.rowId);
                        this.props.onRowSelect({target: {checked}}, _row.object);
                    }
                }
            })
            // $(element).prop('checked', checked);
            // element.closest("td").getElementsByClassName("index-checkbox")[0].classList.add("block")
            // element.closest("td").getElementsByClassName("index-number")[0].classList.add("hidden")
        } else {
            // element.closest("td").getElementsByClassName("index-checkbox")[0].classList.remove("block")
            // element.closest("td").getElementsByClassName("index-number")[0].classList.remove("hidden")

            selectedRows.forEach((_row) =>
            {
                if(this.props.enableChildRowSelect)
                {
                    this.props.onRowSelect({target: {checked}}, _row.object, selectedRowsIds);
                }
                else
                {
                    var index = selectedRowsIds.indexOf(_row.rowId);
                    this.props.onRowSelect({target: {checked}}, _row.object)
                    if (index > -1) {
                        selectedRowsIds.splice(index, 1);
                    }
                }
            })

        }

        state.lastSelectedRowId = row.rowId

        this.setMyState(state, () =>
            {
                this.props.onRowSelect({target: {checked}}, row.object)
                this.setSelectAllCheckboxState()
            }
        )
    }

    setSelectAllCheckboxState()
    {
        let parentElSelector = ('.'+ this.wrapperSelectorClass)
        let selectAllCheckbox      = $(parentElSelector + ' .index-table thead input:checkbox')
        let selectAllCheckboxLabel = $(parentElSelector + ' .index-table thead .index-checkbox label')


        let state = this.state
        let checkedRows = state.selectedRowsIds

        if ( checkedRows.length > 0 && checkedRows.length < this.state.rows.length)
        {
            selectAllCheckbox.prop('checked', false);
            selectAllCheckboxLabel.addClass("middle")
        }

        if (checkedRows.length === 0)
        {
            selectAllCheckbox.prop('checked', false);
            selectAllCheckboxLabel.removeClass("middle")
        }

        if (checkedRows.length === this.state.rows.length)
        {
            selectAllCheckbox.prop('checked', true);
            selectAllCheckboxLabel.removeClass("middle")
        }

        if(this.props.enableChildRowSelect)
        {
            if(this.props.totalComponents === checkedRows.length)
            {
                selectAllCheckbox.prop('checked', true);
                selectAllCheckboxLabel.removeClass("middle");
            }
            else if(checkedRows.length > 0 && checkedRows.length < this.props.totalComponents)
            {
                selectAllCheckbox.prop('checked', false);
                selectAllCheckboxLabel.addClass("middle");
            }
        }
    }

    handleLoad()
    {
        if (this.props.beforeExtendedTableHandleLoad)
        {
            this.props.beforeExtendedTableHandleLoad()
        }
        let wrapperSelectorClass = this.wrapperSelectorClass
        let parentEl = document.querySelector('.'+wrapperSelectorClass)
        parentEl.querySelector('#extended-data-table-wrapper').style.display = "none"
        parentEl.style.visibility = ""
        let tableMaxWidth = parentEl.offsetWidth - parentEl.querySelector('#index-table').offsetWidth

        let paddingRight = parseFloat($(parentEl).css('padding-right'))
        if (paddingRight)
        {
            tableMaxWidth = tableMaxWidth - paddingRight
        }
        // document.getElementById("extended-table-data").closest('#data-table-container').style.maxWidth = tableMaxWidth + 'px'
        parentEl.querySelector('#data-table-container').style.maxWidth = tableMaxWidth + 'px'

        parentEl.querySelector('#extended-data-table-wrapper').style.visibility = ""
        parentEl.style.visibility = ""
        parentEl.querySelector('#extended-data-table-wrapper').style.display = ""
        if (this.props.afterExtendedTableHandleLoad)
        {
            this.props.afterExtendedTableHandleLoad()
        }
    }

    componentWillMount() {
        this.sort({doNotSaveStyles: true})
    }

    componentWillUnmount() {
        let el = document.getElementById('routes')
        el.removeEventListener('widthChange', this.handleLoad);
        window.removeEventListener("resize", this.handleLoad);
        this._ismounted = false
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.clearSelectedRows !== nextProps.clearSelectedRows &&
            nextProps.clearSelectedRows === true
        ) {
            this.selectAllRows(false);
        }

        if (
            (nextProps.rows.length !== this.props.rows.length) ||
            nextProps.syncWithParentState === true
            )
        {
            let state = this.state
            state.rows = nextProps.rows
            state.didInputValueChange = false
            this.setMyState(state, () => {
                this.sort({pauseAutoSorting: nextProps.pauseAutoSorting})
                if (this.props.afterSyncWithParentState)
                    this.props.afterSyncWithParentState()
            })
        }

        let state = this.state
        let shouldSetState = false
        state.rows.forEach((stateRow) =>
        {
            nextProps.rows.forEach((propsRow) =>
            {
                if (propsRow.rowId === stateRow.rowId && stateRow.rowSelected !== propsRow.rowSelected)
                {
                    shouldSetState = true
                    stateRow.rowSelected = propsRow.rowSelected
                }
                if (propsRow.rowId === stateRow.rowId && stateRow.partialSelected !== propsRow.partialSelected)
                {
                    shouldSetState = true
                    stateRow.partialSelected = propsRow.partialSelected
                }
            })
        })

        if (shouldSetState)
        {
            this.setState(state, this.setSelectAllCheckboxState)
        }

        this.setTotalPages()
    }

    setMyState(state = this.state, cb){
        if (this._ismounted){
            this.setState(state, cb)
        }
    }

    autoFitColumnsWidthOfTable()
    {
        let tableId = this.dataTableId

        $("#"+tableId).addClass("auto-fit-class")

        let state = this.state
        let stateHeadings = state.headings
        stateHeadings.forEach((heading, index) =>
        {
            if (heading.visibility)
            {
                let colWidth = document.getElementById(tableId).querySelector("tbody tr").querySelector("th[name='"+heading.key+"']").offsetWidth

                heading.width = colWidth + 2
            }
        })

        this.setMyState(state, () =>
        {
            this.saveStyles();
            $("#"+tableId).removeClass("auto-fit-class")
        })
    }

    componentDidMount() {
        var routesEl = document.getElementById('routes')
        routesEl.addEventListener('widthChange', this.handleLoad)
        window.addEventListener("resize", this.handleLoad);
        this.setTotalPages()
        this._ismounted = true
        this.handleLoad()
        this.initDndTable()
        if (this.props.afterExtendedTableRender)
        {
            this.props.afterExtendedTableRender()
        }
        if(this.props.enableChildRowSelect)
        {
            this.state.selectedRowsIds = this.props.preselectedComponentIds();
            this.setSelectAllCheckboxState();
        }
    }

    performNoEvent(e){
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseDown(e, index) {
        let state = this.state
        window._currentTarget = e.target
        window.initial_pageX = e.pageX
        state.hoveredColIndex = index
        this.setMyState(state)
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e)
    {
        let th = window._currentTarget.closest('th')

        let th_minWidth = parseInt(th.style.minWidth)
        let diff = Math.abs(window.initial_pageX - e.pageX)

        let currentWidth = parseInt(window._currentTarget.closest('th').style.width)
        if (window.initial_pageX > e.pageX)
        {
            currentWidth -= diff
        }
        else if (window.initial_pageX < e.pageX)
        {
            currentWidth += diff
        }

        if (currentWidth < th_minWidth)
        {
            currentWidth = th_minWidth
        }

        th.style.width = currentWidth + "px"
        window.initial_pageX = e.pageX
    }

    onInputChange(e, i)
    {
        let state = this.state
        let row = state.rows[i]
        let cell = row[e.target.name]
        if(`${cell.value}` === e.target.value)
        {
            if (state.didInputValueChange) this.props.onTableChangeDebounced(state.rows)
            return;
        }
        state.didInputValueChange = true;
        cell.value = e.target.value
        let validatedInput = cell.inputProps.onChange(e, i)
        cell.inputProps.message = validatedInput.message
        cell.inputProps.inputClass = validatedInput.class

        if (cell.inputProps.dependent)
        {
            let dependentCell = row[cell.inputProps.dependent]
            let event = {target: {name: cell.inputProps.dependent, value: dependentCell.value}}
            validatedInput = dependentCell.inputProps.onChange(event, i)
            dependentCell.inputProps.message = validatedInput.message
            dependentCell.inputProps.inputClass = validatedInput.class
        }

        this.setState(state, () => {
            this.props.onTableChangeDebounced(state.rows)
        })
    }

    onHeadingClick(event)
    {
        let column       = this.state.column
        let target       = event.target
        if ( target.tagName !== "TH" )
            target       = event.target.closest("th")

        column.name      = target.getAttribute("name")
        column.ascending = !target.className.match("ascending")

        if (this.props.setCurrentSortBy)
        {
            this.props.setCurrentSortBy(column.name, column.ascending)
        }
        // this.selectAllRows(false)
        this.sort()
    }

    onThDoubleClick(event)
    {
        event.preventDefault()
        event.stopPropagation()

        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);

        let wrapperSelectorClass = this.wrapperSelectorClass

        let target      = event.target

        if ( target.tagName !== "TH" )
            target       = event.target.closest("th")

        let columnName   = target.getAttribute("name")
        let largestWidth = 0;
        let all_text_blocks = document.querySelectorAll(
            `.${wrapperSelectorClass} #${this.dataTableId} td:nth-child(${target.cellIndex + 1}) .text-block, 
            .${wrapperSelectorClass} #${this.dataTableId} th:nth-child(${target.cellIndex + 1}) .text-block`)

        all_text_blocks.forEach((textBlock, i) =>
        {
            var $clonedBlock = $(textBlock)
                   .clone()
                   .css({display: 'inline', width: 'fit-content', visibility: ''})
                   .insertAfter(textBlock)

            window.clonedBlock = $clonedBlock
            if( $clonedBlock.innerWidth() > largestWidth ) {
                largestWidth = $clonedBlock.innerWidth()
            }
            $clonedBlock.remove();
        })

        let state = this.state
        let current_heading;
        state.headings.forEach((heading) =>
        {
            if ( heading.key === columnName )
            {
                current_heading = heading
            }
        })
        let leftPadding = parseFloat($(target).css('padding-left'))
        let rightPadding = parseFloat($(target).css('padding-right'))
        let newWidth = largestWidth + leftPadding + rightPadding + 2

        if (newWidth > current_heading.minWidth)
        {
            current_heading.width = newWidth
        }
        else{
            current_heading.width = current_heading.minWidth
        }
        this.setMyState(state, this.saveStyles)
    }

    sort(options={})
    {
        let disableSort = options.disableSort || false
        if (disableSort === true)
            return

        if (this.props.disableDataSorting !== true )
        {
            let column   = this.state.column
            let columns  = this.state.headings
            let rows     = this.state.rows

            columns.forEach((col) =>
            {
                col.className = ""
                if(col.key === column.name)
                    col.className = column.ascending ? "ascending" : "descending"
            })

            function isInt(value) {
                return !isNaN(value) && parseInt(Number(value)) == value &&  !isNaN(parseInt(value));
            }

            rows.sort((a,b) =>
            {
                a = Utils.hash(column.name, a).value
                b = Utils.hash(column.name, b).value

                if(typeof a === "string") a = a.toLowerCase()
                if(typeof b === "string") b = b.toLowerCase()

                if(column.name == "revision"){
                    // a and b passed in this function are always of string type like "1", "99", "c", "A"
                    // if string can be converted to int then convert it, like "1" can be converted to 1
                    if(isInt(a)) a = parseInt(a);
                    if(isInt(b)) b = parseInt(b);

                    //  >,< comparison operators don't work properly, if two varaibles have different type
                    // that's why I'm converting string to number type so comparison operator could work fine
                    // if one variable is of number type and other is of string type then convert the variable of string type to number
                    // adding 1 to iteger type variable because string priorty is higher than number
                    if(typeof a === "number" && typeof b === "string") b = a+1;
                    if(typeof b === "number" && typeof a === "string") a = b+1;

                    // empty revision value like "", must be shown at the top of table, to make this happen,
                    // I'm assigning "zz", because "zz" is greater than all alphabets: "" > "a" is false but "zz" > a is True
                    if(a === "") a = "zz";
                    if(b === "") b = "zz";
                }

                if(a < b) return column.ascending ? -1 :  1
                if(a > b) return column.ascending ?  1 : -1
                return 0
            })

            let state = this.state
            state.rows = rows

            if (options.doNotSaveStyles === true)
            {
                this.setMyState(state)
            }
            else
            {
                this.setMyState(state, this.saveStyles)
            }
        }
    }

    saveStyles() {
        if (this.props.saveStyles === false)
            return
        const {dispatch} = this.props;
        let state = this.state
        let headings = state.headings

        headings = this.sortHeadingByPosition(headings)

        let stylesData = {}

        for(var i=0; i<headings.length ; i++ )
        {
            let style = {}
            let column = headings[i]
            stylesData[column.key] = {}
            style = stylesData[column.key]
            style.visibility = column.visibility
            let columnWidth = parseInt(document.getElementById(this.dataTableId).querySelector("th[name=" + column.key+ "]").style.width)
            style.width = columnWidth
            style.position = column.position
            column.width = columnWidth
        }

        if (this.props.saveMyStyles)
        {
            this.props.saveMyStyles([...headings])
        }
        else
        {
            let data = window.__userStyles
            if (data.styles)
                data.styles[this.props.stylesName] = stylesData
            else
            {
                data.styles = {}
                data.styles[this.props.stylesName] = stylesData
            }

            let column = this.state.column
            data.styles[this.props.stylesName].defaultSortColumnName = column.name
            data.styles[this.props.stylesName].defaultSortAssending  = column.ascending


            let payload = {
                data, user_id: this.props.user.data._id
            }
            dispatch(buildAction(USER.SAVE_USER_PREFERENCES_IN_LOCAL, payload))
        }
    }

    onMouseLeave(e)
    {
        let state = this.state
        state.hoveredColIndex = null
        state.hoveredColName = ""
        window.initial_pageX = null
        this.setMyState(state, this.saveStyles)
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        window._currentTarget = undefined
        e.stopPropagation()
        e.preventDefault()
    }

    onDataRowMouseEnter(e, row)
    {

        let element = e.target
        if (element.tagName !== 'TR')
        {
            element = element.closest("tr")
        }
        let wrapperSelectorClass = this.wrapperSelectorClass

        if (element)
        {
            let rowIndex = element.rowIndex
            let indexEl = document.querySelectorAll('.' + this.wrapperSelectorClass + ' #index-table tbody tr')[rowIndex - 1]

            if (indexEl.querySelector('.index-el'))
            {
                indexEl.querySelector('.index-el').classList.add('active')
            }
        }
    }

    onDataRowMouseLeave(e, row)
    {
        let element = e.target
        if (element.tagName !== 'TR')
        {
            element = element.closest("tr")
        }
        if (element)
        {
            let rowIndex = element.rowIndex
            let indexEl = document.querySelectorAll('.' + this.wrapperSelectorClass + ' #index-table tbody tr')[rowIndex - 1]
            if (indexEl.querySelector('.index-el'))
            {
                indexEl.querySelector('.index-el').classList.remove('active')
            }
        }
        else if(!element && this.wrapperSelectorClass === 'report-list')
        {
            $('.report-list .index-el.active').removeClass('active');
        }
    }

    addExpandHoverClass(e)
    {
        let element = e.target
        element.closest("th").classList.add("expand-me")
    }

    removeExpandHoverClass(e)
    {
        let element = e.target
        element.closest("th").classList.remove("expand-me")
    }

    setHeadingsVisibility(headings)
    {
        let _that = this
        let state = this.state
        let stateHeadings = state.headings
        headings.forEach((heading, index) =>
        {
            stateHeadings.forEach((_heading, index) =>
            {
                if ( _heading.key === heading.key)
                    _heading.visibility = heading.visibility
            })
        })

        state.headings = stateHeadings
        this.setMyState(state, this.saveStyles)
    }

    sortHeadingByPosition(headings)
    {
        let sortedHeadings = headings.sort((a,b)=> {
            if (a.position < b.position)
                return -1;
            if (a.position > b.position)
                return 1;
            return 0;
        });

        return sortedHeadings
    }

    onMouseLeaveOfDragEl(e)
    {
        this.dragDropTable.dragger.destroy()

        setTimeout( () => {
            let state = this.state

            let heading = ""
            let headings = this.state.headings
            let newColState = state.headings
            heading = document.getElementById(this.dataTableId).querySelector("tbody tr").querySelectorAll("th")

            heading.forEach((heading, index) =>
            {
                let headingText = heading.innerText.toLowerCase().trim()
                let headingName = heading.getAttribute('name')

                if (headingText !== "")
                {
                    headings.forEach((col, colIndex) =>
                    {
                        if(col.key.toLowerCase().trim() === headingName.toLowerCase().trim())
                        {
                            newColState[colIndex].position = index
                            let columnWidth = parseInt(heading.style.width)
                            newColState[colIndex].width = columnWidth
                            return
                        }
                    })
                }
            })

            newColState = this.sortHeadingByPosition(newColState)
            state.headings = newColState
            state.key = Math.random()
            this.setMyState(state, () => {
                this.saveStyles()
                this.initDndTable()
            })

        }, 2000)

    }

    toggleColumnSettingVisibility(event)
    {
        event?.stopPropagation();
        this.setState({displayColumnsSetting: !this.state.displayColumnsSetting});
    }

    goNextPage(e)
    {
        e.preventDefault()
        let page = this.state.page
        if (page < this.state.totalPages) {
            this.setState({page: page + 1})
            this.goToTop()
        }
    }

    goPreviousPage(e)
    {
        e.preventDefault()
        let page = this.state.page
        if (page > 1) {
            this.setState({page: page - 1})
            this.goToTop()
        }
    }

    goToTop()
    {
        const section = document.querySelector('.scrollbar-container')
        if (section) {
            section.scrollTop = 0
        }
    }

    setTotalPages()
    {
        this.setState({totalPages: Math.ceil(this.state.rows.length / this.props.paginationSize)})
    }

    render()
    {
        let tooltipText = this.props.vendorTooltipText ? this.props.vendorTooltipText : "";
        if(this.props.loading)
        {
            return <Spinner />
        }

        let resultText = this.props.resultText
        let totalPages = this.state.totalPages
        if(resultText)
        {
            let resultTextList = resultText.split(' ');
            if(resultTextList.length > 1 && parseInt(resultTextList[0]) === 1){
                resultText = `${resultTextList[0]} ${resultTextList[1].substring(0, resultTextList[1].length - 1)}`;
            }
        }

        let rows            = this.state.rows
        let headings        = this.state.headings
        let tableMaxWidth   = this.state.tableMaxWidth
        let page            = this.state.page
        const defaultAlignRightCols  = [
            "quantity",
            "unitPrice",
            "totalPrice",
            "leadTime",
            "mass",
            "quoteMinQuantity",
            "minQuantity",
            "distPackageQuantity",
            "quoteUnitPrice",
            "quoteLeadTime",
            "waste",
            "extendedQuantity",
            "extendedCost"
        ]
        let alignRightCols  = this.props.alignRightCols || defaultAlignRightCols

        let tableclass         = this.props.tableClass ? this.props.tableClass : ''
        let tableBorderedClass = this.props.borderedTable ? " extended-table-data bordered" : " extended-table-data"
        const propsAllowRowSelect = this.props.allowRowSelect

        let dataTableClass  = [tableBorderedClass, tableclass].join(" ")

        if (this.props.scrollPagination) rows = rows.slice(this.props.paginationSize * (page - 1), (this.props.paginationSize * page))

        let markup  =
            <div className="extended-table-section">

                <div id="extended-table" className={this.wrapperSelectorClass + ' ' + this.wrapperClassName + " extended-table" + (this.props.includeToolBar ? ' have-table-icons' : '')} style={{visibility: ''}}>


                    <div className="inlineBlock">
                        <table className="index-table" id="index-table">
                            <thead>
                                <tr>
                                    <th className={"indexes" + (this.state.allowRowSelectAsRadio !== true && propsAllowRowSelect && !this.state.allRowsChecked ? " selected" : '')}>
                                        {
                                            propsAllowRowSelect && this.state.allowRowSelectAsRadio !== true &&
                                            <span
                                                onClick={(e) => this.selectAllRows(this.state.allRowsChecked)}>
                                                <span
                                                    className="index-checkbox block"
                                                    >
                                                    <input
                                                        type="checkbox"
                                                        name="group"
                                                    />
                                                    <label
                                                        htmlFor="group"
                                                    />
                                                </span>
                                            </span>
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rows.map((row, index) =>
                                    {
                                        let rowSelected      = row.rowSelected || this.state.selectedRowsIds.includes(row.rowId)
                                        const allowRowSelect = propsAllowRowSelect && row.allowRowSelect !== false
                                        let isPartialSelected = row.partialSelected ? row.partialSelected : false;
                                        let childBlock =
                                            <tr
                                                key={index + "indexes-" + index}
                                                data-row-id={row.rowId}
                                                >
                                                <td
                                                    className={"indexes" + (rowSelected ? " selected" : '')}
                                                >
                                                {
                                                    row.indexTableEl ?
                                                    row.displayIndexTableElOnHover ?
                                                        <span
                                                            className="index-el"
                                                            onClick={(e) => allowRowSelect && this.onRowSelect(e, row, !rowSelected)}
                                                            >
                                                            {rowSelected ?
                                                                <span
                                                                    className={"index-number block"}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={rowSelected}
                                                                        onChange={null}
                                                                        className={rowSelected ? "checked_input" : ""}
                                                                    />
                                                                    <label
                                                                        htmlFor="group"
                                                                    />
                                                                </span>
                                                                :
                                                                <span
                                                                className={"index-number block"}
                                                                >
                                                               {
                                                                  ((page - 1) * this.props.paginationSize) + (index + 1)
                                                               }
                                                            </span>}
                                                            <span
                                                                className={"index-checkbox hidden"}
                                                            >
                                                                {row.indexTableEl}
                                                            </span>
                                                        </span>
                                                        :
                                                    (row.displayCheckboxOnHover && allowRowSelect) ?
                                                        <span
                                                            className={`index-el ${row.uniqueRowName} persistIdxTableEl`}
                                                            onClick={(e) => this.onRowSelect(e, row, !rowSelected)}
                                                            >
                                                            <span
                                                                className={"index-number" + (rowSelected ? " hidden" : " block")}
                                                                >
                                                            {row.indexTableEl}
                                                            </span>
                                                            <span
                                                                className={"index-checkbox" + (rowSelected ? " block" : " hidden")}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={rowSelected}
                                                                    onChange={null}
                                                                    className={rowSelected ? "checked_input" : ""}
                                                                />
                                                                <label
                                                                    htmlFor="group"
                                                                />
                                                            </span>
                                                        </span>
                                                    :
                                                    row.indexTableEl :
                                                    allowRowSelect ?
                                                    <span
                                                        className={`index-el ${row.uniqueRowName}`}
                                                        onClick={(e) => this.onRowSelect(e, row, !rowSelected)}
                                                        >
                                                        <span
                                                            className={"index-number" + ((rowSelected || isPartialSelected)  ? " hidden" : " block")}
                                                            >
                                                           {
                                                            ((page - 1) * this.props.paginationSize) + (index + 1)
                                                           }
                                                        </span>
                                                        <span
                                                            className={"index-checkbox" + ((rowSelected || isPartialSelected) ? " block" : " hidden")}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                checked={rowSelected}
                                                                onChange={null}
                                                                className={rowSelected ? "checked_input" : ""}
                                                            />
                                                            <label
                                                                htmlFor="group"
                                                                className={`${isPartialSelected ? "middle" : ""}`}
                                                            />
                                                        </span>
                                                    </span>
                                                    :
                                                    ((page - 1) * this.props.paginationSize) + (index + 1)
                                                }
                                            </td></tr>
                                        return childBlock
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div
                        className={`inlineBlock custom-right-border ${this.props.footerRow ? '' : 'last-row-missing'}`}
                        id='data-table-container'
                        style={{maxWidth: (tableMaxWidth+"px"), marginLeft: "unset", verticalAlign: 'top' }}
                        >
                        {
                            this.props.includeToolBar ?
                                    <TableIcons
                                        onSettingsIconClick={this.toggleColumnSettingVisibility}
                                        autoFitColumnsWidth={this.autoFitColumnsWidthOfTable}
                                        actionsList={this.props.tableActionButtons || []}
                                        allowEnlarge={this.props.allowEnlarge || false}
                                        headings={headings}
                                        headingsFilter={this.props.headingsFilter}
                                        removeFilters={this.props.removeFilters}
                                        displayMenu={this.state.displayColumnsSetting}
                                        setHeadingsVisibility={this.setHeadingsVisibility}
                                        type={this.props.type}
                                        resultText={resultText}
                                        totalPages={totalPages}
                                        page={page}
                                        goNextPage={this.goNextPage}
                                        goPreviousPage={this.goPreviousPage}
                                        paginationSize={this.props.paginationSize}
                                        onHideOfColumnSettings={this.toggleColumnSettingVisibility}
                                        dataTableId={this.dataTableId}
                                        extendedTableWrapper={this.wrapperClassName}
                                        showHelp={this.props.showHelp}
                                        helpLink={this.props.helpLink}
                                        downloadTable={this.props.downloadTable}
                                        displayDownloadIcon={this.props.displayDownloadIcon}
                                        downloadIconClass={this.props.downloadIconClass}
                                        customCTAIcon={this.props.customCTAIcon && this.props.customCTAIcon.htmlBody}
                                    />

                                :
                                null
                        }

                        <PerfectScrollbar
                            onScrollLeft={container =>
                                {
                                    container.classList.remove('border-right')
                                }
                            }
                            onScrollRight={container =>
                                {
                                    container.classList.remove('border-right')
                                }
                            }

                            onXReachEnd={container =>
                                {
                                    container.classList.add('border-right')

                                    if (container.scrollLeft > 0 && (container.scrollLeft === container.scrollWidth - container.clientWidth) )
                                    {
                                        container.classList.add('border-right')
                                    }
                                    else if (container.scrollLeft > 0 && (container.scrollLeft !== container.scrollWidth - container.clientWidth) )
                                    {
                                        container.classList.remove('border-right')
                                    }

                                }
                            }

                            id="extended-data-table-wrapper-scroll"
                            className="extended-data-table-wrapper-scroll">
                            <div id="extended-data-table-wrapper"
                                className={this.props.footerRow ? 'have-footer-row' : ''}
                                // style={{overflowY: "hidden", overflowX: "auto", visibility: ''}}
                                style={{visibility: ''}}
                                >
                                <table
                                    key={this.state.key}
                                    id={this.dataTableId}
                                    className={dataTableClass}
                                    >
                                    <tbody>
                                        <tr>
                                        {
                                            headings.map((col, i) =>
                                            {
                                                let textBlock =
                                                <div
                                                    className={"text-block " + (col.class || '')}
                                                    >
                                                    <div
                                                        className={"th-text" + (col.sortable && this.props.disableDataSorting !== true ? '' : ' default-cursor') }
                                                        onClick={col.sortable && this.props.disableDataSorting !== true ? this.onHeadingClick : null}
                                                        data-tip=""
                                                        onMouseOver={(e) => Utils.checkToolTipAppearance(e, col.tooltip)}
                                                        >
                                                        {col.displayName}
                                                    </div>
                                                    {
                                                        col.dragable !== false && col.disabledDefaultDragble !== true &&
                                                        <i
                                                            className="table-handle handler"
                                                            data-row={"handler"}
                                                        />
                                                    }

                                                    {
                                                        col.sortable && rows.length > 1 && this.props.disableDataSorting !== true &&
                                                        <div
                                                            className="sorting-icon-holder"
                                                            onClick={col.sortable && this.props.disableDataSorting !== true ? this.onHeadingClick : null}
                                                            >
                                                            <div
                                                                className="sorting-icon"
                                                            />
                                                        </div>
                                                    }
                                                </div>

                                                let dragEl = col.dragable === false ?
                                                        <span>
                                                            {textBlock}
                                                        </span>
                                                        :
                                                        <span>
                                                        {textBlock}
                                                        </span>

                                                let expandEl = col.expandAble === false ?
                                                        null
                                                        :
                                                        <span
                                                            className="col-move"
                                                            onMouseDown={(e) => this.onMouseDown(e, i)}
                                                            onMouseEnter={(e) => this.addExpandHoverClass(e)}
                                                            onMouseLeave={(e) => this.removeExpandHoverClass(e)}
                                                            onClick={this.performNoEvent}
                                                            onDoubleClick={this.onThDoubleClick}
                                                        >
                                                        </span>

                                                let sortableClass = col.sortable ? ' sortable ' : ''
                                                let hiddenClass   = col.visibility ? ' ' : ' hidden '
                                                let dragAbleClass = col.dragable === false ? ' static-column ' : ''
                                                let thClasses = col.headingClass + " " + col.className + hiddenClass + sortableClass + dragAbleClass

                                                let block =
                                                    <th
                                                        key={i}
                                                        name={col.key}
                                                        className={ thClasses + (this.state.hoveredColIndex === i ? " resizing" : "")}
                                                        style={{width: col.width+"px", minWidth: col.minWidth+"px"}}
                                                        >
                                                        {dragEl}
                                                        {expandEl}
                                                    </th>
                                                    return block
                                            })
                                        }
                                        </tr>
                                        {
                                            rows.map((row, i) =>
                                            {
                                                let isUploadingFile  = row.isUploadingFile ? row.isUploadingFile : false;
                                                let block =
                                                    <tr key={i}
                                                        className={row.rowClassName || ''}
                                                        onMouseEnter={(e) => this.onDataRowMouseEnter(e, row)}
                                                        onMouseLeave={(e) => this.onDataRowMouseLeave(e, row)}
                                                        // data-tip={row.rowToolTip}
                                                        >
                                                    {
                                                        isUploadingFile ?
                                                            <FileUploadRow row={row} headings={headings}/>
                                                        :
                                                        headings.map((heading, index) =>
                                                        {
                                                            let cell = row[heading.key]
                                                            let tdClasses  = (heading.visibility ? '' : 'hidden ')
                                                            tdClasses     += (!!!cell.cellClass ? '' : cell.cellClass)
                                                            tdClasses     += (!!cell.haveInput ? ' have-input' : '')

                                                            let shouldAlignRight = alignRightCols.includes(heading.key)

                                                            let TagName = row.simpleLink ? `${'a'}` : RouterLink
                                                            TagName = cell.notLink === true ? `${'div'}` : TagName
                                                            let targetBlank = row.targetBlank === true ? '_blank' : ''
                                                            let inputProps = cell.inputProps
                                                            let childBlock =

                                                            <td
                                                                key={i + ":" + index}
                                                                className={tdClasses}
                                                                >
                                                                <TagName
                                                                    to={row.rowLink || '#'}
                                                                    href={row.rowLink || '#'}
                                                                    onClick={row.onClick || null}
                                                                    target={targetBlank}
                                                                    >
                                                                <Tooltip
                                                                    placement={"top"}
                                                                    mouseEnterDelay={row.rowToolTip && row.rowToolTip.mouseEnterDelay ? row.rowToolTip.mouseEnterDelay : 0.1}
                                                                    mouseLeaveDelay={0.1}
                                                                    overlayClassName={cell.cellClass !== 'remove-col' && row.rowToolTip ? row.rowToolTip.overlayClassName : 'hidden'}
                                                                    getTooltipContainer={
                                                                            () => document.querySelector(row.rowToolTip ? row.rowToolTip.tooltipContainer : ".routes")
                                                                        }
                                                                    overlay={row.rowToolTip ? row.rowToolTip.overlay : ''}
                                                                >
                                                                    <div
                                                                        className={`${cell.cellCustomClass} ${cell.cellClass === 'remove-col' ? '' : "text-block"} ${(shouldAlignRight ? 'text-align-right' : '')}`}
                                                                        data-tip={cell.showVendorTooltip ? tooltipText : ''} data-for="vendor-disable-input"
                                                                        onMouseOver={cell.tooltip ? (e) => Utils.checkToolTipAppearance(e, cell.tooltip, "text-block") : null}
                                                                        >
                                                                        {
                                                                            heading.input ?
                                                                                <LazyInput
                                                                                    autoComplete="off"
                                                                                    type='text'
                                                                                    name={inputProps.name}
                                                                                    className={inputProps.inputClass}
                                                                                    value={cell.value}
                                                                                    onChange={(event) => this.onInputChange(event, i)}
                                                                                    onClick={(event) => this.onInputChange(event, i)}
                                                                                    data-tip={inputProps.message}
                                                                                    data-place="right"
                                                                                    data-type="error"
                                                                                    timeout={500}
                                                                                />
                                                                            :
                                                                            cell.displayValue
                                                                        }
                                                                    </div>
                                                                </Tooltip>
                                                                </TagName>

                                                            </td>

                                                            return childBlock
                                                        })

                                                    }
                                                    </tr>
                                                return block
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </PerfectScrollbar>
                        {
                            this.props.footerRow &&
                            <div className="extra-footer-row data-cell">
                                <div className={"footer-data-cell"}>
                                    {this.props.footerRow.dataCellEl}
                                </div>
                            </div>
                        }
                        {
                            totalPages && totalPages > 1 ?
                            <div className="pagination-ui">
                                <Pagination
                                    resultText={resultText}
                                    totalResults={this.state.rows.length}
                                    totalPages={totalPages}
                                    page={page}
                                    paginationSize={this.props.paginationSize}
                                    goPreviousPage={this.goPreviousPage}
                                    goNextPage={this.goNextPage}
                                />
                            </div>
                            : null
                        }
                    </div>

                    {
                        this.props.footerRow &&
                        <div className="extra-footer-row index-cell">
                            <div className={"footer-index-cell" + (this.props.footerRow.bordered && this.props.footerRow.indexCellEl ? ' bordered hover' : '')}>
                                {this.props.footerRow.indexCellEl}
                            </div>
                        </div>
                    }
                </div>
            </div>
        return markup
    }
}

export default connect((store) => store)(ExtendedTablePaginated)
