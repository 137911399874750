import { SvgIcon } from "@mui/material";

export const CheckboxFilledIcon = () => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "24",
    height: "24",
  }}>
    <path d="M4.5 21c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-15c0-.4.15-.75.45-1.05.3-.3.65-.45
    1.05-.45h15c.4 0 .75.15 1.05.45.3.3.45.65.45
    1.05v15c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-15z"
      fill="#3CD1B6" fillRule="nonzero" />
  </SvgIcon>
);
