import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../../ui/inner-loading";
import Utils from "../../../../modules/utils";
import Permissions from "../../../../modules/schemas/permissions";
import { Helmet } from "react-helmet";
import PerfectScrollbar from 'react-perfect-scrollbar';
import ItemOverView from "../../common/specifications/item-overview";
import API from "../../../../modules/api";
import { Prompt } from 'react-router';
import WarningModal from "../../../ui/warning-modal";
import Link from "../../../ui/link";
import EditIcon from "../../../../assets/icons/edit";
import InlineIcon from "../../../ui/icon/inline-icon.js";
import ErrorNotification from "../../common/error-notification";
import Actions from "./actions.js";
import Tabs from "../../common/tabs/edit";
import UI from "../../../../action-types/ui";
import buildAction from "../../../../helpers/buildAction";
import validations, { validateField } from "../../../../modules/validations";
import ComponentProductUtils from "../../../../modules/component-product-utils";
import Tooltip from "rc-tooltip";
import EllipsisTooltip from "../../common/ellipsis-tooltip";
import TextArea from "../../../ui/lazy-input/textarea";
import DatePicker from "react-datepicker";
import LazyInput from "../../../ui/lazy-input/input";
import SerializationIcon from "../../../../assets/icons/serialization";
import ItemScheduleIcon from "../../../../assets/icons/item-schedule-icon";
import LinkIcon from "../../../../assets/icons/open-link-icon.js";
import TileItem from "../../common/tile-item";
import ItemDetailsIcon from "../../../../assets/icons/item-details-icon";

export class EditProductionInstance extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.onDateChange = this.onDateChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getData = this.getData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onModalExternalClick = this.onModalExternalClick.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleBlockedNavigation = this.handleBlockedNavigation.bind(this);
        this.handleConfirmNavigationClick = this.handleConfirmNavigationClick.bind(this);
        this.lastClickedTarget = null;
        this.blockNavigation = this.blockNavigation.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.calculateAssemblyErrors = this.calculateAssemblyErrors.bind(this);
        this.getErrorsWarningMsg = this.getErrorsWarningMsg.bind(this);
        this.getTotalErrorsCount = this.getTotalErrorsCount.bind(this);
        this.toggleErrorCount = this.toggleErrorCount.bind(this);
        this.getProductionInstanceAndSetEditPage = this.getProductionInstanceAndSetEditPage.bind(this);
        this.setProductionInstanceInEditForm = this.setProductionInstanceInEditForm.bind(this);
        this.updateEditFormState = this.updateEditFormState.bind(this);
        this.submitProductionInstanceForm = this.submitProductionInstanceForm.bind(this);
        this.updateChildrenInstances = this.updateChildrenInstances.bind(this);
        this.allowedElementsForClick = ["sign out", "private sandbox", "company account", "exit sandbox"];
        this.setEditBarState = this.setEditBarState.bind(this);
        this.updateProductionInstances = this.updateProductionInstances.bind(this);

        this.initialState = {
            productionInstance: null,
            newSerialValue: null,
            childrenInstances: [],
            inputs:
            {
                inputChanged: false,
                serial:
                {
                    value: "",
                    valid: true,
                },
                label:
                {
                    value: "",
                    valid: true,
                },
                productionDate:
                {
                    value: "",
                    valid: true,
                },
                description:
                {
                    value: "",
                    valid: true,
                },
                documents:
                {
                    value: [],
                    valid: true,
                },
                children:
                {
                    value: [],
                    valid: true,
                },
            }
        }

        this.state =
        {
            ...this.initialState,
            isAddedClickListener: false,
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            errorsCount: {
                assemblyTab: 0,
                documentTab: 0
            },
            error:true,
        }
    }

    onDateChange(date, event)
    {
        let name  = "productionDate";
        let value = date && new Date(date.toISOString()).getTime();
        this.onInputChange({target: {name: name, value: Number(value)}});
    }

    updateChildrenInstances(children)
    {
        this.blockNavigation();
        this.setState({childrenInstances: children});
    }

    updateProductionInstances(children)
    {
        let state = this.state;
        this.blockNavigation();
        state.productionInstance.children = children;
        this.setState(state);
    }

    getProductionInstanceAndSetEditPage(payload)
    {
        let { id } = payload;
        this.setState({...this.initialState, children: [], modified: false});

        API.productionInstances.findById(id, (err, data) =>
        {
            if (data)
            {
                this.setProductionInstanceInEditForm(data);
            }
            else if (err)
            {
                this.props.showUiAlert({ type: "errors", errors: err.errors, err: err });
            }
        })
    }

    setProductionInstanceInEditForm(productionInstanceData)
    {
        let { inputs } = this.initialState;
        inputs.serial.value = productionInstanceData.serial;
        inputs.label.value = productionInstanceData.label;
        inputs.productionDate.value = productionInstanceData.productionDate;
        inputs.description.value = productionInstanceData.description;
        inputs.documents.value = productionInstanceData.documents;
        inputs.children.value = productionInstanceData.children;
        this.setState({inputs,productionInstance: productionInstanceData, children: productionInstanceData.children || []});
    }

    displayExpertForecastPopover()
    {
        this.setState({displayExpertForecastPopover: true});
    }

    hideExpertForecastPopover()
    {
        this.setState({displayExpertForecastPopover: false});
    }

    toggleModal(modalName, modalValue)
    {
        if (modalValue === true)
        {
            this.setState({[modalName]: modalValue, cmpId: null});
        }
        else
        {
            this.setState({[modalName]: modalValue});
        }
    }

    showModal(location)
    {
        this.setState({modalVisible: true, lastLocation: location});
    }

    updateRevisionComment(event)
    {
        this.setState({revisionComment: event.target.value});
    }

    closeModal(callback, isSkip=false)
    {
        if (isSkip)
        {
           this.setState({modalVisible: false});
           this.lastClickedTarget = null;
        }
        else
        {
            this.setState({modalVisible: false}, callback);
        }
    }

    handleConfirmNavigationClick()
    {
        this.closeModal(() =>
        {
            const { lastLocation } = this.state;
            const lastClickedTarget = this.lastClickedTarget;

            if (lastLocation)
            {
                this.setState({confirmedNavigation: true}, () => this.props.history.push({pathname: lastLocation.pathname, state: lastLocation.state}));
            }
            else if (lastClickedTarget)
            {
                let targetElementText = lastClickedTarget.innerText && lastClickedTarget.innerText.toLowerCase();
                if (this.allowedElementsForClick.includes(targetElementText))
                {
                    lastClickedTarget.click();
                }
                else if (!!lastClickedTarget.closest(".onboarding-setp-item"))
                {
                    lastClickedTarget.closest(".onboarding-setp-item").click();
                }
                else if (!!lastClickedTarget.closest(".exit-sandbox-btn"))
                {
                    lastClickedTarget.closest(".exit-sandbox-btn").click();
                }
            }
        })
    }

    handleBlockedNavigation(nextLocation)
    {
        const { confirmedNavigation, shouldBlockNavigation } = this.state;
        const lastClickedTarget = this.lastClickedTarget;

        if (!confirmedNavigation && shouldBlockNavigation && !lastClickedTarget)
        {
            this.showModal(nextLocation);
            return false;
        }
        return true;
    }

    onModalExternalClick(event)
    {
        let target     = event.target;
        let parent     = this.refs.productionInstanceForm;
        let isExternal = target !== parent && !Utils.isDescendant(parent, target);
        let state = this.state;
        let targetElementText = event.target.innerText && event.target.innerText.toLowerCase();

        if  (
                !isExternal && event.target.nodeName === "A" && event.target.innerText === "CANCEL" ||
                event.target.classList.contains("delete_btn") && event.target.value === "CANCEL"
            )
        {
            state.shouldBlockNavigation = false;
            this.setState(state);
        }

        if  (
                isExternal && !this.lastClickedTarget &&
                (
                    (!!event.target.closest(".navigation") && this.allowedElementsForClick.includes(targetElementText)) ||
                    (!!event.target.closest(".onboarding-setp-item")) ||
                    (!!event.target.closest(".exit-sandbox-btn")) ||
                    (!!event.target.closest(".help-menu-actions") && (this.allowedElementsForClick.includes(targetElementText) || this.allowedElementsForClick.includes(targetElementText)))
                )
            )
        {
            event.preventDefault();
            event.stopPropagation();
            this.lastClickedTarget = event.target;
            this.showModal();
        }
    }


    componentDidUpdate()
    {
        if (this.state.shouldBlockNavigation)
        {
            window.onbeforeunload = () => true
        }
        else
        {
            window.onbeforeunload = undefined;
        }
    }

    componentWillUnmount()
    {
        window.onbeforeunload = undefined;
        document.body.removeEventListener("click", this.onModalExternalClick);
        this.state.shouldBlockNavigation = undefined;
        this.setState({...this.initialState});
    }

    componentWillMount()
    {
        let id = Utils.getIdFromParams(this.props.match.params);
        Utils.setLocalStorageForAssemblyTree(id);
        id = `${id}?include=children,documents,creator,images,revisions`;
        let payload = { id, history: this.props.history };
        this.getProductionInstanceAndSetEditPage(payload);
    }

    blockNavigation()
    {
        let {
            isAddedClickListener,
            shouldBlockNavigation,
        } = this.state;

        if (!isAddedClickListener)
        {
            document.body.addEventListener("click", this.onModalExternalClick);
            shouldBlockNavigation = true;
            isAddedClickListener = true;
            this.setState({shouldBlockNavigation, isAddedClickListener});
        }
    }

    onInputChange(event, i)
    {
        this.setEditBarState();
        this.blockNavigation();
        this.updateEditFormState({
          value: event.target.value,
          name: event.target.name,
          index: i,
        })
    }

    setEditBarState(value = false)
    {
        this.setState({ error: value });
    }

    updateEditFormState(payload)
    {
        let { inputs, productionInstance, newSerialValue } = this.state;
        let { name, value, index } = payload;
        let input  = "";

        switch(name)
        {
            case "serial" :
            {
                inputs.inputChanged = true;
                input = inputs.serial;
                newSerialValue = value;
                validateField(inputs.serial, validations.productionInstance.serial, value);
                inputs.serialDuplicateOf = null;

                if (input.valid)
                {
                    this.setState({inputs});

                    API.productionInstances.serialExists({serial: value, id: productionInstance._id}, (err, data) =>
                    {
                        if (data)
                        {
                            if (data.exist)
                            {
                                input.message = input.errorSetFromChild ?  input.message : ""; // message is empty so that duplicateOf tolltip will show
                                input.valid   = false;
                                input.class   = "invalid";
                                inputs.serialDuplicateOf = data.duplicate_of;
                            }
                            else
                            {
                                if (!input.errorSetFromChild)
                                {
                                    input.message = "";
                                    input.valid   = true;
                                    input.class   = "";
                                }

                                if (inputs.serialDuplicateOf) delete inputs.serialDuplicateOf
                            }
                            this.setState({inputs});
                        }
                        else if (err)
                        {
                            this.props.showUiAlert({ type: "errors", errors: err.errors, err: err });
                        }
                    })
                }

                break;
            }

            case "productionDate" :
            {
                inputs.inputChanged = true;
                validateField(inputs.productionDate, validations.productionInstance.productionDate, value);
                break;
            }

            case "description" :
            {
                inputs.inputChanged = true;
                validateField(inputs.description, validations.productionInstance.description, value);
                break;
            }

            case "label" :
            {
                inputs.inputChanged = true;
                validateField(inputs.label, validations.productionInstance.label, value);
                break;
            }

            case "documents" :
            {
                inputs.inputChanged = true;
                input = inputs.documents;
                input.value = value.documents;
                input.valid = value.isUploadedDocuments;
                break;
            }
        }
        setTimeout(() => {
        inputs.serial.class != 'invalid' ? this.setEditBarState(true) :''
        }, 1000);
        this.setState({inputs, newSerialValue});
    }

    getData()
    {
        let { inputs, productionInstance } = this.state;
        let productionInstanceData = {};

        productionInstanceData.serial = inputs.serial.value;
        productionInstanceData.label = inputs.label.value;
        productionInstanceData.productionDate = inputs.productionDate.value;
        productionInstanceData.description = inputs.description.value;
        productionInstanceData.documents = inputs.documents.value;
        productionInstanceData.lastModified = productionInstance.lastModified;

        return productionInstanceData;
    }

    onKeyPress(event)
    {
        if (event.target.type !== 'textarea' && event.which === 13 /* Enter */)
        {
          event.preventDefault();
        }
    }

    onSubmit(event, options=null)
    {
        event && event.preventDefault();
        let { inputs }   = this.state;
        let validated = Utils.isValidated(inputs);

        if(!validated)
        {
            return
        }

       let payload =
        {
            data: this.getData(),
            history: this.props.history
        }

        if (options)
        {
            payload.data.revisionComment = options.revisionComment;
            payload.data.saveAsRevision = true;
            payload.data.revision = options.revision;
        }

        if(this.props.location && this.props.location.isVariant)
        {
            payload.data.originalObjectId = this.props.originalProductId;
            payload.data.isVariant = this.props.location.isVariant;
        }

        this.submitProductionInstanceForm(payload);
    }

    submitProductionInstanceForm(payload)
    {
        let { productionInstance, childrenInstances } = this.state;
        let productionInstanceViewLink = `/production/instance/view/${productionInstance._id}`;

        childrenInstances.push({...payload.data, _id:productionInstance._id})

        API.productionInstances.updateBulk(childrenInstances, (err, data) =>
        {
            if (data)
            {
                this.setState({...this.initialState});
                payload.history.push(productionInstanceViewLink)
            }
            else if (err)
            {
                let payload =
                {
                    type: "errors",
                    errors: err.errors,
                    err: err
                }

                if(err.errors[0].message.includes("has been modified by another user"))
                {
                    let errors = err.errors[0].message.split('Please');
                    payload.type = "confirm";
                    payload.errorType = "warning";
                    payload.heading = "Warning";
                    payload.confirmButtonText = "OK";
                    payload.text = `<span>${errors[0]}<br/>Please ${errors[1]}</span>`;;
                    payload.confirmCb = () => {
                        window.onbeforeunload = undefined;
                        Utils.redirectToParentRoute(ComponentProductUtils.makeEditLink(productionInstance, "productionInstance"));
                    };
                }

                this.props.showUiAlert(payload)
            }
        })
    }

    calculateAssemblyErrors(errors)
    {
        let state = this.state;
        state.errorsCount['assemblyTab'] = errors;
        this.setState(state);
    }

    toggleErrorCount(valid, tabName)
    {
        let { errorsCount } = this.state;
        if(!valid)
        {
            errorsCount[tabName]++;
        }
        else if(errorsCount[tabName] > 0)
        {
            errorsCount[tabName]--;
        }
        this.setState({errorsCount});
    }

    getErrorsWarningMsg(){
        let count = this.getTotalErrorsCount();
        return `${count} ${count > 1 ? 'errors' : 'error'} found.<br>All errors must be resolved before saving.`;
    }

    getTotalErrorsCount()
    {
        let { inputs } = this.state;
        let count = 0;
        let _this = this;
        Object.keys(this.state.errorsCount).forEach(function(key){
            count += _this.state.errorsCount[key];
        })

        count += Utils.getErrorsCount(inputs);

        return count;
    }

    render()
    {
        let { productionInstance, inputs, isAddedClickListener, newSerialValue, shouldBlockNavigation,
            errorsCount, error } = this.state;
        let validated = Utils.isValidated(inputs) && error;
        let { user } = this.props;
        let isSerializationEnabled = window.__isSerializationEnabled && window.__libraryType === "GENERAL";
        let childValidated = !(errorsCount.assemblyTab || errorsCount.documentTab);

        let serialDuplicateTooltip = null

        // Show Tooltip with link with serial message (Duplicate Serial Number Found) is not present.
        if (inputs && inputs.serial && !inputs.serial.message)
        {
            serialDuplicateTooltip  = Utils.makeSerialDuplicateInputTooltip(inputs.serialDuplicateOf, "Serial Number")

        }
        // if serialDuplicateTooltip is present then don't set visible props, because if we
        // set visible: true then the Error tooltip keeps showing even without hover.
        let serialDuplicateTooltipProps = serialDuplicateTooltip ? {}: {visible: false}

        if (!productionInstance || !Permissions.can("edit", "production_instance", user.data) || !isSerializationEnabled){
            return <Loading />
        }

        let productionInstanceViewLink = `/production/instance/view/${productionInstance._id}`

        let markup =
            <div className="edit-production-instance-route" ref="productionInstanceForm">
                <Helmet>
                    <title>{Utils.makeTitle(`[${productionInstance.cpn}] (EDIT) ${productionInstance.name}`)}</title>
                </Helmet>

                {
                    shouldBlockNavigation &&
                    <Prompt
                        when={shouldBlockNavigation}
                        message={this.handleBlockedNavigation}
                    />
                }

                {
                    this.state.modalVisible &&
                    <WarningModal
                        onCancel={(e) => this.closeModal(e, true)}
                        onConfirm={this.handleConfirmNavigationClick}
                        title={"Changes will be lost"}
                        description={"You have unsaved changes. If you navigate away without first saving, the changes will be lost."}
                    />
                }
                <form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>
                    <div className="banner-block">
                        <div className="app-row">
                            <div>
                                <InlineIcon><EditIcon /></InlineIcon>
                                <div>You are currently in edit mode.</div>
                            </div>
                            <div>
                                <Link to={productionInstanceViewLink}>CANCEL</Link>
                                <Actions
                                    childValidated={childValidated}
                                    onSubmit={this.onSubmit}
                                    calculateAssemblyErrors={this.calculateAssemblyErrors}
                                    inputs={inputs}
                                    validated={validated}
                                    isDataChanged={isAddedClickListener}

                                />
                            </div>
                            {
                                validated && childValidated ? "" : <ErrorNotification getErrorsWarningMsg={this.getErrorsWarningMsg}/>
                            }
                        </div>
                    </div>
                    <div className="top-block">
                        <div className="nav-block app-row">
                            <EllipsisTooltip
                                classes={"ellipsis-tooltip tooltip-no-width"}
                                title={productionInstance.name}
                                innerClasses={"truncate-name edit-pi-title"}
                            >
                                {productionInstance.name}
                            </EllipsisTooltip>
                        </div>
                        <div className="tiles-wrapper">
                            <PerfectScrollbar className="tiles-section-scroll">
                                <ItemOverView
                                    item={productionInstance}
                                    alias={productionInstance.alias}
                                    isProductionInstance={true}
                                />
                                <TileItem title="Details" icon={ <ItemDetailsIcon /> }>
                                    <div class="description">
                                        <TextArea
                                            name="description"
                                            onChange={this.onInputChange}
                                            placeholder="Enter a production instance description"
                                            value={inputs.description.value}
                                            className={inputs.description.class}
                                            data-tip={inputs.description.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                    </div>
                                    <InlineIcon className="primary-source">
                                        <SerializationIcon />
                                        <span>production info</span>
                                    </InlineIcon>
                                    <div className="inner-info">
                                        <span className="inner-attribute">date</span>
                                        <div className="inner-value">
                                            <DatePicker
                                                popperProps={{
                                                    positionFixed: true // this allows to show date-picker popup over/outside the parent div
                                                }}
                                                selected={inputs.productionDate.value ? new Date(inputs.productionDate.value) : null}
                                                onChange={(date) => this.onDateChange(date)}
                                                dateFormat={"MM/dd/yyyy"}
                                                className={`production-build-date-trigger`}
                                                customInput={
                                                    <input
                                                        type="text"
                                                        value={inputs.productionDate.value}
                                                        data-tip={inputs.productionDate.message}
                                                        data-place="right"
                                                        data-type="error"
                                                    />
                                                }
                                            />
                                            <InlineIcon
                                                className="pi-date-icon"
                                                tooltip={`${inputs.productionDate.message ? inputs.productionDate.message : ""}`}
                                                tooltipType="error"
                                                tooltipPlace="right"
                                            >
                                                <ItemScheduleIcon
                                                    onClick={() => document.querySelector(`.production-build-date-trigger`) && document.querySelector(`.production-build-date-trigger`).click()}
                                                />
                                            </InlineIcon>
                                        </div>
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">serial</span>
                                        <div className="inner-value">
                                            <Tooltip
                                                {...serialDuplicateTooltipProps}
                                                placement={"right"}
                                                // visible={serialDuplicateTooltip}
                                                overlayClassName={"simple-rc-tip error"}
                                                getTooltipContainer={() => document.querySelector(".routes")}
                                                overlay={ serialDuplicateTooltip ?
                                                    <div>
                                                    <p className="serial-validation-tooltip">
                                                        <span className="link-text">
                                                            {serialDuplicateTooltip.errorMessage}
                                                        </span>
                                                        <br/>
                                                        {
                                                        serialDuplicateTooltip.alias === "prd"
                                                        ?
                                                            <Link
                                                            to={serialDuplicateTooltip.viewLink}
                                                            target="_blank"
                                                            className="open-link-holder white"
                                                            >
                                                            <span className="link-text">{serialDuplicateTooltip.linkMessage}
                                                                <InlineIcon >
                                                                <LinkIcon/>
                                                                </InlineIcon>
                                                            </span>
                                                            </Link>
                                                        :
                                                            <span className="link-text">{serialDuplicateTooltip.linkMessage}</span>
                                                        }
                                                    </p>
                                                    </div>
                                                    : ""
                                                }
                                            >
                                                <LazyInput
                                                    type="text"
                                                    name="serial"
                                                    className={inputs.serial.class}
                                                    value={inputs.serial.value}
                                                    onChange={this.onInputChange}
                                                    data-place="right"
                                                    data-type="error"
                                                    data-tip={inputs.serial.message}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="inner-info last-info">
                                        <span className="inner-attribute">label</span>
                                        <div className="inner-value">
                                            <LazyInput
                                                type="text"
                                                name="label"
                                                className={inputs.label.class}
                                                value={inputs.label.value}
                                                onChange={this.onInputChange}
                                                data-place="right"
                                                data-type="error"
                                                data-tip={inputs.label.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">created by</span>
                                        {productionInstance.creator.firstName + " " + productionInstance.creator.lastName}
                                    </div>
                                </TileItem>
                            </PerfectScrollbar>
                        </div>
                    </div>

                    <Tabs
                        tabsType="productionInstance"
                        productionInstance={productionInstance}
                        calculateAssemblyErrors={this.calculateAssemblyErrors}
                        errorsCount={errorsCount}
                        toggleErrorCount={this.toggleErrorCount}
                        onChange={this.onInputChange}
                        inputs={inputs}
                        updateChildrenInstances={this.updateChildrenInstances}
                        updateProductionInstances={this.updateProductionInstances}
                        newSerialValue={newSerialValue}
                        setEditBarState={this.setEditBarState}
                    />
                </form>
            </div>

        return markup
    }
}

const mapDispatchToProps = dispatch => ({
  showUiAlert : payload => dispatch(buildAction(UI.SHOW_ALERT, payload)),
});

const mapStateToProps = state => ({
    user: state.user,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProductionInstance);

