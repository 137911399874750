import { takeEvery, all, put, cps } from 'redux-saga/effects';
import buildAction                  from '../helpers/buildAction';
import API                          from "../modules/api";
import Schemas                      from '../modules/schemas';
import COMPANY                      from '../action-types/company';
import USER                         from '../action-types/user';
import UI                           from '../action-types/ui';
import PRODUCT                      from '../action-types/product';
import validations, {validateField} from '../modules/validations';

export function* submitUpdateForm(action)
{
    yield put(buildAction(UI.LOAD_START));
    try {
        const response = yield cps(API.companies.update, action.payload.data._id, action.payload.data)
        yield put(buildAction(COMPANY.SET_COMPANY_STATE, response));
        yield put(buildAction(COMPANY.GET_CURRENT_COMPANY));
        if(action.payload.inputs.enforceTwoFa.value && action.payload.inputs.enforceTwoFa.value === action.payload.data.settings.twoFaConfiguration.enforceTwoFa)
            action.payload.history.push("/settings/user");
       }
   catch (err) {
        yield put(buildAction(COMPANY.SET_API_ERRORS, err.errors));
    }
    yield put(buildAction(UI.LOAD_END));
}

export function* getCurrent(action)
{
    try {
        yield put(buildAction(UI.LOAD_START));
        const response = yield cps(API.companies.getCurrent);
        const integrations = yield cps(API.integrations.getCompanyIntegrations);
        window.__integrations = integrations;
        window.__revSchemeType = response.revSchemeType;
        window.__cpnSchemeType = response.cpnScheme && response.cpnScheme.activeScheme;
        window.__isAllowedBlankItemNumber = response.settings.isAllowedBlankItemNumber;
        window.__buildScheduleScheme = response.settings.buildScheduleScheme;
        window.__displayRefDesAndItemNumber = response.settings.displayRefDesAndItemNumber;
        window.__isPrimarySourceWarningDisabledInCO = response.settings.isPrimarySourceWarningDisabledInCO;
        window.__customFields = response.settings.customFields;
        window.__customUomLabels = response.settings.customUomLabels;
        window.__isNotRevisionManagedEnabled = response.settings.isNotRevisionManagedEnabled;
        window.__unitPricePrecision = response.settings.unitPricePrecision;
        window.__erpItemTypeOptions = response.settings.erpItemTypeOptions;
        window.__isDcoEnabled  = response.settings.isDcoEnabled;
        window.__isConfigurationsEnabled = response.settings.configurations.isConfigurationsEnabled;
        window.__isCoMandatoryToggleEnabled = response.settings.configurations.coMandatoryToggleEnabled;
        window.__isCommentMandatory = response.settings.configurations.coCommentMandatoryEnabled;
        window.__isSpecValidationEnabled = response.settings.isSpecValidationEnabled;
        window.__allowedDocTypes = response.settings.allowedDocTypes;
        window.__defaultBlacklistedRevisions = response.settings.defaultBlacklistedRevisions;
        window.__isApplicabilityFieldEnabled = response.settings.isApplicabilityFieldEnabled;
        response.logo_src = response.logo && response.logo.src;
        yield put(buildAction(COMPANY.SET_COMPANY_STATE, response));

        let buildScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
        yield put(buildAction(PRODUCT.SET_NON_DEFAULT_FORECAST, {forecastType: buildScheme}));

    }catch (e) {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: e, err: e}));
    }

    yield put(buildAction(UI.LOAD_END));
}

export function* updateInputOnChange(action) {
    let company = Object.assign({}, action.payload.company);
    let target = action.payload.target
    let name = target.name
    let value = target.value
    let inputs = action.payload.inputs
    let input = ""
    let address = company.address || {}

    switch(name)
        {
            case "name" :
                input = inputs.name
                validateField(input, validations.company.name, value)
                company.name  = value
                break

            case "website" :
                input = inputs.website
                company.website  = value
                validateField(input, validations.company.website, value)
                break

            case "logo" :
                if(!value) break
                company.logo = value.imageId
                inputs.logo.valid = value.valid
                break

            case "removeBtn" :
                company.logo = value
                inputs.logo.class = ""
                inputs.logo.style = {}
                break

            case "address.street" :
                input = inputs.address.street
                validateField(input, validations.company.address.street, value)
                address.street = value
                break

            case "address.suite" :
                input = inputs.address.suite
                validateField(input, validations.company.address.suite, value)
                address.suite = value
                break

            case "address.city" :

                input = inputs.address.city
                validateField(input, validations.company.address.city, value)
                address.city = value
                break

            case "address.state" :
                input = inputs.address.state
                validateField(input, validations.company.address.state, value)
                address.state = value
                break

            case "address.zip" :
                input = inputs.address.zip
                validateField(input, validations.company.address.zip, value)
                address.zip = value
                break

            case "cpn-scheme.activeScheme" :
                input = inputs.cpnScheme.activeScheme
                validateField(input, validations.company.cpnScheme.activeScheme, value)
                company.cpnScheme.activeScheme = value
                break

            case "enforceTwoFa" :
                input = input.enforceTwoFa;
                company.settings.twoFaConfiguration.enforceTwoFa = value;
                break;

            default :
        }
        yield put(buildAction(COMPANY.UPDATE_FORM_STATE,{ inputs, company}));

}


export default function*(getState)
{
    yield all([
        takeEvery(COMPANY.SUBMIT_UPDATE_FORM, submitUpdateForm),
        takeEvery(COMPANY.UPDATE_INPUT_ON_CHANGE, updateInputOnChange),
        takeEvery(COMPANY.GET_CURRENT_COMPANY, getCurrent)
    ]);
}
