export function getInitialState()
{
    return {
        steps: [
            {
                key             : "full_bom_assembly_tree",
                class           : "",
                displayName     : "Product Assembly Tree",
                showVideoModal  : false,
                showToolTip     : false,
                isCompleted     : false,
                defaultPath     : "/product/new",
                tooltipText     : "Show Product Assembly Tree"
            },
            {
                key             : "first_product",
                class           : "",
                displayName     : "Creating a New Product",
                showVideoModal  : false,
                showToolTip     : false,
                isCompleted     : false,
                defaultPath     : "/dashboard",
                tooltipText     : "Create a New Product"

            },
            {
                key             : "import_an_existing_spreadsheet",
                queryType       : "type:cmp",
                class           : "",
                displayName     : "Import an Existing Spreadsheet",
                showVideoModal  : false,
                showToolTip     : false,
                isCompleted     : false,
                defaultPath     : "/search",
                tooltipText     : "Import a BOM Spreadsheet"

            },
            {
                key             : "component_from_vendor",
                queryType       : "type:cmp",
                class           : "",
                displayName     : "Creating Components from a Vendor",
                showVideoModal  : false,
                showToolTip     : false,
                isCompleted     : false,
                defaultPath     : "/search",
                tooltipText     : "Add Component from Vendor Catalog"


            },
            {
                key             : "change_order",
                queryType       : "type:co",
                class           : "",
                displayName     : "Creating a Change Order",
                showVideoModal  : false,
                showToolTip     : false,
                isCompleted     : false,
                defaultPath     : "/search",
                tooltipText     : "Create a Change Order"

            },
            {
                key             : "export",
                class           : "",
                displayName     : "Exporting Data and Files",
                showVideoModal  : false,
                showToolTip     : false,
                isCompleted     : false,
                defaultPath     : "/export",
                tooltipText     : "Export Your Product Data"

            }
        ],
        displayOnBoardingNav                         : false,
        currentStep                                  : "",
        status                                       : "In-Progress",
        dummyProductType                             : "drone",
        dummyProductID                               : "",
        displaySampleProductModalOnDashBoard         : false,
        isTutorialSkiped                             : false,
        currentEnv                                   : null,
        showEnvInfoModal                             : false,
        innerLoading                                 : false,
        showInviteModal                              : false,
        activeStepIndex                              : 0,
        loading                                      : false,
        isOnBoardingNotification                     : false
    }
}

let initialState = getInitialState()
export default initialState
