import React, { FC, useCallback, useMemo } from "react";
import { Box, MenuItem, Select as SelectItem, FormControl, Typography, styled } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { InputFieldVariants } from "common/constants";
import { FieldTitle } from "../field-title";

export interface IComponentStyleProps {
  borderRadius?: string,
  marginBottom?: string,
  width?: string,
}

export interface IDropdownProps {
  componentStyles?: IComponentStyleProps,
  defaultValue?: string,
  displayEmpty?: boolean,
  inputProps?: IInputProps,
  isRequired: boolean,
  label?: string,
  onChange: (e: SelectChangeEvent) => void,
  options: string[],
  placeholder?: string,
  variant?: InputFieldVariants
}

export interface IInputProps {
  sx?: {
    backgroundColor?: string,
    padding?: string,
    paddingTop?: string,
  }
}

const titleFieldStyle = {
  fontSize: "0.75rem",
  marginBottom: "0.5rem",
};

export const Select: FC<IDropdownProps> = ({
  componentStyles,
  defaultValue,
  displayEmpty,
  inputProps,
  isRequired,
  label,
  onChange,
  options,
  placeholder,
  variant,
}) => {
  const [value, setValue] = React.useState(defaultValue ?? "");
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event);
  };

  const _fieldTitle = useMemo(() => (
    label && (
      <FieldTitle
      isRequired={isRequired}
      label={label}
      sx={titleFieldStyle}
      />
    )
  ), [isRequired, label]);

  const Options = useMemo(() => (
    options.map((option, index) => (
      <SelectOptions
        divider={true}
        key={index}
        value={option}
      >
        {option}
      </SelectOptions>
    ))
  ), [options]);

  const renderValue = useCallback(selected => {
    if (selected.length) return selected;
    return <Typography>{placeholder}</Typography>;
  }, [placeholder]);

  return (
    <SelectWrapper componentStyles={componentStyles}>
      {_fieldTitle}
      <FormControl fullWidth variant={variant} size="small">
        <SelectItem
          displayEmpty={displayEmpty}
          inputProps={inputProps}
          onChange={handleChange}
          renderValue={renderValue}
          required={isRequired}
          value={value}
        >
          {Options}
        </SelectItem>
      </FormControl>
    </SelectWrapper>
  );
};

const SelectOptions = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.duro.eventModal.selectOptions.backgroundColor,
}));

const SelectWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "componentStyles",
})<Pick<IDropdownProps, "componentStyles">>(({ componentStyles, theme }) => ({
  ...componentStyles,
  "& .MuiInputBase-root": {
    backgroundColor: theme.duro.eventModal.selectOptions.backgroundColor,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0",
  },
}));
