import { FC, useCallback, useMemo, useState } from "react";
import { Switch as SwitchButton, FormGroup, FormControlLabel, Typography, styled } from "@mui/material";

export interface ISwitchProps {
  label?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  sx?: {
    marginLeft?: string
  }
}

export const Switch: FC<ISwitchProps> = (({ label, onChange, sx }) => {
  const [checked, setChecked] = useState(false);

  const toggleChecked = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    onChange(e);
  }, [checked, onChange, setChecked]);

  const switchLabel = useMemo(() => (
    <Typography variant="subtitle1">
      {label ?? ""}
    </Typography>
  ), [label]);

  return (
    <CustomFormGroup sx={sx}>
      <CustomFormLabel
        control={<SwitchButton checked={checked} onChange={toggleChecked} size="small" />}
        label={switchLabel}
      />
    </CustomFormGroup>
  );
});

const CustomFormGroup = styled(FormGroup, {
  shouldForwardProp: prop => prop !== "sx",
})<Pick<ISwitchProps, "sx">>(({ sx }) => sx as any);

const CustomFormLabel = styled(FormControlLabel)({
  fontSize: "0.875rem",
  margin: "0",
});
