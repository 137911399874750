import                                  "./index.css"
import React, { Component }             from "react"
import {connect}                        from "react-redux"
import Link                             from "../../link"
import InlineIcon                       from "../../icon/inline-icon.js"
import ExitArrowSrc                     from "../../../../assets/icons/exit-sandbox"
import logo                             from "../../../../assets/icons/logo.svg"
import sandboxLogo                      from "../../../../assets/icons/sandbox-text.svg"
import COMPANY                          from "../../../../action-types/company"
import ON_BOARDING                      from "../../../../action-types/on-boarding"
import buildAction                      from "../../../../helpers/buildAction"
import SearchBar                        from "../../../../components/page/search/search-bar/index.js"
import GlobalSearch                     from "../../search"
import OnBoarding                       from "../../on-boarding"

class PrivateView extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.onModalExternalClick                       = this.onModalExternalClick.bind(this)
        this.setSwitchLibraryFlag                       = this.setSwitchLibraryFlag.bind(this)
        this.setSwitchLibraryFlagAfterLibrarySwitch     = this.setSwitchLibraryFlagAfterLibrarySwitch.bind(this)
        this.state =
        {
            switchLibrary: false
        }
    }

    setSwitchLibraryFlagAfterLibrarySwitch()
    {
        this.setState({switchLibrary: false})
    }

    setSwitchLibraryFlag()
    {
        let {currentEnv}  = this.props.on_boarding
        this.setState({switchLibrary: true})
    }

    componentWillMount()
    {
        const {dispatch} = this.props
        dispatch(buildAction(COMPANY.GET_CURRENT_COMPANY))
        let user = this.props.user.data
        let displayBlueNotification = user.onboarding.onBoardingNotification && user.login.length > 1 && user.currentEnv === "LIVE" && user.onboarding.completedSteps.length === 0

        if (displayBlueNotification || user.currentEnv === "SANDBOX")
        {
            document.body.addEventListener("click", this.onModalExternalClick)
        }
    }

    onModalExternalClick(event)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ON_BOARDING.SET_ON_BOARDING_NOTIFICATION, false));
        dispatch(buildAction(ON_BOARDING.SHOW_ENV_INFO, false));
        document.body.removeEventListener("click", this.onModalExternalClick)
    }

    // signOut(event)
    // {
    //     event.preventDefault();
    //     const { dispatch } = this.props;
    //     dispatch(buildAction(USER.LOGOUT, {}));
    // }

    // createMenuOption(label, icon, path, labelWapperClass='', border_bottom=false, disabled=false)
    // {
    //     let option = {
    //         label   : label,
    //         icon    : icon,
    //         to      : path.pathname,
    //         state   : path.state,
    //         labelWapperClass,
    //         border_bottom,
    //         disabled
    //     }
    //     return option
    // }

    render()
    {
        // let avatarSrc = this.props.user && this.props.user.data && this.props.user.data.avatar && this.props.user.data.avatar.src
        let branding = null
        if(this.props.on_boarding.currentEnv === "SANDBOX")
        {
            branding =
                <div>
                    <img className="sandbox-logo" src={sandboxLogo} alt="" />
                    <img className="company-sandbox-logo" src={logo} alt="" />
                </div>
        }
        else if(this.props.on_boarding.currentEnv === "LIVE")
        {
            let logoSrc   = this.props.company && this.props.company.logo_src
            branding = logoSrc
                ? <img className="company-logo" src={logoSrc} alt="" />
                : <div className="company-name">{this.props.company.name}</div>
        }

        let {currentEnv} = this.props.user.data
        let markup =

            <div className="private-view">
                {
                    currentEnv === "SANDBOX" &&
                    <div className="sanbox-header-bar">
                        <div className="sanbox-msg">
                            You’re in Sandbox. <span className="exit-sandbox-btn" onClick={this.setSwitchLibraryFlag}>Exit Sandbox<InlineIcon><ExitArrowSrc/></InlineIcon></span>
                        </div>
                    </div>
                }

                <div className="top-row">
                    <div className="branding">
                        <Link to="/dashboard">
                            {branding}
                        </Link>
                    </div>
                    <SearchBar search={this.props.search} location={this.props.location} history={this.props.history}/>
                    <GlobalSearch search={this.props.search} location={this.props.location} history={this.props.history}/>
                    <OnBoarding {...this.props}
                        switchLibrary={this.state.switchLibrary}
                        setSwitchLibraryFlagAfterLibrarySwitch={this.setSwitchLibraryFlagAfterLibrarySwitch}
                    />
                </div>
            </div>
        return markup
    }
}

// export default connect((store) => store)(PrivateView)
export default connect((store) => ({
    user: store.user,
    company: store.company,
    on_boarding: store.on_boarding
}))(PrivateView)
