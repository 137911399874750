import { SvgIcon } from "@mui/material";

export const TreeExpandIcon = () => (
  <SvgIcon viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "16px",
    height: "16px",
  }}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path fill="#CBCBCB" d="M5 14V2l6 6z" />
    </g>
  </SvgIcon>
);
