import UI                           from "../../../action-types/ui"
import PRODUCT                      from "../../../action-types/product"
import ITEM_ASSEMBLY                from "../../../action-types/item-assembly"
import SEARCH                       from "../../../action-types/search"
import ON_BOARDING                  from "../../../action-types/on-boarding"
import buildAction                  from "../../../helpers/buildAction"
import API                          from "../../../modules/api"
import Utils                        from "../../../modules/utils"
import {privatePaths}               from "../../../app/routes.js"
import { getProduct, getUser, getItemAssemblyId }      from "./selector"
import
{
    takeEvery,
    takeLatest,
    all,
    cps,
    put,
    call,
    select
}                                  from "redux-saga/effects"

import { findProductAndSetAssembly }   from "../../item-assembly"

export function* findProductById(action)
{
    try
    {
        let product = window.__userRole !== "VENDOR" ? 'products' : 'productRevision';
        product = yield cps(API[product].findById, action.payload.id)

        if (!product)
        {
            action.payload.history.push({pathname: "/search", state: {query: "type:prd"}})
        }
        else
        {
            let urlParams  = new URLSearchParams(window.location.search);
            let viewRecent = urlParams.has('viewRecent') ? urlParams.get('viewRecent') : null;
            if(product.revisions.length > 1 && window.__userRole === "SUPPLIER" &&!viewRecent)
            {
                let lastReleasedRev = Utils.getLastReleasedRevision(product.revisions);
                action.payload.history.push(`/product/revision/${lastReleasedRev._id}`);
            }
            if(product.documents && product.documents.length == 0){
                yield put(buildAction(PRODUCT.SET_PRODUCT_DOCUMENTS_LOADED, true ));
            }

            if (window.__userRole !== 'VENDOR' && product.variantGroup)
            {
                let variants = yield cps(API.products.getProductVariants, product.variantGroup, {})
                product.variants = variants
            }
            const isFavorite = window.__userRole !== 'VENDOR' ? product.creator.preferences.favorite.products.indexOf(product._id) > -1 : null;

            let payload = {
                product: product,
                isFavorite: isFavorite
            }

            yield put(buildAction(PRODUCT.SET_PRODUCT_IN_VIEW_PAGE, payload))
            let selectedProductId = yield select(getItemAssemblyId)
            if (selectedProductId !== product._id)
            {
                //load product tree
                yield put (buildAction(ITEM_ASSEMBLY.GET_ITEM_ASSEMBLY_LIST,{id: product._id}))
                let selectedTextForProductList = (product.cpn) + " " + product.name
                let treePayload = {selectedTextForProductList, _id: product._id, class: "close", buttonHighlight: true}
                yield put(buildAction(ITEM_ASSEMBLY.SET_SELECTED_TEXT_FOR_PRODUCT_LIST, treePayload))
            }
        }

        if (action.payload.onBoarding)
        {
            let getSampleProductAction = {payload: {id: product._id}}
            yield call(findProductAndSetAssembly, getSampleProductAction);
            yield put(buildAction(ON_BOARDING.SET_LOADING, false));
            let selectedTextForProductList = (product.cpn) + " " + product.name
            let payload = {selectedTextForProductList, _id: product._id, class: "close", buttonHighlight: true}
            yield put(buildAction(ITEM_ASSEMBLY.SET_SELECTED_TEXT_FOR_PRODUCT_LIST, payload))
            let productList = []
            productList.push(product)
            yield put(buildAction(ITEM_ASSEMBLY.SET_PRODUCT_LIST, {productList: productList}));
        }

    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.productSearch)
                            }
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
        yield put(buildAction(ON_BOARDING.SET_LOADING, false));
    }
}

export function* findPorductNestedDataById(action)
{
    try
    {
        const product = yield cps(API.products.findById, action.payload.id);
        if (!product)
        {
            action.payload.history.push({pathname: "/search", state: {query: "type:prd"}});
        }
        else
        {
            let {documents} = product;
            yield put(buildAction(PRODUCT.SET_PRODUCT_NESTED_DOCUMENTS, documents));
            yield put(buildAction(PRODUCT.SET_PRODUCT_DOCUMENTS_LOADED, true ));
        }
    }
    catch(err)
    {

    }
}

export function* duplicate(action)
{
    yield put(buildAction(UI.LOAD_START))
    let productId = action.payload.productId
    try
    {
        const duplicate = yield cps(API.products.createDuplicate, {id: productId});
        action.payload.history.push({pathname: (`/product/edit/${duplicate}`), showAll: true})
        yield put(buildAction(PRODUCT.RESET_PRODUCT_EDIT_FORM, {}))
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, true ))
        yield put(buildAction(PRODUCT.SET_IS_NEW_OBJECT_FLAG, true ))
        yield put(buildAction(PRODUCT.SET_ORIGNAL_PRODUCT_ID, productId ))
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))

    }
    yield put(buildAction(UI.LOAD_END))
}

export function* createVariant(action)
{
    yield put(buildAction(UI.LOAD_START))
    let productId = action.payload.productId

    try
    {
        const variantId = yield cps(API.products.createVariant, productId)
        action.payload.history.push({pathname: (`/product/edit/${variantId}`), showAll: true, isVariant: true})
        yield put(buildAction(PRODUCT.RESET_PRODUCT_EDIT_FORM, {}))
        yield put(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, true ))
        yield put(buildAction(PRODUCT.SET_IS_NEW_OBJECT_FLAG, true ))
        yield put(buildAction(PRODUCT.SET_ORIGNAL_PRODUCT_ID, productId))
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))

    }
    yield put(buildAction(UI.LOAD_END))
}

export function* revertChanges(action)
{
    try
    {
        if(typeof(action.payload.productRevisionId) === 'object')
        {
            action.payload.productRevisionId = action.payload.productRevisionId._id;
        }
        const revisionData = yield cps(API.productRevision.findById, action.payload.productRevisionId)
        let currentProduct = yield select(getProduct)
        let product = Utils.clone(currentProduct)

        product.name               = revisionData.name
        product.revision           = revisionData.revision
        product.status             = revisionData.status
        product.eid                = revisionData.eid
        product.description        = revisionData.description
        product.children           = revisionData.children
        product.manufacturers      = revisionData.manufacturers
        product.forecasts          = revisionData.forecasts
        product.modified           = false
        product.team               = revisionData.team
        product.images             = revisionData.images
        product.documents          = revisionData.documents
        product.cpn                = revisionData.cpn
        product.cpnVariant         = revisionData.cpnVariant
        product.previousStatus     = revisionData.status
        product.previousRevision   = revisionData.revision
        product.revertFlag       = true
        product.revisions.pop();
        product.cpnId = revisionData.cpnId

        const productId = yield cps(API.products.update, product._id, product)
        yield put(buildAction(PRODUCT.RESET_STATES_IN_VIEW_PAGE, {} ))
        yield put(buildAction(PRODUCT.GET_PRODUCT_IN_VIEW_PAGE, {id: `${productId}?include=children,creator,images`}))

        let selectedTextForProductList = (product.cpn) + " " + product.name
        let payload = {selectedTextForProductList, _id: product._id, class: "close", buttonHighlight: true}

        yield put(buildAction(ITEM_ASSEMBLY.SET_SELECTED_TEXT_FOR_PRODUCT_LIST, payload))
        yield put(buildAction(SEARCH.CHANGE_SEARCH_TYPE, { state: {query: "type:prd"} }));

    }
    catch(err)
    {
        let product = yield select(getProduct);

        let payload =
        {
            type: "errors",
            errors: err.errors,
            err
        };

        if (err.errors[0].message.includes('This Product is in an unresolved Change Order with Status:'))
        {
            payload.errorType = "custom";
            payload.closeCb = () => { Utils.redirectToParentRoute(`/product/view/${product._id}`) };
            payload.confirmBtnText = "OK";
            payload.errorHeading = "Error";
        }

        yield put(buildAction(UI.SHOW_ALERT, payload));
        yield put(buildAction(UI.LOAD_END));
    }
}

export function* toggleFavorite(action)
{
   let user    = yield select(getUser)
   let product = yield select(getProduct)
   product     = product ? product : action.payload.product;
   let id      = product._id
   let favs    = user.preferences.favorite.products
   let index   = favs.indexOf(id)
   index > -1 ? favs.splice(index, 1) : favs.push(id)
   try
   {
       yield cps(API.users.update, user._id, user)
       const newUser = yield cps(API.users.getCurrent)
       const isFavorite = !!(newUser.preferences.favorite.products && newUser.preferences.favorite.products.indexOf(product._id) > -1)
       let payload = {
           isFavorite: isFavorite
       }
       yield put(buildAction(PRODUCT.SET_PRODUCT_FAV_IN_VIEW_PAGE, payload))
   }
   catch(err)
   {
       yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
   }
}

export function* setPrimarySource(action)
{
    let data = action.payload
    yield put(buildAction(UI.LOAD_START))

    try
    {
        const productId = yield cps(API.products.update, data._id, data.primarySource);
        yield put(buildAction(PRODUCT.RESET_STATES_IN_VIEW_PAGE, {} ));
        yield put(buildAction(PRODUCT.GET_PRODUCT_IN_VIEW_PAGE, {id: `${productId}?include=children,documents,creator,images`}));
    }

    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
    yield put(buildAction(UI.LOAD_END))


}

export default function* (getState)
{
    yield all([
        takeEvery(PRODUCT.GET_PRODUCT_IN_VIEW_PAGE, findProductById),
        takeLatest(PRODUCT.DUPLICATE, duplicate),
        takeLatest(PRODUCT.REVERT_CHANGES, revertChanges),
        takeLatest(PRODUCT.TOGGLE_FAVORITE, toggleFavorite),
        takeLatest(PRODUCT.SET_PRIMARY_SOURCE, setPrimarySource),
        takeLatest(PRODUCT.CREATE_VARIANT, createVariant),
        takeLatest(PRODUCT.GET_PRODUCT_NESTED_DATA, findPorductNestedDataById),
    ])
}
