import { datadogRum } from "@datadog/browser-rum";
import { userById } from "graphql/query/userQueries";
import ReactGA from "react-ga";
import config from "v1/modules/config";
import { User } from "./models";

/**
 * Sets the user information in the DataDog RUM session, or clears it if no user passed in.
 *
 * @param user The user to assign to the current session.
 */
function setUserForDataDogSession(user?: User) {
  if (typeof user?.id === "string") {
    datadogRum.setUser({
      company: user.primaryCompany?.id,
      email: user.email,
      id: user.id,
      role: user.role,
    });
  }
  else {
    datadogRum.clearUser();
  }
  userById.onNextChange(setUserForDataDogSession);
}

if ((window as any)?.DURO_ENV?.datadog) {
  datadogRum.init((window as any).DURO_ENV.datadog);
  datadogRum.startSessionReplayRecording();
  userById.onNextChange(setUserForDataDogSession);
}

ReactGA.initialize([
  {
    trackingId: config.GA_KEY_FOR_PRIVATE_ROUTES,
    gaOptions: { name: "public_tracker" },
  },
  {
    trackingId: config.GA_KEY,
    gaOptions: { name: "public_tracker" },
  },
], { debug: false, alwaysSendToDefaultTracker: false });

export function analyticsAndTracking(location: Location, user: any) {
  const _window = window as any;
  const publicRoutes = [
    "/legal/terms",
    "/legal/policy",
    "https://duro.zendesk.com/hc/en-us",
  ];
  let intervalTimer: NodeJS.Timer;
  let retries = 0;

  if (config.CLIENT_ENV === "MFG") {
    if (publicRoutes.includes(location.pathname)) {
      ReactGA.pageview(location.pathname, ["public_tracker"]);
    }
    else {
      // Retry in case of race condition
      intervalTimer = setInterval(() => {
        try {
          // user.id will throw if user is undefined
          ReactGA.set({ userId: user.id }, ["private_tracker"]);
          ReactGA.pageview(location.pathname, ["private_tracker"]);

          _window.Appcues.identify(user?.id);
          clearInterval(intervalTimer);
        }
        catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          retries++;
          if (retries > 5) {
            clearInterval(intervalTimer);
          }
        }
      }, 2000);
    }
  }
}
