import { forwardRef, ReactNode } from "react";
import Checkbox from "@mui/material/Checkbox";
import { CheckboxFilledIcon } from "assets/icons/checkboxFilled";
import { CheckboxUnfilledIcon } from "assets/icons/checkboxUnfilled";

export const DuroCheckbox = forwardRef<ReactNode>(props => (
  <Checkbox
    checkedIcon={<CheckboxFilledIcon />}
    icon={<CheckboxUnfilledIcon />}
    {...props}
  />
));
