import                        "./index.css";
import React, {useState, useEffect} from "react";
import ReactTooltip from "react-tooltip";
import MandatoryModal    from "./mandatory-co-modal";
import ToggleBtn         from "../../../../ui/toggle-btn";
import InlineIcon        from "../../../../ui/icon/inline-icon.js";
import LockOpenIcon      from "../../../../../assets/icons/lock-open.js";
import LockCloseIcon     from "../../../../../assets/icons/lock-close.js";
import API               from "../../../../../modules/api"
import Schemas,{permissions as Permissions} from "../../../../../modules/schemas";

const MandatoryApprovalComp = (props) =>
{
    const {company, templateMappings, isSetupStage, finalizeSetupStage} = props
    const [isToggleEnabled, setToggle] = useState(company.settings.configurations.coMandatoryToggleEnabled);
    const [isModalVisible, setModalVisibility] = useState(false);
    let toggelValue = isToggleEnabled ? 'On' : 'Off' ;
    const actionAllowed = Permissions.can('edit', 'configurations', props.user);

    const changeStateOfToggle = () => {
        const data = {settings: {configurations:{coMandatoryToggleEnabled:!isToggleEnabled }}}
        setToggle(!isToggleEnabled);
        window.__isCoMandatoryToggleEnabled = !isToggleEnabled;
        API.companies.update(company._id, data,(err, response) => {
            if (err)
                console.log(err);
        })
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [templateMappings]);

    const showModal = () => setModalVisibility(true);

    const hideModal = () => setModalVisibility(false);

    return(
        <div className={`mandatory-approval`}>
            <div className={`${!actionAllowed || !isToggleEnabled ? 'disabled' : ''} mandatory-block-first`}>
                <div className='block-heading'>
                    <InlineIcon
                        className='mandatory-lock-icon'
                        tooltip={!actionAllowed ? 'Administrator access only' : ''}
                    >
                    {
                        actionAllowed ? <LockOpenIcon/> : <LockCloseIcon/>
                    }
                    </InlineIcon>
                    <h2>Mandatory Approver Templates</h2>
                </div>
                <div>
                    <p>Use approver templates to require the mandatory
                    approval of Change Orders by select users. Unique
                    templates can be assigned for ECOs, MCOs, and DCOs,
                    and based on product and component status.
                    </p>
                </div>
                <button
                  className={`green new-button ${!actionAllowed || !isToggleEnabled ? 'disabled' : ''}`}
                  onClick={() => actionAllowed && isToggleEnabled && showModal()}
                >
                    {isSetupStage ? 'SET UP' : 'EDIT'}
                </button>
            </div>
            <div className={`${!isToggleEnabled ? 'disabled' : ''} mandatory-block-second`}>
                <ToggleBtn
                  name='toggle-btn'
                  selected= {isToggleEnabled}
                  onChange={() => changeStateOfToggle()}
                  disabled={!actionAllowed}
                />
                <span className={`toggel-value ${!isToggleEnabled ? 'disabled' : ''}`}>{toggelValue}</span>
            </div>
            {
                isModalVisible &&
                <MandatoryModal
                    hideModal={hideModal}
                    templates={props.templates}
                    templateMappings={templateMappings}
                    finalizeSetupStage={finalizeSetupStage}
                />
            }
        </div>
    )
}

export default MandatoryApprovalComp;
