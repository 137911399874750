import { SvgIcon } from "@mui/material";

export const SwitchWorkspaceIcon = () => (
  <SvgIcon viewBox="0 0 23.934 24" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "1.496rem",
    height: "1.5rem",
  }}>
    <path d="M0 13.03v-1.994h12.166L8.244 7.114 9.74 5.618l6.415 6.415L9.74 18.45l-1.496-1.496
    3.922-3.923H0zm1.994 1.995v6.98H21.94V1.996H1.994v7.047H0V1.994C0 1.463.2.997.598.598.998.2
    1.463 0 1.994 0H21.94c.532 0 .997.2 1.396.598.4.4.599.865.599 1.396v20.012c0 .531-.2.997-.599
    1.396-.399.399-.864.598-1.396.598H1.994c-.531 0-.997-.2-1.396-.598C.2 23.002 0 22.537 0
    22.006v-6.981h1.994z" fill="#D8D8D8" fillRule="evenodd" />
  </SvgIcon>
);
