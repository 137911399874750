import { useCompany } from "graphql/query/companyQueries";
import { useUser } from "graphql/query/userQueries";

export function useIsBuildEnabled() {
  const { data: user, loading: userLoading } = useUser();

  const { data: company, loading: companyLoading } = useCompany(user?.primaryCompany?.id);

  return {
    isBuildEnabled: !!company?.settings?.isBuildEnabled,
    loading: userLoading || companyLoading,
  };
}
