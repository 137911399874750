import React, { ComponentType, ElementType, FunctionComponent, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useLogoutFunction } from "@propelauth/react";
import USER from "v1/action-types/user";
import buildAction from "v1/helpers/buildAction";
import Utils from "v1/modules/utils";

export interface IDuroMenuOption {
  icon?: ElementType<any>
  isSignOut: boolean
  label: string
  onClick: (event: any) => void
  shortcut?: string
  state?: any
  target?: string
  to?: string
}

export interface IDuroDividerOption {
  isDivider: boolean
}

export interface IDuroMenuProps {
  anchorComponent: ComponentType
  customButtonStyles: any
  name?: string
  options: (IDuroMenuOption | IDuroDividerOption)[]
}

export const DuroMenu: FunctionComponent<IDuroMenuProps> = (props: IDuroMenuProps) => {
  const {
    anchorComponent,
    name = "",
    options,
    customButtonStyles = {},
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useLogoutFunction();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const menuOptions = useMemo(() => options?.map((opt: IDuroMenuOption | IDuroDividerOption, i: number) => {
    if ((opt as IDuroDividerOption).isDivider) {
      return (<Divider key={i} />);
    }

    const {
      icon: Icon, // must uppercase for TS
      isSignOut,
      label,
      onClick,
      target,
      to,
      shortcut,
      state,
    } = opt as IDuroMenuOption;

    const _onClick = (event: any) => {
      // Signout method is here because parent is a class component and can't use hooks.
      // Move this out when parent is fixed.
      if (isSignOut) {
        dispatch(buildAction(USER.LOGOUT, {})); // legacy logout
        Utils.headers = null;
        logout(true);
      }
      if (onClick) {
        onClick(event);
      }
      if (to) {
        if (target) {
          window.open(to, target);
        }
        else {
          history.push({
            pathname: to,
            state,
          });
        }
      }
      handleClose();
    };

    return (
      <MenuItem onClick={_onClick} key={i} data-testid={label}>
        <ListItemIcon>
          {!!Icon && <Icon />}
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>
        {!!shortcut && (
          <Typography variant="body2" color="text.secondary">
            {shortcut}
          </Typography>
        )}
      </MenuItem>
    );
  }), [dispatch, handleClose, history, logout, options]);

  const menuButtonId = `duro-menu-button-${name}`;
  const menuId = `duro-menu-${name}`;
  const menuListId = `duro-menu-list-${name}`;
  return (
    <>
      <Button
        aria-controls={open ? menuButtonId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        data-testid={menuButtonId}
        onClick={handleClick}
        style={{ ...buttonStyle, ...customButtonStyles }}
      >
        {anchorComponent}
      </Button>
      <Menu
        anchorEl={anchorEl}
        data-testid={menuId}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": menuId }}
        PaperProps={{
          sx: {
            "& .MuiListItemIcon-root": {
              height: "14px",
              width: "14px",
              "& svg": {
                height: "14px",
                width: "14px",
                stroke: "#FFFFFF",
              },
            },
          },
        }}
      >
        <MenuList data-testid={menuListId}>
          {menuOptions}
        </MenuList>
      </Menu>
    </>
  );
};

// Styles
const buttonStyle = {
  backgroundColor: "transparent",
  border: 0,
  minWidth: 0,
  padding: 0,
  width: "2.5rem",
};
