import React from "react";

function Icon() {
  return (
    <svg id="release-icon" width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1-LIST" transform="translate(-44.000000, -312.000000)" fill="#3CD1B5" fillRule="nonzero">
                <g id="NAV" transform="translate(0.000000, 70.000000)">
                    <g id="ToolBar" transform="translate(44.000000, 242.000000)">
                        <g id="Group-2">
                            <g id="RELEASE">
                                <g id="Release">
                                    <path d="M14.6166667,1.85833333 L13.4583333,0.458333333 C13.2333333,0.175 12.8916667,0 12.5,0 L2.5,0 C2.10833333,0 1.76666667,0.175 1.53333333,0.458333333 L0.383333333,1.85833333 C0.141666667,2.14166667 0,2.51666667 0,2.91666667 L0,13.3333333 C0,14.25 0.75,15 1.66666667,15 L13.3333333,15 C14.25,15 15,14.25 15,13.3333333 L15,2.91666667 C15,2.51666667 14.8583333,2.14166667 14.6166667,1.85833333 Z M2.7,1.25 L12.3,1.25 L12.975,2.4625 L2.03333333,2.4625 L2.7,1.25 Z M1.25,13.75 L1.25,3.75 L13.75,3.75 L13.75,13.75 L1.25,13.75 Z M4.58333333,7.54166667 L4.58333333,9.95833333 L7.08333333,9.95833333 L7.08333333,12.0833333 L10.4166667,8.75 L7.08333333,5.41666667 L7.08333333,7.54166667 L4.58333333,7.54166667 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default Icon;
