import { PageWrapper } from "build/components/pageWrapper";
import { useIsBuildEnabled } from "build/hooks/isBuildEnabled";
import React from "react";
import { useParams } from "react-router-dom";

interface BuildLotRouteParams {
  lotId: string;
}

export interface BuildLotProps {
  isEditMode?: boolean;
}

export function BuildLot({ isEditMode }: BuildLotProps) {
  const { lotId } = useParams<BuildLotRouteParams>();
  const { isBuildEnabled, loading: isBuildEnabledLoading } = useIsBuildEnabled();

  return (
    <PageWrapper
      isBuildEnabled={isBuildEnabled}
      isLoading={isBuildEnabledLoading}
    >
      This is the Lot {lotId}! It is in {isEditMode ? "edit" : "view"} mode
    </PageWrapper>
  );
}
