import                      "./index.css"
import React, {Component} from "react"
import {connect}          from "react-redux"
import Icon               from "../icon"
import closeSrc           from "../../../assets/icons/close.svg"
import buildAction        from "../../../helpers/buildAction"
import CILoading          from "../component-inner-loading"
import LazyInput          from "../lazy-input/input.js"
import InlineIcon         from "../icon/inline-icon.js"
import SearchIcon         from "../../../assets/icons/search.js"
import ReactTooltip       from 'react-tooltip'
import Spinner            from "../../../assets/icons/spinner-green.js"
import EllipsisTooltip    from "../../page/common/ellipsis-tooltip";
import Tooltip            from 'rc-tooltip';
import SearchListArea     from "./search-list-area";

class RightSearchMenu extends Component
{
    constructor(props)
    {
        super(props)
        if(!window.location.pathname.match("/changeorder/new") && !window.location.pathname.match("/changeorder/edit"))
        {
            this.mode = 'itemAssembly'
        }
        else{
            this.mode = 'changeOrder'
        }

        this.state =
        {
            selected: 0,
            added_flag: false,
            page: 1,
            renderList: true,
            modal :
            {
                open    : false,
                width   : 270,
                minWidth: 270,
            },
            minWidth: 270,
            maxWidth: 405,
            exportMenuWidth: 270
        }
        this.onMouseDown            = this.onMouseDown.bind(this)
        this.onMouseMove            = this.onMouseMove.bind(this)
        this.onMouseLeave           = this.onMouseLeave.bind(this)
        this.onSearchFocus          = this.onSearchFocus.bind(this)
        this.onSearchFocusOut       = this.onSearchFocusOut.bind(this)
        this.updateHeight           = this.updateHeight.bind(this)
        this.getDisabledClazz       = this.getDisabledClazz.bind(this)
        this.onScroll               = this.onScroll.bind(this)
        this.add                    = this.add.bind(this)
    }

    componentWillUpdate()
    {
        this.updateHeight()
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.page !== this.props.page)
        {
            this.setState({ page: nextProps.page })
        }

        let inputs = nextProps.search.inputs
        let selected = 0;

        if (this.mode === 'changeOrder'){
            for(let i =0; i < inputs.length ; i++)
            {
                let input = inputs[i]
                if (!input.isAddedInList && input.checked)
                {
                    selected++
                }
            }
        }
        else {
            for(let i =0; i < inputs.length ; i++)
            {
                let input = inputs[i]
                if(input.checked && !input.isAddedInList){
                    selected++
                }
            }
        }

        if(this.state.selected!=selected){
            this.setState({selected: selected})
        }
        if (this.state.added_flag)
        {
            for(let i =0; i < inputs.length ; i++)
            {
                if(inputs[i].isAddedInList){
                    this.setState({added_flag: false})
                    break
                }
            }
        }

    }

    updateHeight()
    {
        let topHeaderHeight = document.querySelector(".private-view .top-row").offsetHeight
        let heightLimit = (window.innerHeight - topHeaderHeight) + "px"
        let minHeightLimit = heightLimit
        document.getElementsByClassName("right-search-expand")[0].style.height = heightLimit
        document.getElementsByClassName("right-search-expand")[0].style.minHeight = minHeightLimit
    }

    onSearchFocus()
    {
        let state = this.state
        state.searchFocused = true
        this.setState(this.state)
    }

    onSearchFocusOut()
    {
        let state = this.state
        state.searchFocused = false
        this.setState(this.state)
    }

    onMouseDown(e)
    {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e)
    {
        let position = document.body.offsetWidth - e.pageX
        let state = this.state;
        let isExportSearch = (this.props.name === "export-search-bar");
        if (isExportSearch || position < 500)
        {
            if(isExportSearch)
            {
                position = position-state.exportMenuWidth;
            }
            position = position > 404 ? 405 : position;
            if (state.modal.width !== e.pageX){
                state.modal.class    = ""
                state.modal.width    = position
                state.modal.minWidth = position
                state.renderList     = false;
                this.setState(state);
            }
            e.stopPropagation()
            e.preventDefault()
        }
    }

    onMouseLeave(e)
    {
        let state = this.state;
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        state.renderList = true;
        e.preventDefault()
        e.stopPropagation()
        e.preventDefault()
        state.modal.minWidth = state.modal.width >= 320 ? state.maxWidth : state.minWidth;
        this.setState(state);
    }

    getDisabledClazz()
    {
        let disabledClazz = "disabled"
        let inputs = []
        if (this.props.name === "prd-cmp-list-for-co")
        {
            inputs = this.props.search.inputs
        }
        else
        {
            inputs = this.props.search.inputs
        }

        inputs.forEach((input) =>
        {
            if (input.checked && !input.isAddedInList)
            {
                disabledClazz = ""
                return
            }
        })
        if(this.props.disableAll)
        {
            disabledClazz = 'disabled';
        }
        return disabledClazz
    }

    onScroll(event)
    {
        var node = event.target;
        if(node)
        {
            let inputs = [];
            let sub_height = node.scrollHeight - node.scrollTop;
            let page = this.state.page
            if (sub_height <= node.clientHeight + 160 && this.props.page <= this.props.max_pages && page === this.props.page)
            {
                this.setState({page: this.state.page + 1})
                this.props.getDataFromAPI()
            }
        }

    }

    add(){

        let state = this.state
        state.added_flag = true
        this.setState(state)

        let _this = this
        setTimeout(function(){
            _this.props.add()
        }, 300);

    }

    componentDidUpdate()
    {
        ReactTooltip.rebuild()
    }

    render()
    {
        let modal  = this.state.modal
        let loading = this.props.loading_flag
        let coId, search, inputs, mode
        let addBtnClazz = this.getDisabledClazz()
        mode = this.mode
        if (mode === 'changeOrder')
        {
            coId = this.props.coId
        }
        let {disableAll} = this.props;
        search = this.props.search
        inputs = this.props.search.inputs

        let innerDiv = <div id="right-list-content">
                        <div className="header-block">
                            <h2 className={this.props.customClass}>{this.props.heading}</h2>
                        </div>

                        <div className="searchListHolder" ref="searchNode">
                            <div className={"search-area-holder " + (this.state.searchFocused ? "active" : "")}>
                                <InlineIcon>
                                    <SearchIcon/>
                                </InlineIcon>
                                <LazyInput
                                    type="text"
                                    value={search.value}
                                    onChange={(e) => this.props.onSearchChange(e)}
                                    onFocus={this.onSearchFocus}
                                    onBlur={this.onSearchFocusOut}
                                    placeholder="Enter a search term or query"
                                />
                            </div>


                            <div className={search.count > 0 ? "visible" : ""}>
                                <div className="result-count">
                                    <span > Returned  { loading ? '0' : search.count } results </span>
                                    <span className="selected_count"> { loading ? '0' : this.state.selected} Selected </span>
                                </div>
                                <div className="select-all-area">
                                    <div className="select-all-holder">
                                        <div>
                                            <input
                                                className={`checked-input ${disableAll ? ' disabled' : ''}`}
                                                type="checkbox"
                                                name="category-check-all"
                                                checked={search.selectAllFlag}
                                                onChange={disableAll ? null : (e) => this.props.selectAll()}
                                            />
                                            <label
                                                className={`checked-label ${search.selectAllButoonClazz} ${disableAll ? ' disabled' : ''}`}
                                                htmlFor="category-check-all"
                                            />
                                            <span className="select-all-label">SELECT ALL</span>
                                        </div>
                                        <button
                                            onClick={this.add}
                                            type="button"
                                            className={"btn-add " + addBtnClazz}
                                            >
                                            {
                                                this.state.added_flag ?
                                                    <div>
                                                        <div className="spinner">
                                                            <InlineIcon>
                                                                <Spinner/>
                                                            </InlineIcon>
                                                        </div>
                                                        <span>ADD</span>
                                                    </div>
                                               : 'ADD'}
                                        </button>

                                    </div>
                                </div>
                                <SearchListArea
                                renderList={this.state.renderList}
                                loading={loading}
                                inputs={inputs}
                                mode={mode}
                                disableDesignResults={this.props.disableDesignResults}
                                disableAll={disableAll}
                                coId={coId}
                                onInputChange={this.props.onInputChange}
                                />
                            </div>
                        </div>
                    </div>;
        let markup =
            <div
                ref={ (modalNode) => this.modalNode = modalNode}
                className="modal open right-search-menu"
                style={{width: modal.width+"px", minWidth: modal.minWidth+"px"}}
                onScroll={this.onScroll}
                >
                <div
                    className="right-search-expand"
                    onMouseDown={(e) => this.onMouseDown(e)}
                >
                    <div className="border"/>
                </div>
                {
                    this.props.onModalClose ?
                    <div className="close-icon" onClick={this.props.onModalClose}>
                        <Icon
                            src={closeSrc}
                        />
                    </div> :
                    null
                }
                {
                    disableAll ?
                    <Tooltip
                        placement={"right"}
                        overlayClassName={"disabled-all-tooltip"}
                        getTooltipContainer={() => document.querySelector(".routes")}
                        overlay={ <div>
                                    <span className="disbale-tooltip-text">Modifications are not permitted to Change Orders generated from 3rd party applications.</span>
                                  </div>
                                }
                    >
                        {innerDiv}
                    </Tooltip>
                    :
                    innerDiv
                }
            </div>
        return markup
    }
}

export default RightSearchMenu
