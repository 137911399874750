export const getHeadings = (headings, headingsProps) =>
  headings.map((headingItem, headingIdx) => ({
    ...headingItem,
    position: headingItem.position || headingIdx,
    ...(headingsProps ? headingsProps[headingItem.key] : {}),
  }));

export const HEADINGS = {
  cpn: {
    key: "cpn",
    displayName: "CPN",
    tooltip: "CPN",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 350,
    visibility: true,
    disabled: true,
  },
  quantity: {
    key: "quantity",
    displayName: "QTY",
    tooltip: "QTY",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  waste: {
    key: "waste",
    displayName: "Waste %",
    tooltip: "Waste",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 100,
    visibility: true,
    disabled: false,
  },
  extendedQuantity: {
    key: "extendedQuantity",
    displayName: "Extended Quantity",
    tooltip: "Extended Quantity",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 100,
    visibility: true,
    disabled: false,
  },
  extendedCost: {
    key: "extendedCost",
    displayName: "Extended Cost",
    tooltip: "Extended Cost",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 100,
    visibility: true,
    disabled: false,
  },
  level: {
    key: "level",
    displayName: "Level",
    tooltip: "Level",
    sortable: true,
    ascending: true,
    minWidth: 62,
    width: 62,
    visibility: true,
    disabled: false,
  },
  images: {
    key: "images",
    displayName: "Images",
    tooltip: "Images",
    sortable: false,
    ascending: true,
    minWidth: 90,
    width: 90,
    visibility: true,
    disabled: false,
  },
  category: {
    key: "category",
    displayName: "Category",
    tooltip: "Category",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 151,
    visibility: true,
    disabled: false,
  },
  procurement: {
    key: "procurement",
    displayName: "Procurement",
    tooltip: "Procurement",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    disabled: false,
  },
  cmpState: {
    key: "cmpState",
    displayName: "Workflow State",
    tooltip: "Workflow State",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    disabled: false,
  },
  revision: {
    key: "revision",
    displayName: "Revision",
    tooltip: "Revision",
    sortable: true,
    ascending: true,
    minWidth: 120,
    width: 135,
    visibility: true,
    disabled: false,
  },
  refDes: {
    key: "refDes",
    displayName: "Ref Des",
    tooltip: "Ref Des",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 120,
    visibility: true,
    disabled: false,
  },
  itemNumber: {
    key: "itemNumber",
    displayName: "Item Number",
    tooltip: "Item Number",
    sortable: true,
    ascending: true,
    visibility: true,
    disabled: false,
  },
  eid: {
    key: "eid",
    displayName: "EID",
    tooltip: "EID",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  status: {
    key: "status",
    displayName: "Status",
    tooltip: "Status",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  notes: {
    key: "notes",
    displayName: "Notes",
    tooltip: "Notes",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  mass: {
    key: "mass",
    displayName: "MASS (g)",
    tooltip: "MASS (g)",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    headingClass: "mass-heading",
    disabled: false,
  },
  unitOfMeasure: {
    key: "unitOfMeasure",
    displayName: "Unit Of Measure",
    tooltip: "Unit Of Measure",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  unitPrice: {
    key: "unitPrice",
    displayName: "Unit Price",
    tooltip: "Unit Price",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    disabled: false,
  },
  totalPrice: {
    key: "totalPrice",
    displayName: "Total Price",
    tooltip: "Total Price",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  leadTime: {
    key: "leadTime",
    displayName: "Lead Time",
    tooltip: "Lead Time",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  description: {
    key: "description",
    displayName: "Description",
    tooltip: "Description",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    disabled: false,
  },
  mpn: {
    key: "mpn",
    displayName: "MPN",
    tooltip: "MPN",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    disabled: false,
  },
  manufacturer: {
    key: "manufacturer",
    displayName: "Manufacturer",
    tooltip: "Manufacturer",
    sortable: true,
    ascending: true,
    minWidth: 117,
    width: 145,
    visibility: true,
    disabled: false,
  },
  lastUpdated: {
    key: "lastUpdated",
    displayName: "Last Updated",
    tooltip: "Last Updated",
    sortable: true,
    ascending: true,
    width: 170,
    minWidth: 170,
    visibility: true,
    disabled: false,
  },
  name: {
    key: "name",
    displayName: "Name",
    tooltip: "Name",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 140,
    visibility: true,
    disabled: true,
  },
  remove:{
    key: "remove",
    displayName: "",
    sortable: false,
    minWidth: 30,
    width: 30,
    visibility: true,
    headingClass: "remove-col",
    dragable: false,
    expandAble: false,
    disabled: true,
    hideFromSettings: true,
  },
  mfrMpn: {
    key: "mfrMpn",
    displayName: "MPN",
    tooltip: "MPN",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 71,
    visibility: true,
    disabled: true,
  },
  mfrName: {
    key: "mfrName",
    displayName: "MANUFACTURER",
    tooltip: "MANUFACTURER",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 140,
    visibility: true,
    disabled: true,
  },
  mfrDescription: {
    key: "mfrDescription",
    displayName: "MFR DESCRIPTION",
    tooltip: "MFR DESCRIPTION",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 151,
    visibility: true,
    disabled: false,
  },
  distName: {
    key: "distName",
    displayName: "DISTRIBUTOR",
    tooltip: "DISTRIBUTOR",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 120,
    visibility: true,
    disabled: false,
  },
  distDpn: {
    key: "distDpn",
    displayName: "DPN",
    tooltip: "DPN",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 71,
    visibility: true,
    disabled: false,
  },
  distDescription: {
    key: "distDescription",
    displayName: "DIST DESCRIPTION",
    tooltip: "DIST DESCRIPTION",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 152,
    visibility: true,
    disabled: false,
  },
  quoteMinQuantity: {
    key: "quoteMinQuantity",
    displayName: "MIN QTY",
    tooltip: "MIN QTY",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 91,
    visibility: true,
    disabled: false,
  },
  quoteUnitPrice: {
    key: "quoteUnitPrice",
    displayName: "UNIT PRICE",
    tooltip: "UNIT PRICE",
    sortable: true,
    ascending: true,
    minWidth: 128,
    width: 128,
    visibility: true,
    disabled: false,
  },
  quoteLeadTime: {
    key: "quoteLeadTime",
    displayName: "QUOTE LEAD TIME",
    tooltip: "QUOTE LEAD TIME",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 149,
    visibility: true,
    disabled: false,
  },
  quoteLastUpdated: {
    key: "quoteLastUpdated",
    displayName: "LAST UPDATED",
    tooltip: "LAST UPDATED",
    sortable: true,
    ascending: true,
    minWidth: 170,
    width: 170,
    visibility: true,
    disabled: false,
  },
  serial: {
    key: "serial",
    displayName: "SERIAL",
    tooltip: "SERIAL",
    sortable: true,
    ascending: true,
    minWidth: 180,
    width: 200,
    visibility: true,
    disabled: false,
  },
  label: {
    key: "label",
    displayName: "LABEL",
    tooltip: "LABEL",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 145,
    visibility: true,
    disabled: false,
  },
  productionDate: {
    key: "productionDate",
    displayName: "PRODUCTION DATE",
    tooltip: "PRODUCTION DATE",
    sortable: true,
    ascending: true,
    minWidth: 71,
    width: 200,
    visibility: true,
    disabled: false,
  },
};
