import                        './index.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector }        from 'react-redux';
import { Helmet }             from 'react-helmet';
import SettingSideNav         from '../setting-side-nav';
import MandatoryApproverComp  from './mandatory-approver';
import MandatoryComment       from './mandatory-comment';
import Utils                  from '../../../../modules/utils';
import API                    from '../../../../modules/api';
import Loading                from '../../../ui/inner-loading';

const Configurations = props =>
{
    const user = useSelector((store) => store.user.data);
    const [company, setCompany] = useState({});
    const [templateMappings, setTemplateMappings] = useState({});
    const [isSetupStage, setIsSetupStage] = useState(true);
    const [showLoading, setShowLoading]   = useState(true);
    const [templates, setTemplates]       = useState([]);
    const [templatesFetched, setTemplatesFetched] = useState(false);

    const finalizeSetupStage = useCallback((mappings) => {
        let isSetup = true;
        let oldMappings = {...templateMappings};
        if (!!mappings)
        {
            let updatedRules = Object.keys(mappings);
            updatedRules.forEach(rule => {
                oldMappings[rule] = mappings[rule];
            })
        }

        isSetup = oldMappings && Object.values(oldMappings).every((value)=> value === null)
        setIsSetupStage(isSetup);
        if((Object.values(mappings || {})).length)
            setTemplateMappings(oldMappings);
    }, [templateMappings]);

    const getCoTemplate = () => {
        API.services.getCoTemplate((err, response) => {
            if(response) {
                const companyTemplates = response.filter((template) => { return template.isPublic === true });
                setTemplates(companyTemplates);
                setTemplatesFetched(true);
            }
        }, true)
    }

    const afterTemplateMappingCreation = useCallback((err, res) => {
        if(res)
        {
            getCompanyCoTemplateMappings();
        }
    }, []);

    useEffect(() => {
        API.companies.getCurrent((err, response) => {
            if(response) {
                setCompany({_id:response._id, settings: response.settings});
                if(response && !response.settings.coTemplateMappings)
                    API.services.createCoTemplateMappings({company: company._id}, afterTemplateMappingCreation);
                else getCompanyCoTemplateMappings();
            }
        })
    }, [afterTemplateMappingCreation, company._id])

    const getCompanyCoTemplateMappings = () => {
        API.services.getCompanyCoTemplateMappings((err, res) => {
            if(res) setTemplateMappings(res);
        });
    };

    useEffect(() => {
        if (Object.keys(templateMappings).length) getCoTemplate();
    },[templateMappings]);

    useEffect(() => {
        if(templatesFetched) {
            finalizeSetupStage();
            setShowLoading(false);
        }
    }, [finalizeSetupStage, templatesFetched])

    return (
        showLoading ? <Loading/> :
        <div className="configuration-settings-route app-row">
            <Helmet>
                <title>{Utils.makeTitle("Settings - Configuration")}</title>
            </Helmet>
            <SettingSideNav location={props.location} user={user}/>
            <div className="main-block">
                <div className="content-block">
                    <h1>Configuration</h1>
                    <div className="sub-heading">
                        <h2>Change Orders</h2>
                    </div>
                        <MandatoryApproverComp
                            user={user}
                            company={company}
                            templateMappings={templateMappings}
                            isSetupStage={isSetupStage}
                            finalizeSetupStage={finalizeSetupStage}
                            templates={templates}
                        />
                        <MandatoryComment
                            user={user}
                            company={company}
                        />
                </div>
            </div>
        </div>
    )
}
export default Configurations;
