// ToDo: Remove this navbar hackery when it's replaced by new component
function setScrollHight() {
  if (document.getElementById("sidebar-view")) {

    let topHeaderHeight = document.querySelector(".private-view .top-row").offsetHeight
    let heightLimit = (window.innerHeight - topHeaderHeight) + "px"
    let exportHeaderHeight = document.querySelector('.header-block') ? document.querySelector('.header-block').offsetHeight : 0
    let heightLimitforExport = (window.innerHeight - topHeaderHeight - exportHeaderHeight) + "px"
    document.getElementById("sidebar-view").style.minHeight = "1px"
    document.getElementById("sidebar-view").style.minHeight = heightLimit
    document.getElementById("sidebar-view").style.maxHeight = heightLimit
    document.getElementsByClassName("main-content")[0].style.height = heightLimit

    if (document.getElementsByClassName("export-scroll-area")[0]) {
      document.getElementsByClassName("export-scroll-area")[0].style.height = heightLimit
      const rightSearchMenu = document.getElementsByClassName("right-search-menu")[0]
      if (rightSearchMenu) rightSearchMenu.style.height = heightLimit
      const rightSearchExpand = document.getElementsByClassName("right-search-expand")[0]
      if (rightSearchExpand) rightSearchExpand.style.height = heightLimit
      const exportScreenScroll = document.getElementsByClassName("export-screen-scroll")[0]
      if (exportScreenScroll) exportScreenScroll.style.height = heightLimit
      const exportScreenContent = document.getElementsByClassName("export-screen-content")[0]
      if (exportScreenContent) exportScreenContent.style.minHeight = heightLimitforExport
    }

    if (document.getElementsByClassName("on-boarding-holder")[0]) {
      document.getElementsByClassName("on-boarding-holder")[0].style.height = heightLimit
    }
  }
  if (
    document.getElementById("sidebar-view") &&
    document.getElementsByClassName("navScrollArea")[0]
  ) {
    document.getElementsByClassName("navScrollArea")[0].style.height = "1px"
    let z = document.getElementById("sidebar-view").offsetHeight
    // 71 is th height of the left nav li's and we have three of them.

    let sideNaveLinkElements = document.getElementsByClassName("sideNavLinks")
    let sideNavLinksHeight = sideNaveLinkElements[0].offsetHeight * sideNaveLinkElements.length

    document.getElementsByClassName("navScrollArea")[0].style.height = (z - sideNavLinksHeight - 44) + "px"
  }
  if (document.getElementsByClassName("edit-product-route").length > 0) {
    let modal = document.querySelector(".edit-product-route .upload-side-area .modal.open")
    let scrollArea = document.querySelector(".edit-product-route .upload-side-area .upload-screen-scroll")
    if (modal) modal.style.height = document.getElementsByClassName("edit-product-route")[0].offsetHeight + "px";
    if (scrollArea) scrollArea.style.height = document.getElementsByClassName("edit-product-route")[0].offsetHeight + "px";
  }
  else if (document.getElementsByClassName("edit-component-route").length > 0) {
    let modal = document.querySelector(".edit-component-route .upload-side-area .modal.open")
    let scrollArea = document.querySelector(".edit-component-route .upload-side-area .upload-screen-scroll")
    if (modal) modal.style.height = document.getElementsByClassName("edit-component-route")[0].offsetHeight + "px";
    if (scrollArea) scrollArea.style.height = document.getElementsByClassName("edit-component-route")[0].offsetHeight + "px";
  }
}

function onHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight, newHeight;
  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight != newHeight)
      callback();
    lastHeight = newHeight;

    if (elm.onHeightChangeTimer)
      clearTimeout(elm.onHeightChangeTimer);

    elm.onHeightChangeTimer = setTimeout(run, 200);
  })();
}

window.changeRoutesWidth = function () {
  let el = document.getElementById('routes')
  if (el) {
    let event = new CustomEvent('widthChange', { "detail": "nothing" });
    el.dispatchEvent(event);
    el.dispatchEvent(event);
  }
}

onHeightChange(document.body, function () {
  setScrollHight()
});
window.changeLeftNavHeight = function () {
  setScrollHight()
}
window.onresize = function (event) {
  window.changeRoutesWidth()
  setScrollHight()
};
