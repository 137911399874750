export const enum ButtonVariants {
  CONTAINED = "contained",
  OUTLINED = "outlined",
  TEXT = "text",
}

export const enum CurrencyType {
  AUD = "AUD",
  CAD = "CAD",
  EURO = "EURO",
  GBP = "GBP",
  RMB = "RMB",
  USD = "USD",
}

export const enum DefaultResolution {
  DASHBOARD_IMAGE = "r255_160",
  FEATURE_IMAGE = "r185_116",
  INLINE_IMAGE = "r38_24",
  ENLARGED_VIEW = "r134_84"
}

export const enum InputFieldVariants {
  FILLED = "filled",
  OUTLINED = "outlined",
  STANDARD = "standard",
}

export const enum Group {
  ENGINEERING = "ENGINEERING",
  MANAGEMENT = "MANAGEMENT",
  MANUFACTURER = "MANUFACTURER",
  PROCUREMENT = "PROCUREMENT",
  QA = "QA",
  REVIEWER = "REVIEWER",
  SUPPLIER = "SUPPLIER",
}

export const enum Role {
  ADMINISTRATOR = "ADMINISTRATOR",
  APPROVER = "APPROVER",
  REVIEWER = "REVIEWER",
  SUPPLIER = "SUPPLIER",
  USER = "USER",
  VENDOR = "VENDOR",
}

export const enum ToolbarItemType {
  ACTION = "action",
  DIVIDER = "divider",
}

export const enum UserStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}
