import                           "./index.css"
import React, {Component}   from "react"
import { connect }          from "react-redux"
import PRODUCT              from "../../../../action-types/product"
import CO                   from "../../../../action-types/changeorder"
import buildAction          from "../../../../helpers/buildAction"
import Utils                from "../../../../modules/utils"
import Actions              from "./actions"
import Tabs                 from "../../common/tabs/view"
import Loading              from "../../../ui/inner-loading"
import {Helmet}             from "react-helmet"
import PerfectScrollbar     from "react-perfect-scrollbar";
import ItemOverView         from "../../common/specifications/item-overview";
import ItemDetails          from "../../common/specifications/item-details";
import ItemTeam             from "../../common/specifications/item-team";
import ItemSchedule         from "../../common/specifications/item-schedule";
import ItemERP              from "../../common/specifications/item-erp-view";

export class ProductRevision extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            showLoading: false,
        }
        this.resetPageStates    = this.resetPageStates.bind(this);
        this.getRevisionFromApi = this.getRevisionFromApi.bind(this);
        this.resetPageStates();
        this.getNestedData      = this.getNestedData.bind(this);
        this.compareRevisions   = this.compareRevisions.bind(this);
        this.deleteProduct      = this.deleteProduct.bind(this);
        this.updateProduct      = this.updateProduct.bind(this);
        this.refreshItemViewRoute = this.refreshItemViewRoute.bind(this);
        this.addToChangeOrder   = this.addToChangeOrder.bind(this);
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
    }

    componentDidUpdate()
    {
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
    }

    componentWillMount()
    {
        const id = this.props.match.params.id
        this.getRevisionFromApi(id)
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.id !== this.props.match.params.id)
        {
            this.resetPageStates()
            this.getRevisionFromApi(nextProps.match.params.id)
        }
    }

    getRevisionFromApi(id)
    {
        let user         = this.props.user && this.props.user.data ? this.props.user.data : {};
        let payload = { id, history: this.props.history, user }
        let query_params =  "include=children,creator,images,revisions&history=true";
        const {dispatch} = this.props;

        if(window.__userRole === 'VENDOR')
        {
            if (this.props.location && this.props.location.showAll)
            {
                payload.id = `${id}?showAll=true&${query_params}`;
            }
            else
            {
                payload.id = `${id}?${query_params}`;
            }
            if (this.props.location && this.props.location.onBoarding)
            {
                payload.onBoarding = true;
            }
            dispatch(buildAction(PRODUCT.GET_PRODUCT_IN_VIEW_PAGE, payload ));
        }
        dispatch(buildAction(PRODUCT.GET_REVISION_IN_REVISION_PAGE, payload ));
    }

    getNestedData(name){
        const {dispatch} = this.props;
        let id = this.props.product.revisionPage.revision._id;
        let payload = { id:`${id}?include=${name}`, name };
        dispatch(buildAction(PRODUCT.GET_REVISION_NESTED_DATA, payload));
    }

    resetPageStates()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(PRODUCT.RESET_STATES_IN_REVISION_PAGE, {} ))
    }

    compareRevisions(sourceRevId, targetRevId)
    {
        let { product }  = this.props.product.revisionPage
        this.props.history.push({pathname: `/product/diff/${product._id}?sourceRevId=${sourceRevId}&targetRevId=${targetRevId}`});
    }

    deleteProduct()
    {
        this.setState({showLoading: true});
        let { product }  = this.props.product.revisionPage
        const { dispatch, history } = this.props;
        dispatch(buildAction(PRODUCT.DELETE_PRODUCT,
        {
            id: product._id,
            history,
            viewRoute: true
        }))
    }

    updateProduct()
    {
        this.resetPageStates();
        this.getProductFromApi(this.props.match.params.id);
        Utils.setLocalStorageForAssemblyTree(this.props.match.params.id);
        this.setState({ openVariantModal: true });
    }

    updateVariantsTab(forcefulyGetData=false)
    {
        this.setState({getDataFromApi: forcefulyGetData });
    }

    refreshItemViewRoute(props)
    {
        const id = this.props.match.params.id;
        this.resetPageStates()
        this.getRevisionFromApi(id)
    }

    addToChangeOrder(data)
    {
        const {dispatch} = this.props;
        let children = data.children
        data.children = {}
        let childId = children[0]._id
        data.children.products = [childId]

        let payload = {history: this.props.history, data: data, addChildren: true, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    render()
    {
        let { product, revision, backgroundStyle } = this.props.product.revisionPage;
        let cpnVariant = ""
        let { company } = this.props;
        let massPrecision = Utils.getCompanyMassPrecision(company);
        let user = this.props.user.data


        if( revision && Utils.haveVariantScheme(company.data, user.currentEnv))
        {
            cpnVariant = "-"+revision.cpnVariant
        }

        if (!product || !revision || this.state.showLoading){
            return <Loading />
        }

        let forecastKeys = []
        revision.forecasts.forEach((spec) => forecastKeys.push(spec.key))

        let markup =

            <div className="product-revision-route">
                <Helmet>
                    <title>{Utils.makeTitle("["+product.cpn+"] (Revision) "+product.name)}</title>
                </Helmet>
                <div className="background-holder">
                    <div className="background" style={backgroundStyle} />
                </div>
                <div className="top-block">
                    <Actions {...this.props}
                        revision={revision}
                        product={product}
                        compareRevisions={this.compareRevisions}
                        deleteProduct={this.deleteProduct}
                        updateProduct={this.updateProduct}
                        updateVariantsTab={this.updateVariantsTab}
                        refreshItemViewRoute={this.refreshItemViewRoute}
                        addToChangeOrder={this.addToChangeOrder}
                    />
                    <div className="tiles-wrapper">
                        <PerfectScrollbar className="tiles-section-scroll">
                            <ItemOverView item={revision} alias="prd" isPrdOrCmpViewPage={false} />
                            <ItemDetails item={revision} defaultCurrency={product.defaultCurrency} massPrecision={massPrecision} isPrdOrCmpViewPage={false}/>
                            <ItemTeam item={revision}/>
                            <ItemSchedule item={revision}/>
                            <ItemERP item={revision} />
                        </PerfectScrollbar>
                    </div>
                </div>

                <Tabs
                    massPrecision={massPrecision}
                    tabsType="prd"
                    product={product}
                    documents={revision.documents}
                    manufacturers={revision.manufacturers}
                    assemblyData={revision.children}
                    history={this.props.history}
                    mode="revision"
                    location={this.props.location}
                    getNestedData={this.getNestedData}
                    documents_loaded={this.props.product.revisionPage.documents_loaded}
                    children_loaded={this.props.product.revisionPage.children_loaded}
                    defaultCurrency={revision.defaultCurrency}
                    paramId={this.props.match.params.id}
                />

            </div>

        return markup
    }
}

export default connect((store) => store)(ProductRevision)
