import { gql } from "@apollo/client";

export const ComponentFragment = gql`
  fragment componentFragment on Component {
    id
    archived
    category
    cpn {
      counter
      displayValue
      id
      prefix
      variant
    }
    name
    lastModified
    status
    version
  }
`;

export const ComponentRevisionFragment = gql`
  fragment componentRevisionFragment on ComponentRevision {
    id
    archived
    category
    cpn {
      counter
      displayValue
      id
      prefix
      variant
    }
    name
    lastModified
    status
    version
  }
`;

export const ProductFragment = gql`
  fragment productFragment on Product {
    id
    archived
    cpn {
      counter
      displayValue
      id
      prefix
      variant
    }
    name
    lastModified
    status
    version
  }
`;

export const ProductRevisionFragment = gql`
  fragment productRevisionFragment on ProductRevision {
    id
    archived
    cpn {
      counter
      displayValue
      id
      prefix
      variant
    }
    name
    lastModified
    status
    version
  }
`;
