import { SvgIcon } from "@mui/material";

export const BuildWorkspaceIcon = () => (
  <SvgIcon viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "2.5rem",
    height: "2.5rem",
  }}>
    <defs>
      <path id="ee3rk5jzaa" d="M.607-.148h13v24h-13z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect strokeOpacity=".5" stroke="#7A5F9F" strokeWidth="3" fill="#BF8AFF" x="1.5" y="1.5"
        width="37" height="37" rx="8" />
      <g transform="translate(13.393 8.148)">
        <mask id="wuf5z8p58b" fill="#fff">
          <use href="#ee3rk5jzaa" />
        </mask>
        <path d="M8.407 12.164c0-1.187-.734-1.469-1.315-1.469h-.398V6.508c.49-.375 1.193-.687
        2.233-.687 2.754 0 4.68 1.531 4.68 6.093v5.844c0 4.563-1.926 6.094-4.68 6.094-1.009
        0-1.743-.313-2.233-.657v-4.218h.398c.581 0 1.315-.282 1.315-1.47v-5.343zM.607
        23.57h5.17V-.148H.607V23.57z" fill="#231F20" mask="url(#wuf5z8p58b)" />
      </g>
    </g>
  </SvgIcon>
);
