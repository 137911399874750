
import { takeLatest, takeEvery, all, put, cps, select }from 'redux-saga/effects';
import UI                                   from '../action-types/ui';
import USERS                                from '../action-types/users';
import USER                                 from '../action-types/user';
import { getAllEnabledUsers }               from 'graphql/query/userQueries';
import { client }                           from 'graphql/apolloClient';
import buildAction                          from '../helpers/buildAction';
import API                                  from "../modules/api";
import Utils                                from '../modules/utils'
import validations, {validateField}         from '../modules/validations';
import { getSubErrors } from 'v1/modules/utils/errors';

export const usersData = state => state.users
export const currentUser = state => state.user

export function getData(state)
{
    let users = state.users
    return users
}

export function* getList(action)
{
    const user = yield select(currentUser)
    yield put(buildAction(UI.LOAD_START));
    const { fetchEnabledUsers } = action.payload;
    yield put(buildAction(USERS.SET_USERS_LOADING, true));
    try {
        const users = fetchEnabledUsers
        ? (yield getAllEnabledUsers(client, user.data._id, "no-cache")).data.primaryCompany.users
        : yield cps(API.users.list);
        yield put(buildAction(USERS.SET_USERS_LIST, { users, currentUserId: user.data._id }));
    }
    catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }));
    }
    yield put(buildAction(USERS.SET_USERS_LOADING, false));

    yield put(buildAction(UI.LOAD_END));
}

export function* resendInvite(action)
{
    try
    {
        yield cps(API.users.resendInvite, action.payload.email)
        const userData = yield cps(API.users.getCurrent)
        yield put(buildAction(USER.SET_CURRENT_USER, null ));
        yield put(buildAction(USER.SET_CURRENT_USER, userData));

        yield put(buildAction(USERS.GET_USERS_LIST));
    }
    catch (err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }));
    }
}

export function* markOrDeleteDisable(action)
{
    try
    {
        let data = {}
        if (action.payload.type === "DISABLED")
            data.status = "DISABLED"
        else data.archived = true
        yield cps(API.users.updateCoreApi, action.payload._id, data)

        const userData = yield cps(API.users.getCurrent)
        yield put(buildAction(USER.SET_CURRENT_USER, null ));
        yield put(buildAction(USER.SET_CURRENT_USER, userData));

        yield put(buildAction(USERS.GET_USERS_LIST));
    }
    catch (err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }));
    }
}

export function* reactivate(action)
{
    try
    {
        yield cps(API.users.updateCoreApi, action.payload.id, { status: "ENABLED" })
        const userData = yield cps(API.users.getCurrent)
        yield put(buildAction(USER.SET_CURRENT_USER, null ));
        yield put(buildAction(USER.SET_CURRENT_USER, userData));

        yield put(buildAction(USERS.GET_USERS_LIST));
    }
    catch (err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }));
    }
}

export function* addUsersToGroup(action)
{
    const user = yield select(currentUser)
    yield put(buildAction(UI.LOAD_START));
    try
    {
        let response = yield cps(API.users.addToGroup, action.payload.data)
        response = yield cps(API.users.list)
        yield put(buildAction(USERS.SET_USERS_LIST, {users: response, currentUserId: user.data._id}));
    }
    catch (err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }));
    }
    yield put(buildAction(UI.LOAD_END));
}

export default function* (getState) {
    yield all([
        takeEvery(USERS.GET_USERS_LIST, getList),
        takeLatest(USERS.RE_SEND_INVITE, resendInvite),
        takeLatest(USERS.MARK_DISABLE_OR_DELETE, markOrDeleteDisable),
        takeLatest(USERS.REACTIVATE, reactivate),
        takeLatest(USERS.ADD_TO_GROUP, addUsersToGroup),
    ]);
}
