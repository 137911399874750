import { SvgIcon } from "@mui/material";

export const DesignWorkspaceIcon = () => (
  <SvgIcon viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "2.5rem",
    height: "2.5rem",
  }}>
    <g fill="none" fillRule="evenodd">
      <rect stroke="#2F2B34" strokeWidth="3" fill="#BF8AFF" x="1.5" y="1.5" width="37" height="37"
        rx="8" />
      <rect stroke="#0AB090" strokeWidth="3" fill="#3CD1B5" x="1.5" y="1.5" width="37" height="37"
        rx="8" />
      <path d="M21 32h5V8h-5v24zm-2.735-6.333c0 1.185.744 1.467 1.332
      1.467H20v4.21c-.496.344-1.239.656-2.26.656C14.95 32 13 30.471 13 25.917v-5.833C13 15.529
      14.951 14 17.74 14c1.052 0 1.764.312 2.26.686v4.18h-.403c-.588 0-1.332.282-1.332 1.467v5.334z"
        fill="#231F20" />
    </g>
  </SvgIcon>
);
