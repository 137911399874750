import                            "./index.css"
import React, { Component }  from "react"
import { connect }           from "react-redux"
import Backdrop from '@mui/material/Backdrop';
import Loading               from "../../../ui/inner-loading"
import Utils                 from "../../../../modules/utils"
import COMPONENT             from "../../../../action-types/component"
import CO                    from "../../../../action-types/changeorder"
import buildAction           from "../../../../helpers/buildAction"
import Actions               from "./modules/actions"
import Tabs                  from "../../common/tabs/view"
import SourcingUtils from "../../common/sourcing/sourcing-utils"
import LoadingIcon           from "../../../../assets/icons/loading"
import InlineIcon            from "../../../ui/icon/inline-icon.js"
import {Helmet}              from "react-helmet"
import URLSearchParams       from "url-search-params"
import PerfectScrollbar      from "react-perfect-scrollbar"
import ItemOverView          from "../../common/specifications/item-overview"
import ItemDetails           from "../../common/specifications/item-details"
import ItemSpecs             from "../../common/specifications/item-specs"
import ItemCustomProperties  from "../../common/specifications/item-custom-properties"
import USER                  from "../../../../action-types/user";
import ItemERP               from "../../common/specifications/item-erp-view"

export class ViewComponent extends Component
{
    constructor(props)
    {
        super(props)
        let urlParams = new URLSearchParams(window.location.search)
        let tab       = urlParams.has('tab') ? urlParams.get('tab') : null;
        if (tab)
        {
            Utils.setStore("component-tab", "variants");
        }
        this.state = {
            showFullDesc: false,
            showLoading: false,
            openVariantModal: false,
            getDataFromApi: false,
            descriptionLength: 365
        }
        this.toggleFavorite         = this.toggleFavorite.bind(this);
        this.toggleDesc             = this.toggleDesc.bind(this);
        this.revertChanges          = this.revertChanges.bind(this);
        this.makeDuplicate          = this.makeDuplicate.bind(this);
        this.getComponentFromApi    = this.getComponentFromApi.bind(this);
        this.deleteComponent        = this.deleteComponent.bind(this);
        this.setPrimarySourceInApi  = this.setPrimarySourceInApi.bind(this);
        this.addToChangeOrder       = this.addToChangeOrder.bind(this);
        this.getNestedData          = this.getNestedData.bind(this);
        this.compareRevisions       = this.compareRevisions.bind(this);
        this.openVariantModal       = this.openVariantModal.bind(this);
        this.closeVariantModal      = this.closeVariantModal.bind(this);
        this.createVariant          = this.createVariant.bind(this);
        this.updateVariantsTab      = this.updateVariantsTab.bind(this);
        this.updateCopmonent        = this.updateCopmonent.bind(this);
        this.refreshItemViewRoute   = this.refreshItemViewRoute.bind(this);
        this.loadComponentAndSetLocalStorage = this.loadComponentAndSetLocalStorage.bind(this);
    }

    compareRevisions(sourceRevId, targetRevId)
    {
        let { component }       = this.props.components.viewPage
        this.props.history.push({pathname: `/component/diff/${component._id}?sourceRevId=${sourceRevId}&targetRevId=${targetRevId}`});
    }

    addToChangeOrder(data)
    {
        const {dispatch} = this.props;

        let children = data.children
        data.children = {}
        let childId = children[0]._id
        data.children.components = [childId]

        let payload = {history: this.props.history, data: data, addChildren: true, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    loadComponentAndSetLocalStorage()
    {
        const { dispatch } = this.props;
        const id = this.props.match.params.id
        dispatch(buildAction(COMPONENT.RESET_STATES_IN_VIEW_PAGE));
        this.getComponentFromApi(id);
        Utils.setLocalStorageForAssemblyTree(id);
    }

    refreshItemViewRoute()
    {
        this.loadComponentAndSetLocalStorage();
    }

    componentWillMount()
    {
        const { dispatch, user, history } = this.props;
        let urlParams          = new URLSearchParams(window.location.search);
        let switchEnvToGeneral = urlParams && urlParams.has("switchEnvToGeneral") ? urlParams.get("switchEnvToGeneral") : null;
        let redirectUrl        = `/component/view/${this.props.match.params.id}`;
        if(switchEnvToGeneral && user && user.data && user.data.currentEnv !== "LIVE")
        {
            let libraries = user.data.libraries;
            let payload =
            {
                activeLibrary: libraries[0].type === "PERSONAL" ? libraries[1] : libraries[0],
                currentEnv: "LIVE",
                cb: this.refreshItemViewRoute,
                redirectUrl,
                history,
                user
            };
            dispatch(buildAction(USER.SWITCH_ENV, payload));
        }
        else
        {
            if(switchEnvToGeneral) history.push({pathname: redirectUrl});
            this.loadComponentAndSetLocalStorage();
        }
    }

    toggleDesc(){
        this.setState(prevState => ({
            showFullDesc: !prevState.showFullDesc
        }))
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.id !== this.props.match.params.id)
        {
            this.resetPageStates();
            this.getComponentFromApi(nextProps.match.params.id)
        }
    }

    toggleFavorite(){
        const {dispatch} = this.props;
        let { component }  = this.props.components.viewPage

        let payload =
        {
            user: this.props.user.data,
            component
        }
        dispatch(buildAction(COMPONENT.MARK_FAVOURITE, payload))
    }

    revertChanges(){
        let { component }  = this.props.components.viewPage;
        let componentId = component.revisions[component.revisions.length - 1];
        if (component.revisions.length > 1)
        {
            componentId = component.revisions[component.revisions.length - 2];
        }
        const {dispatch} = this.props;
        let payload =
        {
            componentId,
            component,
            history: this.props.history
        }
        dispatch(buildAction(COMPONENT.REVERT_CHANGES, payload));
    }

    deleteComponent(){
        let state = this.state
        state.showLoading = true
        this.setState(state)
        let { component }       = this.props.components.viewPage
        const {dispatch} = this.props;
        let payload =
        {
            component,
            history: this.props.history,
            viewRoute: true
        }
        dispatch(buildAction(COMPONENT.DELETE_COMPONENT, payload))
    }

    setPrimarySourceInApi(primarySource){
        if (primarySource)
        {
            const {dispatch} = this.props
            let { component }       = this.props.components.viewPage
            let {primarySourceId, rolledUpCost} = primarySource;
            component   = SourcingUtils.setPrimarySrc(primarySourceId, rolledUpCost, component);
            let payload =
            {
                primarySource: {
                    _id          : primarySourceId,
                    rolledUpCost : rolledUpCost,
                    settingPrimarySourceFromViewPage: true,
                    doc: component
                },
                _id: component._id
            }
            dispatch(buildAction(COMPONENT.SET_PRIMARY_SOURCE, payload))
        }
    }

    makeDuplicate(actionType='duplicate'){
        let { component }  = this.props.components.viewPage;
        const {dispatch} = this.props;
        let payload =
        {
            componentId: component._id,
            history: this.props.history,
        };
        dispatch(buildAction(COMPONENT.MAKE_DUPLICATE, payload));
    }

    getComponentFromApi(id)
    {
        let state = this.state;
        state.openVariantModal = false;
        this.setState(state);
        const {dispatch} = this.props;
        const user       = this.props.user && this.props.user.data ? this.props.user.data : {};
        let payload = { id, history: this.props.history, user }
        let query_params =  `include=creator,images${Utils.checkForRestrictedRole(window.__userRole) ? ',revisions&history=true' : ''}`;
        if (this.props.location && this.props.location.showAll)
        {
            payload.id = `${id}?showAll=true&${query_params}`;
        }
        else
        {
            payload.id = `${id}?${query_params}`;
        }
        payload.id = `${payload.id}&populateIntegration=true`;
        dispatch(buildAction(COMPONENT.SET_LOADING, true));
        dispatch(buildAction(COMPONENT.GET_COMPONENT, payload));
    }

    getNestedData(name){
        const {dispatch} = this.props;
        let id = this.props.components.viewPage.component._id;
        let payload = { id:`${id}?include=${name}`, name };
        dispatch(buildAction(COMPONENT.GET_COMPONENT_NESTED_DATA, payload));
    }


    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(buildAction(COMPONENT.RESET_STATES_IN_VIEW_PAGE))
    }

    createVariant(componentId)
    {
        const {dispatch} = this.props;
        let payload = {componentId, history: this.props.history}
        dispatch(buildAction(COMPONENT.CREATE_VARIANT, payload));
    }

    openVariantModal(e, component)
    {
        let state = this.state
        state.openVariantModal = true
        this.setState(state)
    }

    closeVariantModal(e, component)
    {
        let state = this.state
        state.openVariantModal = false
        this.setState(state)
    }

    resetPageStates()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(COMPONENT.RESET_STATES_IN_VIEW_PAGE, {}))
    }

    updateCopmonent()
    {
        this.resetPageStates();
        this.getComponentFromApi(this.props.match.params.id);
        let state = this.state;
        state.openVariantModal = true;
        this.setState(state);
    }
    updateVariantsTab(forcefulyGetData=false)
    {
        let state = this.state;
        state.getDataFromApi = forcefulyGetData;
        this.setState(state);
    }

    render()
    {
        let { component }   = this.props.components.viewPage
        if (!component || this.state.showLoading || this.props.components.viewPage.loading){
            return <Loading />
        }

        let { company, user } = this.props;
        let massPrecision = Utils.getCompanyMassPrecision(company);
        let showLoading = this.props.components.viewPage.loading

        let primarySource = SourcingUtils.setPrimarySourceIfRolledUpSourceIsPrimary(component)
        let cpn                        = Utils.getCpn(component);
        const { isVendorCmp, vendorLabel, isOriginallyVendorCmp } = Utils.getVendorLabel(component);
        const showCustomProperties = component.customProperties && !!component.customProperties.length && (isVendorCmp || isOriginallyVendorCmp) && vendorLabel !== "VALISPACE" 
        let componentViewClass = `view-component-route ${isVendorCmp ? "vendor-component-view": ""}${showLoading ? "loading" : ""}`;

        let tabsUpdatedAt = this.props.components.viewPage.tabsUpdatedAt;
        tabsUpdatedAt = tabsUpdatedAt ? `-${tabsUpdatedAt}` : "";
        let tabsKey = `tabs-primary-source${tabsUpdatedAt}`;
        let markup =
            <div className={componentViewClass}>
                <Helmet>
                    <title>{Utils.makeTitle("["+cpn+"] "+component.name)}</title>
                </Helmet>

                <div className="top-block">
                    <Actions
                        {...this.props}
                        user={user.data}
                        component={component}
                        toggleFavorite={this.toggleFavorite}
                        revertChanges={this.revertChanges}
                        makeDuplicate={this.makeDuplicate}
                        history={this.props.history}
                        deleteComponent={this.deleteComponent}
                        addToChangeOrder={this.addToChangeOrder}
                        setLoading={false}
                        compareRevisions={this.compareRevisions}
                        openVariantModal={this.openVariantModal}
                        openVariantModalFlag={this.state.openVariantModal}
                        closeVariantModal={this.closeVariantModal}
                        updateCopmonent={this.updateCopmonent}
                        updateVariantsTab={this.updateVariantsTab}
                        createVariant={this.createVariant}
                        getDataFromApi={true}
                        location={this.props.location}
                        search={this.props.search}
                        refreshItemViewRoute={this.refreshItemViewRoute}
                    />
                    <div className="tiles-wrapper">
                        <PerfectScrollbar className="tiles-section-scroll">
                            <section className="tiles-container">
                                <ItemOverView item={component} alias="cmp" isPrdOrCmpViewPage={true} />
                                <ItemDetails item={component} alias="cmp" defaultCurrency={component.defaultCurrency} massPrecision={massPrecision} isPrdOrCmpViewPage={true}/>
                                <ItemSpecs item={component} massPrecision={massPrecision} />
                                {showCustomProperties && <ItemCustomProperties item={component} mode="view"/>}
                                <ItemERP item={component}/>
                            </section>
                        </PerfectScrollbar>
                    </div>
                </div>

                <Tabs
                    user={user}
                    tabsType="cmp"
                    component={component}
                    updateVariantsTab={this.updateVariantsTab}
                    getDataFromApi={this.state.getDataFromApi}
                    dispatch={this.props.dispatch}
                    documents={component.documents}
                    manufacturers={component.manufacturers}
                    assemblyData={component.children}
                    componentCategory={component.category}
                    history={this.props.history}
                    setPrimarySourceInApi={this.setPrimarySourceInApi}
                    paramId={this.props.match.params.id}
                    getNestedData={this.getNestedData}
                    documents_loaded={this.props.components.viewPage.documents_loaded}
                    children_loaded={this.props.components.viewPage.children_loaded}
                    key={tabsKey}
                    defaultCurrency={component.defaultCurrency}
                />

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showLoading}
                >
                    <InlineIcon><LoadingIcon /></InlineIcon>
                </Backdrop>
            </div>

        return markup
    }
}

export default connect((store) => store)(ViewComponent)
