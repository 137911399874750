import { isNil, omitBy } from "lodash";
import { getUser } from "../graphql/query/userQueries";
import { client } from "../graphql/apolloClient";

export enum ModelType {
  COMPONENT = "COMPONENT",
  PRODUCT = "PRODUCT",
}

export function extractCpnRules(library: any, modelType: ModelType) {
  const { cpnRules } = library;
  const baseRules = cpnRules?.base;
  // Strip out null fields to prevent overwriting populated keys.
  const componentRules = omitBy(cpnRules?.component, isNil);
  const productRules = omitBy(cpnRules?.product, isNil);
  switch (modelType) {
    case ModelType.COMPONENT:
      return { ...baseRules, ...componentRules };
    case ModelType.PRODUCT:
      return { ...baseRules, ...productRules };
    default:
  }
  return baseRules;
}

export interface GetVariantFlagsArgs {
  currentCompanyCpnType: string
  isCreating: boolean
  modelType: ModelType
}

// ToDo: Deprecate
export async function getLegacyCpnConfig(args: GetVariantFlagsArgs) {
  const { currentCompanyCpnType, isCreating, modelType } = args;
  const { data: user } = await getUser(client);
  let isCpnVariantEditable;
  let isCpnVariantScheme;
  let cpnType = currentCompanyCpnType;
  if (cpnType === "CPN_RULES") {
    const cpnRules = extractCpnRules(user?.activeLibrary, modelType);
    isCpnVariantScheme = !!cpnRules?.variantLength;
    isCpnVariantEditable = isCpnVariantScheme; // ToDo: need new cpnRule for create vs edit
  }
  else {
    // Legacy CPN types
    cpnType = user?.activeLibrary.type === "GENERAL" ? cpnType : "DEFAULT"; // Sandbox = default cpnType
    isCpnVariantEditable = cpnType === "CUSTOM-CODE-WITH-10-DIGIT" ? isCreating : true;
    isCpnVariantScheme = isCpnVariantEditable && (
      user?.primaryCompany?.legacyCpnVariantScheme === "EXTRA-TWO-DIGIT-VARIANT"
    );
  }
  return { cpnType, isCpnVariantEditable, isCpnVariantScheme };
}

//-----------------------------------------------------------------------------
export interface FormatLegacyCpnArgs {
  cpn: string
  hasVariant: boolean
  library: any
  modelType: ModelType
  variant: string
}

// ToDo: Deprecate. CPN biz logic should not be in the client.
// Format the cpn (prefix+counter) and variant to get the display value
export function formatLegacyCpn(args: FormatLegacyCpnArgs) {
  const { cpn, hasVariant, library, modelType, variant } = args;
  const { counterDelimiter = "", hideVariantStart, variantLength } = extractCpnRules(library, modelType);
  let displayValue = cpn;
  const lastPart = cpn.split(counterDelimiter)?.pop();
  if (variantLength) {
    // Prevents repeated appended cpnVariant
    if (lastPart === variant) {
      return displayValue;
    }
    // The initial item doesn't show the variant suffix if hideVariantStart = true and no variants exist.
    if (hideVariantStart && !hasVariant) {
      return displayValue;
    }
    displayValue += `${counterDelimiter}${variant}`;
  }
  return displayValue;
}
