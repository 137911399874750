import                       "./index.css"
import React            from "react"
import Utils            from "../../../../../../modules/utils"
import Schemas          from "../../../../../../modules/schemas"
import {connect}        from "react-redux";
import USER_FORM        from "../../../../../../action-types/user_form"
import buildAction      from "../../../../../../helpers/buildAction"
import Icon             from "../../../../../ui/icon"
import Link             from "../../../../../ui/link"
import questionSrc      from "../../../../../../assets/icons/question-mark.svg"
import Tooltip          from 'rc-tooltip'
import Permissions      from "../../../../../../modules/schemas/permissions"
import                  'rc-tooltip/assets/bootstrap_white.css'
import LazyInput from "v1/components/ui/lazy-input/input";
import { DEFAULT_USER_GROUPS } from "v1/modules/constants";

export class UserFormView extends React.Component
{
    constructor(props, context)
    {
      super(props, context);
      this.onSubmit          = this.onSubmit.bind(this);
      this.onCancel          = this.onCancel.bind(this);
      this.onInputChange     = this.onInputChange.bind(this);
      this.toggleAddMore     = this.toggleAddMore.bind(this);
      this.changeGroupsValue = this.changeGroupsValue.bind(this);
      this.setSubmitCb       = this.setSubmitCb.bind(this);
      this.haveGroup         = this.haveGroup.bind(this);

      this.state             = {
          addMore: false,
          groups: this.props.groupName ? [this.props.groupName.toUpperCase()] : this.props.userForm.inputs.groups.value
      }
    }

    onInputChange(event)
    {
      const {dispatch} = this.props;
      Utils.persistCursor(event, event.target.value)
      dispatch(buildAction(USER_FORM.UPDATE_INPUT_ON_CHANGE, event.target))
    }

    haveGroup(groupName)
    {
      return this.state.groups.includes(groupName.toUpperCase())
    }

    onSubmit(event)
    {
        const {dispatch} = this.props;
        event.preventDefault()
        Utils.blur()
        let data = Utils.fromInputsToValues({...this.props.userForm.inputs})

        //if super admin then set the role value as ADMINISTRATOR (TODO: should be handle in api side.)
        if (this.props.userForm.user.superAdmin && Schemas.helpers.isSuperAdminUser(this.props.userForm.user, this.props.user.data))
        {
            data.role = "ADMINISTRATOR"
        }

        let payload = { data }
        payload.successCb = this.setSubmitCb
        if (this.props.mode === "edit")
        {
            payload.update = true
            payload.userId = this.props.userForm.user._id
        }
        if (this.props.fromSandbox && document.getElementsByClassName("popover-tool-tip-container")[0])
        {
          payload.fromSandbox = true
          document.getElementsByClassName("popover-tool-tip-container")[0].style.visibility = "hidden"
        }
        dispatch(buildAction(USER_FORM.SUBMIT_USER_FORM, payload))
    }

    setSubmitCb(data=null){
      const {dispatch} = this.props;
      if ( this.state.addMore == true ){
        let reset_list = ["firstName", "lastName", "email"]
        dispatch(buildAction(USER_FORM.RESET_THESE_FIELDS, {reset_list}))
      }
      else
      {
        dispatch(buildAction(USER_FORM.RESET_FORM))
        this.props.onCancel()
      }

      if (this.props.afterSubmitSuccessCb)
      {
        this.props.afterSubmitSuccessCb(data)
      }
       if(this.props.fromSandbox && document.getElementsByClassName("popover-tool-tip-container")[0])
      {
        document.getElementsByClassName("popover-tool-tip-container")[0].style.visibility = "visible"
      }
    }

    changeGroupsValue(event){
      const groups = this.state.groups
      let index;
      let value = event.target.value.toUpperCase()
      if (event.target.checked) {
        groups.push(value)
      } else {
        index = groups.indexOf(value)
        groups.splice(index, 1)
      }
      this.setState({ groups: groups })

      const {dispatch} = this.props;
      let target = {
        name: 'groups',
        value: groups
      }
      dispatch(buildAction(USER_FORM.UPDATE_INPUT_ON_CHANGE, target))
    }

    onCancel(event)
    {
        const {dispatch} = this.props;
        event.preventDefault()
        dispatch(buildAction(USER_FORM.RESET_FORM))
        this.props.onCancel()
    }

    toggleAddMore(){
      this.state.addMore = !this.state.addMore
      this.setState(this.state)
    }

    render()
    {
        let inputs = this.props.userForm && this.props.userForm.inputs
        let disabledClazz = this.props.userForm.user.superAdmin ? " disabled" : ""
        let allowedUserRoles = Utils.getAllowedUserRoles(this.props.company);
        let forceDisableRoleValue = ""
        let user = this.props.user.data

        if (
          (this.props.userForm.user.superAdmin &&
                    Schemas.helpers.isSuperAdminUser(this.props.userForm.user, this.props.user.data))
          || (this.props.userForm.user._id === this.props.user.data._id && this.props.userForm.user.role !== 'ADMINISTRATOR')

        )
        {
            disabledClazz = ""
            forceDisableRoleValue = " disabled"
        }

        let groupsDisabledClass = Permissions.can("edit", "groups", user) ? "" : " disabled"

        if (!inputs){
            return null
        }
        user.role !== "ADMINISTRATOR"
        ? allowedUserRoles = allowedUserRoles.filter(function(item){
            return item !== "ADMINISTRATOR" ;
          })
        : null

        let markup =

            <form className="new-user-form-block" onSubmit={this.onSubmit}>
                <h1>{this.props.mode === "edit" ? "Edit User" : "New User"}</h1>
                <div className="flex-inputs">
                  <div>
                    <label htmlFor="firstName">First Name *</label>
                    <Tooltip
                        overlay={inputs.firstName.message}
                        overlayClassName={"error" + (inputs.firstName.message.length > 0 ? "" : " hidden")}
                        getTooltipContainer={() => document.querySelector(".routes")}
                    >
                      <LazyInput
                          name="firstName"
                          id="user-name-id"
                          placeholder="First name"
                          className={inputs.firstName.class + disabledClazz}
                          value={inputs.firstName.value}
                          onChange={this.onInputChange}
                      />
                    </Tooltip>
                  </div>

                  <div>
                    <label htmlFor="lastName">Last Name *</label>
                    <Tooltip
                        overlay={inputs.lastName.message}
                        overlayClassName={"error" + (inputs.lastName.message.length > 0 ? "" : " hidden")}
                        getTooltipContainer={() => document.querySelector(".routes")}
                    >
                      <LazyInput
                          name="lastName"
                          placeholder="Last name"
                          className={inputs.lastName.class + disabledClazz}
                          value={inputs.lastName.value}
                          onChange={this.onInputChange}
                      />
                    </Tooltip>
                  </div>
                </div>


                <label htmlFor="email">Email *</label>
                <Tooltip
                    overlay={inputs.email.message}
                    overlayClassName={"error" + (inputs.email.message.length > 0 ? "" : " hidden")}
                    getTooltipContainer={() => document.querySelector(".routes")}
                >
                  <LazyInput
                      name="email"
                      placeholder="Email"
                      className={inputs.email.class + disabledClazz}
                      value={inputs.email.value}
                      onChange={this.onInputChange}
                  />
                </Tooltip>

                <div className="flex-inputs">
                  <div>
                    <label htmlFor="title">Job Title</label>
                    <Tooltip
                        overlay={inputs.title.message}
                        overlayClassName={"error" + (inputs.title.message.length > 0 ? "" : " hidden")}
                        getTooltipContainer={() => document.querySelector(".routes")}
                    >
                      <LazyInput
                          name="title"
                          id="user-name-id"
                          placeholder="Job title"
                          className={inputs.title.class + disabledClazz}
                          value={inputs.title.value}
                          onChange={this.onInputChange}
                      />
                    </Tooltip>
                  </div>

                  <div>
                    <label htmlFor="role">
                      Role *
                      <span className="question-icon">
                        <Link
                          to={{ pathname: "https://duro.zendesk.com/hc/en-us/articles/360033385192-Account-Management" }}
                          target="_blank"
                          >
                          <Icon src={questionSrc} />
                        </Link>
                      </span>
                    </label>
                    <Tooltip
                        overlay={inputs.role.message}
                        overlayClassName={"error" + (inputs.role.message.length > 0 ? "" : " hidden")}
                        getTooltipContainer={() => document.querySelector(".routes")}
                    >
                      <select
                          name="role"
                          className={inputs.role.class + disabledClazz + forceDisableRoleValue}
                          value={inputs.role.value}
                          onChange={this.onInputChange}
                          readOnly={inputs.role.readOnly}
                          data-tip={inputs.role.message}
                          data-place="right"
                          data-type="error"
                          >
                          {Utils.displayUserRoleOptions(allowedUserRoles)}
                      </select>

                    </Tooltip>
                  </div>
                </div>
                <div className="groups">
                  <label
                    htmlFor="groups"
                    className='muted-text'>
                    GROUPS
                  </label>
                  <div className="flex-inputs">                
                  {
                  DEFAULT_USER_GROUPS.map(group => (
                    <div>
                      <input
                        type="checkbox"
                        name="group"
                        value={group}
                        checked={this.haveGroup(group)}
                        onChange={this.changeGroupsValue}
                        className={`${disabledClazz} ${groupsDisabledClass}`}
                      />
                      <label htmlFor="group" />
                      <span className="label_value">
                        {group}
                      </span>
                    </div>
                  )
                  )}
                  </div>
                </div>

                <hr/>
                <div className="button-sections">
                  <button
                      type="button"
                      value="cancel"
                      onClick={this.onCancel}
                      className="empty">
                    Cancel
                  </button>

                  {
                    this.props.mode === "new" ?
                      <div>
                        <input
                            type="checkbox"
                            name="add_more"
                            onClick={this.toggleAddMore}
                        />
                        <label
                            htmlFor="group"
                        />
                        <span className="label_value">
                          Add Another
                        </span>
                      </div>
                      : null
                  }

                  <input
                      type="submit"
                      value="Save"
                      className={inputs.submit.class}
                  />
                </div>

            </form>

        return markup
    }
}

export default connect((store) => store)(UserFormView)
