import React, {Component} from "react";
import CILoading          from "../../component-inner-loading";
import EllipsisTooltip    from "../../../page/common/ellipsis-tooltip";

class SearchListArea extends Component
{
    constructor(props)
    {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return nextProps.renderList;
    }

    render()
    {
        let {loading, inputs, mode, disableDesignResults, disableAll, coId} = this.props;
        let markup = <div className="list-area">
                    {
                        loading == true ?
                        <CILoading position="top" topInPx="50"/>
                    :
                    <ul>
                        {
                            inputs.map((input, i) =>
                            {
                                let disabled = disableDesignResults == true && input.status === 'DESIGN'
                                if (mode === 'changeOrder')
                                {
                                    if (input.result.co)
                                    {
                                        if (input.result.co._id === coId && input.isAddedInList)
                                        {
                                            disabled = true
                                        }
                                    }
                                }

                                let labelClass = input.isAddedInList ? "cross-checked" : disabled ? " disabled" : ""
                                disabled = labelClass.length > 0 || disableAll;
                                let block =
                                    <li key={i}>
                                        <span>
                                            <input
                                                className={`checked-input ${disableAll ? 'disabled' : ''}`}
                                                type="checkbox"
                                                name="category-check-all"
                                                checked={input.isAddedInList || !input.checked ? false : true}
                                                onClick={disabled ? null : (e) => this.props.onInputChange(i,e)}
                                                disabled={disabled}
                                            />
                                            <label
                                                className={`checked-label ${labelClass} ${disableAll ? 'disabled' : ''}`}
                                                htmlFor="category-check-all"
                                                disabled={disabled}
                                            />
                                        </span>

                                    <EllipsisTooltip
                                        index={i}
                                        classes={"ellipsis-tooltip co-form-tip"}
                                        title={`${input.cpn} ${input.name}`}
                                        innerClasses={"cpn-name-holder"}
                                    >
                                            <span className="cpn">
                                                {input.cpn}
                                            </span>
                                            <span data-name="name">
                                                {input.name}
                                            </span>
                                        </EllipsisTooltip>
                                    </li>
                                return block
                            })
                        }
                    </ul>
                    }
                </div>
        return markup;
    }
}

export default SearchListArea;
