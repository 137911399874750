import                         "./index.css";
import React                   from "react";
import Tooltip                 from 'rc-tooltip';
import Icon                    from "../../../ui/icon";
import coAlertSrc              from "../../../../assets/icons/co-alert.svg";

const RevisionField = (props) =>
{
    let {revision, item, vendor=false} = props;
    let alias            = item.alias === 'prd' ? 'Product' : 'Component';
    let tooltipMessage   = `${alias} has been modified`;
    let allowIconForCO   = item.co && ['REJECTED', 'NONE'].includes(item.co.resolution) && item.modified;

    return( <span className='revision-value'>
            {revision ? revision : "—"}
            {
                !vendor && props.showIcon && item.modified || allowIconForCO ?
                <Tooltip
                    placement={"right"}
                    overlayClassName={"custom-rev-tooltip"}
                    overlay={tooltipMessage}
                >
                    <div className="modified">
                        <Icon
                            src={coAlertSrc}
                        />
                    </div>
                </Tooltip>
                :
                ""
            }
            </span>
    )
}

RevisionField.defaultProps = {
    showIcon: true
}

export default RevisionField;
