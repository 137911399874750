import                      "./index.css"
import React, { Component } from "react"
import {connect}            from "react-redux"
import InlineIcon           from "../icon/inline-icon.js"
import ProductIcon          from "../../../assets/icons/product-icon"
import ComponentIcon        from "../../../assets/icons/assembly-add-icon"
import ChangeOrderIcon      from "../../../assets/icons/triangle-icon"
import ReleaseIcon          from "../../../assets/icons/release";
import buildAction          from "../../../helpers/buildAction"
import { privatePaths }     from "../../../app/routes"
import Query                from "../../../modules/query"
import SEARCH               from "../../../action-types/search"
import ITEM_ASSEMBLY        from "../../../action-types/item-assembly"
import UI                   from "../../../action-types/ui"
import ItemsList            from "../items-list/index"
import Utils                from "../../../modules/utils"
import Permissions          from "../../../modules/schemas/permissions"
import Icon                 from "../icon";

class Sidebar extends Component
{
    constructor(props, context) {
        super(props, context);
        this.onOptionClick     = this.onOptionClick.bind(this)
        this.setSearchState    = this.setSearchState.bind(this)
        this.isProductPath     = this.isProductPath.bind(this)
        this.isComponentPath   = this.isComponentPath.bind(this)
        this.isChangeOrderPath = this.isChangeOrderPath.bind(this)
        this.isReleasePath     = this.isReleasePath.bind(this)
        this.onMouseDown       = this.onMouseDown.bind(this)
        this.onMouseMove       = this.onMouseMove.bind(this)
        this.onMouseUp         = this.onMouseUp.bind(this)
        this.onMouseLeave      = this.onMouseLeave.bind(this)
        this.openCloseList     = this.openCloseList.bind(this)
        this.setScrollHight    = this.setScrollHight.bind(this)
        this.isValidRoute      = this.isValidRoute.bind(this)
        this.handleRezise      = this.handleRezise.bind(this)
        this.openCloseItemListForOnBoarding      = this.openCloseItemListForOnBoarding.bind(this)


        this.state             = {
                openItemList: false,
                widthClass: "",
                style: Utils.getStore("side-bar-styles") || {
                    width: "270",
                    minWidth: "270"
                }
            }

        this.initialState       = Utils.clone(this.state)
    }

    onOptionClick(event, option)
    {
        event.preventDefault();
        option.pathname === "/search" && this.setSearchState(option);
        window.changeLeftNavHeight();

        if (option.state.query === "type:prd")
        {
            this.props.history.push({pathname: option.pathname, state: option.state});
            const {dispatch} = this.props;
            dispatch(buildAction(ITEM_ASSEMBLY.MARK_ASSEMBLY_UN_SELECTED));
            let state = this.state;
            state.openItemList = true;
            this.setState(state);
        }
        else if (option.state.query === "type:cmp")
        {
            this.props.history.push({pathname: option.pathname, state: option.state});
        }
        else
        {
            this.props.history.push({pathname: option.pathname, state: option.state});
        }
    }

    componentDidMount()
    {
        this.setScrollHight()
        this.handleRezise()
        window.addEventListener('resize', this.handleRezise)
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', this.handleRezise)
    }

    setScrollHight()
    {
        window.changeLeftNavHeight()
    }

    componentWillReceiveProps(nextProps)
    {
        let state = this.state
        const {dispatch} = this.props;

        if(nextProps.item_assembly.openItemList)
        {
            state.openItemList = true
            dispatch(buildAction(ITEM_ASSEMBLY.SET_OPEN_ITEM_LIST, false));
        }

        if (this.props.item_assembly.resetAssembly || (!this.isValidRoute() && JSON.stringify(this.initialState) !== JSON.stringify(this.state)))
        {
            state = this.initialState
            state.style.class = this.state.style.class
            dispatch(buildAction(ITEM_ASSEMBLY.RESET_STATE));
            dispatch(buildAction(ITEM_ASSEMBLY.RESET_ITEM_LIST, false));
        }

        if (this.props.on_boarding.currentEnv === "SANDBOX" && this.props.location.onBoarding && window.location.pathname.match("/product/view") && this.props.item_assembly.productList.length)
        {
            this.openCloseItemListForOnBoarding(true)
        }
        else if (this.props.on_boarding.currentEnv === "SANDBOX" && this.props.on_boarding.displayOnBoardingNav && this.props.location.onBoarding && !window.location.pathname.match("/product/view"))
        {
            this.openCloseItemListForOnBoarding(false)
        }

        this.setState(state)
    }

    componentDidUpdate(prevProps, prevState)
    {
        this.setScrollHight()

        window.changeRoutesWidth()

        if (!this.isValidRoute())
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.remove("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.remove("list-bottom");
        }

    }

    isValidRoute()
    {
        let route = window.location.pathname
        let isValidRoute = false
        if (
                (
                    route.match("/component")   ||
                    route.match("/product")     ||
                    route.match("/changeorder") ||
                    route.match("/dashboard")   ||
                    route.match("/search")      ||
                    route.match("/export")

                )
            )
            isValidRoute = true
        return isValidRoute
    }

    setSearchState(option) {
        const {dispatch} = this.props;
        let value  = option.state ? option.state.query : ""
        let query  = Query.parse(value)
        let string = Query.stringify(query)

        const payload = {
          value: value,
          lean: true,
          query: {
            string: string,
            object: query
          }
        }
        dispatch(buildAction(SEARCH.RESET_STATE, payload));
        dispatch(buildAction(SEARCH.GET_SEARCH_DATA));
    }

    isProductPath()
    {
        let query = this.props.search.value
        return (this.props.location.pathname.match("/search") && privatePaths.productSearch.state.query === query) ||
               this.props.location.pathname.match("/product")
    }

    isComponentPath()
    {
        let query = this.props.search.value
        return (this.props.location.pathname.match("/search") && privatePaths.componentSearch.state.query === query) ||
               this.props.location.pathname.match("/component")
    }

    isChangeOrderPath()
    {
        let query = this.props.search.value
        return (this.props.location.pathname.match("/search") && privatePaths.changeOrderSearch.state.query === query) ||
               this.props.location.pathname.match("/changeorder")
    }

    isReleasePath()
    {
        let query = this.props.search.value;
        return (this.props.location.pathname.match("/search") && privatePaths.releaseSearch.state.query === query) ||
               this.props.location.pathname.match("/release");
    }

    handleRezise()
    {
        let style = this.state.style
        let currentWidth = style.class === 'narrow' ? 55 : style.width

        if (window.innerWidth <= 768) {
            style.class = 'narrow'
            style.width = 55
            style.minWidth = 55
        } else {
            style.class = currentWidth === 55 ? 'narrow' : ''
            style.width = currentWidth
            style.minWidth = currentWidth
        }

        this.setState({ style })
    }

    onMouseDown(e) {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseUp(e) {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        e.preventDefault();
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e) {
        if (e.pageX >= 55){
            if (this.state.style.width !== e.pageX){
                let state = this.state
                state.style.class    = ""
                state.style.width    = e.pageX
                state.style.minWidth = e.pageX
                this.setState(this.state)
            }
        }

        let rightSideBarElm = document.querySelector(".sidebar-view")
        if (rightSideBarElm)
        {
            let diffContainerElm = document.querySelector(".diff-tool-container")
            let diffHolderWidth = window.innerWidth - rightSideBarElm.clientWidth
            if (diffContainerElm)
            {
                diffContainerElm.style.maxWidth = diffHolderWidth + 'px'
            }
        }
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseLeave(e){
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);

        e.preventDefault();
        e.stopPropagation()
        e.preventDefault()
        let state = this.state

        if (state.openItemList && this.props.item_assembly.productList.length)
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.add("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.add("list-bottom");
        }
        if (state.style.width >= 141 && state.style.width < 270)
        {
            state.style.class    = ""
            state.style.width    = 270
            state.style.minWidth = 270
        }
        else if (state.style.width >= 55 && state.style.width < 141)
        {
            state.style.class    = "narrow"
            if (state.openItemList && this.props.item_assembly.productList.length)
            {
                let element = document.getElementById("componentNavLink")
                if ( element ) element.classList.remove("list-bottom");
                element = document.getElementById("changeOrderNavLink")
                if ( element ) element.classList.remove("list-bottom");
            }
        }
        else if (state.style.width > 800)
        {
            state.style.width    = 800
            state.style.minWidth = 800
            state.style.class    = ""
        }
        else{
            state.style.class    = ""
        }

        setTimeout(() => {
            Utils.setStore("side-bar-styles", state.style)
            this.setState(state)
            window.changeRoutesWidth()
        }, 10);


        let rightSideBarElm = document.querySelector(".sidebar-view")
        if (rightSideBarElm)
        {
            let diffContainerElm = document.querySelector(".diff-tool-container")
            let diffHolderWidth = window.innerWidth - rightSideBarElm.clientWidth
            if (state.style.width === 800 || state.style.width === 270)
            {
                diffHolderWidth = window.innerWidth - state.style.width
            }
            else if (state.style.class === "narrow")
            {
                diffHolderWidth = window.innerWidth - 55
            }
            if (diffContainerElm)
            {
                diffContainerElm.style.maxWidth = diffHolderWidth + 'px'
            }
        }
        e.stopPropagation()
        e.preventDefault()
    }

    openCloseItemListForOnBoarding(openCloseFlag)
    {
        let state = this.state
        state.openItemList = openCloseFlag
        if (state.openItemList && this.props.item_assembly.productList.length)
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.add("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.add("list-bottom");
        }
        else
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.remove("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.remove("list-bottom");
        }
        this.setState(state)
    }

    openCloseList() {
        let state = this.state
        if (this.props.item_assembly.productList.length)
            state.openItemList = state.openItemList === false ? true : false
        if (state.openItemList && this.props.item_assembly.productList.length)
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.add("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.add("list-bottom");
        }
        else
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.remove("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.remove("list-bottom");
        }
        this.setState(state)
    }

    render()
    {

        var componentSectionClass, changeOrderSectionClass = ""

        componentSectionClass = componentSectionClass + (this.isComponentPath() ? " active": "")

        let componentLastOneClass = Permissions.can("view", "change_order", this.props.user.data) && window.__libraryType === 'GENERAL' ? "last-one" : "last"
        componentSectionClass = componentSectionClass + ((this.state.openItemList && this.state.style.class !== "narrow") ? (" list-bottom " + componentLastOneClass) : "")

        changeOrderSectionClass = changeOrderSectionClass + (this.isChangeOrderPath() ? " active": " ")
        changeOrderSectionClass = changeOrderSectionClass + ((this.state.openItemList && this.state.style.class !== "narrow") ? " list-bottom last" : "")

        // let componentLastOneClass = Permissions.can("view", "release", this.props.user.data) ? "last-one" : "last"
        let releaseSectionClass = (this.state.openItemList && this.state.style.class !== "narrow") ? "release-tab list-bottom end-last" : "release-tab";
        releaseSectionClass = releaseSectionClass + (this.isReleasePath() ? " active": "")

        if (window.__libraryType !== 'GENERAL') changeOrderSectionClass = `${changeOrderSectionClass} end-last`
        let markup =

            <div className={"sidebar-view " + this.state.style.class} style={{width: this.state.style.width+"px", minWidth: this.state.style.minWidth+"px"}} id="sidebar-view">
                <div
                    className="sidebar-expand"
                    onMouseDown={(e) => this.onMouseDown(e)}
                >
                    <div className="border"/>
                </div>

                <div className={"nav-list " + ((this.state.openItemList && this.state.style.class !== "narrow") ? "expanded-products" : "")} style={{maxWidth: this.state.style.maxWidth}}>
                    <ul>
                        <li className="item-tab">
                            <a  className="close-tab sideNavLinks" onClick={this.openCloseList} >
                            </a>
                            <span
                                className={this.isProductPath() ? "product-nav active": "product-nav"}
                                onClick={(e) => this.onOptionClick(e, privatePaths.productSearch)}
                                data-tip={this.state.style.class === "narrow" ? "Products" : ""}
                            >
                                <span className="nav-icon">
                                    <InlineIcon>
                                      <ProductIcon />
                                    </InlineIcon>
                                </span>
                                <span className="nav-text">Products</span>
                            </span>
                            {
                                this.isValidRoute() ?
                                <div className={ (this.state.openItemList && this.state.style.class !== "narrow") ? "" : "hidden"}>
                                    <ItemsList {...this.props} />
                                </div> : null

                            }
                        </li>


                        <li className={componentSectionClass} id="componentNavLink">
                            <a
                                href="javascript:void(0)"
                                className="sideNavLinks"
                                onClick={(e) => this.onOptionClick(e, privatePaths.componentSearch)}
                                data-tip={this.state.style.class === "narrow" ? "Component Library" : ""}
                            >
                                <span className="nav-icon">
                                    <InlineIcon>
                                      <ComponentIcon />
                                    </InlineIcon>
                                </span>
                                <span className="nav-text">Components</span>
                            </a>
                        </li>

                        {
                            Permissions.can("view", "change_order", this.props.user.data) &&
                            <li className={changeOrderSectionClass} id="changeOrderNavLink">
                                <a
                                    href="javascript:void(0)"
                                    className="sideNavLinks"
                                    onClick={(e) => this.onOptionClick(e, privatePaths.changeOrderSearch)}
                                    data-tip={this.state.style.class === "narrow" ? "Change Orders" : ""}
                                >
                                    <span className="nav-icon">
                                        <InlineIcon>
                                          <ChangeOrderIcon />
                                        </InlineIcon>
                                    </span>
                                    <span className="nav-text">Change Orders</span>
                                </a>

                            </li>
                        }

                        {
                            window.__libraryType === 'GENERAL' &&
                            <li className={releaseSectionClass} id="releaseNavLink">
                                <a
                                    href="javascript:void(0)"
                                    className="sideNavLinks"
                                    onClick={(e) => this.onOptionClick(e, privatePaths.releaseSearch)}
                                    data-tip={this.state.style.class === "narrow" ? "Release Library" : ""}
                                >
                                    <span className="nav-icon">
                                        <InlineIcon>
                                            <ReleaseIcon/>
                                        </InlineIcon>
                                    </span>
                                    <span className="nav-text">Releases</span>
                                </a>
                            </li>
                        }
                    </ul>
                </div>

            </div>
        return markup
    }
}

export default connect((store) => store)(Sidebar)
