import React, { Component } from "react"
import RightSearchMenu      from "../../../../../../ui/right-search-menu"
import Utils                from "../../../../../../../modules/utils"
import Query                from "../../../../../../../modules/query"
import {connect}            from "react-redux"
import buildAction          from "../../../../../../../helpers/buildAction"
import ITEM_ASSEMBLY        from "../../../../../../../action-types/item-assembly"
import PRODUCT              from "../../../../../../../action-types/product"
import COMPONENT            from "../../../../../../../action-types/component"
import DISPLAYSIDEBAR       from "../../../../../../../action-types/display-sidebar"
import API                  from "../../../../../../../modules/api"
import Config               from "../../../../../../../modules/config"

let searchState =
{
    page                 : 1,
    value                : "",
    selections           : [],
    inputs               : [],
    results              : [],
    count                : 0,
    selectAllFlag        : false,
    selectAllButoonClazz : ""
}
class AssemblySearchMenu extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            modal :
            {
                open     : false,
                width    : 270,
                minWidth : 270
            },
            child_render_flag : true,
            parentId          : this.props.component._id,
            loading_flag      : true,
            search            : {...searchState},
            lastchecked       : -1
        }
        this.onSearchChange           = this.onSearchChange.bind(this)
        this.onInputChange            = this.onInputChange.bind(this)
        this.selectAll                = this.selectAll.bind(this)
        this.add                      = this.add.bind(this)
        this.setSelectAllButtonStatus = this.setSelectAllButtonStatus.bind(this)
        this.getDataFromAPI           = this.getDataFromAPI.bind(this)
        this.showHideAssemblySearch   = this.showHideAssemblySearch.bind(this)

    }

    componentWillUnmount()
    {
        let dispatch = this.props.dispatch
        dispatch(buildAction(DISPLAYSIDEBAR.SET_LIBRARY_FLAG, {display: false}))
    }

    componentWillReceiveProps(nextProps)
    {
        if (!this.props.side_bar_flag && nextProps.side_bar_flag)
        {
            let app_row = document.getElementsByClassName("banner-block")
            if(app_row && app_row[0])
            {
                app_row[0].classList.add("open-right-nav");
            }
            let state            = this.state
            state.loading_flag   = true
            state.search = {...searchState}
            // state.search.page           = 1
            // state.search.results = []
            // state.search.inputs  = []
            state.lastchecked       = -1
            this.setState(state, this.getDataFromAPI)
        }
        if (this.props.side_bar_flag && !nextProps.side_bar_flag)
        {
            let app_row = document.getElementsByClassName("banner-block")
            if(app_row && app_row[0])
            {
                app_row[0].classList.remove("open-right-nav");
            }
        }
    }

    onInputChange(i, event)
    {
        let search = this.state.search
        let state = this.state
        let current = i
        if (event.shiftKey)
        {
            let checkedstate = false
            let start = -1;
            let end  = -1;
            if(!search.inputs[current].checked)
            {
                checkedstate = true
            }

            if(state.lastchecked < current && state.lastchecked !== -1)
            {
                start = state.lastchecked
                end = current
            }
            if(state.lastchecked > current && state.lastchecked !== -1)
            {
                start = current
                end = state.lastchecked
            }
            if(start >= 0 && end >= 0)
            {
                for(let j = start; j <= end; j++)
                {
                    search.inputs[j].checked = checkedstate
                }
                if(!search.inputs[current].checked)
                {
                    search.inputs[current].checked = checkedstate
                }
            }
        }
        else
        {
            search.inputs[i].checked = !search.inputs[i].checked
        }
        state.lastchecked = i
        state.child_render_flag = !state.child_render_flag
        this.setSelectAllButtonStatus(search)
        this.setState(state)
    }

    setSelectAllButtonStatus(search)
    {

        let columns = search.inputs
        let count = 0
        columns.map((col, i) =>
        {
            if (col.checked || col.isAddedInList)
            {
                count++
            }
        })

        if(count === columns.length && columns.length > 0)
        {
            search.selectAllFlag = true
            search.selectAllButoonClazz = ""
        }
        else if (count !== columns.length && count > 0)
        {
            search.selectAllFlag = false
            search.selectAllButoonClazz = "middle"
        }
        else
        {
            search.selectAllButoonClazz = "";
        }
    }


    selectAll()
    {
        let search = this.state.search
        if (!search.selectAllFlag)
        {
            search.selectAllFlag = true
            search.selectAllButoonClazz = ""
            search.inputs.forEach(input => input.checked = true)
        }
        else
        {
            search.selectAllFlag = false
            search.inputs.forEach(input => input.checked = false)
        }
        this.setSelectAllButtonStatus(search);
        this.setState({search})
    }

    getQuery(value="")
    {
       let list  = this.props.addedChildList
       let excludeList = " limit:"+ Config.RIGHT_SIDE_BAR_LIMIT + " exclude: " + this.state.parentId + " "
       list.forEach((item, i) =>
       {
           excludeList += item.component._id + " "
       })
       let query        = Query.parse(value)
       query.type = "cmp"
       query.response_type = "assemblyEdit";
       let queryString  = Query.stringify(query)
       return queryString + excludeList
    }

    onSearchChange(event)
    {
        let state = this.state
        let value  = event.target.value
        state.search.value = value
        state.loading_flag = true
        state.search.page = 1
        this.setState(state, () => this.getDataFromAPI(true));
    }

    getDataFromAPI(onSearchChangeFlag=false)
    {
        let state = this.state
        let value = state.search.value
        let page  = state.search.page
        let query = this.getQuery(value ? value : '')

        API.search( query, page, (err, data) =>
        {
            if(err)
            {
                //TODO: handle err
            }
            let searchInputs = []
            let results = data.results
            let state = this.state
            let search     = state.search
            if(onSearchChangeFlag)
            {
                state.search.inputs = []
                state.search.results = []
                if (value && value.trim())
                {
                    let payload = {
                        searchString: value,
                        searchSource: "Edit Assembly search sidebar",
                        resultsCount: data.count
                    }
                    API.services.recordSearch(payload)
                }
            }
            let index = this.state.search.results.length
            results.forEach((result, i) =>
            {
                let cpn = Utils.getCpn(result);
                let input =
                {
                    cpn   :  cpn,
                    name   : result.name,
                    checked: false,
                    index : index + i,
                    isAddedInList : false
                }
                searchInputs.push(input)
            })
            search.inputs  = [...search.inputs,...searchInputs]
            search.results = [...search.results,...results]
            search.count   = data.count
            search.page    = search.page + 1
            this.setSelectAllButtonStatus(search);
            this.setState((state) => ({search: state.search, loading_flag: false}));
        })
    }

    add()
    {
        let search      = this.state.search
        let inputs      = search.inputs
        let parentType  = this.props.parentType
        let selectedCmps = []
        inputs.forEach((input) =>
        {
            if (input.checked && !input.isAddedInList)
            {
                input.isAddedInList = true
                let child =
                {
                    quantity  : "",
                    refDes    : "",
                    itemNumber: "",
                    component : search.results[input.index],
                    inputs    :
                    {
                        quantity :
                        {
                            value : "",
                            message: "",
                            valid: false,
                            class: ""
                        },
                        refDes :
                        {
                            value : ""
                        },
                        itemNumber :
                        {
                            value : ""
                        },
                        notes :
                        {
                            value : "",
                            message: "",
                            valid: true,
                            class: ""
                        },
                        waste :
                        {
                            value : "",
                            message: "",
                            valid: true,
                            class: ""
                        }
                    },
                    newlyAdded: true
                }

                let error = this.props.validations.quantity.validateErrors({refDes: ""}, "")
                child.inputs.quantity.message = error ? error[0].message : ""
                child.inputs.quantity.valid = !child.inputs.quantity.message
                child.inputs.quantity.class = child.inputs.quantity.valid ? "" : "invalid"



                if (this.props.parentType === "Component" && this.props.component && Utils.isItemNumber(this.props.component.category))
                {
                    let isAllowedBlankItemNumber = window.__isAllowedBlankItemNumber
                    let error = this.props.validations.itemNumber.validateErrors({isAllowedBlankItemNumber}, "")
                    child.inputs.itemNumber.message = error ? error[0].message : ""
                    child.inputs.itemNumber.valid = !child.inputs.itemNumber.message
                    child.inputs.itemNumber.class = child.inputs.itemNumber.valid ? "" : "invalid"
                }
                selectedCmps.push(child)
            }
        })

        if(selectedCmps.length < 1) return
        this.props.newlyAddedComponentsFromRightSearch(selectedCmps)
        search.selectAllFlag = false
    }

    showHideAssemblySearch(event)
    {
        let app_row = document.getElementsByClassName('banner-block');
        if(app_row && app_row[0])
        {
            app_row[0].classList.remove("open-right-nav");
        }
        this.props.showHideAssemblySearch()
    }

    render()
    {
        let markup;
        if(this.props.side_bar_flag)
        {
            markup =
                <RightSearchMenu
                    heading="Add Components"
                    customClass="add-component-child-assemblies"
                    add={this.add}
                    onSearchChange={this.onSearchChange}
                    onInputChange={this.onInputChange}
                    selectAll={this.selectAll}
                    search={this.state.search}
                    onModalClose={this.showHideAssemblySearch}
                    changeorders={this.props.changeorders}
                    loading_flag={this.state.loading_flag}
                    getDataFromAPI={this.getDataFromAPI}
                    query={this.state.query}
                    max_pages={Math.ceil(this.state.search.count/Config.RIGHT_SIDE_BAR_LIMIT)}
                    page={this.state.search.page}
                />
        }
        else
        {
            markup = null
        }
        return markup
    }
}

const mapStateToProps = (state) =>
{
    return {
        file_import_bar_flag: state.displaysidebar.file_import_bar_flag,
        side_bar_flag: state.displaysidebar.side_bar_flag
    };
};
export default connect(mapStateToProps)(AssemblySearchMenu)
