import {
  takeEvery,
  takeLatest,
  all,
  cps,
  put,
  select
} from "redux-saga/effects"
import { pad } from "lodash"
import UI from "../../../action-types/ui"
import COMPONENT from "../../../action-types/component"
import ITEM_ASSEMBLY from "../../../action-types/item-assembly"
import ASSEMBLY_EDIT from "../../../action-types/assembly-edit"
import {privatePaths} from "../../../app/routes.js"
import { userById } from "graphql/query/userQueries"
import buildAction from "../../../helpers/buildAction"
import { getInputs, getComponent } from "./selector"
import API from "../../../modules/api"
import Utils from "../../../modules/utils"
import { extractCpnRules, formatLegacyCpn } from "utils/cpn"

import validations, { validateField, schemas}  from '../../../modules/validations'

export function* findComponentById(action)
{
    let {id} = action.payload
    let response = null
    yield put(buildAction(ASSEMBLY_EDIT.SET_CHILDREN, {children: [], modified: false}))
    yield put(buildAction(COMPONENT.DISABLE_FORM, true))
    yield put(buildAction(COMPONENT.RESET_COMPONENT_EDIT_FORM_INPUTS))
    try
    {
        const component = yield cps(API.components.findById, id)
        if (!component) action.payload.history.push({pathname: "/search", state: {query: "type:cmp"}})
        else
        {
            let urlParams = new URLSearchParams(window.location.search)
            let refer = urlParams.has('refer') ? urlParams.get('refer') : null
            if (action.payload.editMode && component.co && component.status.toLowerCase() !== "design" && refer !== "co" && component.isEditableWhileInCo !== true)
            {
                throw `This Component is in an unresolved Change Order with Status: ${component.co.status} and can not be edited.`;
            }

            if (action.payload.hasOwnProperty('bulkRevisionValue') && action.payload.hasOwnProperty('bulkStatusValue'))
            {
                if(action.payload.bulkStatusValue !== component.status )
                {
                    component.status = action.payload.bulkStatusValue
                    component.modified = true
                }
                if(action.payload.bulkRevisionValue !== component.revision)
                {
                    component.revision = action.payload.bulkRevisionValue
                    component.modified = true
                }
            }
            yield put(buildAction(COMPONENT.SET_COMPONENT_IN_EDIT_FORM, component))
            yield put(buildAction(ASSEMBLY_EDIT.SET_CHILDREN, {children: component.children || []}))
        }

        if (component && component.archived && !component.revisions.length)
        {
            yield put(buildAction(COMPONENT.EDIT_FORM_EID_CHANGE, {value: component.eid}));
            yield put(buildAction(COMPONENT.EDIT_FORM_NAME_CHANGE, {value: component.name}));
            if(!window.__isIntelligentCpnScheme && window.__currentCompanyCpnType === "FREE-FORM")
                yield put(buildAction(COMPONENT.EDIT_FORM_CPN_CHANGE, {value: component.cpn}));
        }

    }
    catch(err)
    {
        let { id } = action.payload;
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err,
            closeCb: action.payload.notFoundCb || function() {
                Utils.redirectToParentRoute(privatePaths.componentSearch)
            }
        }

        if (err.includes('This Component is in an unresolved Change Order with Status:'))
        {
            let error = {errors: [{message: err}]}

            payload.errors = error.errors;
            payload.err = error;
            payload.errorType = "custom";
            payload.closeCb = () => { Utils.redirectToParentRoute(`/component/view/${id}`) };
            payload.confirmBtnText = "OK";
            payload.errorHeading = "Error";
        }

        yield put(buildAction(UI.SHOW_ALERT, payload))

    }
}

export function* deleteComponent(action)
{
    try
    {
        let deleteRequestBody = {}
        if (action.payload.forceDelete) deleteRequestBody = {forceDelete: true}
        yield cps(API.components.deleteById, action.payload.component._id, deleteRequestBody)
        yield put(buildAction(ITEM_ASSEMBLY.REMOVE_COMPONENT_FROM_LEFT_NAV_TREE, {_id: action.payload.component._id}))
        if (action.payload.viewRoute)
            action.payload.history.push({pathname: "/search", state: {query: "type:cmp"}})
        else
        {
            yield put(buildAction(COMPONENT.RESET_COMPONENT_EDIT_FORM, {}))
            if (action.payload.originalComponentId)
                action.payload.history.push("/component/view/" + action.payload.originalComponentId)
            else
                action.payload.history.push("/component/new/manual")
        }
    }
    catch(err)
    {
        let cb = function()
        {
            action.payload.history.push("/component/edit/" + action.payload.component._id)
        }
        let payload =
        {
            err: err,
            errorType: "custom",
            type: "errors",
            errors: err.errors,
            closeCb: cb
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

function* onNameChange(action)
{
    let inputs    = yield select(getInputs)
    let component = yield select(getComponent)
    let input     = inputs.name
    let value     = action.payload.value

    try
    {
        validateField(inputs.name, validations.component.name, value)
        inputs["nameDuplicateOf"] = null
        if (input.valid)
        {
            input.valid = false
            yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))
            let data = yield cps(API.components.nameExists, {name: value, id: component._id})
            let exists = data.exist
            input.message = exists ? "Name already exists in library." : ""
            input.valid   = !input.message
            input.class   = input.valid ? "" : "invalid"
            inputs["nameDuplicateOf"] = data.duplicate_of
        }
        yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}


function* onEidChange(action)
{
    let inputs    = yield select(getInputs)
    let component = yield select(getComponent)
    let input     = inputs.eid
    let value     = action.payload.value

    try
    {
        validateField(input, validations.component.eid, value)
        inputs["eidDuplicateOf"] = null
        if (input.valid)
        {
            input.valid = false
            yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))
            try
            {
                let payload = {eid: String(value), id: component._id}
                let data = yield cps(API.components.eidExists, payload)
                let exists = data.exist
                input.message = exists ? "EID already exists in library." : ""
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                inputs["eidDuplicateOf"] = data.duplicate_of
            }
            catch(err)
            {
                input.message = "Some error has been occured"
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }
        yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

function* onCpnChange(action)
{
    let inputs    = yield select(getInputs)

    let component = yield select(getComponent)
    let input     = inputs.cpn
    let value     = action.payload.value
    try
    {
        inputs["cpnDuplicateOf"] = null
        if (input.valid)
        {
            input.valid = false
            yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))
            try
            {
                let payload = {cpn: String(value), id: component._id, company: component.company, lib: component.library}
                let data = yield cps(API.components.cpnExists, payload)
                let exists = data.exist
                input.message = exists ? "CPN already exists in library." : ""
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                data.duplicate_of ? inputs["cpnDuplicateOf"] = data.duplicate_of : ""
            }
            catch(err)
            {
                input.message = "Some error has been occured"
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }
        yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

export function* updateInputState(action)
{
    let inputs = yield select(getInputs)
    let component = yield select(getComponent)

    let name   = action.payload.name
    let value  = action.payload.value
    let input  = ""
    let statusesWithIntegerRevision = ["DESIGN", "PROTOTYPE"]
    let statusesWithNonIntegerRevision = ["PRODUCTION", "OBSOLETE"]
    const isSpecValidationEnabled = window.__isSpecValidationEnabled;

    switch(name)
    {
        case "name" :
        {
            inputs.inputChanged = true
            yield put(buildAction(COMPONENT.EDIT_FORM_NAME_CHANGE, {value}))
            break
        }

        case "category" :
        {
            inputs.inputChanged = true
            let categoryObject  = schemas.categories.findByName2(value, window.__categories)
            input           = inputs.category
            if (value === component.category)
            {
                inputs.specs = component.specs
                component.categoryCPN = component.cpn
                if (component.status === inputs.status.value){
                    inputs.revision.value = component.originalRevision
                }
            }
            else
            {
                inputs.specs    = getSpecInputs(value, isSpecValidationEnabled)
                if  (action.payload.cpnType === "CPN_RULES") {
                    const { activeLibrary } = userById();
                    const { prefixDelimiter, counterLength } = extractCpnRules(activeLibrary, "COMPONENT");
                    component.categoryCPN = `${categoryObject.code}${prefixDelimiter}${pad("", counterLength, "X")}`
                }
                else if(!window.__nonIntelligentCPN())
                {
                    component.categoryCPN = categoryObject.code + '-XXXXX'
                }
                else if(window.__nonIntelligentCPN() && !component.archived)
                {
                    component.categoryCPN = Utils.getCpnTag(categoryObject, false)
                }

                if ( inputs.status.value.toLowerCase() === "design"){
                    inputs.revision.value = component.originalRevision//"1"
                }
            }
            validateField(input, validations.component.category, value, {categoryObject: categoryObject, cpnType: window.__currentCompanyCpnType, isSpecValidationEnabled});
            Utils.blur();
            break;
        }

        case "revision" :
        {
            inputs.inputChanged = true
            validateField(inputs.revision, validations.component.revision, value.toUpperCase(), {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})

            if (inputs.status.value === 'DESIGN' && component.status === 'DESIGN')
            {
                if (inputs.revision.valid) component.originalRevision = value.toUpperCase()
            }

            break
        }

        case "status" :
        {
            inputs.inputChanged = true

            inputs.status.value   = value
            if  (
                    statusesWithIntegerRevision.includes(value) &&
                    statusesWithIntegerRevision.includes(component.status) &&
                    inputs.category.value === component.category ||
                    statusesWithNonIntegerRevision.includes(value) &&
                    statusesWithNonIntegerRevision.includes(component.status) &&
                    inputs.category.value === component.category
                )
            {
                inputs.revision.value = component.originalRevision
            }
            else
            {
                inputs.revision.value = validations.component.revision.normalize({status: inputs.status.value}, inputs.revision.value).revision
            }

            validateField(inputs.revision, validations.component.revision, inputs.revision.value, {status: inputs.status.value})
            break
        }

        case "eid" :
        {
            inputs.inputChanged = true
            yield put(buildAction(COMPONENT.EDIT_FORM_EID_CHANGE, {value}))
            break
        }

        case "description" :
        {
            inputs.inputChanged = true
            input       = inputs.description
            validateField(input, validations.component.description, value)
            break
        }

        case "mass" :
        {
            inputs.inputChanged = true
            input       = inputs.mass
            validateField(input, validations.component.mass, value, {massPrecisionValue: window.__massPrecisionValue});
            break
        }

        case "spec" :
        {
            inputs.inputChanged = true
            input   = inputs.specs[action.payload.index]

            let categoryObject  = inputs.category.value && inputs.category.value ? schemas.categories.findByName2(inputs.category.value, window.__categories) : []
            validateField(input, validations.component.specs.value, value, {categoryObject: categoryObject, key: input.key, isSpecValidationEnabled});
            break
        }

        case "customProperties" :
        {
            inputs.inputChanged = true
            input   = inputs.customProperties[action.payload.index]
            input.value = value;
            break
        }

        case "images" :
        {
            inputs.inputChanged = true
            input       = inputs.images
            input.value = value
            break
        }

        case "documents" :
        {
            inputs.inputChanged = true
            input       = inputs.documents
            input.value = value.documents
            input.valid = value.isUploadedDocuments
            break
        }

        case "children" :
        {
            inputs.inputChanged = true
            input       = inputs.children
            input.value = value.data
            input.valid = value.valid
            break
        }

        case "manufacturers" :
        {
            // if (value.data.length !== 0)
            inputs.inputChanged = value.inputChanged

            input       = inputs.manufacturers
            input.value = value.data
            input.valid = value.valid
            break
        }

        case "cpnVariant" :
        {
            inputs.inputChanged = true
            input       = inputs.cpnVariant
            let mode = window.__libraryType === 'GENERAL' ? 'LIVE' : 'SANDBOX';
            const { activeLibrary } = userById();
            const cpnRules = extractCpnRules(activeLibrary, "COMPONENT");
            validateField(input, validations.component.cpnVariant, value, {cpnRules, cpnType: action.payload.cpnType, isIntelligentCpnScheme: window.__isIntelligentCpnScheme, nonIntelligent: window.__nonIntelligent, mode: mode});
            if (input.valid)
            {
                input.valid = false
                yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))
                try
                {
                    let cpn;
                    // ToDo: Need to handle the cpn already exists logic on the plm-api side
                    if (window.__cpn_rules() ) {
                      cpn = formatLegacyCpn({ 
                        cpn: component.cpn, hasVariant: component.variantGroup, library: activeLibrary, modelType: "COMPONENT", variant: value
                      })
                    }
                    else if(window.__companyIs11DigitCpnType())
                    {
                        cpn = `${component.cpn.split("-")[0]}-${component.cpn.split("-")[1]}-${component.cpn.split("-")[2]}-${String(value)}`;
                    }
                    else if(window.__companyIs9DigitCpnType())
                    {
                        cpn = `${component.cpn.split("-")[0]}-${component.cpn.split("-")[1]}-${String(value)}`;
                    }
                    else if(window.__with6DigitPrefixAndCounter() || window.__nonIntelligentCPN())
                    {
                        cpn = `${component.cpn.split("-")[0]}-${String(value)}`;
                    }
                    else if(window.__conditional01Variant()) {
                        cpn = `${component.cpn.slice(0,10)}${String(value)}`;
                    }
                    else
                    {
                        cpn = component.cpn.slice(0,10) + String(value);
                    }
                    let res = yield cps(API.components.findByCpn, cpn);
                    input.message = (res && res._id !== component._id) ? "CPN already exists in library." : ""
                    input.valid   = !input.message
                    input.class   = input.valid ? "" : "invalid"
                }
                catch(err)
                {
                    input.message = "Some error has been occured"
                    input.valid   = !input.message
                    input.class   = input.valid ? "" : "invalid"
                    yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
                }
            }
            break
        }

        case "unitOfMeasure" :
        {
            inputs.inputChanged = true
            input = inputs.unitOfMeasure
            validateField(input, validations.component.unitOfMeasure, value)
            break
        }

        case "cpn" :
        {
            inputs.inputChanged = true;
            value = value.trim();
            validateField(inputs.cpn, validations.cpn.value, value, {cpnType: action.payload.cpnType, isIntelligentCpnScheme: window.__isIntelligentCpnScheme, nonIntelligent: window.__nonIntelligent})
            yield put(buildAction(COMPONENT.EDIT_FORM_CPN_CHANGE, {value}));
            break
        }

        default :
        {
            // noop
        }
    }
    let _modified = undefined
    if(input.message)
    {
        _modified = false
    }
    else if (inputs.isUploadedThumbnails)
    {
        _modified = true
    }
    if (_modified === undefined)
        yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS, inputs))
    else
        yield put(buildAction(COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS_WITH_MODIFIED, {inputs: inputs, modified: _modified}))
}

export function* searchComponents(action)
{
    try
    {
        const data = yield cps(API.search, action.payload.query)
        yield put(buildAction(COMPONENT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, data.results ))
    }
    catch(err)
    {
        yield put(buildAction(COMPONENT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, [] ))
    }
}

export function* uploadImage(action)
{
    let inputs = yield select(getInputs)
    if (action.payload.thumbnailUploaderrors.length > 0)
    {
        let errors = []
        let errorsArray = action.payload.thumbnailUploaderrors
        errors.push({ message: "Error in uploading following files:" })
        errorsArray.forEach(function(error, index)
        {
            errors.push({ message: "("+ (index+1) + ") " + error.file })
        })
        errors.push(errorsArray[0].errors[0])
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
    }
    yield put(buildAction(COMPONENT.UPDATE_IMAGES_STATE_ON_EDIT_PAGE, action.payload))

    if (inputs.isUploadedThumbnails)
    {
        yield put(buildAction(COMPONENT.SET_EDIT_PAGE_MODIFIED, true ))
    }
    else
    {
        yield put(buildAction(COMPONENT.SET_EDIT_PAGE_MODIFIED, false ))
    }
}

export function* submitComponentForm(action)
{
    yield put(buildAction(UI.LOAD_START))

    yield put(buildAction(COMPONENT.SET_EDIT_PAGE_MODIFIED, false ))
    let component = yield select(getComponent)
    let componentViewLink = `/component/view/${component._id}${ window.__userRole === "SUPPLIER" ? '?viewRecent=true' : ''}`;
    let componentEditLink = `/component/edit/${component._id}`;

    try
    {
        const showAll = action.payload.showAll ? "?showAll=true" : "";
        const latestComponent = yield cps(API.components.findById, (component._id + showAll))
        let urlParams = new URLSearchParams(window.location.search)
        let refer = urlParams.has('refer') ? urlParams.get('refer') : null
        if (latestComponent.co && latestComponent.status.toLowerCase() !== "design" && refer !== "co" && component.isEditableWhileInCo !== true)
        {
            let errorsPayload =
            {
                type: "errors",
                errorType: "custom",
                modalClass: 'changeorder-warning',
                confirmBtnText : "Ok",
                errorHeading: 'Error',
                donotThrowError: true,
                errors: [{message: "This component is in an open change order and cannot be edited."}]
            }
            yield put(buildAction(UI.SHOW_ALERT, errorsPayload))
            return
        }
        yield cps(API.components.update, component._id, action.payload.data)
        yield put(buildAction(COMPONENT.RESET_COMPONENT_EDIT_FORM, {}))
        action.payload.history.push(componentViewLink)
        let payload = {_id: component._id}
        yield put(buildAction(ITEM_ASSEMBLY.GET_LATEST_CHILDREN, payload))
    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err
          }
        if (err.errors[0].message === "Client schema validation error")
        {
            payload.closeCb = () => {},
            payload.donotThrowError = true
            payload.errorType = "custom"
            payload.errors = [{message: "By switching to revision control Duro validation rules are now active. Any errors found must be corrected before you can continue. If you do not wish to correct these errors now, switch back to design mode before saving."}]
        }
        else if(err.errors[0].message.includes("This Component has been modified by another user"))
        {
            let errors = err.errors[0].message.split('Please');
            payload.type = "confirm";
            payload.errorType = "warning";
            payload.heading = "Warning";
            payload.confirmButtonText = "OK";
            payload.text = `<span>${errors[0]}<br/>Please ${errors[1]}</span>`;;
            payload.confirmCb = () => {
                window.onbeforeunload = undefined;
                Utils.redirectToParentRoute(componentEditLink);
            };
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
    yield put(buildAction(UI.LOAD_END))
}

export function* setPrimarySource(action)
{
    let data = action.payload
    yield put(buildAction(COMPONENT.SET_LOADING, true));
    try
    {
        yield cps(API.components.update, data._id, data.primarySource);
        let component = yield cps(API.components.findById, `${data._id}?include=children,documents,creator,images`);
        // let whereUsed   = yield cps(API.cos.getParentAssemblies, { items: [component._id] })
        //     component.whereUsed = whereUsed
        yield put(buildAction(COMPONENT.SET_COMPONENT_PRIMARY_SOURCE, component));
    }

    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
    yield put(buildAction(COMPONENT.SET_LOADING, false ))

}

function getSpecInputs(category, isSpecValidationEnabled) {
    const categoryObject  = category ? schemas.categories.findByName2(category, window.__categories) : [];
    const specs  = category ? schemas.categories.findByName2(category, window.__categories).specs : []
    let inputs = []
    if (specs && specs.names)
    {
        inputs = specs.names && specs.names.map((spec) =>
        {
            let required = specs.options.required.includes(spec) ? true : false;
            const { allowedValues, isDropDown} = specs.specSettings.find(specName => Object.keys(specName) == spec)[spec];
            let input =
            {
                value   : "",
                class   : "",
                valid   : true,
                message : "",
                schema  : {name: spec, displayName: spec, required: required, allowedValues, isDropDown},
                key     : spec
            };

            validateField(input, validations.component.specs.value, input.value, {categoryObject: categoryObject, key: input.key, isSpecValidationEnabled});
            return input;
        });
    }

    return inputs
}

export default function* (getState)
{
    yield all([
        takeEvery(COMPONENT.GET_COMPONENT_AND_SET_IN_EDIT_PAGE, findComponentById),
        takeLatest(COMPONENT.UPDATE_EDIT_FORM_INPUT_STATE, updateInputState),
        takeLatest(COMPONENT.SEARCH_COMPONENTS_IN_EDIT_PAGE, searchComponents),
        takeLatest(COMPONENT.SUBMIT_COMPONENT_EDIT_FORM, submitComponentForm),
        takeLatest(COMPONENT.DELETE_COMPONENT, deleteComponent),
        takeEvery(COMPONENT.UPLOAD_IMAGES_ON_EDIT_PAGE, uploadImage),
        takeLatest(COMPONENT.EDIT_FORM_NAME_CHANGE, onNameChange),
        takeLatest(COMPONENT.EDIT_FORM_EID_CHANGE, onEidChange),
        takeLatest(COMPONENT.SET_PRIMARY_SOURCE, setPrimarySource),
        takeLatest(COMPONENT.EDIT_FORM_CPN_CHANGE, onCpnChange),

    ])
}
