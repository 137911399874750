import { FetchPolicy } from "@apollo/client";
import { AliasType, ModelType, QueryType, useFetchItem } from "graphql/query/itemQueries";

export const useGetItem = (
  alias: AliasType = AliasType.CMP,
  fetchPolicy: FetchPolicy = "cache-first",
  model: ModelType = ModelType.WORKING,
) => {
  const {
    error: parentError,
    fetchItem: fetchParent,
    item: parentItem,
    loading: parentLoading,
  } = useFetchItem(alias, fetchPolicy, model, QueryType.ITEM);

  const {
    error: childrenError,
    fetchItem: fetchChildren,
    item: childItems,
    loading: childrenLoading,
  } = useFetchItem(alias, fetchPolicy, model, QueryType.CHILDREN);

  return {
    childItems,
    childrenError,
    childrenLoading,
    fetchChildren,
    fetchParent,
    parentError,
    parentItem,
    parentLoading,
  };
};
