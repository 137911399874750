import "./index.css"
import React, { Component }                     from "react"
import { connect }                              from "react-redux"
import { withRouter } from "react-router";
import { Switch, Redirect, Route } from "react-router-dom"
import { publicPaths, privatePaths }            from "../../app/routes"
import USER                                     from "../../action-types/user"
import ON_BOARDING                              from "../../action-types/on-boarding"
import buildAction                              from "../../helpers/buildAction"
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar                         from 'react-perfect-scrollbar'
import Notification                             from "../ui/notification";

// // -- common components --
import Loading                                  from "../ui/loading"
import Header                                   from "../ui/header"
import Sidebar from "../ui/sidebar"
import Alert                                    from "../ui/alert"

// // -- public routes --
import Policy                                   from "../page/legal/policy"
import Terms from "../page/legal/terms"

// // -- private routes --
import Dashboard                                from "./../page/dashboard"
import CompanyProfile                           from "./../page/settings/company"
import Groups                                   from "./../page/settings/groups"
import UserProfile                              from "./../page/settings/user"
import Users                                    from "./../page/settings/users"
import Webhooks                                 from "./../page/settings/webhooks"
import ViewWebhook                              from "./../page/webhook/view"
import NewProduct                               from "./../page/product/new"
import EditProduct                              from "./../page/product/edit"
// import ViewProduct                              from "./../page/product/view"
import ViewProduct                              from "./../page/product/view/tile-view"
import DiffProduct                              from "./../page/product/diff"
import DiffComponent                            from "./../page/component/diff"
import ExportRoute                              from "./../page/export"
import RevisionProduct                          from "./../page/product/revision"
import EditComponent                            from "./../page/component/edit"
import ViewComponent                            from "./../page/component/view"
import RevisionComponent                        from "./../page/component/revision"
import NewChangeOrder                           from "./../page/changeorder/new"
import ViewChangeOrder                          from "./../page/changeorder/view"
import ViewRelease                              from "./../page/release/view"
import EditChangeOrder                          from "./../page/changeorder/edit"
import Search                                   from "./../page/search"
import ViewProductionInstance                   from "./../page/production-instance/view"
import EditProductionInstance                   from "./../page/production-instance/edit"

import Permissions                              from "../../modules/schemas/permissions"
import InlineIcon                               from "../ui/icon/inline-icon.js"
import ModalBox                                 from "../ui/modal-box"
import LoadingIcon from "../../assets/icons/loading"
import Integrations                             from "./../page/settings/integrations";
import URLSearchParams                          from "url-search-params";
import Configurations                           from "./../page/settings/configurations";
import { AppSectionProvider } from "common/appSectionProvider"
import { AppSection } from "context"
import { BuildDashboard } from "build/pages/dashboard"
import { BuildInstance } from "build/pages/instance"
import { BuildInstances } from "build/pages/instances"
import { BuildLots } from "build/pages/lots"
import { BuildLot } from "build/pages/lot"

class Routes extends Component
{

    constructor(props, context) {
        super(props, context);
        this.setScrollHight = this.setScrollHight.bind(this)
        this.allowedRoute = this.allowedRoute.bind(this)
        this.closeAccountSetupProgressModal   = this.closeAccountSetupProgressModal.bind(this);
    }

    closeAccountSetupProgressModal()
    {
        const {syncAccountSetupProgress} = this.props;
        syncAccountSetupProgress();
    }

    componentWillMount()
    {
        // const { user, switchENV } = this.props
        const {switchENV } = this.props
        const user = this.props.user.data
        let isSandboxMode = user && user.currentEnv === "SANDBOX"
        let settingsRoutes =
        [
            "/settings/company",
            "/settings/user",
            "/settings/users",
            "/settings/groups",
            "/settings/integrations",
            "/settings/webhooks",
            "/settings/configurations",
        ]
        if (isSandboxMode && settingsRoutes.includes(window.location.pathname))
        {
            let libraries = user.libraries
            let payload = {
                activeLibrary: libraries[0].type  === "PERSONAL" ?  libraries[1] : libraries[0],
                user: user,
                currentEnv: "LIVE"
            }
            switchENV(payload)
            // dispatch(buildAction(USER.SWITCH_ENV, payload));
        }
    }

    componentDidMount()
    {
        this.setScrollHight()
    }

    allowedRoute(action, className, user, pathname, componentName)
    {
        let urlParams = new URLSearchParams(window.location.search)
        let tab  = urlParams.has('tab') ? urlParams.get('tab') : null

        // If the user does not have permission to view this route, then hide it.
        if (
            !Permissions.can(action, className, user) &&
            // Allow vendors to edit the sourcing tab.
            !(window.__userRole === 'VENDOR' && action === 'edit' && tab && tab.includes('sourcing'))
        ) {
            return null;
        }

        const PageComponent = componentName;

        return (
            <Route path={pathname} render={props =>
                <AppSectionProvider section={AppSection.DESIGN}>
                    <PageComponent {...props} />
                </AppSectionProvider>
            } />
        )
    }

    allowedBuildRoute = (action, className, path, componentName) => {
        const { user } = this.props;

        // TODO: BLD-47: Hide or disable the build feature if build is disabled.

        if (!Permissions.can(action, className, user.data)) {
            return null;
        }

        const PageComponent = componentName;
        const { pathname, state } = path

        return (
            <Route path={pathname}>
                <AppSectionProvider section={AppSection.BUILD}>
                    <PageComponent {...(state ?? {})} />
                </AppSectionProvider>
            </Route>
        )
    }

    setScrollHight()
    {
        window.changeLeftNavHeight()
    }

    componentDidUpdate(prevProps, prevState)
    {
        this.setScrollHight()
    }

    dispatchCustomScrollToEndEvent(container)
    {
        const pathname = window.location.pathname
        const havePagination = pathname === "/search" ||
                             pathname.includes('component/edit') ||
                             pathname.includes('component/view') ||
                             pathname.includes('component/revision') ||
                             pathname.includes('product/edit') ||
                             pathname.includes('product/view') ||
                             pathname.includes('changeorder/new') ||
                             pathname.includes('changeorder/edit') ||
                             pathname.includes('changeorder/view') ||
                             pathname.includes('product/revision') ||
                             pathname.includes('product/diff') ||
                             pathname.includes('component/diff') ||
                             pathname.includes('release/view') ||
                             pathname.includes('webhook/edit') ||
                             pathname.includes('webhook/view') ||
                             pathname.includes('production/instance/view') ||
                             pathname.includes('production/instance/edit')

        if (container.scrollTop !== 0 && havePagination)
        {
            let el = document.getElementById('routes')
            let event = new CustomEvent('scrollToEnd')
            el.dispatchEvent(event);
        }
    }

    render()
    {
        const { ui, company, on_boarding } = this.props;
        const user = this.props.user.data

        const loading                            = ui.loading

        let markup = null
        const header  = ui.nolayout ? null : <Route path="/" component={Header} />
        const sidebar = ui.nolayout ? null : <Route path="/" component={Sidebar} />

        let contentClass = ""

        if (this.props.history.location.pathname) {
            contentClass = this.props.history.location.pathname
            contentClass = contentClass.split('/').join(' ')
        }
        let isSandboxMode = user && user.currentEnv === "SANDBOX"
        if (isSandboxMode)
        {
            contentClass += ' sandbox-env '
        }

        const publicRoutes =
            <Switch>
              <Route path={publicPaths.legal.policy.pathname} component={Policy} />
                <Route path={publicPaths.legal.terms.pathname} component={Terms} />
          </Switch>

        let privateDefaultRoute = privatePaths.dashboard.pathname
        const signInPath = decodeURIComponent(window.location.search)
        const allowedRoute = this.allowedRoute
        const allowedBuildRoute = this.allowedBuildRoute

        if (signInPath !== "")
        {
            privateDefaultRoute = signInPath.replace("?dest=","")
        }
        const isConfigurationsEnabled = window && window.__isConfigurationsEnabled;
        const privateRoutes =
            <Switch>
                {allowedRoute("view", "company_profile", user, privatePaths.companySettings.pathname, CompanyProfile)}
                {isConfigurationsEnabled && allowedRoute("view", "configurations", user, privatePaths.configurations.pathname, Configurations)}
                {allowedRoute("view", "user_profile", user, privatePaths.userProfile.pathname, UserProfile)}
                {allowedRoute("view", "user", user, privatePaths.companySettingsUsers.pathname, Users)}
                {allowedRoute("view", "groups", user, privatePaths.groupsSetting.pathname, Groups)}
                {allowedRoute("view", "integrations", user, privatePaths.integrationsSetting.pathname, Integrations)}
                {allowedRoute("view", "webhook", user, privatePaths.webhookSettings.pathname, Webhooks)}

                {allowedRoute("view", "dashboard", user, privatePaths.dashboard.pathname, Dashboard)}
                {allowedRoute("view", "change_order", user, privatePaths.changeOrderShow.pathname, ViewChangeOrder)}
                {allowedRoute("view", "release", user, privatePaths.releaseShow.pathname, ViewRelease)}
                {allowedRoute("view", "product", user, privatePaths.productView.pathname, ViewProduct)}
                {allowedRoute("view", "product", user, privatePaths.productDiff.pathname, DiffProduct)}
                {allowedRoute("view", "component", user, privatePaths.componentDiff.pathname, DiffComponent)}
                {allowedRoute("view", "component", user, privatePaths.componentView.pathname, ViewComponent)}
                {allowedRoute("view", "export_cmp_prd", user, privatePaths.export.pathname, ExportRoute)}
                {allowedRoute("view", "search", user, privatePaths.search.pathname, Search)}

                {allowedRoute("create", "change_order", user, privatePaths.changeOrderNew.pathname, NewChangeOrder)}
                {allowedRoute("create", "product", user, privatePaths.productNew.pathname, NewProduct)}
                {allowedRoute("edit", "change_order", user, privatePaths.editChangeOrder.pathname, EditChangeOrder)}
                {allowedRoute("edit", "product", user, privatePaths.productEdit.pathname, EditProduct)}
                {allowedRoute("edit", "component", user, privatePaths.componentEdit.pathname, EditComponent)}
                {allowedRoute("view", "revision", user, privatePaths.componentRevision.pathname, RevisionComponent)}
                {allowedRoute("view", "revision", user, privatePaths.productRevision.pathname, RevisionProduct)}
                {allowedRoute("view", "webhook", user, privatePaths.webhookView.pathname, ViewWebhook)}

                {allowedRoute("view", "production_instance", user, privatePaths.productionInstanceView.pathname, ViewProductionInstance)}
                {allowedRoute("edit", "production_instance", user, privatePaths.productionInstanceEdit.pathname, EditProductionInstance)}

                {/* TODO: BLD-53: Set proper action and className values for build page permissions */}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildDashboard, BuildDashboard)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildLots, BuildLots)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildLotEdit, BuildLot)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildLotView, BuildLot)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildInstances, BuildInstances)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildInstance, BuildInstance)}

                <Route path={publicPaths.legal.policy.pathname} component={Policy} />
                <Route path={publicPaths.legal.terms.pathname} component={Terms} />
                {
                    Object.keys(company.data).length &&
                    <Redirect to={privateDefaultRoute} />
                }
            </Switch>

        let cpnScheme = company && company.cpnScheme ? (company.cpnScheme.activeScheme ? company.cpnScheme.activeScheme.toLowerCase() : "") : ""
        const app =
            <div className={(contentClass + (loading ? " hidden" : " app " + cpnScheme) )}>
                {header}
                <div className="two-col">
                    {user ? sidebar : "" }
                    <div className="main-content">
                        <PerfectScrollbar
                            onYReachEnd={(container) => this.dispatchCustomScrollToEndEvent(container)}
                            className="custom-scroll"
                        >
                            <div id="routes" className="routes">
                            { user ? privateRoutes : publicRoutes }
                            </div>

                            {
                                on_boarding.accountSetupCompleted === false &&
                                <div className={"new-custom-confirm-modal onboarding-in-progress"}>
                                    <ModalBox
                                        onClose={this.closeAccountSetupProgressModal}
                                    >
                                    <div>
                                    <h1>
                                        Your account is being set up
                                    </h1>
                                    <p>Please wait while we set up a sample product in your sandbox.</p>
                                    <div className="loading-block">
                                        <InlineIcon>
                                          <LoadingIcon />
                                        </InlineIcon>
                                        </div>
                                    </div>
                                    </ModalBox>
                                </div>
                            }
                            {
                                user ?
                                <Notification
                                user={this.props.user.data}
                                /> : null
                            }
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>

        return (
            <>
                {app}
                {loading ? "" : null}
                <Loading />
                <Alert />
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
  syncAccountSetupProgress : () => dispatch(buildAction(ON_BOARDING.SYNC_ACCOUNT_SETUP_PROGRESS, {closeProgressSynchingModal: true})),
  switchENV                : (payload) => dispatch(buildAction(USER.SWITCH_ENV, payload))
});

const mapStateToProps = state => ({
  // // user: state.user.data,
  // user: state.user,
  // ui: state.ui,
  // company: state.company,
  // on_boarding: state.on_boarding

    ui: state.ui,
    user: state.user,
    signin: state.signin,
    signup: state.signup,
    postOAuth2: state.postOAuth2,
    company: state.company,
    product: state.product,
    components: state.components,
    changeorders: state.changeorders,
    authscreen: state.authscreen,
    user_profile: state.user_profile,
    userForm: state.userForm,
    users: state.users,
    search: state.search,
    help: state.help,
    invite: state.invite,
    // item_assembly: state.item_assembly,
    on_boarding: state.on_boarding,
    // assembly_edit: state.assembly_edit,
})

const _Routes = withRouter(Routes);
export default connect(mapStateToProps, mapDispatchToProps)(_Routes)

// export default connect((store) => store, mapDispatchToProps)(Routes)
