import { FC, ReactNode, ReactElement } from "react";
import { Dialog, styled } from "@mui/material";

export { HeaderSection } from "./headerSection";
export { ActionSection } from "./actionSection";
export { Content } from "./content";

export interface IModalProps {
  width?: string,
}

export interface IBaseModalTypes {
  children?: ReactNode | JSX.Element | ReactElement,
  modalProps?: IModalProps,
  onClose: (e: MouseEvent | React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
  open: boolean,
}

const Modal: FC<IBaseModalTypes> = ({
  children,
  onClose,
  open,
  ...others
}) => (
  <Dialog
    onClose={onClose}
    open={open}
    {...others}
  >
    {children}
  </Dialog>
);

export const BaseModal = styled(Modal, {
  shouldForwardProp: prop => prop !== "modalProps",
})(({ modalProps, theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.duro.dialogModal.backgroundColor,
    backgroundImage: "none",
    borderRadius: "0.5rem",
    boxShadow: "none",
    margin: "2rem",
    maxWidth: modalProps?.width ?? "24rem",
    padding: "1rem",
    width: "100%",
  },
}));
