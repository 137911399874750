import                                       "./index.css"
import                                       "./../index.css"
import React, { Component }                  from "react"
import NewUserModalBox                       from "./user-modal-box"
import UsersView                             from "./users-view"
import UserModalBox                          from "../../../ui/new-user-modal-box"
import SettingSideNav                        from '../setting-side-nav';
import {connect}                             from "react-redux";
import {Helmet}                              from "react-helmet";
import Utils                                 from "../../../../modules/utils"

export class Users extends Component
{

    constructor(props, context)
    {
        super(props, context)
        this.onModalClose    = this.onModalClose.bind(this)
        this.showAddNewUser  = this.showAddNewUser.bind(this)
        this.closeAddNewUser = this.closeAddNewUser.bind(this)

        this.state = {
            displayInviteModal: false,
            addNewUser: false
        }
    }

    componentWillMount()
    {
        if (this.props.location && this.props.location.onBoarding)
        {
            this.state.displayInviteModal = true
            this.setState(this.state)
        }
    }

    showAddNewUser()
    {
        this.state.addNewUser = true
        this.setState(this.state)
    }

    closeAddNewUser()
    {
        this.state.addNewUser = false
        this.setState(this.state)
    }

    onModalClose()
    {
        this.state.displayInviteModal = false
        this.setState(this.state)
    }

    render()
    {
        let displayModal = this.state.displayInviteModal ? this.state.displayInviteModal : false
        let markup =

        <div className="users-settings-route">
            <Helmet>
                <title>{Utils.makeTitle("Settings - Users")}</title>
            </Helmet>
            <SettingSideNav location={this.props.location} user={this.props.user.data}/>
            <div className="main-block">
                <div className="content-block">
                    <UsersView
                        showAddNewUser={this.showAddNewUser}
                    />
                    <div>
                        {
                            this.state.addNewUser ?
                            <NewUserModalBox
                                onClose={this.closeAddNewUser}
                                displayModal={this.state.addNewUser}
                                mode="new"
                            />
                            : ""
                        }
                    </div>

                    <div>
                        {
                            displayModal ?
                            <UserModalBox
                            onModalClose={this.onModalClose}
                            history={this.props.history}
                            /> : ""
                        }
                    </div>
                </div>
            </div>
        </div>

        return markup
    }
}

export default connect((store) => store)(Users)
