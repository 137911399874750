import                   './index.css';
import React, {useState, useEffect} from 'react';
import ToggleBtn         from '../../../../ui/toggle-btn';
import InlineIcon        from '../../../../ui/icon/inline-icon.js';
import LockOpenIcon      from '../../../../../assets/icons/lock-open.js';
import LockCloseIcon     from '../../../../../assets/icons/lock-close.js';
import API               from '../../../../../modules/api'
import {permissions as Permissions} from '../../../../../modules/schemas';
import ReactTooltip from 'react-tooltip';

const MandatoryComment = ({user, company}) =>
{
    const [isToggleEnabled, setToggle] = useState(company.settings.configurations.coCommentMandatoryEnabled);
    let toggelValue = isToggleEnabled ? 'On' : 'Off' ;
    const actionAllowed = Permissions.can('edit', 'configurations', user);

    const changeStateOfToggle = () => {
        const data = {settings: {configurations:{coCommentMandatoryEnabled:!isToggleEnabled }}}
        setToggle(!isToggleEnabled);
        API.companies.update(company._id, data,(err, response) => {
            if (!err)
                window.__isCommentMandatory = !isToggleEnabled;
        })
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [company.settings.configurations.coCommentMandatoryEnabled])

    return(
        <div className={`mandatory-comment`}>
            <div className={`${!actionAllowed || !isToggleEnabled ? 'disabled' : ''} mandatory-block-first`}>
                <div className='block-heading'>
                    <InlineIcon
                        className='mandatory-lock-icon'
                        tooltip={!actionAllowed ? 'Administrator access only' : ''}
                    >
                    {
                        actionAllowed ? <LockOpenIcon/> : <LockCloseIcon/>
                    }
                    </InlineIcon>
                    <h2>Mandatory Comments</h2>
                </div>
                <div>
                    <p>Requires users to enter a comment in order to submit
                    their decision on a Change Order.
                    </p>
                </div>
            </div>
            <div className={`${!isToggleEnabled ? 'disabled' : ''} mandatory-block-second`}>
                <ToggleBtn
                  name='toggle-btn'
                  selected= {isToggleEnabled}
                  onChange={() => changeStateOfToggle()}
                  disabled={!actionAllowed}
                />
                <span className={`toggel-value ${!isToggleEnabled ? 'disabled' : ''}`}>{toggelValue}</span>
            </div>
        </div>
    )
}

export default MandatoryComment;
