import                               "./index.css"
import React, { Component }     from "react"
import {connect}                from "react-redux"
import Utils                    from "../../../modules/utils"
import ItemAssembly             from "../item-assembly/index"
import buildAction              from "../../../helpers/buildAction"
import ITEM_ASSEMBLY            from "../../../action-types/item-assembly"
import ArrowIcon                from "../../../assets/icons/arrow-icon"
import InlineIcon               from "../icon/inline-icon.js"
import CustomBlueToolTip        from "../../ui/custom-blue-tooltips-for-on-boarding"

class ItemsList extends Component
{
    constructor(props, context) {
        super(props, context);
        this.selectProduct  = this.selectProduct.bind(this)
        this.onClickHandler       = this.onClickHandler.bind(this)
        this.onSelectHeading      = this.onSelectHeading.bind(this)
        this.closeModal           = this.closeModal.bind(this)
        this.onModalExternalClick = this.onModalExternalClick.bind(this)
    }

    componentWillMount()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.RESET_STATE));
    }

    selectProduct(item)
    {
        const path = window.__userRole === "VENDOR" ? "revision" : "view"
        this.props.history.push({pathname: `/product/${path}/${item._id}`})
        this.closeModal()
    }

    onSelectHeading(item)
    {
        let state = this.props.item_assembly

        if(this.props.item_assembly._id)
        {
            const path = window.__userRole === "VENDOR" ? "revision" : "view"
            state.buttonHighlight = true
            const {dispatch} = this.props;
            dispatch(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
            dispatch(buildAction(ITEM_ASSEMBLY.MARK_ASSEMBLY_UN_SELECTED));
            this.props.history.push({pathname: `/product/${path}/${this.props.item_assembly._id}`})
        }
    }

    closeModal()
    {
        document.body.removeEventListener("click", this.onModalExternalClick)
        let state  = this.props.item_assembly
        state.class = "close"
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
    }

    onModalExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.dropDownModalNode
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        if(isExternal) this.closeModal()
    }

    onClickHandler(event)
    {
        let state = this.props.item_assembly
        state.class = state.class == "close" ? "open" : "close"
        if (state.class === "open")
            document.body.addEventListener("click", this.onModalExternalClick)

        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
    }

    render()
    {
        let items = this.props.item_assembly.productList
        if (!items.length)
            return null
        let markup =


            <div className="item-assembly-view" id="items-list-view">
                <div className="nav-list side-nav-holder">
                    <div ref="dropDownModalNode" className="custom-dropdown">
                        <div className="dropdown">
                            <div className={"dropbtn " + this.props.item_assembly.class}>
                            <span
                                onClick={this.props.item_assembly.selectedTextForProductList.toLowerCase() === "select product" ? this.onClickHandler : this.onSelectHeading}
                                className={this.props.item_assembly.buttonHighlight ? "green" : ""}
                            >
                                {this.props.item_assembly.selectedTextForProductList}
                            </span>
                            <CustomBlueToolTip notAllowedForStep={5}><InlineIcon onClick={this.onClickHandler}><ArrowIcon /></InlineIcon></CustomBlueToolTip>
                            </div>
                            <div className={this.props.item_assembly.class + " dropdown-content"}  id="myDropdown" >
                                {
                                    items.map((item, i) =>
                                    {
                                        let block =
                                            <a key={i} onClick={(e) => this.selectProduct(item)}>
                                            {(Utils.getCpn(item)) + " " + item.name}
                                            </a>
                                        return block
                                    })
                                }
                            </div>
                          </div>
                        </div>
                    <ItemAssembly {...this.props}/>
                </div>

            </div>
        return markup
    }
}

export default connect((store) => store)(ItemsList)
