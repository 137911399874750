
import ASSEMBLY_EDIT        from '../action-types/assembly-edit.js'
import initialState         from './initial-states/assembly-edit.js'
import { handleActions }    from 'redux-actions'


function setChildren(state, { payload })
{
    state.children = payload.children
    state.modified = payload.modified
    return {...state}
}

export default handleActions({
    [ASSEMBLY_EDIT.SET_CHILDREN]: setChildren,
}, initialState);
