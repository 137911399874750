import                          "./index.css"
import React, {Component}   from "react"
import Utils                from "../../../../../modules/utils"
import Schemas              from "../../../../../modules/schemas"
import API                  from "../../../../../modules/api"
import Icon                 from "../../../../ui/icon"
import ExpandIcon           from "../../../../../assets/icons/expand-icon"
import RefreshIcon          from "../../../../../assets/icons/refresh-icon.js";
import LinkSrc              from "../../../../../assets/icons/link.js";
import AddSrc               from "../../../../../assets/icons/add-row.js";
import DeleteSrc            from "../../../../../assets/icons/cross-icon.js";
import Distributors         from "./distributors"
import {validateField}      from "../../../../../modules/validations"
import PrimarySourceIcon    from "../../../../../assets/icons/checkbox-with-center-dot"
import SourcingUtils        from "../sourcing-utils.js"
import alertErrorSrc        from "../../../../../assets/icons/alert-error.svg"
import RolledUpTable        from "../rolled-up-table"
import LazyInput            from "../../../../ui/lazy-input/input.js"
import SearchIcon           from "../../../../../assets/icons/search.js"
import LinkIcon             from "../../../../../assets/icons/open-link-icon.js"
import Link                 from "../../../../ui//link"
import InlineIcon           from "../../../../ui/icon/inline-icon.js"
import { connect }          from "react-redux"
import Tooltip              from 'rc-tooltip'
import                      'rc-tooltip/assets/bootstrap_white.css'
import TableIcons           from "../../extended-table/table-icons"
import $                    from "jquery";
import RefreshQuoteModal    from "../../validation-running-modal"

export class Sourcing extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.onHeadingClick         = this.onHeadingClick.bind(this)
        this.sort                   = this.sort.bind(this)
        this.toggleRow              = this.toggleRow.bind(this)
        this.expandAll              = this.expandAll.bind(this)
        this.collapseAll            = this.collapseAll.bind(this)
        this.add                    = this.add.bind(this)
        this.remove                 = this.remove.bind(this)
        this.onInputChange          = this.onInputChange.bind(this)
        this.validateInputValues    = this.validateInputValues.bind(this)
        this.toggleSrcInput         = this.toggleSrcInput.bind(this)
        this.onChange               = this.onChange.bind(this)
        this.validateAllFields      = this.validateAllFields.bind(this)
        this.setManufacturers       = this.setManufacturers.bind(this)
        this.setPrimaryManufacturer = this.setPrimaryManufacturer.bind(this)
        this.schema                 = this.props.schema
        this.clientSchema           = this.props.clientSchema
        this.onSkuChange            = this.onSkuChange.bind(this)
        this.onSkuSubmit            = this.onSkuSubmit.bind(this)
        this.checkRefererSkuValue   = this.checkRefererSkuValue.bind(this)
        this.setSkuInputRef         = this.setSkuInputRef.bind(this)
        this.checkMpnAlreadyUsed    = this.checkMpnAlreadyUsed.bind(this)
        this.validateMPNInput       = this.validateMPNInput.bind(this)
        this.toggleRefreshBtn       = this.toggleRefreshBtn.bind(this)
        this.refreshQuote           = this.refreshQuote.bind(this)
        this.selectAllItems         = this.selectAllItems.bind(this);
        this.resetQuoteStates       = this.resetQuoteStates.bind(this);
        this.setCheckBoxState       = this.setCheckBoxState.bind(this);
        this.updateSourcingState    = this.updateSourcingState.bind(this);
        this.clearSourceWarning     = this.clearSourceWarning.bind(this);
        this.removeSource           = this.removeSource.bind(this);
        this.checkQuotesError       = this.checkQuotesError.bind(this);
        this.checkSingleQuoteError  = this.checkSingleQuoteError.bind(this);
        this.checkDistributorError  = this.checkDistributorError.bind(this);
        this.toggleCountOnRemove    = this.toggleCountOnRemove.bind(this);
        this.validateSourcingFields = this.validateSourcingFields.bind(this);

        this.state =
        {
            manufacturers : Utils.clone(this.props.manufacturers),
            showRefererTooltip: false,
            alreadyUsedMpnData: null,
            quoteRefreshbtn: false,
            processModal: false,
            alreadyUsedMpn: false,
            inputs :
            {
                sku :
                {
                    value   : "",
                    message : "",
                    class   : "disabled"
                }
            },
            inputChanged: false,
            column :
            {
                name: "manufacturer-name",
                ascending : true
            },

            columns : this.createColumns()

        }

        this.componentWillMount = this.sort

    }

    createColumns()
    {
        let columns =
        [
            {
                name     : "checkBox",
                class    : "",
                value    : "",
                width    : "30px",
                sortable : false
            },

            {
                name     : "mpn.key",
                class    : "sortable",
                value    : "MPN",
                width    : "20%",
                sortable : true
            },

            {
                name: "manufacturer-name",
                class    : "sortable",
                value    : "Manufacturer",
                width    : "25%",
                sortable : true
            },


            {
                name: "manufacturer-description",
                class    : "sortable",
                value: "Description",
                width    : "25%",
                sortable : true
            },

            {
                value    : "Data Sheet",
                width    : "30%"
            }
        ];

        if(SourcingUtils.isWarrantyFieldEnabled(this.props.isDiffTool))
        {
            columns.push(
            {
                name     : "Warranty.value",
                class    : "sortable",
                value    : "Warranty",
                width    : "25%",
                sortable : true
            });
        }

        columns.push(
        {
            value    : "",
            width    : "30px"

        });

        return columns;
    }

    resetQuoteStates()
    {
        this.props.manufacturers.forEach((man) =>
        {
            man.distributors.forEach((dist) =>
            {
                dist.quotes.forEach((quote) =>
                {
                    if (quote.lastUpdated === null) {
                        quote.lastUpdated = this.props.component.lastModified;
                    }

                    let lastDateTime   = new Date(quote.lastUpdated);
                    let currentDateTime   = Date.now();

                    const diffTime = Math.abs(currentDateTime - lastDateTime);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays > 1) {
                        quote.unitPriceState = "";
                        quote.leadTimeState = "";
                    }
                })
            })
        })
    }

    validateMPNInput()
    {
        let state = this.state
        let {manufacturers} = this.state
        let skuValue = state.inputs.sku.value
        if (skuValue)
        {
            let count = 0
            manufacturers.forEach((man) => { if (skuValue === man.mpn.key) count++})
            if (count > 0)
            {
                state.inputs.sku.class = "disabled"
                state.alreadyUsedMpn = true
                state.alreadyUsedMpnData = null
                state.mpnIconClass = "duro-icon-error-triangle";
                state.mpnIconToolTip = "";
            }
            else
            {
                state.inputs.sku.class = ""
                state.alreadyUsedMpn = false
                state.alreadyUsedMpnData = null
                state.mpnIconClass = "";
                state.mpnIconToolTip = "";
                this.checkMpnAlreadyUsed();
            }
        }
    }

    setMfrCheckBoxState(manufacturers, options)
    {
        let checkedMfr = $("input[name=selectAllDistributors]:checked").length;
        $(`.mfrCheckBox label`).removeClass("middle");
        if(checkedMfr === manufacturers.length)
        {
            $(`input[name=selectAllMFR]`).prop("checked", true);
        }
        else if(options.showDashLineInMfr)
        {
            $(`input[name=selectAllMFR]`).prop("checked", false);
            $(".mfrCheckBox label").addClass("middle");
        }
        else
        {
            $(`input[name=selectAllMFR]`).prop("checked", false);
        }
    }

    setDistAndQuotesCheckBoxState(man, options={showDashLineInMfr: false, showDashLine: false}, manIndex)
    {
        man.distributors.forEach((dist, distIndex) =>
        {
            let checkedQuotes = 0;
            $(`span.man-${manIndex}.dist-${distIndex+1} label`).removeClass("middle");
            let unSetQuotes = 0;
            let quoteIndex = 0;
            dist.quotes.forEach((quote, index) => {
                if(quote.inputs && quote.inputs.isChecked.value)
                {
                    checkedQuotes++;
                    $(`.man-${manIndex}.dist-${distIndex+1}.quote-${index+1} span.primary-source-checkbox > input[name=isPrimary]`).prop("checked", true);
                }
                if(quote.inputs && !quote.inputs.isChecked.value)
                {
                    $(`.man-${manIndex}.dist-${distIndex+1}.quote-${index+1} span.primary-source-checkbox > input[name=isPrimary]`).prop("checked", false);
                }
            })
            if(checkedQuotes === dist.quotes.length && dist.quotes.length>0)
            {
                $(`span.man-${manIndex}.dist-${distIndex+1} > input[name=selectAllQuotes]`).prop("checked", true);
                options.showDashLineInMfr = true;
            }
            else if(checkedQuotes === 0 && dist.quotes.length>0)
            {
                $(`span.man-${manIndex}.dist-${distIndex+1} > input[name=selectAllQuotes]`).prop("checked", false);
            }
            else if(dist.quotes.length > 0)
            {
                $(`span.man-${manIndex}.dist-${distIndex+1} > input[name=selectAllQuotes]`).prop("checked", false);
                $(`span.man-${manIndex}.dist-${distIndex+1} label`).addClass("middle");
                options.showDashLine = true;
                options.showDashLineInMfr = true;
            }
        })
    }

    setCheckBoxState()
    {
        let options = {showDashLineInMfr: false, showDashLine: false};
        this.state.manufacturers.forEach((man, manIndex) =>
        {
            let manClass = "man-"+(manIndex+1)+" ";
            if (man.distributors && man.distributors.length > 0)
            {
                $(`.select-all-distributors.${manClass} label`).removeClass("middle");
                options.showDashLine = false;
                this.setDistAndQuotesCheckBoxState(man, options, manIndex+1);

                let checkedDist = $(`.select-all-qoutes.${manClass} input:checked`).length;
                if(checkedDist === man.distributors.length && man.distributors.length>0)
                {
                    $(`.primary-source-checkbox.${manClass} input[name=selectAllDistributors]`).prop("checked", true);
                    options.showDashLineInMfr = true;
                }
                else if(options.showDashLine || (checkedDist !== man.distributors.length && checkedDist > 0) && man.distributors.length>0)
                {
                    options.showDashLineInMfr = true;
                    $(`.primary-source-checkbox.${manClass} input[name=selectAllDistributors]`).prop("checked", false);
                    $(`.select-all-distributors.${manClass} label`).addClass("middle");
                }
                else if(man.distributors.length>0)
                {
                    $(`.primary-source-checkbox.${manClass} input[name=selectAllDistributors]`).prop("checked", false);
                }
            }
            else if(man.distributors && man.distributors.length === 0 && $(`.select-all-distributors.${manClass} input:checked`).length > 0)
            {
                options.showDashLineInMfr = true;
            }
        });
        this.state.manufacturers.length > 0 && this.setMfrCheckBoxState(this.state.manufacturers, options);
    }

    selectAllItems(event, data, nameOfClass)
    {
        let checked = event.target.checked;
        switch(event.target.name){
            case "selectAllMFR":
            {
                let mfrWithMiddle = $(`.mfrCheckBox label[class=middle]`);
                if (data.length) {
                    data.forEach((man, manIndex) =>
                    {
                        let manClass = "man-"+(manIndex+1)+" "; // preparing manufacturer class
                        if (man.distributors)
                        {
                            $(`.primary-source-checkbox.${manClass} input[name=selectAllDistributors]`).prop("checked", checked);
                            man.distributors.forEach((dist, distIndex) =>
                            {
                                let distClass = manClass + 'dist-'+(distIndex+1)+' '; // preparing distributors class
                                let finalDistClass = Utils.replaceSpaceWithDot(`${manClass} dist-${distIndex+1}`);
                                $(`.primary-source-checkbox.${finalDistClass} input[name=selectAllQuotes]`).prop("checked", checked);
                                if (dist.quotes && dist.quotes.length > 0)
                                {
                                    dist.quotes.forEach((quote, quoteIndex) =>
                                    {
                                        let quoteClass = distClass + 'quote-'+(quoteIndex+1); // preparing Quote class
                                        // replacing spaces with dot
                                        let finalQuoteClass = Utils.replaceSpaceWithDot(quoteClass);
                                        // Update the checkbox...
                                        let element = $('.quotes .'+finalQuoteClass+' .primary-source-checkbox input:checkbox');
                                        if(mfrWithMiddle.length === 0)
                                        {
                                            element.click();
                                        }
                                        else if(!element.prop('checked'))
                                        {
                                            element.click();
                                            element.prop("checked", checked);
                                        }
                                        // this input value decide to compare data with API...
                                        quote.inputs.isChecked.value = checked;
                                    })
                                }
                            })
                        }

                    })
                    this.toggleRefreshBtn();
                }
                break;
            }
            case "selectAllDistributors":
            {
                let distWithMiddle = $(`.select-all-distributors.${nameOfClass} label[class=middle]`);
                let manClass = nameOfClass; // preparing manufacturer class
                if (manClass) {
                    if (data.length > 0)
                    {
                        data.forEach((dist, distIndex) =>
                        {
                            let distClass = `${manClass} dist-${distIndex+1} `; // preparing distributors class
                            $(`.primary-source-checkbox.${manClass} input[name=selectAllQuotes]`).prop("checked", checked);
                            if (dist.quotes && dist.quotes.length > 0)
                            {
                                dist.quotes.forEach((quote, quoteIndex) =>
                                {
                                    let quoteClass = distClass + 'quote-'+(quoteIndex+1); // preparing Quote class
                                    // replacing spaces with dot
                                    let finalQuoteClass = Utils.replaceSpaceWithDot(quoteClass);
                                    // Update the checkbox...
                                    let element = $(`.quotes .${finalQuoteClass} .primary-source-checkbox input[type=checkbox]`);
                                    if(distWithMiddle.length === 0)
                                    {
                                        element.click();
                                    }
                                    else if(!element.prop('checked'))
                                    {
                                        element.click();
                                        element.prop("checked", checked);
                                    }
                                    // this input value decide to compare data with API...
                                    quote.inputs.isChecked.value = checked;
                                })
                            }
                        })
                    }
                    this.toggleRefreshBtn();
                }
                break;
            }
            case "selectAllQuotes":
            {

                let classForMiddle = nameOfClass.split(" ");
                let className = classForMiddle[0]+"."+classForMiddle[1];
                let quoteWithMiddle = $(`.select-all-qoutes.${className} label[class=middle]`);
                let distClass = nameOfClass; // preparing distributors class
                if (distClass) {

                    if (data.length > 0)
                    {

                        data.forEach((quote, quoteIndex) =>
                        {
                            let quoteClass = distClass + 'quote-'+(quoteIndex+1); // preparing Quote class
                            // replacing spaces with dot
                            let finalQuoteClass = Utils.replaceSpaceWithDot(quoteClass);
                            // Update the checkbox...
                            let element = $('.quotes .'+finalQuoteClass+' .primary-source-checkbox input:checkbox');
                            if(quoteWithMiddle.length === 0)
                            {
                                element.click();
                            }
                            else if(!element.prop('checked'))
                            {
                                element.click();
                                element.prop("checked", checked);
                            }

                            // this input value decide to compare data with API...
                            quote.inputs.isChecked.value = checked;
                        })
                    }
                }
                this.toggleRefreshBtn();
                break;
            }
        }
        this.setCheckBoxState();
    }

    toggleRefreshBtn(removePrimarySourceCount, quote)
    {
        let count = 0;
        let primarySourceCount = 0;
        $("input[name='isPrimary']:checked").each(function () {
            count++;
        });

        // include primary source row as well...
        if ($(".quotes .primarySource").length) {
           count++;
           primarySourceCount++;
        }
        // this will work when someone de-select primary source...
        if (removePrimarySourceCount) {
            count -= removePrimarySourceCount
        }

        // handling refresh (Update) button...
        if (count >= 1) {
            $('.ui-icon.update').removeClass("disabled");
            this.state.toggleRefreshBtn = true;
            this.setState({quoteRefreshbtn: true});
        } else {
            $('.ui-icon.update').addClass("disabled");
            this.state.toggleRefreshBtn = false;
            this.setState({quoteRefreshbtn: false})
        }

        let minQuantity = quote && quote.inputs ? quote.inputs.minQuantity.value : "";
        let checked = quote && quote.inputs ? quote.inputs.isChecked.value : false;
        let valid = quote && quote.inputs ? quote.inputs.minQuantity.valid : false;
        // handling set primary button
        if ((count  - primarySourceCount) >= 2 || (count - primarySourceCount) === 0 || (minQuantity === "" && checked) || (!valid && checked)) {
            this.props.disablePrimarySourceBtn();
        } else if ((count - primarySourceCount) === 1 &&  minQuantity !== "" && checked && valid) {
            this.props.enablePrimarySourceBtn();
        }
    }

    onHeadingClick(event)
    {
        let column       = this.state.column
        let target       = event.target
        if ( target.tagName !== "TH" )
            target       = target.closest("th")
        column.name = target.getAttribute("manufacturer-name")
        column.ascending = !target.className.match("ascending")
        this.setState(this.state, this.sort)
    }

    setManufacturers(manufacturers, options={})
    {
        manufacturers.forEach((man) =>
        {
            man.class  = man.expanded ? "expanded " : ""
            let datasheetSrc = Utils.getDataSheetLinkToDisplay(man.datasheet);
            if (!man.inputs){
                man.inputs =
                {
                    name :
                    {
                        value : man.name || "",
                        message : "",
                        class   : "",
                        valid   : true
                    },
                    mpn :
                    {
                        key :
                        {
                            value : man.mpn.key || "",
                            message : "",
                            class   : "",
                            valid : true,
                            alreadyUsedMpn: true,
                            alreadyUsedMpnData: null
                        },
                        src :
                        {
                            value : man.mpn.src || "",
                            message : "",
                            class   : "",
                            active_class : man.mpn.src ? "" : "empty",
                            valid : true
                        }
                    },

                    description :
                    {
                        value : man.description || "",
                        message : "",
                        class   : "",
                        valid : true
                    },

                    datasheet :
                    {
                        src :
                        {
                            value : datasheetSrc,
                            message : "",
                            class   : "",
                            valid : true,
                            active_class : datasheetSrc ? "" : "empty"
                        }
                    },
                    distributors :
                    {
                        value : man.distributors,
                        valid : true
                    }
                }

                if(SourcingUtils.isWarrantyFieldEnabled(this.props.isDiffTool))
                {
                    man.inputs['warranty'] =
                    {
                        value :  man.warranty.value || "0",
                        message : "",
                        class   : "",
                        valid : true
                    }

                    man.inputs['warrantyUnit'] =
                    {
                        value : man.warranty.units || "MONTHS",
                        message : "",
                        class   : "",
                        valid : true
                    }
                }
                this.validateAllFields(man, {trackInputChanged: options.trackInputChanged})
            }
        })

        return manufacturers;
    }

    sort(cb)
    {
        let column        = this.state.column
        let columns       = this.state.columns
        let manufacturers = this.state.manufacturers

        columns.forEach((col) =>
        {
            col.class = col.sortable ? "sortable " : col.class
            if(col.name === column.name) col.class += column.ascending ?  "ascending" : "descending"
        })

        manufacturers = this.setManufacturers(manufacturers)

        manufacturers.sort((a,b) =>
        {
            a = Utils.hash(column.name, a)
            b = Utils.hash(column.name, b)

            if(typeof a === "string") a = a.toLowerCase()
            if(typeof b === "string") b = b.toLowerCase()

            if(a < b) return column.ascending ? -1 :  1
            if(a > b) return column.ascending ?  1 : -1
            return 0
        })


        this.setState(this.state, cb)
    }

    setPrimaryManufacturer() {
        let manufacturers = this.state.manufacturers
        manufacturers.forEach((man) =>
        {
            man.isPrimary = false
            if (man.distributors.length > 0)
            {
                man.distributors.forEach((dist) =>
                {
                    dist.isPrimary = false
                    if (dist.quotes.length > 0)
                    {
                        dist.quotes.forEach((quote) =>
                        {
                            if (quote.isPrimary == true) {
                                man.isPrimary = true
                                dist.isPrimary = true
                                quote.isPrimary = true
                            }

                        }) // end dist.quotes.forEach((quote, i) =>
                    } // if (dist.quotes.length > 0)
                }) // end man.distributors.forEach((dist, i) =>
            } // end if (man.distributors.length > 0)
        }) // end manufacturers.forEach((man, i) =>
        this.setState(this.state)
    }

    componentDidMount() {
        this.setPrimaryManufacturer()
        this.checkRefererSkuValue();
    }

    setSkuInputRef(element){
      this.skuInputRef = element;
    };

    checkRefererSkuValue()
    {
        let history = this.props.history
        let locationState = history && history.location && history.location.state
        if (locationState && locationState.refererSku)
        {
            let state   = this.state
            let value   = locationState.refererSku
            let sku     = state.inputs.sku
            sku.value   = value
            sku.class   = "valid"
            state.showRefererTooltip = true
            this.setState(state)

            let element = document.querySelector(".add-mpn-block input")
            if (element) element.focus()
        }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.status !== this.props.status) {
        let manufacturers = this.state.manufacturers
        manufacturers.forEach((man) =>
        {
            this.validateAllFields(man, {trackInputChanged: true})
        })

        let validated = Utils.isValidated(this.state.manufacturers)
        if (!validated && this.props.status !== "DESIGN")
        {
            this.props.showSourcingTab()
            this.expandAll()
            this.props.dispalyErrorModal()
        }
      }
    }

    validateAllFields(manufacturer, options={})
    {
        this.validateInputValues(manufacturer, "manufacturer-name", manufacturer.inputs.name.value, options)
        // this.validateInputValues(manufacturer, "mpn.key", manufacturer.inputs.mpn.key.value, options)
        this.validateInputValues(manufacturer, "mpn.src", manufacturer.inputs.mpn.src.value, options)
        this.validateInputValues(manufacturer, "manufacturer-description", manufacturer.inputs.description.value, options)
        this.validateInputValues(manufacturer, "datasheet.src", manufacturer.inputs.datasheet.src.value, options)

        let man = manufacturer
        let input = man.inputs.mpn.key
        let _this = this
        if (man.mpn.key)
        {
            API.components.checkIfMpnUsed({ mpn: man.mpn.key, _id: this.props.itemId, cat: this.props.objectData && this.props.objectData.category }, (err, res) =>
            {
                if (res && res.data && !res.data.isValidMpn)
                {
                    let isMpnValid = input.valid;
                    let {data} = res;
                    input.class   = "invalid"
                    input.message = "MPN already exists in your library"
                    input.valid = false
                    if(isMpnValid !== input.valid)
                    {
                        this.props.toggleErrorCount(input.valid, 'sourcingTab');
                    }
                    input.alreadyUsedMpn = true
                    input.alreadyUsedMpnData = data
                }
                else
                {
                    this.validateInputValues(man, name, input.value, {trackInputChanged: true})
                    if (!man.inputs.mpn.key.valid)
                    {
                        input.alreadyUsedMpn = true
                        input.alreadyUsedMpnData = null
                    }
                }

                let state = _this.state
                this.setState(state, this.onChange)
            })
        }
        else
        {
            this.validateInputValues(man, name, input.value, {trackInputChanged: true})
            if (!man.inputs.mpn.key.valid)
            {
                input.alreadyUsedMpn = true
                input.alreadyUsedMpnData = null
            }
        }
    }

    toggleRow(event, i)
    {
        let manufacturer   = this.state.manufacturers[i]
        manufacturer.expanded = event.target.className.indexOf("expanded") > -1 ? false : true
        manufacturer.class = manufacturer.expanded ? "expanded" : ""
        this.setState(this.state)
    }

    refreshQuote()
    {
        let state     = this.state;
        let category  = this.props.category;
        let _this     = this;
        _this.setState({processModal : true});
        let callBackCount = 0;
        this.state.manufacturers.forEach((man, manIndex) =>
        {
            API.components.getSource(category, man.mpn.key, (err, source) =>
            {
                if(err)
                {
                    if (err && err.errors.length) {
                        SourcingUtils.handleNotExistQuotes(man);
                    }
                    if (_this.state.manufacturers.length === manIndex + 1) {
                        _this.setState({processModal : false});
                    }
                }
                if (source) {
                    let manuArray = [];
                    manuArray.push(source);
                    let apiData = SourcingUtils.manufacturersToRowData(manuArray);
                    if (man.distributors.length > 0)
                    {
                        man.distributors.forEach((dist, distIndex) =>
                        {
                            if (dist.quotes.length > 0)
                            {
                                dist.quotes.forEach((quote, quoteIndex) =>
                                {
                                    if (quote.inputs.isChecked.value) {
                                        quote.unitPriceState = "worsened";
                                        quote.lastUpdated = Date.now();
                                        let dateTime = Utils.dateTimeWithLongFormat(quote.lastUpdated);
                                        quote.inputs.lastUpdated.value = `${dateTime.dateValue} ${dateTime.timeValue}`;
                                        apiData.forEach((apiResp, index) => {
                                            if (apiResp.mfrMpnKey === man.mpn.key &&
                                                 apiResp.distDpnKey === dist.dpn.key && 
                                                 apiResp.distPackageType.trim().toUpperCase() === dist.package.type &&
                                                 apiResp.distPackageQuantity === dist.package.quantity &&
                                                  Number(apiResp.quoteMinQuantity) === quote.minQuantity) {
                                                    quote.modified = false;
                                                    SourcingUtils.checkQuoteState(apiResp, quote);
                                            }
                                        })
                                        let uniqueRowName = `.man-${manIndex+1}.dist-${distIndex+1}.quote-${quoteIndex+1}`;
                                        let selected = document.querySelectorAll(`${uniqueRowName} input:checked`).length;
                                        if(quote.unitPriceState === "worsened" && selected > 0) $(`${uniqueRowName} input:checkbox`).click();
                                    }

                                }) // end dist.quotes.forEach((quote, i) =>
                            } // if (dist.quotes.length > 0)
                        }) // end man.distributors.forEach((dist, i) =>
                    } // end if (man.distributors.length > 0)
                }
                if (_this.state.manufacturers.length === manIndex + 1) {
                    _this.setState({manufacturers : _this.state.manufacturers, processModal : false})
                }
            })
        }) // end manufacturers.forEach((man, i) =>
    }

    expandAll()
    {
        this.state.manufacturers.forEach((man) =>
        {
            man.expanded = true
            man.class = "expanded"
            man.distributors.forEach((dist) =>
            {
                dist.expanded = true
                dist.class = "expanded"
                dist.quotes.forEach((quote) =>
                {
                    quote.class = "expanded"
                })
            })
        })
        this.setState(this.state)
    }

    collapseAll()
    {
        this.state.manufacturers.forEach((man) =>
        {
            man.class = ""
            man.expanded = false
            man.distributors.forEach((dist) =>
            {
                dist.class = ""
                dist.expanded = false
                dist.quotes.forEach((quote) =>
                {
                    quote.class = ""
                })
            })
        })
        this.setState(this.state)
    }

    add(data)
    {
        data = data ||
        {
            expanded    : true,
            name        : "",
            description : "",
            leadTime    : {},

            mpn         :
            {
                value : "",
                src   : "",
                key   : ""
            },
            datasheet   :
            {
                src : ""
            },
            distributors :
            [
                {
                    expanded: true,
                    name        : "",
                    description : "",
                    dpn         :
                    {
                        key : "",
                        src   : ""
                    },
                    package   :
                    {
                        type     : "",
                        quantity : 1
                    },
                    quotes :
                    [
                        {
                            expanded: true,
                            minQuantity : "",
                            unitPrice   : "",
                            leadTime    : {},
                            isPrimary   : false,
                            isNewlyCreated : true,
                            uniqueId    : Utils.generateUniqueId()
                        }
                    ]
                }
            ]
        }
        if(SourcingUtils.isWarrantyFieldEnabled(this.props.isDiffTool))
        {
            data['warranty'] =
            {
                value : 0,
                units : "MONTHS"
            }
        }

        this.state.manufacturers.push(data)
        this.resetQuoteStates();
        this.state.manufacturers = this.setManufacturers(this.state.manufacturers, {trackInputChanged: true})
        this.setState(this.state, this.onChange)
    }

    remove(i)
    {
        this.toggleCountOnRemove(this.state.manufacturers[i], "mpn");
        this.state.manufacturers.splice(i, 1)
        this.state.manufacturers.forEach((man, index) =>
        {
            if (man.inputs.mpn.key.valid || man.inputs.mpn.key.message === "Duplicate MPN Found")
            {
                this.validateInputValues(man, "mpn.key", man.inputs.mpn.key.value, {trackInputChanged: true})
                if (!man.inputs.mpn.key.valid)
                {
                    man.inputs.mpn.key.alreadyUsedMpn = true
                    man.inputs.mpn.key.alreadyUsedMpnData = null
                }
                else
                {
                    man.inputs.mpn.key.alreadyUsedMpn = false
                    man.inputs.mpn.key.alreadyUsedMpnData = null
                }
            }
        })
        if(this.state.manufacturers.length === 0)
        {
            this.props.onPrimarySourceChange(false);
        }
        else
        {
            let checkedQuote = 0;
            let quote = null;
            let isValid = false;
            this.state.manufacturers.forEach((man, manIndex) => {
                man.distributors.forEach((dist, distIndex) => {
                    dist.quotes.forEach((q, quoteIndex) => {
                        if(q.inputs.isChecked.value){
                            checkedQuote++;
                            if(q.inputs.minQuantity.value !== "" && q.inputs.minQuantity.valid)
                            {
                                quote = q;
                                isValid = true;
                            }
                        }
                    });
                });
            });
            if(checkedQuote === 1 && isValid)
            {
                this.props.onPrimarySourceChange(true, quote);
            }
            else
            {
                this.props.onPrimarySourceChange(false, quote);
            }
        }
        if (this.props.tabsType === "cmp") this.validateMPNInput()
        this.setState(this.state, () => this.onChange({trackInputChanged: true}))
    }

    onInputChange(event, i, fieldName)
    {
        let state = this.state
        let name  = event.target.name
        let value = event.target.value
        if (fieldName === 'leadTimeUnit' || fieldName === 'warrantyUnit')
            name = fieldName;
        let man = this.state.manufacturers[i]
        if (name === "mpn.key") man.mpn.key = value
        if (name === "mpn.key" || name === "manufacturer-name")
        {

            let input = man.inputs.mpn.key
            let manufacturers = this.state.manufacturers
            if (name === "manufacturer-name") man.name = value
            if (this.props.tabsType === "cmp") this.validateMPNInput()

            if (man.mpn.key)
            {
                API.components.checkIfMpnUsed({ mpn: man.mpn.key, _id: this.props.itemId }, (err, res) =>
                {
                    let {data} = res
                    if (data && !data.isValidMpn)
                    {
                        let isMpnValid = input.valid;
                        input.class   = "invalid"
                        input.message = "MPN already exists in your library"
                        input.valid = false
                        input.alreadyUsedMpn = true
                        if(isMpnValid !== input.valid)
                        {
                            this.props.toggleErrorCount(input.valid, 'sourcingTab');
                        }
                        input.alreadyUsedMpnData = data
                        if (name === "manufacturer-name")
                        {
                            this.state.inputChanged = true
                            this.validateSourcingFields(man.inputs.name, this.clientSchema.name, man.name, {status: this.props.status, key: man.mpn.key}, {valid_class: ""}, man.inputs.name.valid);
                        }
                    }
                    else
                    {
                        this.validateInputValues(man, name, value, {trackInputChanged: true})
                        if (!man.inputs.mpn.key.valid)
                        {
                            input.alreadyUsedMpn = true
                            input.alreadyUsedMpnData = null
                        }
                    }

                    manufacturers.forEach((man, index) =>
                    {
                        if (man.inputs.mpn.key.valid || man.inputs.mpn.key.message === "Duplicate MPN Found")
                        {
                            this.validateInputValues(man, "mpn.key", man.inputs.mpn.key.value, {trackInputChanged: true})
                            if (!man.inputs.mpn.key.valid)
                            {
                                man.inputs.mpn.key.alreadyUsedMpn = true
                                man.inputs.mpn.key.alreadyUsedMpnData = null
                            }
                            else
                            {
                                man.inputs.mpn.key.alreadyUsedMpn = false
                                man.inputs.mpn.key.alreadyUsedMpnData = null
                            }
                        }
                    })
                    
                    this.setState(state, this.onChange)
                })
            }
            else
            {
                this.validateInputValues(man, name, value, {trackInputChanged: true})
                if (!man.inputs.mpn.key.valid)
                {
                    input.alreadyUsedMpn = true
                    input.alreadyUsedMpnData = null
                }
            }
        }
        else
        {
            this.validateInputValues(man, name, value, {trackInputChanged: true})
        }

        Utils.persistCursor(event, value)
        this.setState(this.state, this.onChange)
    }

    validateInputValues(man, name, value, options={}){
        let input, validator = null
        let trackInputChanged = options.trackInputChanged
        let {manufacturers} = this.state
        let state = this.state



        switch(name)
        {
            case "manufacturer-name":
            {
                this.state.inputChanged = trackInputChanged ? true : false

                validator   = this.clientSchema.name
                input       = man.inputs.name
                this.validateSourcingFields(input, validator, value, {status: this.props.status, key: man.mpn.key}, {valid_class: ""}, input.valid);
                man.name              = value
                this.validateSourcingFields(man.inputs.mpn.key, this.clientSchema.mpn.key, man.mpn.key, {status: this.props.status, name: man.name, manufacturers: manufacturers}, {valid_class: ""}, man.inputs.mpn.key.valid);
                break
            }

            case "mpn.key" :
            {
                this.state.inputChanged = trackInputChanged ? true : false
                validator   = this.clientSchema.mpn.key
                input       = man.inputs.mpn.key
                this.validateSourcingFields(input, validator, value, {status: this.props.status, name: man.name, manufacturers: manufacturers}, {valid_class: ""}, input.valid);
                man.mpn.key = value
                this.validateSourcingFields(man.inputs.name, this.clientSchema.name, man.name, {status: this.props.status, key: man.mpn.key}, {valid_class: ""}, man.inputs.name.valid);
                break
            }

            case "mpn.src" :
            {
                this.state.inputChanged = trackInputChanged ? true : false

                validator   = this.clientSchema.mpn.src
                input       = man.inputs.mpn.src
                this.validateSourcingFields(input, validator, value, {key: man.inputs.mpn.key}, {valid_class: ""}, input.valid);
                man.mpn.src = value
                break
            }

            case "manufacturer-description":
            {
                this.state.inputChanged = trackInputChanged ? true : false

                validator   = this.clientSchema.description
                input       = man.inputs.description
                this.validateSourcingFields(input, validator, value, {}, {valid_class: ""}, input.valid);
                man.description              = value
                break
            }

            case "datasheet.src" :
            {
                this.state.inputChanged = trackInputChanged ? true : false

                validator   = this.clientSchema.datasheet.src
                input       = man.inputs.datasheet.src
                this.validateSourcingFields(input, validator, value, {}, {valid_class: ""}, input.valid);
                man.datasheet.src = value
                break
            }

            case "distributors" :
            {
                this.state.inputChanged = value.inputChanged ? true : false

                man.inputs.distributors.value = value.data
                man.inputs.distributors.valid = value.valid
                man.distributors              = value.data
                break
            }

            case "warranty" :
            {
                state.inputChanged = trackInputChanged ? true : false
                value = value === "" ? 0 : (isNaN(value) ? value : parseInt(value));
                validator   = this.clientSchema.warranty.value;
                input       = man.inputs.warranty;
                this.validateSourcingFields(input, validator, value, {warranty: {value: value, units: man.inputs.warrantyUnit.value}}, {valid_class: ""}, input.valid);
                let unit = Utils.warrantyFieldUnit(input.value, man.inputs.warrantyUnit.value);
                man.warranty = {value: value, units: unit};
                break
            }

            case "warrantyUnit" :
            {
                state.inputChanged = trackInputChanged ? true : false
                let warrantyValue = parseInt(man.inputs.warranty.value) ? parseInt(man.inputs.warranty.value) : 0
                let warrantyUnit = Utils.warrantyFieldUnit(warrantyValue, value);
                man.inputs.warrantyUnit.value = value;
                man.warranty = {value: man.inputs.warranty.value, units: warrantyUnit}
                break
            }

            default :
            {
                // noop
            }
        }
    }

    toggleSrcInput(event, i)
    {
        let name      = event.target.name
        let man       = this.state.manufacturers[i]
        let inputNode = event.target.nextSibling
        let input     = null

        let onClick = (event) =>
        {
            if(event.target !== inputNode) close()
        }

        let onKeyDown = (event) =>
        {
            if(event.key === "Escape") close()
        }

        let open = () => {
          if (input.src.active_class === "edit") return;
          document.addEventListener("keydown", onKeyDown, { capture: true });
          document.addEventListener("click", onClick, { capture: true });
          input.src.active_class = "edit";
          this.setState(this.state, () => inputNode.focus());
        };

        let close = () => {
          document.removeEventListener("keydown", onKeyDown, { capture: true });
          document.removeEventListener("click", onClick, { capture: true });
          input.src.active_class = input.src.value ? "" : "empty";
          this.setState(this.state);
        };

        switch(name)
        {
            case "mpn.src.icon" :
            {
                input = man.inputs.mpn
                open()
                break
            }

            case "datasheet.src.icon" :
            {
                input = man.inputs.datasheet
                open()
                break
            }

            default :
            {
                // noop
            }
        }
    }

    onChange(options={})
    {
        if(this.props.onChange)
        {
            let data  = {data: this.state.manufacturers, valid: true, inputChanged: (this.state.inputChanged || options.trackInputChanged)}
            let event = Utils.getEvent(this, data)
            this.props.onChange(event)
        }
        this.setCheckBoxState();
    }

    onSkuChange(event)
    {
        let state = this.state
        let sku     = state.inputs.sku
        let value   = event.target.value
        sku.value   = value
        sku.class   = sku.value ? "" : "disabled"
        state.showRefererTooltip = false

        state.alreadyUsedMpn = false
        state.alreadyUsedMpnData = null
        state.mpnIconClass = ""
        state.mpnIconToolTip = ""
        this.checkMpnAlreadyUsed()
        this.setState(state)
    }

    onSkuSubmit(event)
    {
        event.preventDefault()
        let state     = this.state
        let category  = this.props.category
        state.inputs.sku.class = "disabled"
        state.showRefererTooltip = false
        state.mpnIconClass = ""
        state.mpnIconToolTip = ""
        this.setState(state)
        API.components.getSource(category, state.inputs.sku.value, (err, source) => {
            if(err) {
                return this.setState(this.state)
            }

            state.inputs.sku.value   = ""
            state.inputs.sku.class = "disabled"
            this.setState(this.state, () =>
            {
                this.add(source)
            })
        }, false);
    }

    checkMpnAlreadyUsed()
    {
        let state = this.state
        let {inputs} = state
        let value = inputs.sku.value
        state.inputs.sku.class = "disabled"
        this.setState(state)

        let {manufacturers} = this.state
        if (value)
        {
            API.components.checkIfMpnUsed({ mpn: value, _id: this.props.itemId, category: this.props.objectData.category}, (err, res) =>
            {
                if(err)
                {
                    state.mpnIconClass = "duro-icon-warning-circle";
                    state.mpnIconToolTip = "MPN not found";
                    state.alreadyUsedMpn = false
                    return this.setState(state)
                }

                let {data} = res
                if (data && !data.isValidMpn)
                {
                    state.inputs.sku.class   = "disabled"
                    state.alreadyUsedMpn = true
                    state.alreadyUsedMpnData = data

                }
                else if (data && data.isValidMpn)
                {
                    state.alreadyUsedMpn = false
                    state.alreadyUsedMpnData = null
                    state.inputs.sku.class   = ""
                    state.mpnIconClass = "duro-icon-confirm-circle";
                    state.mpnIconToolTip = "MPN found";
                }

                let count = 0
                manufacturers.forEach((man) =>
                {
                    if (value === man.mpn.key) count++
                })

                if (count > 0)
                {
                    state.inputs.sku.class = "disabled"
                    state.mpnIconClass = "duro-icon-error-triangle";
                    state.alreadyUsedMpn = true
                    state.alreadyUsedMpnData = null
                }

                this.setState(state, this.onChange)
            })
        }
        else
        {
            state.inputs.sku.class = "disabled"
            state.alreadyUsedMpn = false
            state.alreadyUsedMpnData = null
        }
    }

    getIconsActionsList()
    {
        let actionsList = [];
        let actionEntry;
        actionEntry =
        {
            type          : 'action',
            iconSrc       : <PrimarySourceIcon />,
            toolTip       : "Set selected as Primary Source",
            name          : "Set Primary",
            iconClassName : ("primarySource " + (this.props.allowPrimarySourceIcon === true ? 'white' : 'dark')),
            onClick       : (this.props.allowPrimarySourceIcon === true ? this.props.setPrimarySourceInObject : null),
            active        : (this.props.allowPrimarySourceIcon === true ? true : false)
        };
        actionsList.push(actionEntry);
        if (this.props.tabsType === 'cmp') {
            actionsList.push({ type: 'divider' });
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <RefreshIcon/>,
                toolTip       : "Refresh Quote",
                name          : "Update",
                iconClassName : "update",
                className     : "update" + ((this.state.quoteRefreshbtn === true || this.props.primarySource.minQuantity) ? "" : " disable-pointer-event"),
                onClick       : (this.refreshQuote),
                active        : ((this.state.quoteRefreshbtn === true || this.props.primarySource.minQuantity) ? true : false)
            };
            actionsList.push(actionEntry);
        }
        actionsList.push({ type: 'divider' });
        actionEntry =
        {
            type          : 'action',
            iconSrc       : <ExpandIcon />,
            toolTip       : "Expand all rows",
            name          : "Expand",
            iconClassName : "expand",
            onClick       : (this.expandAll),
            active        : this.state.manufacturers.length
        };
        actionsList.push(actionEntry);
        actionEntry =
        {
            type          : 'action',
            iconSrc       : <ExpandIcon />,
            toolTip       : "Collapse all rows",
            name          : "Collapse",
            iconClassName : "collapse",
            onClick       : (this.collapseAll),
            active        : this.state.manufacturers.length
        };
        actionsList.push(actionEntry);
        return actionsList;
    }

    updateSourcingState(updatedData, trackInputChanged=false)
    {
        let { component }      = this.props;
        component.manufacturers = updatedData;
        // API.components.update(component._id, component, (err, source) =>
        // {
        //     if(err)
        //     {
        //         // toDo.
        //     }
        //     if (source) {
        //         // toDo.
        //     }
        // })
        this.state.manufacturers = updatedData;
        if(trackInputChanged)
        {
            this.setState(this.state, this.onChange({trackInputChanged: true}));
        }
        else
        {
            this.setState(this.state, this.onChange);
        }
    }

    removeSource(event, quoteId, selectedQuoteIds)
    {
        let filteredData = null;
        let removeAllSource = false;
        if(selectedQuoteIds.includes(quoteId)) removeAllSource = true;
        if (removeAllSource)
        {
            filteredData = SourcingUtils.getFilteredData(this.state.manufacturers, null);
        }
        else
        {
            filteredData = SourcingUtils.getFilteredData(this.state.manufacturers, quoteId);
        }
        this.updateSourcingState(filteredData, true);
    }

    clearSourceWarning(event, quoteId, selectedQuoteIds)
    {
        let clearedData = null;
        let clearAllSourceWarning = false;
        if(selectedQuoteIds.includes(quoteId)) clearAllSourceWarning = true;
        if (clearAllSourceWarning) {
            clearedData = SourcingUtils.getClearedData(this.state.manufacturers, null);
        } else {
            clearedData = SourcingUtils.getClearedData(this.state.manufacturers, quoteId);
        }
        this.updateSourcingState(clearedData);
    }

    toggleCountOnRemove(item, name)
    {
        if(name === "quote")
        {
            this.checkSingleQuoteError(item);
        }
        else
        {
            let isKeyValid  = item.inputs[name].key.valid;
            let isNameValid = item.inputs.name.valid;
            if(!isKeyValid)
            {
                this.props.toggleErrorCount(true, 'sourcingTab');
            }
            if(!isNameValid)
            {
                this.props.toggleErrorCount(true, 'sourcingTab');
            }
            switch(name)
            {
                case "dpn":
                {
                    this.checkQuotesError(item);
                    break;
                }
                case "mpn":
                {
                    this.checkDistributorError(item);
                    break;
                }
            }
        }
    }

    checkSingleQuoteError(item)
    {
        let quoteItems = ['minQuantity', 'unitPrice'];
        for(let value of quoteItems)
        {
            let isValid = item.inputs[value].valid;
            if(!isValid) this.props.toggleErrorCount(true, 'sourcingTab');
        }
    }

    checkQuotesError(item)
    {
        item.quotes.forEach((quote) => {
            this.checkSingleQuoteError(quote);
        })
    }

    checkDistributorError(item)
    {
        item.distributors.forEach((dist) => {
            let isKeyValid  = dist.inputs.dpn.key.valid;
            let isNameValid = dist.inputs.name.valid;
            if(!isKeyValid)
            {
                this.props.toggleErrorCount(true, 'sourcingTab');
            }
            if(!isNameValid)
            {
                this.props.toggleErrorCount(true, 'sourcingTab');
            }
            this.checkQuotesError(dist);
        })
    }

    validateSourcingFields(input, validator, value, data, valid_class, prevValue)
    {
        validateField(input, validator, value, data, valid_class);
        if(input.valid !== prevValue)
        {
            this.props.toggleErrorCount(input.valid, 'sourcingTab');
        }
    }

    renderWarrantyField(man, i)
    {
        let warrantyInput = man.inputs.warranty;

        return (
            <td className="warranty-inputs">
                <div
                className={warrantyInput.class}
                data-tip={warrantyInput.message}
                data-place="right"
                data-type="error"
                >
                    <LazyInput
                        type="text"
                        name="warranty"
                        autoComplete="off"
                        value={warrantyInput.value}
                        className={warrantyInput.class}
                        data-tip={warrantyInput.message}
                        data-place="right"
                        data-type="error"
                        onChange={(event) => this.onInputChange(event, i)}
                    />
                    <div className="radio">
                        <input
                            type="radio"
                            value="MONTHS"
                            onChange={(event) => this.onInputChange(event, i, "warrantyUnit")}
                            checked={["MONTHS", "MONTH"].includes(man.warranty.units)}
                           />
                        <label />
                        <span>Month(s)</span>
                    </div>

                    <div className="radio">
                        <input
                            type="radio"
                            value="YEARS"
                            onChange={(event) => this.onInputChange(event, i, "warrantyUnit")}
                            checked={["YEARS", "YEAR"].includes(man.warranty.units)}
                           />
                        <label />
                        <span>Year(s)</span>
                    </div>
                </div>
            </td>
        )
    }

    render()
    {
        if (this.props.activeTab !== "sourcing") return null
        let manufacturers      = this.state.manufacturers
        let sku                = this.state.inputs.sku
        let isAssembly         = this.props.isAssembly
        let propsPrimarySource = this.props.primarySource
        let state = this.state
        let { alreadyUsedMpnData, alreadyUsedMpn } = state

        let mpnDuplicateTooltip = {}

        if (alreadyUsedMpn && !alreadyUsedMpnData)
        {
            mpnDuplicateTooltip.errorMessage = "Duplicate MPN Found"
        }
        else if (alreadyUsedMpn && alreadyUsedMpnData)
        {
            mpnDuplicateTooltip = Utils.makeDuplicateInputTooltip(alreadyUsedMpnData, "MPN")
        }
        else
        {
            mpnDuplicateTooltip = null
        }

        let showWarranty = SourcingUtils.isWarrantyFieldEnabled(this.props.isDiffTool);

        let sku_markup = this.props.editSku ?
        <div className="add-mpn-block">
            <div>
                <InlineIcon className="search-icon">
                    <SearchIcon/>
                </InlineIcon>
                {
                    <Tooltip
                        placement={"right"}
                        overlayClassName={"simple-rc-tip error" + (mpnDuplicateTooltip ? "" : " hidden")}
                        getTooltipContainer={() => document.querySelector(".routes")}
                        overlay={ mpnDuplicateTooltip ?
                                <div>
                                    <p>
                                        <span className="link-text">{mpnDuplicateTooltip.errorMessage}</span>
                                        <br/>
                                        {
                                            alreadyUsedMpn && alreadyUsedMpnData &&
                                            <Link
                                                to={mpnDuplicateTooltip.viewLink}
                                                target="_blank"
                                                className="open-link-holder white"
                                                >
                                                <span className="link-text">
                                                    {mpnDuplicateTooltip.linkMessage}
                                                    <InlineIcon >
                                                        <LinkIcon/>
                                                    </InlineIcon>
                                                </span>
                                            </Link>
                                        }
                                    </p>
                                </div>
                                : ""
                            }
                        >

                        <div className="mpn-input-holder-sourcing">
                            <LazyInput
                                type="text"
                                autoComplete="off"
                                ref={this.setSkuInputRef}
                                value={sku.value}
                                className={alreadyUsedMpnData ? "invalid" : ""}
                                onChange={this.onSkuChange}
                                placeholder="Enter MPN or DPN"
                            />
                            <span
                                className={`ui-icon ${state.mpnIconClass}`}
                                data-tip={`${state.mpnIconToolTip ? state.mpnIconToolTip : ""}`}
                                data-place="right"
                                data-type={`${state.mpnIconToolTip === "MPN not found" ? "error" : ""}`}
                            />
                        </div>
                    </Tooltip>
                }

                <Tooltip
                        placement={"right"}
                        visible={this.state.showRefererTooltip}
                        overlayClassName={"blue-rc-tool-tip add-alternate-tooptip"}
                        getTooltipContainer={() => document.querySelector(".routes")}
                        overlay={<div><p><span>Add Alternate Source</span></p></div>}
                    >
                    <button
                    className={sku.class}
                    onClick={this.onSkuSubmit}
                    >
                    ADD
                </button>
                </Tooltip>

            </div>
        </div> : ""

        let markup =

            <div className="sources-tab app-row">
            {
                this.state.processModal && <RefreshQuoteModal open={true}
                hideModal={this.state.processModal ? "" : "hide-modal"}
                message="Checking for updates to your sourcing data."  />
            }

                { sku_markup }

                {
                    isAssembly &&
                    <div>
                        <h3 className="muted-text mb10">
                            ROLLED UP COST
                        </h3>

                        <RolledUpTable
                            userStyles={this.props.userStyles}
                            onRolledUpSelect={this.props.onRolledUpSelect}
                            onRolledUpDeSelect={this.props.onPrimarySourceDeselect}
                            children={this.props.children}
                            objectData={this.props.objectData}
                            onRolledUpInputChanged={this.props.onRolledUpInputChanged}
                            syncWithRolledUpCost={this.props.syncWithRolledUpCost}
                            afterSyncWithRolledUpCost={this.props.afterSyncWithRolledUpCost}
                            editMode={true}
                            manufacturers={manufacturers}
                            defaultCurrency={this.props.defaultCurrency}
                        />

                    </div>
                }

                {
                    isAssembly &&
                    <h3 className="muted-text mt10 mb10">
                        CUSTOM
                    </h3>
                }

                <div className="data-block sourcing-table-wrap extended-table">
                    <TableIcons
                        actionsList={this.getIconsActionsList()}
                        allowEnlarge={false}
                        displaySettings={false}
                        displayAutoFit={false}
                        className={'sourcing-table-icons'}
                        resultText={`${manufacturers.length} Manufacturer${manufacturers.length !== 1 ? 's' : ''}`}
                    />
                    <table className="sourcing-table">
                        <thead>
                            <tr>
                            {
                                this.state.columns.map((col, i) =>
                                {
                                    let headerCheckBox =
                                        <span
                                            className="primary-source-checkbox mfrCheckBox"
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="selectAllMFR"
                                                    onClick={
                                                        (e) => this.selectAllItems(event, manufacturers)
                                                    }
                                                />
                                                <label
                                                    htmlFor="group"
                                                />
                                            </span>
                                    let textBlock =
                                    <div
                                        className={"text-block"}
                                        >
                                        <div onClick={col.sortable ? this.onHeadingClick : null}>{col.value}</div>
                                        {manufacturers.length > 1 && <div onClick={col.sortable ? this.onHeadingClick : null}/>}
                                    </div>

                                    let block =
                                        <th
                                            key={i}
                                            name={col.name}
                                            className={col.class + " resizing---"}
                                            style={{width: col.width}}
                                            >
                                            <span className="handler">
                                                { (this.props.tabsType === 'cmp' && i == 0) ? headerCheckBox : textBlock}
                                            </span>
                                        </th>
                                    return block
                                })
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                manufacturers.map((man, i) =>
                                {
                                    let mpnDuplicateTooltipForTableInputs = null
                                    if (man.inputs.mpn.key.alreadyUsedMpn && man.inputs.mpn.key.alreadyUsedMpnData)
                                    {
                                        mpnDuplicateTooltipForTableInputs = Utils.makeDuplicateInputTooltip(man.inputs.mpn.key.alreadyUsedMpnData, "MPN")
                                    }

                                    let manClass = ("man-"+(i+1)+" ")
                                    let rowA =
                                        <tr className={ manClass + ((man.isPrimary && propsPrimarySource.minQuantity !== '')? 'primary-source' : '')} key={i + "-standard"}>
                                            <td
                                                className={"expand-btn " + man.class}
                                                >
                                                <div className={"expand-btn inlineBlock " + man.class}
                                                onClick={(event) => this.toggleRow(event, i)}/>
                                            </td>

                                            <td className="mpn-val-cell">
                                                <div
                                                    // className={man.inputs.mpn.key.class}
                                                    // data-tip={man.inputs.mpn.key.message}
                                                    // data-place="top"
                                                    // data-type="error"
                                                >
                                                <Tooltip
                                                    placement={"right"}
                                                    overlayClassName={"simple-rc-tip error" + (mpnDuplicateTooltipForTableInputs ? "" : " hidden")}
                                                    getTooltipContainer={() => document.querySelector(".routes")}
                                                    overlay={ mpnDuplicateTooltipForTableInputs ?
                                                            <div>
                                                                <p>
                                                                    <span className="link-text">{mpnDuplicateTooltipForTableInputs.errorMessage}</span>
                                                                    <br/>
                                                                    {
                                                                        man.inputs.mpn.key.alreadyUsedMpnData && man.inputs.mpn.key.alreadyUsedMpn &&
                                                                        <Link
                                                                            to={mpnDuplicateTooltipForTableInputs.viewLink}
                                                                            target="_blank"
                                                                            className="open-link-holder white"
                                                                            >
                                                                            <span className="link-text">
                                                                                {mpnDuplicateTooltipForTableInputs.linkMessage}
                                                                                <InlineIcon >
                                                                                    <LinkIcon/>
                                                                                </InlineIcon>
                                                                            </span>
                                                                        </Link>
                                                                    }

                                                                </p>
                                                            </div>
                                                            : ""
                                                        }
                                                    >


                                                    <LazyInput
                                                        type="text"
                                                        autoComplete="off"
                                                        name="mpn.key"
                                                        value={man.inputs.mpn.key.value}
                                                        className={man.inputs.mpn.key.class}
                                                        data-tip={man.inputs.mpn.key.message}
                                                        data-place="right"
                                                        data-type="error"
                                                        onChange={(event) => this.onInputChange(event, i)}
                                                    />
                                                </Tooltip>
                                                    <InlineIcon
                                                        name="mpn.src.icon"
                                                        className={"link-icon " + man.inputs.mpn.src.class + " " + man.inputs.mpn.src.active_class}
                                                        onClick={(event) => this.toggleSrcInput(event, i)}
                                                        tooltip={man.inputs.mpn.src.message}
                                                        tooltipType="error"
                                                    >
                                                        <LinkSrc />
                                                    </InlineIcon>
                                                    <LazyInput
                                                        type="text"
                                                        autoComplete="off"
                                                        name="mpn.src"
                                                        className={man.inputs.mpn.src.class + " " + man.inputs.mpn.src.active_class}
                                                        value={man.inputs.mpn.src.value}
                                                        placeholder="Paste URL"
                                                        data-tip={man.inputs.mpn.src.message}
                                                        data-type="error"
                                                        onChange={(event) => this.onInputChange(event, i)}
                                                    />
                                                </div>
                                            </td>

                                            <td>
                                                <LazyInput
                                                    type="text"
                                                    autoComplete="off"
                                                    name="manufacturer-name"
                                                    value={man.inputs.name.value}
                                                    className={man.inputs.name.class}
                                                    data-tip={man.inputs.name.message}
                                                    data-place="top"
                                                    data-type="error"
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                />
                                            </td>

                                            <td>
                                                <LazyInput
                                                    type="text"
                                                    autoComplete="off"
                                                    name="manufacturer-description"
                                                    value={man.inputs.description.value}
                                                    className={man.inputs.description.class}
                                                    data-tip={man.inputs.description.message}
                                                    data-place="top"
                                                    data-type="error"
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                />
                                            </td>

                                            <td className="datasheet-val-cell">
                                                <div>
                                                    <InlineIcon
                                                        name="datasheet.src.icon"
                                                        className={"link-icon " + man.inputs.datasheet.src.class + " " + man.inputs.datasheet.src.active_class}
                                                        onClick={(event) => this.toggleSrcInput(event, i)}
                                                        tooltip={man.inputs.datasheet.src.message}
                                                        tooltipType="error"
                                                    >
                                                        <LinkSrc />
                                                    </InlineIcon>
                                                    <LazyInput
                                                        type="text"
                                                        autoComplete="off"
                                                        name="datasheet.src"
                                                        className={man.inputs.datasheet.src.class + " " + man.inputs.datasheet.src.active_class}
                                                        value={man.inputs.datasheet.src.value}
                                                        placeholder="Paste URL"
                                                        data-tip={man.inputs.datasheet.src.message}
                                                        data-type="error"
                                                        onChange={(event) => this.onInputChange(event, i)}
                                                    />
                                                </div>
                                            </td>

                                            {
                                                showWarranty && this.renderWarrantyField(man, i)
                                            }

                                            <td className="remove-col">
                                                <InlineIcon onClick={(e) => this.remove(i)}>
                                                    <DeleteSrc />
                                                </InlineIcon>
                                            </td>
                                        </tr>

                                    let rowB =
                                        <tr
                                            key={i + "-expandable"}
                                            className={"expandable " + man.class}
                                            >
                                            <td colSpan="6">
                                                <Distributors
                                                    name="distributors"
                                                    tabsType={this.props.tabsType}
                                                    distributors={man.distributors}
                                                    component={this.props.component}
                                                    selectAllItems={this.selectAllItems}
                                                    manClass={manClass}
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                    schema={this.schema.manufacturers.distributors}
                                                    manufacturers={this.state.manufacturers}
                                                    clientSchema={this.clientSchema.distributors}
                                                    status={this.props.status}
                                                    toggleRefreshBtn={this.toggleRefreshBtn}
                                                    primarySource={this.props.primarySource}
                                                    onPrimarySourceChange={this.props.onPrimarySourceChange}
                                                    onPrimarySourceDeselect={this.props.onPrimarySourceDeselect}
                                                    syncPrimaryQuoteInputWithData={this.props.syncPrimaryQuoteInputWithData}
                                                    removeSource={this.removeSource}
                                                    clearSourceWarning={this.clearSourceWarning}
                                                    toggleCountOnRemove={this.toggleCountOnRemove}
                                                    validateSourcingFields={this.validateSourcingFields}
                                                    defaultCurrency={this.props.defaultCurrency}
                                                />
                                            </td>
                                        </tr>

                                    return [rowA, rowB]
                                })
                            }
                            <tr
                                className="add-row add-manufacturer"
                                onClick={() => this.add()}
                                >
                                <td>
                                    <div
                                    >
                                        <InlineIcon tooltip={"Add new manufacturer"} tooltipPlace="top">
                                            <AddSrc />
                                        </InlineIcon>
                                    </div>
                                </td>
                                <td>
                                    <span className="add-row-text">
                                        Add Row
                                    </span>
                                </td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        return markup
    }
}

// export default Sourcing
export default connect((store) => ({
    // user: store.user,
    children: store.assembly_edit.children,
    // assembly_edit: store.assembly_edit,
    // childrenSync: store.assembly_edit.sync

}))(Sourcing)
