import React, { ComponentType, FunctionComponent, Key, useCallback, useMemo } from "react";
import { Box, Button, styled, SxProps, Theme, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridApiRef,
} from "@mui/x-data-grid-pro";
import { InputFieldVariants, ToolbarItemType } from "common/constants";

export interface ToolBarItem {
  disabled?: boolean,
  Icon?: ComponentType,
  label?: string,
  onClick?: (e: MouseEvent | React.MouseEvent) => void,
  type: ToolbarItemType,
}

export const COLLAPSE_TOOLBAR_ICON_PROPS = {
  Icon: UnfoldLessOutlinedIcon,
  label: "Collapse",
  type: ToolbarItemType.ACTION,
};

const commonButtonStyle = (theme: Theme, customButtonStyle: SxProps<Theme> = {}): any => (
  {
    "& .MuiButton-root ":
      {
        border: "0 !important",
        padding: 0,
        marginRight: "1rem",
        textTransform: "none",
        "& .MuiButton-startIcon": {
          marginRight: "0.313rem",
          "& svg": {
            fontSize: "20px",
          },
        },
        ...customButtonStyle,
        "&:not(.Mui-disabled)": {
          color: theme.palette.common.white,
        },
        "&.Mui-disabled": {
          color: theme.duro.complexTable.toolbar.disabledButtonColor,
        },
        "&:hover": {
          backgroundColor: "transparent",
          color: `${theme.palette.primary.main} !important`,
          border: 0,
        },
        "& .MuiTypography-root": {
          fontSize: "13px",
        },
        "& .MuiBadge-badge": { display: "none" },
      },
  });

const ToolbarContainer = styled(Box)(({ theme }) => (
  {
    backgroundColor: theme.duro.complexTable.backgroundColor,
    border: `0.063rem solid ${theme.duro.complexTable.borderColor}`,
    display: "inline-block",
    bottom: "-0.313rem",
    borderRadius: "0.25rem",
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    "& .MuiDataGrid-toolbarContainer": {
      backgroundColor: theme.duro.complexTable.backgroundColor,
      padding: 0,
    },
    ...commonButtonStyle(theme),
  }
));

const IconDivider = styled(Box)(({ theme }) => ({
  borderRight: `0.063rem solid ${theme.duro.complexTable.borderColor}`,
  display: "inline-flex",
  height: "1rem",
  marginRight: "0.938rem",
}));

export const InitialDivider = styled(Box)(({ theme }) => ({
  borderRight: `0.063rem solid ${theme.duro.complexTable.borderColor}`,
  height: "1.875rem",
  width: "3.125rem",
}));

const ToolbarItemsContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  padding: "0.125rem 1.25rem 0.125rem",
  "& .MuiButton-root ": {
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const FiltersCounter = styled(Box)(({ theme }) => ({
  display: "inline-block",
  top: "-5px",
  padding: "0 10px",
  color: theme.palette.primary.main,
  "& .MuiTypography-root": {
    fontSize: "13px",
    display: "inline-block",
  },
  ...commonButtonStyle(theme, {
    height: 0,
    top: "-2px",
    left: "-10px",
    color: "#d8d8d8 !important",
    "& svg": {
      fontSize: "17px !important",
    },
  }),
}));

export interface IGridToolBarProps {
  apiRef: GridApiRef;
  filters: any;
  items: Array<ToolBarItem>;
}

export const GridToolbar: FunctionComponent<IGridToolBarProps> = (props: IGridToolBarProps) => {
  const { apiRef, filters, items } = props;
  const clearFilters = useCallback(() => (apiRef?.current?.setFilterModel({ items: [] })), [apiRef]);
  const showFiltersCount = useMemo(() => {
    if (!filters?.items?.length) return null;
    return (
      <FiltersCounter>
        <Typography>{`${filters?.items?.length} Filters Active`}</Typography>
        <Button
          onClick={clearFilters}
          startIcon={<HighlightOffIcon />}
          variant={InputFieldVariants.OUTLINED}>
        </Button>
      </FiltersCounter>
    );
  }, [filters, clearFilters]);

  const renderItems = useMemo(() => (
    items.map((item: ToolBarItem, index: number) => {
      const { disabled, Icon, label, onClick, type } = item;
      const key: Key = `${type}-${index}`;
      switch (type) {
        case ToolbarItemType.ACTION:
          return (
            <Button
              disabled={disabled}
              key={key}
              onClick={onClick}
              startIcon={Icon ? <Icon /> : null}
              variant={InputFieldVariants.OUTLINED}
            >
              {label}
            </Button>
          );
        case ToolbarItemType.DIVIDER:
          return <IconDivider key={key} />;
        default:
          return null;
      }
    })
  ), [items]);
  return (
    <Box>
      <ToolbarContainer>
        <GridToolbarContainer>
          <InitialDivider />
          <ToolbarItemsContainer>
            {renderItems}
          </ToolbarItemsContainer>
          <GridToolbarFilterButton/>
          <GridToolbarColumnsButton/>
        </GridToolbarContainer>
      </ToolbarContainer>
      { showFiltersCount }
    </Box>
  );
};
