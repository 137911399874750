import                                                    "./index.css"
import React                                              from "react"
import { Link }                                           from 'react-router-dom'
import Schemas, {permissions as Permissions}              from "../../../../modules/schemas"
import {connect}                                          from 'react-redux';

class SettingSideNav extends React.Component
{
    render()
    {
        let canViewUsers = Permissions.can("view", "user", this.props.user)
        let canViewGroups = Permissions.can("view", "groups", this.props.user)
        let currentEnv = this.props.user.currentEnv
        let role       = this.props.user.role;
        let currentPathName = this.props.location.pathname
        let markup =
            <div className="setting-side-nav">
                <div className="crumb-block">SETTINGS</div>
                <div className="nav-block">
                    {
                        canViewUsers && currentEnv === 'LIVE' ?
                        <Link
                          className={currentPathName === "/settings/users" ? "active" : ""}
                          to="/settings/users">
                          users
                        </Link> : null
                    }
                    {
                        canViewGroups && currentEnv === 'LIVE' ?
                        <Link
                          className={currentPathName === "/settings/groups" ? "active" : ""}
                          to="/settings/groups">
                          groups
                        </Link> : null
                    }
                    { currentEnv === 'LIVE' ?
                      <Link
                        className={currentPathName === "/settings/company" ? "active" : ""}
                        to="/settings/company">
                        company profile
                      </Link> : null
                    }

                    {
                       this.props.configurations && this.props.configurations.isConfigurationsEnabled ? <Link
                          className={currentPathName === "/settings/configurations" ? "active" : ""}
                          to="/settings/configurations">
                          configuration
                        </Link> : null
                    }

                    <Link
                      className={currentPathName === "/settings/user" ? "active" : ""}
                      to="/settings/user">
                      your profile
                    </Link>
                    {
                        (role === 'ADMINISTRATOR' || role === 'USER') && currentEnv === 'LIVE' ?
                        <Link
                          className={currentPathName === "/settings/integrations" ? "active" : ""}
                          to="/settings/integrations">
                          integrations
                        </Link> : null
                    }
                    { currentEnv === 'LIVE' ?
                      <Link
                        className={currentPathName === "/settings/webhooks" ? "active" : ""}
                        to="/settings/webhooks">
                        webhooks
                      </Link> : null
                    }
                </div>
            </div>

        return markup
    }
}

export default connect((store) => store.company.data.settings)(SettingSideNav)
