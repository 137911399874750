import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import {
  Box,
  LinearProgress,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import {
  GridAlignment,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridFooterContainer,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueFormatterParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Grid, SortMode } from "common/components/grid/grid";
import { COLLAPSE_TOOLBAR_ICON_PROPS, InitialDivider, ToolBarItem } from "common/components/grid/gridToolbar";
import { ToolbarItemType } from "common/constants";
import { InputField } from "common/input-field";
import { Select } from "common/select";
import { AliasType } from "graphql/query/itemQueries";
import { GroupingColDef } from "./groupingColDef";
import { IParentItemProps } from "../serialization-modal";
import moment from "moment";
import { colorPalette } from "theme";
import RevisionField from "v1/components/page/common/revision-field";
import StatusField from "v1/components/page/common/status-field";

export interface INode {
  alias: AliasType,
  category: string,
  children?: IParentItemProps[],
  id: string,
  name: string,
  parent?: string,
  status: string,
  version: string,
}
export interface ITableProps {
  childrenCount: number,
  childrenToggle: boolean,
  currentItem: IParentItemProps,
  loading?: boolean,
  productionRun: number,
}

export type ITreeNode = INode & {
  _path: string[],
  cpn: string,
  lastUpdated: number,
  qtyNeeded: number,
  qtyToBuild: number,
  notes: string,
}

const notesStyle = {
  style: {
    backgroundColor: colorPalette.black,
    borderRadius: "0",
    padding: "0.125rem 0.425rem",
  },
};

const qtyToBuildStyle = {
  style: {
    backgroundColor: colorPalette.black,
    borderRadius: "0",
    padding: "0.125rem 0.425rem",
  },
};

const serialIdProps = {
  componentStyle: { width: "100%" },
  inputStyle: { sx: { backgroundColor: colorPalette.black, padding: "0.25rem 0.475rem" } },
  options: ["Auto", "Manual", "Commodity", "Unique Commodity"],
};

export const Table: FC<ITableProps> = (({
  childrenCount,
  childrenToggle,
  currentItem,
  loading,
  productionRun,
}) => {
  const apiRef = useGridApiRef();
  const onChangeSerialId = useCallback((selectEvent: SelectChangeEvent<string>) => {
    //  need to handle onChangeSerialId
  }, []);

  const onIconClick = useCallback(() => {
    //  need to handle onIconClick
  }, []);

  const columnDefs: GridColDef[] = useMemo(() => (
    [
      {
        field: "name",
        headerName: "NAME",
        width: 200,
      },
      {
        field: "serialId",
        headerName: "SERIAL ID",
        renderCell: (): ReactNode => (
          <Select
            componentStyles={serialIdProps.componentStyle}
            defaultValue="Auto"
            inputProps={serialIdProps.inputStyle}
            isRequired={false}
            onChange={onChangeSerialId}
            options={serialIdProps.options}
          />
        ),
        width: 200,
      },
      {
        field: "revision",
        headerName: "REV",
        renderCell: (): ReactNode => (
          <RevisionField item={currentItem} revision={currentItem.version} showIcon={false} />
        ),
        width: 100,
      },
      {
        field: "status",
        headerName: "STATUS",
        renderCell: (params: GridRenderCellParams): ReactNode => (
          <StatusField status={params.value} />
        ),
        width: 120,
      },
      {
        field: "qtyNeeded",
        headerAlign: "left" as GridAlignment,
        headerName: "QTY NEEDED",
        type: "number",
        width: 200,
      },
      {
        editable: true,
        field: "qtyToBuild",
        headerAlign: "left" as GridAlignment,
        headerName: "QTY TO BUILD",
        renderCell: (params: GridRenderCellParams): ReactNode => (
          <InputField
            inputStyles={qtyToBuildStyle}
            isRequired={false}
            value={params.value}
          />
        ),
        type: "number",
        width: 200,
      },
      {
        field: "category",
        headerName: "CATEGORY",
        width: 200,
      },
      {
        editable: true,
        field: "notes",
        headerName: "NOTES",
        renderCell: (params: GridRenderCellParams): ReactNode => (
          <InputField
            inputStyles={notesStyle}
            isRequired={false}
            placeholder={"Enter Notes"}
            value={params.value}
          />
        ),
        width: 200,
      },
      {
        field: "lastUpdated",
        headerName: "LAST UPDATED",
        type: "date",
        valueFormatter: (params: GridValueFormatterParams) => (
          moment(params?.value).format("MMM D, YYYY hh:mmA")),
        width: 200,
      },
    ]
  ), [currentItem, onChangeSerialId]);

  const toolbarItems: Array<ToolBarItem> = useMemo(() => ([
    {
      disabled: false,
      Icon: FilterListIcon,
      label: "Filter",
      onClick: onIconClick,
      type: ToolbarItemType.ACTION,
    },
    {
      type: ToolbarItemType.DIVIDER,
    },
    {
      disabled: false,
      Icon: PinOutlinedIcon,
      label: "Bulk Serial ID",
      onClick: onIconClick,
      type: ToolbarItemType.ACTION,
    },
    {
      type: ToolbarItemType.DIVIDER,
    },
    {
      ...COLLAPSE_TOOLBAR_ICON_PROPS,
      disabled: false,
      onClick: onIconClick,
    },
  ]), [onIconClick]);

  const assignProps = useCallback((
    cmp: IParentItemProps,
    parentPath: string[] = [],
    quantity: number = 1,
    tree: ITreeNode[] = [],
  ) => {
    const { alias, category, cpn, id, lastModified, name, status, version } = cmp;
    const currentCpn = cpn.displayValue;
    const _path = [...parentPath, ...[currentCpn]];
    tree.push({
      _path,
      alias,
      category,
      cpn: currentCpn,
      id,
      lastUpdated: lastModified,
      name,
      notes: "",
      qtyNeeded: quantity,
      qtyToBuild: quantity * productionRun,
      status,
      version: version === "" ? "-" : version,
    });
    return _path;
  }, [productionRun]);

  const customFooter = useCallback(() => (
    <CustomGridFooter>
      <InitialDivider />
      <Box>
        <FooterTypoGraphy>
          {childrenCount} Children
        </FooterTypoGraphy>
      </Box>
    </CustomGridFooter>
  ), [childrenCount]);

  const generateTree = useCallback((
    node: IParentItemProps,
    tree: ITreeNode[] = [],
    parentPath: string[] = [],
    quantity: number = 1,
  ) => {
    const immediateParentPath = assignProps(node, parentPath, quantity, tree);
    if (childrenToggle && node.children) {
      for (const children of node.children) {
        generateTree(children.component, tree, immediateParentPath, children.quantity);
      }
    }
  }, [assignProps, childrenToggle]);

  const getRows = useMemo(() => {
    const tree: ITreeNode[] = [];
    generateTree(currentItem, tree);
    return tree;
  }, [currentItem, generateTree]);

  const [rows, setRows] = useState<ITreeNode[]>(getRows);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);

  useEffect(() => (setSelectionModel(() => rows.map(r => r?.id))), [rows]);
  useEffect(() => (setRows(getRows)), [getRows, currentItem]);

  const onSelectionChange = (_selectionModel: GridSelectionModel, _details: GridCallbackDetails) => {
    if (rows.length < 2) return;
    setSelectionModel(() => rows.filter(r => _selectionModel.includes(r.id)).map(r => r?.id));
  };

  const getLoadingOverlay = useCallback(() => <CustomLinearProgress />, []);

  const groupingColDef: GridGroupingColDefOverride = {
    headerName: "CPN",
    renderCell: params => <GroupingColDef {...params} />,
  };

  const formControlFields: string[] = ["serialId", "qtyToBuild", "notes"];
  const getCellClassName = (params: GridCellParams) => (
    formControlFields.includes(params.field) ? "form-control-field" : "");

  const isCellEditable = useCallback((params: GridCellParams<number>) => (
    apiRef.current.isRowSelected(params.id)
  ), [apiRef]);

  return (
    <Grid
      apiRef={apiRef}
      autoHeight={true}
      columnDefinition={columnDefs}
      customFooter={customFooter}
      data={rows}
      defaultGroupingExpansionDepth={childrenCount ? 1 : 0}
      deselectedRowClassName={"disabled"}
      getCellClassName={getCellClassName}
      isCellEditable={isCellEditable}
      loading={loading}
      loadingOverlay={getLoadingOverlay}
      onSelectionChange={onSelectionChange}
      scrollEndThreshold={100}
      selectionModel={selectionModel}
      sortMode={SortMode.CLIENT}
      toolbarItems={toolbarItems}
      treeColumnDefinition={groupingColDef}
      treeData={true}
    />
  );
});

const CustomGridFooter = styled(GridFooterContainer)({
  backgroundColor: colorPalette.taupeGrayShade,
  border: `0.063rem solid ${colorPalette.spanishGray}`,
  borderTop: "0",
  display: "flex",
  justifyContent: "space-between",
  minHeight: "2.125rem",
  padding: "0",
});

const CustomLinearProgress = styled(LinearProgress)({
  zIndex: "1000",
});

const FooterTypoGraphy = styled(Typography)({
  paddingRight: "1.888rem",
});
