import                           "./index.css"
import                           "react-datepicker/dist/react-datepicker.css"
import React, {Component}    from "react"
import DatePicker            from "react-datepicker"
import moment                from "moment"
import { connect }           from "react-redux"
import PRODUCT               from "../../../../action-types/product"
import buildAction           from "../../../../helpers/buildAction"
import Schemas               from "../../../../modules/schemas"
import Utils                 from "../../../../modules/utils"
import Icon                  from "../../../ui/icon"
import AddSrc                from "../../../../assets/icons/add-row"
import DeleteIcon            from "../../../../assets/icons/cross-icon"
import ItemScheduleIcon      from "../../../../assets/icons/item-schedule-icon"
import Thumbnails            from "../../common/thumbnails"
import validations           from "../../../../modules/validations"
import Documents             from "../../common/documents/new"
import {Helmet}              from "react-helmet"
import LazyInput             from "../../../ui/lazy-input/input.js"
import TextArea              from "../../../ui/lazy-input/textarea.js"
import Tooltip               from 'rc-tooltip'
import InlineIcon            from "../../../ui/icon/inline-icon.js"
import Link                  from "../../../ui/link"
import LinkIcon              from "../../../../assets/icons/open-link-icon.js"
import config                from "../../../../modules/config"
import ExpertForecastPopover from "../../common/expert-forecast-popover"


export class NewProduct extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.getProductTeam           = this.getProductTeam.bind(this)
        this.getComponentCategoryList = this.getComponentCategoryList.bind(this)
        this.addForecast              = this.addForecast.bind(this)
        this.remove                   = this.remove.bind(this)
        this.onDateChange             = this.onDateChange.bind(this)
        this.onInputChange            = this.onInputChange.bind(this)
        this.getForecasts             = this.getForecasts.bind(this)
        this.getData                  = this.getData.bind(this)
        this.onSubmit                 = this.onSubmit.bind(this)
        this.onKeyPress               = this.onKeyPress.bind(this)
        this.onDocumentChange         = this.onDocumentChange.bind(this)
        this.onThumbnailChange        = this.onThumbnailChange.bind(this)
        this.displayExpertForecastPopover = this.displayExpertForecastPopover.bind(this)
        this.hideExpertForecastPopover = this.hideExpertForecastPopover.bind(this)
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
        this.state =
        {
            displayExpertForecastPopover: false
        }
    }

    displayExpertForecastPopover()
    {
        this.setState({displayExpertForecastPopover: true})
    }

    hideExpertForecastPopover()
    {
        this.setState({displayExpertForecastPopover: false})
    }

    componentDidUpdate()
    {
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
    }

    componentDidMount(){
        if (this.forecastScheme === "EXPERT")
        {
            const {dispatch} = this.props
            dispatch(buildAction(PRODUCT.SET_NON_DEFAULT_FORECAST, {forecastType: this.forecastScheme}))
        }
    }

    componentWillUnmount()
    {
        const {dispatch} = this.props
        dispatch(buildAction(PRODUCT.RESET_PRODUCT_NEW_FORM))
    }

    getProductTeam()
    {
        let { inputs } = this.props.product.newPage
        let team = {}
        inputs.productTeam.forEach((input) => team[input.schema.value] = { email: input.value })
        return team
    }

    getComponentCategoryList()
    {
        let checkedItems = []
        let { inputs } = this.props.product.newPage

        inputs.categoryList.map((input) =>
        {
            if (input.checked)
            {
                let item = input.schema.value

                checkedItems.push(item)
            }

            return null
        })

        return checkedItems
    }


    addForecast(forecastLabelType="")
    {
        const {dispatch} = this.props
        dispatch(buildAction(PRODUCT.ADD_NEW_FORECAST, {forecastType: this.forecastScheme, forecastLabelType}))
    }

    remove(i)
    {
        const {dispatch} = this.props
        dispatch(buildAction(PRODUCT.REMOVE_FORECAST, {index: i, forecastType: this.forecastScheme}))
    }

    onDateChange(date, event, i)
    {
        // if (!date) return
        // mock an input event when date is changed
        let name  = "forecastsTarget"
        let value = date ? new Date(date.toISOString()).getTime() : ""
        this.onInputChange({target: {name: name, value: value}}, i)
    }

    onInputChange(event, i)
    {

        const {dispatch} = this.props;
        let payload = {
            value: event.target.value,
            name: event.target.name,
            index: i
        }

        if (event.target.name === "name")
        {
            dispatch(buildAction(PRODUCT.NEW_FORM_NAME_CHANGE, payload))
        }
        else if (event.target.name === "eid")
        {
            dispatch(buildAction(PRODUCT.NEW_FORM_EID_CHANGE, payload))
        }
        else
        {
            dispatch(buildAction(PRODUCT.UPDATE_NEW_FORM_INPUT_STATE, payload))
        }

    }

    onDocumentChange(event, payload)
    {
        const {dispatch} = this.props;
        let { inputs } = this.props.product.newPage
        dispatch(buildAction(PRODUCT.UPDATE_NEW_FORM_INPUT_STATE,
        {
            value: payload,
            name: event.target.name
        }))
    }

    onThumbnailChange(images, error)
    {
        const {dispatch} = this.props;
        let { inputs } = this.props.product.newPage
        dispatch(buildAction(PRODUCT.UPDATE_NEW_FORM_INPUT_STATE,
        {
            value: {images: images, isUploadedThumbnails: error },
            name: "images"
        }))
    }

    getForecasts()
    {
        let { inputs } = this.props.product.newPage
        let forecastInputs = inputs.forecasts

        return forecastInputs.map((input) =>
        {
            let data =
            {
                key        : input.key.value,
                targetDate : input.targetDate.value ? Number(input.targetDate.value) : "",
                volume     : input.volume.value ? Number(input.volume.value) : "",
                counter     : Number(input.counter.value)
            }
            return data
        })
    }

    getData()
    {
        let { inputs } = this.props.product.newPage

        let data =
        {
            name         : inputs.name.value,
            revision     : inputs.revision.value,
            status       : inputs.status.value,
            eid          : inputs.eid.value,
            description  : inputs.description.value,
            images       : inputs.images,
            documents    : inputs.documents,
            children     : [],
            team         : this.getProductTeam(),
            categories   : this.getComponentCategoryList(),
            forecasts    : this.getForecasts(),
            archived     : true
        }

        return data
    }

    onSubmit(event)
    {
        event.preventDefault()
        const {dispatch} = this.props
        let { inputs } = this.props.product.newPage

        if(inputs.submit.class === "disabled")
        {
            return
        }
        let payload =
        {
            data: this.getData(),
            history: this.props.history
        }
        dispatch(buildAction(PRODUCT.SUBMIT_PRODUCT_NEW_FORM, payload))
    }

    onKeyPress(event) {
        if (event.target.type != 'textarea' && event.which === 13 /* Enter */) {
          event.preventDefault();
        }
    }

    render()
    {
        let form   = this.props.product.newPage
        let { inputs } = this.props.product.newPage
        let forecastKeys = []
        inputs.forecasts.forEach((input) => forecastKeys.push(input.key.value))
        const day = 60 * 60 * 24 * 1000;

        if (window.__libraryType === "GENERAL")
        {
            if(!inputs.revision.value)  inputs.revision.value = Utils.getInitialRevision()
        }

        let { nameDuplicateOf, eidDuplicateOf } = inputs
        let eidDuplicateTooltip  = Utils.makeDuplicateInputTooltip(eidDuplicateOf, "EID")
        let nameDuplicateTooltip = Utils.makeDuplicateInputTooltip(nameDuplicateOf, "Name")

        let markup =

            <div className="new-product-route app-row">
                <Helmet>
                    <title>{Utils.makeTitle("New Product")}</title>
                </Helmet>

                <div className="crumb-block">PRODUCTS / NEW</div>

                <div className="main-block">

                    <div className="content-block">

                        <h1>New Product</h1>
                        <h3>Create a new product by entering details manually.</h3>

                        <div className="product-category-block">
                            <p className="small-margin">Tell us which component categories this project includes:</p>

                            {
                                inputs.categoryList.map((input, i) =>
                                {
                                    let block =
                                        <div key={i}>

                                            <input
                                                type="checkbox"
                                                name="categoryItem"
                                                value={input.schema.displayName}
                                                onChange={(event) => this.onInputChange(event, i)}
                                            />
                                            <label htmlFor="" />
                                            <span>
                                                <span>{input.schema.displayName}</span>
                                            </span>

                                        </div>
                                    return block
                                })
                            }

                        </div>

                        <form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>

                            <label htmlFor="name">Product Name*</label>
                            <Tooltip
                                placement={"right"}
                                visible={nameDuplicateTooltip}
                                overlayClassName={"simple-rc-tip error"}
                                getTooltipContainer={() => document.querySelector(".routes")}
                                overlay={ nameDuplicateTooltip ?
                                        <div>
                                            <p>
                                                <span className="link-text">{nameDuplicateTooltip.errorMessage}</span>
                                                <br/>
                                                <Link
                                                    to={nameDuplicateTooltip.viewLink}
                                                    target="_blank"
                                                    className="open-link-holder white"
                                                    >
                                                    <span className="link-text">{nameDuplicateTooltip.linkMessage}
                                                        <InlineIcon >
                                                            <LinkIcon/>
                                                        </InlineIcon>
                                                    </span>
                                                </Link>
                                            </p>
                                        </div>
                                        : ""
                                    }
                                >
                                <LazyInput
                                    type="text"
                                    name="name"
                                    placeholder="Enter product name"
                                    value={inputs.name.value}
                                    className={inputs.name.class}
                                    onChange={this.onInputChange}
                                    data-tip={inputs.name.message}
                                    data-place="right"
                                    data-type="error"
                                />
                            </Tooltip>
                            <div className="rev-block">
                                <div>
                                    <label htmlFor="revision">Revision*</label>
                                    <LazyInput
                                        type="text"
                                        name="revision"
                                        value={inputs.revision.value}
                                        onChange={this.onInputChange}
                                        className={inputs.revision.class}
                                        data-tip={inputs.revision.message}
                                        data-place="right"
                                        data-type="error"

                                    />
                                </div>
                                <div>
                                    <label htmlFor="status">Lifecycle Status*</label>
                                    <select
                                        name="status"
                                        value={inputs.status.value}
                                        onChange={this.onInputChange}
                                        >
                                        {Utils.toOptions(Schemas.product.status.list())}
                                    </select>
                                </div>
                            </div>

                            <label htmlFor="eid">EID</label>
                            <Tooltip
                                placement={"right"}
                                visible={eidDuplicateTooltip}
                                overlayClassName={"simple-rc-tip error"}
                                getTooltipContainer={() => document.querySelector(".routes")}
                                overlay={ eidDuplicateTooltip ?
                                        <div>
                                            <p>
                                                <span className="link-text">{eidDuplicateTooltip.errorMessage}</span>
                                                <br/>
                                                <Link
                                                    to={eidDuplicateTooltip.viewLink}
                                                    target="_blank"
                                                    className="open-link-holder white"
                                                    >
                                                    <span className="link-text">{eidDuplicateTooltip.linkMessage}
                                                        <InlineIcon >
                                                            <LinkIcon/>
                                                        </InlineIcon>
                                                    </span>
                                                </Link>
                                            </p>
                                        </div>
                                        : ""
                                    }
                                >
                                <LazyInput
                                    type="text"
                                    name="eid"
                                    placeholder="External ID"
                                    className={inputs.eid.class}
                                    value={inputs.eid.value}
                                    onChange={this.onInputChange}
                                    data-tip={inputs.eid.message}
                                    data-place="right"
                                    data-type="error"
                                />
                            </Tooltip>
                            <label htmlFor="description">Product Description</label>
                            <TextArea
                                name="description"
                                placeholder="Enter product description"
                                value={inputs.description.value}
                                onChange={this.onInputChange}
                                className={inputs.description.class}
                                data-tip={inputs.description.message}
                                data-place="right"
                                data-type="error"

                            />

                            <div className="team-block">
                                <div>
                                    <h3 className="sub-heading small-margin">Product Team</h3>
                                </div>

                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                inputs.productTeam.map((input, i) =>
                                                {
                                                    let block =
                                                        <tr key={i}>
                                                            <td>
                                                                {input.schema.displayName}
                                                            </td>
                                                            <td>
                                                                <LazyInput
                                                                    type="text"
                                                                    className={input.class}
                                                                    placeholder="Enter Email"
                                                                    name="productTeam"
                                                                    value={input.value}
                                                                    onChange={(event) => this.onInputChange(event, i)}
                                                                    data-tip={input.message}
                                                                    data-place="right"
                                                                    data-type="error"

                                                                />
                                                            </td>
                                                        </tr>
                                                    return block
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="forecasting-block">
                                <h3 className="sub-heading small-margin">Build Schedule Forecast</h3>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Production</th>
                                            <th>Target Date</th>
                                            <th>Volume</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            inputs.forecasts.map((input, i) =>
                                            {
                                                let block =
                                                    <tr key={i}>
                                                        <td>
                                                            {validations.product.forecasts.getForecastDisplayName(input.key.value, input.counter.value, this.forecastScheme, forecastKeys)}
                                                        </td>
                                                        <td className="forecast-date">
                                                            <div className="custom-date-picker-input-holder">
                                                                <DatePicker
                                                                    selected={input.targetDate.value ? new Date(input.targetDate.value) : null}
                                                                    onChange={(date) => this.onDateChange(date, null, i)}
                                                                    className={`forecasts-date-picker ${input.targetDate.class} custom-date-trigger-${i}`}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    data-tip={input.targetDate.message}
                                                                    data-place="right"
                                                                    data-type="error"
                                                                    customInput={
                                                                        <input
                                                                            type="text"
                                                                            value={input.targetDate.value ? Utils.dateFormatWithSlash(input.targetDate.value) : null}
                                                                            data-tip={input.targetDate.message}
                                                                            data-place="right"
                                                                            data-type="error"
                                                                            className={`${input.targetDate.class}`}
                                                                        />
                                                                    }
                                                                />
                                                                <InlineIcon
                                                                    tooltip={`${input.targetDate.message ? input.targetDate.message : ""}`}
                                                                    tooltipType="error"
                                                                    tooltipPlace="right"
                                                                >
                                                                   <ItemScheduleIcon onClick={() => document.querySelector(`.custom-date-trigger-${i}`) && document.querySelector(`.custom-date-trigger-${i}`).click()}/>
                                                                 </InlineIcon>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <LazyInput
                                                                type="text"
                                                                placeholder="Volume"
                                                                name="forecastsVolume"
                                                                value={input.volume.value}
                                                                onChange={(event) => this.onInputChange(event, i)}
                                                                className={`${input.volume.class}`}
                                                                data-tip={input.volume.message}
                                                                data-place="right"
                                                                data-type="error"
                                                                autoComplete="off"

                                                            />
                                                        </td>

                                                        <td>
                                                            <InlineIcon
                                                                onClick={input.isDisabled ? null : () => this.remove(i)}
                                                                className={`${input.isDisabled ? "disabled" : ""} remove-forecast`}
                                                            >
                                                              <DeleteIcon />
                                                            </InlineIcon>
                                                        </td>
                                                    </tr>
                                                return block

                                            })
                                        }

                                        <tr className="add-row">
                                            <td>
                                                <div className="add-forecast" onClick={this.forecastScheme === "DEFAULT" ? this.addForecast : this.displayExpertForecastPopover}>
                                                    <InlineIcon ><AddSrc/></InlineIcon>
                                                    <div>ADD NEW FORECAST</div>

                                                </div>
                                                {
                                                    this.state.displayExpertForecastPopover &&
                                                    <ExpertForecastPopover
                                                        addForecast={this.addForecast}
                                                        hideExpertForecastPopover={this.hideExpertForecastPopover}
                                                    />
                                                }
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                            <label htmlFor="thumbnails">Thumbnails</label>
                            <Thumbnails
                                name="images"
                                onChange={this.onThumbnailChange}
                            />

                            <label htmlFor="documents">Documents</label>
                            <Documents
                                name="documents"
                                onChange={this.onDocumentChange}
                                clientSchema={validations.product.documents}
                                documentSchema={Schemas.product.documents}
                            />
                            <input
                                type="submit"
                                value="create"
                                className={inputs.submit.class}
                            />

                        </form>

                    </div>

                </div>


            </div>

        return markup
    }
}

export default connect((store) => store)(NewProduct)
