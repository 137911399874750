import { takeEvery, all, cps, put } from "redux-saga/effects";
import UI                           from "../../../action-types/ui";
import COMPONENT                    from "../../../action-types/component";
import buildAction                  from "../../../helpers/buildAction";
import API                          from "../../../modules/api";
import {privatePaths}               from "../../../app/routes.js";
import Schemas                      from "../../../modules/schemas";
import Utils                        from "../../../modules/utils"


export function* findRevisionById(action)
{
    try
    {
        const revision = yield cps(API.componentRevision.findById, action.payload.id);
        if(!revision)
        {
            let errors = [];
            let err = new Error("Invalid Revision ID");
            errors.push(err);
            yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}));
        }
        else
        {
            try
            {
                let parentId = `${revision.parent}?include=revisions&history=true`;
                const component = yield cps(API.components.findById, parentId);
                if (component && component.revisions[component.revisions.length -1]._id === revision._id && !Utils.checkForRestrictedRole(window.__userRole))
                {
                    action.payload.history.replace(`/component/view/${component._id}`)
                }
                else
                {
                    if(!component)
                    {
                        let errors = [];
                        let err = new Error("Invalid Component ID");
                        errors.push(err);
                        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}));
                    }
                    else
                    {
                        let isAssembly = Schemas.component.category.getType(revision.category).toLowerCase() === "assembly";
                        let documents_length = revision.documents.length;
                        if(!isAssembly || (revision.children && revision.children.length == 0)){
                            yield put(buildAction(COMPONENT.SET_REVISION_CHILDREN_LOADED, true ));
                        }
                        if(documents_length == 0){
                            yield put(buildAction(COMPONENT.SET_REVISION_DOCUMENTS_LOADED, true ));
                        }

                        let node = document.createElement("span");
                        let cvs  = document.createElement("canvas");
                        let ctx  = cvs.getContext("2d");
                        let txt = "REVISION " + revision.revision;

                        document.body.appendChild(node);
                        node.style.fontSize   = "50px";
                        node.style.fontFamily = "Arial";
                        node.style.fontWeight = "bold";
                        node.innerText        = txt;
                        cvs.width             = node.offsetWidth;
                        cvs.height            = node.offsetHeight + 20;
                        ctx.font              = "bold 40px Arial";
                        ctx.fillStyle         = "#E1E1E1";
                        document.body.removeChild(node);
                        ctx.fillText(txt, 40 , 40);;
                        let payload = {
                            revision: revision,
                            component: component,
                            backgroundStyle: {
                                backgroundImage: "url(" + cvs.toDataURL() + ")"
                            }
                        };
                        yield put(buildAction(COMPONENT.SET_REVISION_IN_REVISION_PAGE, payload));
                    }

                }


            }
            catch(err)
            {
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }

    }
    catch(err)
    {
        let payload =
                    {
                        type: "errors",
                        errors: err.errors,
                        err: err,
                        closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.componentSearch)
                            }
                    }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

export function* findRevisionNestedDataById(action)
{
    let {id, name} = action.payload;
    try
    {
        const revision = yield cps(API.componentRevision.findById, action.payload.id)
        if (!revision)
        {
            action.payload.history.push({pathname: "/search", state: {query: "type:cmp"}});
        }
        else
        {
            if(name == "documents")
            {
                let {documents} = revision;
                yield put(buildAction(COMPONENT.SET_REVISION_NESTED_DOCUMENTS, documents));
                yield put(buildAction(COMPONENT.SET_REVISION_DOCUMENTS_LOADED, true ));
            }
            else {
                let {children} = revision;
                yield put(buildAction(COMPONENT.SET_REVISION_NESTED_CHILDREN, children));
                yield put(buildAction(COMPONENT.SET_REVISION_CHILDREN_LOADED, true ));
            }
        }
    }
    catch(err)
    {

    }
}

export default function* (getState)
{
    yield all([
        takeEvery(COMPONENT.GET_REVISION_IN_REVISION_PAGE, findRevisionById),
        takeEvery(COMPONENT.GET_REVISION_NESTED_DATA, findRevisionNestedDataById),
    ])
}
