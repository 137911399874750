import "./index.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import PRODUCT from "../../../../../action-types/product";
import CO from "../../../../../action-types/changeorder";
import buildAction from "../../../../../helpers/buildAction";
import Utils from "../../../../../modules/utils";
import Actions from "../actions";
import Tabs from "../../../common/tabs/view";
import SourcingUtils from "../../../common/sourcing/sourcing-utils";
import Schemas from "../../../../../modules/schemas";
import Loading from "../../../../ui/inner-loading";
import { Helmet } from "react-helmet";
import URLSearchParams from "url-search-params";
import API from "../../../../../modules/api";
import PerfectScrollbar from 'react-perfect-scrollbar';
import ItemOverView from "../../../common/specifications/item-overview";
import ItemDetails from "../../../common/specifications/item-details";
import ItemTeam from "../../../common/specifications/item-team";
import ItemSchedule from "../../../common/specifications/item-schedule";
import ItemERP from "../../../common/specifications/item-erp-view";

export class ViewProduct extends Component
{

    constructor(props, context)
    {
        super(props, context)
        let urlParams = new URLSearchParams(window.location.search)
        this.state = {
            showFullDesc: false,
            showLoading: false,
            openVariantModal: false,
            getDataFromApi: false,
            descriptionLength: 365,
            refreshSerializationTab: false,
            isProductionBuildExist: false
        }
        this.duplicate                          = this.duplicate.bind(this)
        this.toggleDesc                         = this.toggleDesc.bind(this)
        this.toggleFavorite                     = this.toggleFavorite.bind(this)
        this.revertChanges                      = this.revertChanges.bind(this)
        this.resetPageStates                    = this.resetPageStates.bind(this)
        this.getProductFromApi                  = this.getProductFromApi.bind(this)
        this.deleteProduct                      = this.deleteProduct.bind(this)
        this.setPrimarySourceInApi              = this.setPrimarySourceInApi.bind(this)
        this.addToChangeOrder                   = this.addToChangeOrder.bind(this)
        this.openVariantModal                   = this.openVariantModal.bind(this);
        this.closeVariantModal                  = this.closeVariantModal.bind(this);
        this.createVariant                      = this.createVariant.bind(this);
        this.getNestedData                      = this.getNestedData.bind(this);
        this.compareRevisions                   = this.compareRevisions.bind(this);
        this.updateProduct                      = this.updateProduct.bind(this);
        this.updateVariantsTab                  = this.updateVariantsTab.bind(this);
        this.refreshItemViewRoute               = this.refreshItemViewRoute.bind(this);
        this.loadProductAndSetLocalStorage      = this.loadProductAndSetLocalStorage.bind(this);
        this.refreshSerializationTab            = this.refreshSerializationTab.bind(this);
        this.setIsProductionBuildExist          = this.setIsProductionBuildExist.bind(this);
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"

        this.resetPageStates()
    }

    componentDidUpdate()
    {
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
    }

    compareRevisions(sourceRevId, targetRevId)
    {
        let id = this.props.product.viewPage.product._id;
        this.props.history.push({pathname: `/product/diff/${id}?sourceRevId=${sourceRevId}&targetRevId=${targetRevId}`});
    }

    refreshSerializationTab()
    {
        this.setState({refreshSerializationTab: true});
    }

    setIsProductionBuildExist()
    {
        this.setState({isProductionBuildExist: true});
    }

    createVariant(productId)
    {
        const {dispatch} = this.props;
        let payload = {productId, history: this.props.history}
        dispatch(buildAction(PRODUCT.CREATE_VARIANT, payload))
    }

    openVariantModal(e, product)
    {
        let state = this.state
        state.openVariantModal = true
        this.setState(state)
    }

    closeVariantModal(e, product)
    {
        let state = this.state
        state.openVariantModal = false
        this.setState(state)
    }

    getNestedData(name){
        const {dispatch} = this.props;
        let id = this.props.product.viewPage.product._id;
        let payload = { id:`${id}?include=${name}`, name };
        dispatch(buildAction(PRODUCT.GET_PRODUCT_NESTED_DATA, payload));
    }

    addToChangeOrder(data)
    {
        const {dispatch} = this.props;
        let children = data.children
        data.children = {}
        let childId = children[0]._id
        data.children.products = [childId]

        let payload = {history: this.props.history, data: data, addChildren: true, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    componentWillMount()
    {
        this.loadProductAndSetLocalStorage();
    }

    componentWillUnmount()
    {
        this.resetPageStates();
    }

    refreshItemViewRoute()
    {
        this.resetPageStates();
        this.loadProductAndSetLocalStorage();
    }

    loadProductAndSetLocalStorage()
    {
        let id = this.props.match.params.id;
        if(id.includes('?'))
        {
            id = id.split('?')[0];
        }
        this.getProductFromApi(id);
        Utils.setLocalStorageForAssemblyTree(id);
    }

    setPrimarySourceInApi(primarySource){
        if (primarySource)
        {
            const {dispatch} = this.props
            let { product } = this.props.product.viewPage
            let {primarySourceId, rolledUpCost} = primarySource;
            product     = SourcingUtils.setPrimarySrc(primarySourceId, rolledUpCost, product);

            let payload =
            {
                primarySource: {
                    _id: primarySource.primarySourceId,
                    rolledUpCost : primarySource.rolledUpCost,
                    settingPrimarySourceFromViewPage: true,
                    doc: product
                },
                _id: product._id
            }
            dispatch(buildAction(PRODUCT.SET_PRIMARY_SOURCE, payload))
        }
    }

    toggleDesc(){
        this.setState(prevState => ({
            showFullDesc: !prevState.showFullDesc
        }))
    }

    componentWillReceiveProps(nextProps){
        let nextId = nextProps.match.params.id;
        let previousId = this.props.match.params.id;
        if(nextId.includes('?'))
        {
            nextId = nextId.split('?')[0];
        }

        if(previousId.includes('?'))
        {
            previousId = previousId.split('?')[0];
        }

        if (nextId !== previousId)
        {
            this.resetPageStates()
            this.getProductFromApi(nextId)
            this.closeVariantModal()
        }
    }

    getProductFromApi(id)
    {
        const {dispatch} = this.props;
        let user = this.props.user && this.props.user.data ? this.props.user.data : {};
        let payload = { id, history: this.props.history, user }
        let query_params =  `include=children,creator,images${user.role === "SUPPLIER" ? ',revisions&history=true' : ''}`;
        if (this.props.location && this.props.location.showAll)
        {
            payload.id = `${id}?showAll=true&${query_params}`;
        }
        else
        {
            payload.id = `${id}?${query_params}`;
        }
        if (this.props.location && this.props.location.onBoarding)
        {
            payload.onBoarding = true;
        }
        dispatch(buildAction(PRODUCT.GET_PRODUCT_IN_VIEW_PAGE, payload ));

        API.productionBuilds.isProductionBuildExist({parentId: Utils.getIdFromParams({id})}, (err, response) =>
        {
            if(err)
            {
                this.setState({isProductionBuildExist: false})
            }

            this.setState({isProductionBuildExist: response})
        });
    }

    resetPageStates()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(PRODUCT.RESET_STATES_IN_VIEW_PAGE, {} ))
    }

    duplicate()
    {
        let { product } = this.props.product.viewPage;
        const {dispatch} = this.props
        dispatch(buildAction(PRODUCT.DUPLICATE,
        {
            productId: product._id,
            history: this.props.history
        }))
    }

    revertChanges()
    {
        let { product } = this.props.product.viewPage;
        if (product.revisions.length > 0){
            const {dispatch} = this.props;
            let productRevisionId = product.revisions[product.revisions.length - 1];
            if (product.revisions.length > 1)
            {
                productRevisionId = product.revisions[product.revisions.length - 2];
            }
            dispatch(buildAction(PRODUCT.REVERT_CHANGES,
            {
                productRevisionId: productRevisionId,
                history: this.props.history
            }));
        }
    }

    deleteProduct()
    {
        let state = this.state
        state.showLoading = true
        this.setState(state)
        let { product }  = this.props.product.viewPage
        const {dispatch} = this.props
        dispatch(buildAction(PRODUCT.DELETE_PRODUCT,
        {
            id: product._id,
            history: this.props.history,
            viewRoute: true
        }))
    }

    toggleFavorite(user)
    {
        const {dispatch} = this.props
        dispatch(buildAction(PRODUCT.TOGGLE_FAVORITE,
        {
            user: user
        }))
    }


    updateProduct()
    {
        this.resetPageStates();
        this.getProductFromApi(this.props.match.params.id);
        Utils.setLocalStorageForAssemblyTree(this.props.match.params.id);
        let state = this.state;
        state.openVariantModal = true;
        this.setState(state);
    }

    updateVariantsTab(forcefulyGetData=false)
    {
        let state = this.state;
        state.getDataFromApi = forcefulyGetData;
        this.setState(state);
    }

    render()
    {
        let { company } = this.props;
        let { product }  = this.props.product.viewPage
        let { isFavorite }  = this.props.product.viewPage
        let { isProductionBuildExist } = this.state;
        let list = Schemas.product.team.list()
        if (!product || this.state.showLoading || this.props.on_boarding.loading){
            return (<Loading />)
        }
        let longDesc = product.description.length > this.state.descriptionLength
        let showFullDesc = this.state.showFullDesc

        let primarySource = SourcingUtils.setPrimarySourceIfRolledUpSourceIsPrimary(product)
        let primarySourceLeadTimeValue = primarySource.leadTime.value === null ? '' : primarySource.leadTime.value
        let primarySourceLeadTimeUnits = primarySource.leadTime.units === null ? '' : primarySource.leadTime.units
        let cpn                        = Utils.getCpn(product);
        let forecastKeys = []
        product.forecasts.forEach((spec) => forecastKeys.push(spec.key))
        let massPrecision = Utils.getCompanyMassPrecision(company);

        let markup =

            <div className="view-product-route tiles-view">
                <Helmet>
                    <title>{Utils.makeTitle(`[${cpn}] ${product.name}`)}</title>
                </Helmet>

                <div className="top-block">

                    <Actions
                        {...this.props}
                        product={product}
                        isFavorite={isFavorite}
                        duplicate={this.duplicate}
                        toggleFavorite={this.toggleFavorite}
                        revertChanges={this.revertChanges}
                        deleteProduct={this.deleteProduct}
                        addToChangeOrder={this.addToChangeOrder}
                        user={this.props.user}
                        openVariantModal={this.openVariantModal}
                        compareRevisions={this.compareRevisions}
                        openVariantModalFlag={this.state.openVariantModal}
                        closeVariantModal={this.closeVariantModal}
                        updateProduct={this.updateProduct}
                        updateVariantsTab={this.updateVariantsTab}
                        createVariant={this.createVariant}
                        getDataFromApi={true}
                        location={this.props.location}
                        search={this.props.search}
                        history={this.props.history}
                        refreshItemViewRoute={this.refreshItemViewRoute}
                        refreshSerializationTab={this.refreshSerializationTab}
                        setIsProductionBuildExist={this.setIsProductionBuildExist}
                        isProductionBuildExist={isProductionBuildExist}
                    />
                    <div className="tiles-wrapper">
                        <PerfectScrollbar className="tiles-section-scroll">
                            <ItemOverView item={product} alias="prd" isPrdOrCmpViewPage={true}/>
                            <ItemDetails item={product} alias="prd" defaultCurrency={product.defaultCurrency} massPrecision={massPrecision} isPrdOrCmpViewPage={true}/>
                            <ItemTeam item={product}/>
                            <ItemSchedule item={product} isPrdOrCmpViewPage={true} />
                            <ItemERP item={product} />
                        </PerfectScrollbar>
                    </div>
                </div>

                <Tabs
                    massPrecision={massPrecision}
                    tabsType="prd"
                    product={product}
                    documents={product.documents}
                    manufacturers={product.manufacturers}
                    updateVariantsTab={this.updateVariantsTab}
                    getDataFromApi={this.state.getDataFromApi}
                    assemblyData={product.children}
                    history={this.props.history}
                    setPrimarySourceInApi={this.setPrimarySourceInApi}
                    paramId={this.props.match.params.id}
                    getNestedData={this.getNestedData}
                    documents_loaded={this.props.product.viewPage.documents_loaded}
                    children_loaded={true}
                    user={this.props.user}
                    defaultCurrency={product.defaultCurrency}
                    refreshSerializationTab={this.state.refreshSerializationTab}
                    isProductionBuildExist={isProductionBuildExist}
                />

            </div>

            return markup
    }
}

export default connect((store) => store)(ViewProduct)
