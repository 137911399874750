import                              "./index.css"
import React, {Component}           from "react"
import SettingsIcon                  from "../../../../../assets/icons/settings"
import EnlargeIcon                  from "../../../../../assets/icons/enlarge-icon"
import AutoFitIcon                  from "../../../../../assets/icons/auto-width-adjust-icon"
import CancelIcon                   from "../../../../../assets/icons/cancel-outline"
import HideToolbarIcon              from "../../../../../assets/icons/hide-toolbar-icon.js"
import InlineIcon                   from "../../../../ui/icon/inline-icon.js"
import ShowHideColumnsMenu          from "../show-hide-columns-popup"
import Utils                        from "../../../../../modules/utils"
import HelpIcon                     from "../../../../../assets/icons/help-icon.js";
import Link                         from "../../../../ui/link";
import DownloadReportIcon           from "../../../../../assets/icons/download-report.js";
import {DuroMenu} from "../../../../../../common/duroMenu";

class TableIcons extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.state =
        {
            isToolbarVisible: true
        };
        this.hideUnHideToolbar        = this.hideUnHideToolbar.bind(this)
        this.getLastColumnDimensions  = this.getLastColumnDimensions.bind(this)
        this.updatePostionOfCTA       = this.updatePostionOfCTA

    }

    componentDidMount()
    {
        let extendedTableWrapper = this.props.extendedTableWrapper ? `.${this.props.extendedTableWrapper.split(' ')[0]}` : '';
        let el = document.querySelector(`${extendedTableWrapper} .table-icons-section`);
        let _this = this
        this.updatePostionOfCTA()
        Utils.resizeSensor(el, function()
        {
            _this.updatePostionOfCTA();
        });
    }

    componentDidUpdate() {
        let extendedTableWrapper = this.props.extendedTableWrapper ? `.${this.props.extendedTableWrapper.split(' ')[0]}` : '';
        let el = document.querySelector(`${extendedTableWrapper} .table-icons-section`);
        if (!el)
        {
            return
        }
        let _this = this
        this.updatePostionOfCTA()
        Utils.resizeSensor(el, function()
        {
            _this.updatePostionOfCTA();
        });
    }

    updatePostionOfCTA()
    {
        let defaultValues = {x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0};
        let extendedTableWrapper = this.props.extendedTableWrapper ? `.${this.props.extendedTableWrapper.split(' ')[0]}` : '';
        let ctaIcons = document.querySelector(`${extendedTableWrapper} #table-cta-icons`);
        let recordsCount = document.querySelector(`${extendedTableWrapper} .records-count`);
        let nextSiblingPosition = 0;
        let isUsingTableColumn = false;
        if(!ctaIcons)
        {
            return;
        }
        let ctaIconsPos = ctaIcons ? ctaIcons.getBoundingClientRect() : defaultValues;
        let recordsCountPos  = recordsCount ? recordsCount.getBoundingClientRect() : defaultValues;
        let lastColumn = this.getLastColumnDimensions();
        if(ctaIcons.classList.contains('hide-toolbar-icons'))
        {
            ctaIcons.classList.remove('hide-title');
            ctaIcons.classList.remove('hide-actions');
            if((document.getElementById('extended-table-data') && document.getElementById('extended-table-data').offsetWidth >= 350))
            {
                recordsCount.classList.remove('display-none');
            }
            return;
        }

        if(ctaIcons.classList.contains('import-table-icons') || ctaIcons.classList.contains('import-table-icons-with-use-existing'))
        {
            return;
        }

        if(!recordsCount || recordsCount.classList.contains('display-none'))
        {
            isUsingTableColumn = true;
            nextSiblingPosition = lastColumn.right;
        }
        else
        {
            nextSiblingPosition = recordsCountPos.left;
        }
        let distance = nextSiblingPosition - ctaIconsPos.right - 36;
        if (distance < 25)
        {
            if(ctaIcons.classList.contains('hide-title'))
            {
                ctaIcons.classList.add('hide-actions');
                if(recordsCount)
                {
                    recordsCount.classList.add('display-none');
                }
            }
            else
            {
                ctaIcons.classList.add('hide-title');
            }
            let distanceFromLastColumn = lastColumn.right - ctaIconsPos.right
            if(lastColumn.right < ctaIconsPos.right || distanceFromLastColumn <= 60)
            {
                ctaIcons.classList.add('hide-actions');
                ctaIcons.classList.add('hide-title');
                if(recordsCount)
                {
                    recordsCount.classList.add('display-none');
                }
            }
        }
        else if (distance >= 350)
        {
            if(!ctaIcons.classList.contains('hide-actions') && ctaIcons.classList.contains('hide-title'))
            {
                ctaIcons.classList.remove('hide-title');
            }
            else
            {
                if(recordsCount && recordsCount.classList.contains('display-none'))
                {
                    recordsCount.classList.remove('display-none');
                }
                ctaIcons.classList.remove('hide-actions');
            }
        }
    }

    hideUnHideToolbar()
    {
        let state = this.state;
        state.isToolbarVisible = !state.isToolbarVisible;
        this.setState(state);
    }

    getLastColumnDimensions()
    {
        let tableId = this.props.dataTableId ? this.props.dataTableId : 'extended-table' ;
        let stateHeadings = this.props.headings ? this.props.headings : [];
        let defaultValues = {x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0};
        let lastCol = 0;
        stateHeadings.forEach((heading, index) =>
        {
            if (heading.visibility && document.getElementById(tableId))
            {
                lastCol = document.getElementById(tableId).querySelector("tbody tr").querySelector("th[name='"+heading.key+"']").getBoundingClientRect();
            }
        });
        let lastColDimensions = lastCol ? lastCol : defaultValues;
        return lastColDimensions;
    }

    render()
    {
        let actionsList  = this.props.actionsList
        let className = ["table-cta-icons", this.props.className ? this.props.className : ''].join(' ')
        let displaySettings = this.props.hasOwnProperty("displaySettings") && this.props.displaySettings === false ? false : true
        let displayDownloadIcon = this.props.hasOwnProperty("displayDownloadIcon") ? this.props.displayDownloadIcon : false;
        let displayAutoFit = this.props.hasOwnProperty("displayAutoFit") && this.props.displayAutoFit === false ? false : true
        let menuActionsList = [];
        let resultText = this.props.resultText ? this.props.resultText : null;
        let totalPages = this.props.totalPages ? this.props.totalPages : 0
        let page = this.props.page ? this.props.page : 1
        let headingsFilter = this.props.headingsFilter
        actionsList.forEach((item, itemProps) =>
        {
            if(item.type === 'action')
            {
                {
                    menuActionsList.push({
                        label: item.name,
                        icon:  () => item.iconSrc,
                        to: '#',
                        onClick: item.onClick,
                        disabled: !item.active,
                        ...itemProps
                    });
                }
            }
        });
        if(displayAutoFit)
        {
            menuActionsList.push({
                label: "Autofit",
                icon:  () => <AutoFitIcon />,
                to: '#',
                onClick: this.props.autoFitColumnsWidth,
                disabled: false
            });
        }
        if(this.props.allowEnlarge)
        {
            menuActionsList.push({
                label: "Enlarge",
                icon:  () => <EnlargeIcon />,
                to: '#',
                onClick: this.props.enlargeTableView,
                disabled: false
            });
        }
        let alreadyDisplayAutofit = false;
        let autofitMarkup = <span
                    className={"action-item active flex-v"}
                    data-tip={"Auto fit columns width"}
                    onClick={this.props.autoFitColumnsWidth}
                    onMouseOver={this.onMouseHover}
                    onMouseLeave={this.onMouseLeave}
                    >
                        <InlineIcon>
                          <AutoFitIcon />
                        </InlineIcon>
                        <span className="item-title">Autofit</span>
                    </span>

        let markup =
        <div className="table-icons-section">
            <div id="table-cta-icons" className={className + (this.state.isToolbarVisible ? ' ' : ' hide-toolbar-icons')}>
                <span
                className={"action-item hide-unhide-toolbar-icon flex-v"}
                data-tip={this.state.isToolbarVisible ? 'Hide toolbar' : 'Show toolbar'}
                onClick={this.hideUnHideToolbar}
                onMouseOver={this.onMouseHover}
                onMouseLeave={this.onMouseLeave}
                >
                    <InlineIcon className={"flex-v"}>
                        <HideToolbarIcon/>
                    </InlineIcon>
                </span>
                <span className="divider"/>
                { this.state.isToolbarVisible ? '' : (
                    <DuroMenu anchorComponent={<span style={{ marginLeft: "5px" }}
                                                     className="dots-holder">...</span>}
                              name="table"
                              options={menuActionsList}
                              customButtonStyles={{ fontSize: "20px" }}>
                    </DuroMenu>
                )}
                {
                    actionsList.map((item, i) =>
                    {
                        const { onDoubleClick, onClick } = item;
                        let clickHandler = e => onClick(e);
                        let dbClickHandler = e => {};

                        if(onDoubleClick)
                        {
                            let timer = 0;
                            const delay = 200;
                            let prevent = false;
                            clickHandler = (e) => {
                                timer = setTimeout(() => {
                                    if (!prevent) {
                                        onClick(e);
                                    }
                                    prevent = false;
                                }, delay);
                            };

                            dbClickHandler = (e) => {
                                clearTimeout(timer);
                                prevent = true;
                                onDoubleClick(e);
                            };
                        }

                        let block;
                        let itemClass = (item.className ? (item.className + ' ') : '' ) + ("action-item" + (item.active ? ' active' : '') ) + ' flex-v'
                        if (item.type === 'customEl')
                        {
                            block = item.element
                        }
                        else if (item.type === 'action')
                        {
                            block =
                                <span
                                    key={i}
                                    className={itemClass}
                                    data-tip={item.toolTip}
                                    onClick={clickHandler}
                                    onDoubleClick={dbClickHandler}
                                >
                                    <InlineIcon
                                        className={(item.active ? 'flex-v ' : 'disabled flex-v ') + (item.iconClassName ? item.iconClassName : '')}
                                    >
                                        {item.iconSrc}
                                    </InlineIcon>
                                    <span
                                        className="item-title"
                                        >
                                        {item.name}
                                    </span>
                                </span>
                        }

                        else if (item.type === 'divider')
                        {
                            block = <span className="divider" key={i}/>
                        }

                        else if (item.type === 'autofit')
                        {
                            alreadyDisplayAutofit = true;
                            block = displayAutoFit && autofitMarkup
                        }


                        return block
                    })
                }

                {
                    displayDownloadIcon &&
                        <span
                            className={`action-item table-settings-wrapper flex-v ${this.props.downloadIconClass}`}
                            data-tip="Download"
                            onClick={() => this.props.downloadTable(this.props.headings)}
                            onMouseOver={this.onMouseHover}
                            onMouseLeave={this.onMouseLeave}
                            >
                            <InlineIcon
                                className={`flex-v ${this.props.downloadIconClass}`}
                            >
                              <DownloadReportIcon />
                            </InlineIcon>
                            <span className="item-title">Download</span>
                        </span>
                }

                {
                    !alreadyDisplayAutofit && displayAutoFit && autofitMarkup

                }

                {
                    this.props.allowEnlarge === true &&
                    <span
                        className={"action-item active flex-v"}
                        data-tip={"Enlarge the table"}
                        onClick={this.props.enlargeTableView}
                        onMouseOver={this.onMouseHover}
                        onMouseLeave={this.onMouseLeave}
                        >
                        <InlineIcon>
                          <EnlargeIcon />
                        </InlineIcon>
                        <span className="item-title">Enlarge</span>
                    </span>
                }

                {
                    displaySettings &&
                        <span
                            className={"action-item active table-settings-wrapper flex-v"}
                            data-tip="Table settings"
                            onClick={this.props.onSettingsIconClick}
                            onMouseOver={this.onMouseHover}
                            onMouseLeave={this.onMouseLeave}
                            >
                            <InlineIcon
                                className="setting-icon flex-v"
                            >
                              <SettingsIcon />
                            </InlineIcon>
                            <span className="item-title">Settings</span>
                            <ShowHideColumnsMenu
                                headings={this.props.headings}
                                displayMenu={this.props.displayMenu}
                                setHeadingsVisibility={this.props.setHeadingsVisibility}
                                onHideOfColumnSettings={this.props.onHideOfColumnSettings}
                            />
                        </span>
                }

                {
                    false &&
                    <span className="dots-holder">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                    </span>
                }

                {
                   this.props.showHelp &&
                    <div className="help-icon" data-tip="Help">
                        <Link
                        to={this.props.helpLink}
                        target="_blank">
                            <InlineIcon className="co-link-icon">
                                <HelpIcon/>
                            </InlineIcon>
                        </Link>
                    </div>
                }

            </div>
            {
                headingsFilter > 0 ?
                <div className="filters-active">
                    <span>{`${headingsFilter} Filter${headingsFilter > 1 ? 's' : ''} Active`}</span>
                    <span onClick={this.props.removeFilters}>
                        <CancelIcon />
                    </span>
                </div> : null
            }
            {
               this.props.customCTAIcon && this.props.customCTAIcon
            }
            {
                resultText ?
                <div className="grey records-count">{resultText}</div>
                : null
            }
        </div>
        return markup
    }


}

export default TableIcons
