import                              "./index.css"
import React, { Component }         from "react"
import {connect}                    from 'react-redux';

import UI                           from '../../../action-types/ui'
import buildAction                  from '../../../helpers/buildAction'

import ModalAlert                   from "./modal"
import ConfirmAlert                 from "./confirm"
import DefaultAlert                 from "./default"
import { ErrorModal } from "../../../../../src/common/error-modal/index.tsx";

class Alert extends Component
{
    constructor(props, context) {
        super(props, context);
        this.confirm = this.confirm.bind(this);
        this.reject = this.reject.bind(this);
        this.close = this.close.bind(this);
    }

    confirm()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(UI.CLOSE_ALERT, { confirmCb: true }))
    }

    reject()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(UI.CLOSE_ALERT, { rejectCb: true }))
    }

    close()
    {
        const {dispatch, ui} = this.props;
        dispatch(buildAction(UI.CLOSE_ALERT, {}));
        if(ui && ui.alert && ui.alert.closeCb)
            ui.alert.closeCb();
    }

    render()
    {
        let errors = null
        let alert = this.props.ui.alert
        // handle no data passed
        if(!alert.display)
        {
            return null
        }
        if(alert.type === "modal")
        {
            // handle strings passed as data
            return <ModalAlert confirm={this.close} reject={this.close} text={alert.text} />
        }
        else if(alert.type === "errors")
        {
            // handle runtime error sent
            if(alert instanceof Error)
            {
                errors =
                [
                    alert
                ]
            }

            // handle array of errors passed
            if(!errors && alert.errors)
            {
                errors = alert.errors
            }
            // handle API errors object passed as data
            return <ErrorModal
                onClose={this.close}
                open={true}
                title={alert.errorHeading}
                confirmLabel={alert.confirmBtnText}
                onConfirm={alert.confirmCb ?? this.close}
                errors={errors}
                variant={alert.errorType}
                />
        }

        else if(alert.type === "confirm")
        {
            // handle confirmation passed as data
            return <ConfirmAlert confirm={this.confirm} reject={this.reject} text={alert.text} errorType={alert.errorType} heading={alert.heading} confirmButtonText={alert.confirmButtonText}/>
        }
        else
        {
            // handle default
            return <DefaultAlert confirm={this.close} reject={this.close} />
        }
    }
}

export default connect((store) => store)(Alert)
