import { PageWrapper } from "build/components/pageWrapper";
import { useIsBuildEnabled } from "build/hooks/isBuildEnabled";
import React from "react";
import { useParams } from "react-router-dom";

interface BuildInstanceRouteParams {
  instanceId: string
}

export function BuildInstance() {
  const { instanceId } = useParams<BuildInstanceRouteParams>();
  const { isBuildEnabled, loading: isBuildEnabledLoading } = useIsBuildEnabled();

  return (
    <PageWrapper
      isBuildEnabled={isBuildEnabled}
      isLoading={isBuildEnabledLoading}
    >
      This is the Instance {instanceId}!
    </PageWrapper>
  );
}
