import                               "./index.css"
import React, { Component }     from "react"
import {connect}                from "react-redux"
import PerfectScrollbar         from "react-perfect-scrollbar";
import buildAction              from "../../../helpers/buildAction"
import ITEM_ASSEMBLY            from "../../../action-types/item-assembly"
import ArrowIcon                from "../../../assets/icons/arrow-icon"
import InlineIcon from "../icon/inline-icon.js"
import Utils                    from "../../../modules/utils";


class ItemAssembly extends Component
{
    constructor(props, context) {
        super(props, context);
        this.getChildAssembly                = this.getChildAssembly.bind(this)
        this.generateKey                     = this.generateKey.bind(this)
        this.generateNavMarkup               = this.generateNavMarkup.bind(this)
        this.redirectToItem                  = this.redirectToItem.bind(this)
        this.markSelectedComponent           = this.markSelectedComponent.bind(this)
        this.unMarkAllComponents             = this.unMarkAllComponents.bind(this)
        this.findAndUnmarkSelectedComponents = this.findAndUnmarkSelectedComponents.bind(this)
        this.checkToolTipAppearance          = this.checkToolTipAppearance.bind(this)
        this.removeHOverState                = this.removeHOverState.bind(this)
        this.addHOverState                   = this.addHOverState.bind(this)
        this.childNameHover = this.childNameHover.bind(this)
    }

    getChildAssembly(component)
    {
        if (!component.iconClass)
            component.iconClass = "open"
        else
            component.iconClass = component.iconClass == "open" ? "" : "open"

        if (!component.listClass)
            component.listClass = "open"
        else
            component.listClass = component.listClass == "open" ? "close" : "open"

        const {dispatch} = this.props;
        if (component.iconClass === "open")
            dispatch(buildAction(ITEM_ASSEMBLY.GET_AND_SET_CHILD_ASSEMBLY,{component: component}))
        else
        {
            component.children = []
            dispatch(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
            dispatch(buildAction(ITEM_ASSEMBLY.GET_AND_SET_CHILD_ASSEMBLY,{component: component}))
        }
    }

    redirectToItem(component)
    {
        let path = window.__userRole === "VENDOR" ? "revision" : "view";
        this.props.history.push({pathname: `/component/${path}/${component._id}`})
        this.markSelectedComponent(component)
    }

    markSelectedComponent(component)
    {
        this.unMarkAllComponents()
        component.selectedClass = "selected"
        const {dispatch} = this.props;
        this.props.item_assembly.buttonHighlight = false
        dispatch(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
    }

    unMarkAllComponents()
    {
        let data = this.props.item_assembly
        this.findAndUnmarkSelectedComponents(data)
    }

    findAndUnmarkSelectedComponents(root)
    {
        root.selectedClass = ""
        if (root.children.length > 0)
            root.children.forEach((c) => this.findAndUnmarkSelectedComponents(c))
        else
            return root
    }

    generateKey(){
        return (1 + Math.random() * (100000 - 1) + "").replace('.', new Date().getTime())
    }

    checkToolTipAppearance(e){
        let element = e.target
        this.addHOverState(e)
        element.dataset.tip = ""

        if (element.offsetWidth < element.scrollWidth){
            element.dataset.tip = element.dataset.tootipText
        }
    }

    addHOverState(e){
        let element = e.target
        element.closest("li").classList.add("hover-state")
    }

    removeHOverState(e){
        var elems = document.querySelectorAll("li");
        [].forEach.call(elems, function(el) {
            el.classList.remove("hover-state");
        });
    }

    childNameHover(e){
        e.stopPropagation();
        this.addHOverState(e)
    }

    generateNavMarkup(root)
    {
        if (root.children) {
            if(root.alias)
            {
                root.cpn = Utils.getCpn(root);
            }
        return (
            <div key={this.generateKey()} className="assembly-ul-list">
                {
                    root.haveChildren ?
                    <InlineIcon
                        onClick={(e) => this.getChildAssembly(root) }
                        className={root.iconClass}
                        onMouseOver={(e) => this.addHOverState(e)}
                    >
                      <ArrowIcon />
                    </InlineIcon>

                    : null
                }
                <span
                    data-tip=""
                    data-tootip-text={root.cpn + " " + root.name}
                    data-place="top"
                    className={root.selectedClass + " name-area"}
                    onMouseOver={(e) => this.checkToolTipAppearance(e)}
                    onMouseOut={(e) => this.removeHOverState(e)}
                    onClick={(e) => this.redirectToItem(root)}
                    >
                    <span onMouseOver={(e) => this.childNameHover(e)} onMouseOut={(e) => this.removeHOverState(e)}><span onMouseOut={(e) => this.removeHOverState(e)} onMouseOver={(e) => this.childNameHover(e)} className="cpn">{root.cpn}</span>{root.name}</span>
                </span>

            { root.children.length > 0 ?
                <ul className={"assembly-ul-list-inner " + root.listClass}>
                    {
                        root.children.map(c =>  (<li
                        onMouseOver={(e) => e.stopPropagation() }
                        onMouseOut={(e) => this.removeHOverState(e)}
                        key={this.generateKey()}
                        className={(c.haveChildren ? "have-child" : "") + (c.isDeleted ? " hidden" : "")}
                        >
                        {this.generateNavMarkup(c)}
                        </li>))
                    }
                </ul>
                :
                null
            }
            </div>
        );
        }
        else {
            if(root.alias)
            {
                root.cpn = Utils.getCpn(root);
            }
            return <p key={this.generateKey()} onClick={(e) => alert(" " + root._id + " -- simple p " + root.children.length)}> <span><span className="cpn">{root.cpn}</span>{root.name}</span> </p>;
        }
    }

    render()
    {
        let data = this.props.item_assembly
        if (data.children.length === 0 && data.selectedTextForProductList !== "Select Product")
        {
            return (<div><h1>Assembly is not present</h1></div>);
        }
        else if (data.children.length === 0 && data.selectedTextForProductList === "Select Product")
        {
            return null
        }
        else
        {
            return (
                <div>
                    <PerfectScrollbar className="navScrollArea content">
                          {this.generateNavMarkup(data)}
                    </PerfectScrollbar>
                </div>
            );
        }
    }

}

// export default connect((store) => store)(ItemAssembly)
export default connect((store) => ({
    item_assembly: store.item_assembly
}))(ItemAssembly)
