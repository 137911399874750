import "./index.css";
import React, { Component } from "react";
import CoIcon from "../../../../../../assets/icons/co";
import CTAIconToolbar from "../../../../common/cta-icon-toolbar";
import EllipsisTooltip from "../../../../common/ellipsis-tooltip";
import InlineIcon from "../../../../../ui/icon/inline-icon.js";
import Link from "../../../../../ui/link";
import Utils from "../../../../../../modules/utils";
import VendorPillbox from "../../../components/vendor";

export class Actions extends Component {
  constructor(props) {
    super(props);
  }

  gotoRevision = (id, isMostRecent = false) => {
    let { history } = this.props;
    if (isMostRecent)
      history.push(
        `/component/view/${id}${
          Utils.checkForRestrictedRole(window.__userRole) && window.__userRole !== "VENDOR" ? "?viewRecent=true" : ""
        }`
      );
    else history.push(`/component/revision/${id}`);
  };

  render() {
    const { component, user } = this.props;
    const { co } = this.props.component;
    let disabledRevisionId = null;
    const revision = component.revisions.slice(-1)[0];
    if (revision) disabledRevisionId = revision;

    let componentEditLink = "/component/edit/" + component._id;
    const { vendorLabel } = Utils.getVendorLabel(component);

    const markup = (
      <div className="actions-block">
        {(component.archived || co) && (
          <div className={`banner-block${component.archived ? " archived" : ""}`}>
            <div className="app-row">
              {component.archived && (
                <div>
                  <div>This component has been deleted.</div>
                </div>
              )}
              {co && (
                <div>
                  <InlineIcon>
                    <CoIcon />
                  </InlineIcon>
                  <div>This component is in an unresolved change order</div>
                  <Link to={`/changeorder/view/${co._id}`}>{co.con}</Link>
                  <div>{`with Status: ${co.status}`}</div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={`nav-block${component.archived ? " vendor " : " "}app-row`}>
          <div className="header-left">
            <VendorPillbox
              isVendorCmp={Utils.getOriginalVendor(component)}
              label={vendorLabel}
              componentId={component._id}
              isComponentLinked={Utils.isComponentLinked(component.vendorInfo)}
              fromView={true}
              className={co ? "not-editable" : ""}
              isAddedInCO={!!co}
            />
            <EllipsisTooltip classes={"ellipsis-tooltip tooltip-no-width"} title={component.name} innerClasses={"truncate-name"}>
              {component.name}
            </EllipsisTooltip>
          </div>
          <CTAIconToolbar
            {...this.props}
            user={user}
            item={component}
            itemName="component"
            itemEditLink={componentEditLink}
            disabledRevisionId={disabledRevisionId}
            gotoRevision={this.gotoRevision}
          />
        </div>
      </div>
    );
    return markup;
  }
}

export default Actions;
