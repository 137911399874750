import React, { Component }         from "react"
import Utils                        from "../../../../modules/utils"
import { connect }                  from "react-redux"

class Actions extends Component {
    constructor(props) {
        super(props)
        this.update                     = this.update.bind(this);
        this.getButtonsState            = this.getButtonsState.bind(this);
        this.getActionButtons           = this.getActionButtons.bind(this);
        this.showSaveAsRevisionModal    = this.showSaveAsRevisionModal.bind(this);
        const {proceed, proceedSaveAsRevision} = this.getButtonsState(this.props);
        this.state                      =
        {
            proceed:  proceed,
            proceedSaveAsRevision: proceedSaveAsRevision,
        };
    }

    getButtonsState(props) {
        const {
            inputs,
            modified,
            children,
            childrenModified,
            assemblyErrors,
        } = props;
        const validated = Utils.isValidated(inputs);
        const childrenValidated = Utils.isValidated(children);
        return {
            proceed: (modified || childrenModified) && validated && childrenValidated && assemblyErrors === 0,
            proceedSaveAsRevision: validated && childrenValidated && assemblyErrors === 0,
        };
    }

    componentWillReceiveProps(nextProps)
    {
        this.update(nextProps);
    }

    update(props)
    {
        const {proceed, proceedSaveAsRevision} = this.getButtonsState(props);
        this.state.proceed = proceed;
        this.state.proceedSaveAsRevision = proceedSaveAsRevision;
        this.setState(this.state);
    }

    showSaveAsRevisionModal()
    {
        this.props.showHideSaveAsRevisionModal(true)
    }

    getActionButtons()
    {
        const { inputs, isNewObject } = this.props;
        let markup =
                <div>
                    {
                        inputs.status.value === "DESIGN" && !isNewObject ?
                        <div>
                            <input className={this.state.proceed ? "" : "disabled"} type="submit" value="SAVE" onClick={this.props.onSubmit}/>
                            {
                                !this.props.isVendorCmp && <button className={"btn-black-theme " + (this.state.proceedSaveAsRevision ? "" : "disabled")} type="button" onClick={this.showSaveAsRevisionModal}>
                                    SAVE AS REVISION
                                </button>
                            }
                        </div>
                        :
                        <input className={this.state.proceed ? "" : "disabled"} type="submit" value="SAVE" />
                    }
                </div>
        return markup;
    }

    render()
    {
        return this.getActionButtons()
    }
}

export default connect((store) => ({
    children: store.assembly_edit.children,
    childrenModified: store.assembly_edit.modified,
    inputs: store.components.editPage.inputs,
    modified: store.components.editPage.modified,
}))(Actions)
