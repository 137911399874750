import { SvgIcon } from "@mui/material";

export const FilledCheckbox = () => (
  <SvgIcon viewBox="0 0 23.934 24" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "1.496rem",
    height: "1.5rem",
  }}>
    <path
      d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.2.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5"
    >
    </path>
  </SvgIcon>
);
