import { gql } from "@apollo/client";

export const ComponentChildrenFragment = gql`
  fragment componentChildrenFragment on Component {
    children {
      quantity
      component {
        id
        cpn {
          id
          prefix
          counter
          variant
          displayValue
        }
        category
        name
        status
        version
        lastModified
        children {
          quantity
          component {
            id
            cpn {
              id
              prefix
              counter
              variant
              displayValue
            }
            category
            name
            status
            version
            lastModified
          }
        }
      }
    }
  }
`;

export const ComponentRevisionChildrenFragment = gql`
  fragment componentRevisionChildrenFragment on ComponentRevision {
    children {
      quantity
      component {
        id
        cpn {
          id
          prefix
          counter
          variant
          displayValue
        }
        category
        name
        status
        version
        lastModified
        children {
          quantity
          component {
            id
            cpn {
              id
              prefix
              counter
              variant
              displayValue
            }
            category
            name
            status
            version
            lastModified
          }
        }
      }
    }
  }
`;

export const ProductChildrenFragment = gql`
  fragment productChildrenFragment on Product {
    children {
      quantity
      component {
        id
        cpn {
          id
          prefix
          counter
          variant
          displayValue
        }
        children {
          quantity
          component {
            id
            cpn {
              id
              prefix
              counter
              variant
              displayValue
            }
            category
            name
            status
            version
            lastModified
          }
        }
        category
        name
        status
        version
        lastModified
      }
    }
  }
`;

export const ProductRevisionChildrenFragment = gql`
  fragment productRevisionChildrenFragment on ProductRevision {
    children {
      quantity
      component {
        id
        cpn {
          id
          prefix
          counter
          variant
          displayValue
        }
        children {
          quantity
          component {
            id
            cpn {
              id
              prefix
              counter
              variant
              displayValue
            }
            category
            name
            status
            version
            lastModified
          }
        }
        category
        name
        status
        version
        lastModified
      }
    }
  }
`;
