import React, {Component} from "react";
import                    "./index.css";
import Tooltip            from 'rc-tooltip';
import { connect }        from "react-redux";
import Link               from "../../../../../ui/link";
import ModalBox           from "../../../../../ui/modal-box";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import API                from "../../../../../../modules/api";
import Loading            from "../../../../../ui/inner-loading";
import Utils              from "../../../../../../modules/utils";
import Config             from "../../../../../../modules/config";
import ExtendedTable      from "../../../../common/extended-table";
import Schemas            from "../../../../../../modules/schemas";
import InlineIcon         from "../../../../../ui/icon/inline-icon.js";
import TimeStampColumn    from "../../../../common/timestamp-column";
import InlineImageViewer  from "../../../../common/inline-image-viewer/view";
import LinkIcon           from "../../../../../../assets/icons/open-link-icon.js";
import HeaderBanner       from "../../../../common/header-banner";
import AlertIcon          from "../../../../../../assets/icons/alert-icon.js";
import CpnField           from "../../../../common/cpn-field";
import SubmitBtn          from "../../../../../ui/spinner-button";

class ReviewScreen extends Component
{
    constructor(props)
    {
        super(props)
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.valispaceReviewScreen || {}
        }
        catch(error) {
            viewStyles = {}
        }
        this.state =
        {
            loading: false,
            components  : Utils.clone(this.props.data) || {},
            categories : [],
            checkedRows  : [],
            bulkCategory : [],
            results : [],
            clearSelectedRows : false,
            disabledContinue : false,
            duplicateComponents: {},
            headings :
            [
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : false,
                    ascending   : true,
                    minWidth    : 250,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 302),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "CPN",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "category",
                    displayName : "Category",
                    tooltip     : "Category",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "category", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "category", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "category", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "image",
                    displayName : "Image",
                    tooltip     : "Image",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "image", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "image", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "image", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 396),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "creator",
                    displayName : "Creator",
                    tooltip     : "Creator",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 110,
                    width       : Utils.getStyleValue(viewStyles, "creator", "width", 233),
                    position    : Utils.getStyleValue(viewStyles, "creator", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "creator", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "documents",
                    displayName : "Documents",
                    tooltip     : "Documents",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 110,
                    width       : Utils.getStyleValue(viewStyles, "documents", "width", 233),
                    position    : Utils.getStyleValue(viewStyles, "documents", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "documents", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "Last Modified",
                    tooltip     : "Last Modified",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 150,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 295),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    disabled    : false
                }
            ],
        }
        this.getRows    = this.getRows.bind(this);
        this.onContinue = this.onContinue.bind(this);
        this.backButton = this.backButton.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.initCategories = this.initCategories.bind(this);
        this.onSelectAllRows = this.onSelectAllRows.bind(this);
        this.assignBulkCategory = this.assignBulkCategory.bind(this);
        this.validateEmptyCategory  = this.validateEmptyCategory.bind(this);
        this.getExistingComponents  = this.getExistingComponents.bind(this);
        this.assignCategoryToInputs = this.assignCategoryToInputs.bind(this);
        this.validateExistingComponents = this.validateExistingComponents.bind(this);
    }

    componentWillMount()
    {
        let state = this.state;
        state.categories = this.initCategories()
        this.setState(state);
    }

    componentDidMount()
    {
        let { components } = this.state;
        Object.keys(components).forEach((item) => {
            if(!components[item].isChecked)
            {
                delete components[item];
            }
        })
        this.validateExistingComponents();
        this.validateEmptyCategory();
    }

    validateEmptyCategory()
    {
        let state = this.state;
        state.disabledContinue = false;
        Object.keys(state.components).forEach((input) =>
        {
            state.components[input].className = "";
            if(!state.components[input].category)
            {
                state.components[input].className = "invalid";
                state.disabledContinue = true;
            }
        })
        this.setState(state);
    }

    validateExistingComponents()
    {
        let data = [];
        let state = this.state;
        Object.keys(state.components).forEach((input) =>
        {
            data.push({name: state.components[input].name, vendorId:  state.components[input].id});
        })

        API.integrations.bulkNameExists(data, (err, results) =>
        {
            if(err)
            {
                return;
            }
            Object.keys(state.components).forEach((input) =>
            {
                let result = results.filter((item) => { return item.name === state.components[input].name; })
                if(result[0].alreadyExist)
                {
                    state.components[input].exist = true;
                    let dupCmp = result[0].duplicate_of;
                    state.duplicateComponents[input] = {
                        _id: dupCmp._id,
                        cpn: dupCmp.cpn,
                        name: dupCmp.name,
                        alias: dupCmp.alias,
                        cpnVariant: dupCmp.cpnVariant
                    }
                }
                else
                {
                    state.components[input].exist = false;
                }
            })
            this.setState(state);
        });
        this.getExistingComponents();
    }

    getExistingComponents()
    {
        let state = this.state;
        let vendorIDs = [];
        let existingComponentsCount = 0;
        Object.keys(state.components).forEach((input) =>
        {
            vendorIDs.push(state.components[input].id);
        })

        API.integrations.getExistingComponents(vendorIDs, (err, results) =>
        {
            if(err)
            {
                return;
            }

            Object.keys(state.components).forEach((input) =>
            {
                let result = results.filter((item) => { return parseInt(item.vendorId) === state.components[input].id});
                let oldComp = state.components[input];
                if(result[0])
                {
                    oldComp.category    = result[0].category;
                    oldComp.className   = "";
                    oldComp.toBeUpdated = true;
                    oldComp.cmpId       = result[0]._id;
                    oldComp.status      = result[0].status;
                    oldComp.cpn         = result[0].cpn;
                    oldComp.isAlreadyExist = true;
                    existingComponentsCount++;
                    state.duplicateComponents[input] = {
                        _id: result[0]._id,
                        cpn: result[0].cpn,
                        name: result[0].name,
                        alias: result[0].alias,
                        cpnVariant: result[0].cpnVariant
                    }
                }
                else
                {
                    oldComp.toBeUpdated = false;
                    oldComp.category = "";
                    oldComp.className = "invalid";
                    oldComp.isAlreadyExist = false;
                }
            })
            if(existingComponentsCount === Object.keys(state.components).length)
            {
                state.disabledContinue = false;
            }
            this.setState(state);
        });
    }

    initCategories()
    {
        let type  = this.props.type;
        let cats  = Schemas.component.category.list();
        let listA = [];
        let listB = [];
        let assemblyCategories = [];

        cats.forEach((category) =>
        {
            category.type === type ? listA.push(category) : listB.push(category);
            category.type === "ASSEMBLY" && assemblyCategories.push(category);
        })

        if(listA.length > 0)
        {
            listA.push(<option></option>);
        }

        return {options: Utils.toOptions([...listA, ...listB]), list: [...listA, ...listB], assemblyType: Utils.toOptions(assemblyCategories)};
    }

    getCategories()
    {
        return {options: this.state.categories.options, list: this.state.categories.list, assemblyType: this.state.categories.assemblyType};
    }

    assignCategoryToInputs(e, i, id)
    {
        let value = e.target.value;
        let state = this.state;
        state.components[id].category = value;
        let category = state.categories.list.find((item) => item.name === value);
        state.components[id].cpn = `${category.code ? category.code : 'xxx'}-xxxxx`;
        state.components[id].categoryCpn = state.components[id].cpn;
        this.validateEmptyCategory();
        this.setState(state);
    }

    onSelectAllRows(selectAll)
    {
        let state = this.state;
        let { inputs } = this.state;
        state.clearSelectedRows = false;
        if(selectAll)
        {
            inputs.forEach((result, i) =>
            {
                if(result.isAlreadyExist)
                {
                    state.checkedRows.push(result.id);
                }
            })
        }
        else
        {
            state.checkedRows = [];
            state.bulkCategory = "";
        }

        this.setState(state);
    }

    onRowSelect(event, result)
    {
        let state = this.state;
        let index = state.checkedRows.indexOf(result.id);
        if (event.target.checked && !(index > -1)) {
            state.checkedRows.push(result.id);
        }
        else if (!event.target.checked && index > -1)
        {
            state.checkedRows.splice(index, 1);
        }
        if(state.checkedRows.length === 0)
        {
            state.bulkCategory = "";
        }
        this.setState(state);
    }

    onContinue()
    {
        let state = this.state;
        let selectedResults = [];
        Object.keys(state.components).forEach((input) => {
            if(state.components[input].category)
            {
                selectedResults.push(state.components[input]);
            }
        })

        this.props.importComponents(selectedResults);
    }

    assignBulkCategory(event)
    {
        let value = event.target.value;
        let state = this.state;
        state.bulkCategory = value;
        state.checkedRows.forEach((row) => {
            if(state.components[row] && state.components[row].isAlreadyExist)
            {
                state.components[row].category = value;
            }
        })
        this.validateEmptyCategory();
        this.setState(state);
    }

    getRows()
    {
        let state = this.state;
        let imagesWithSrc = [];
        let statusTooltipMessage = "Component is not in design status";

        let rows = Object.keys(state.components).length > 0 ? Object.keys(state.components).map((result, i) =>
        {
            let component = state.components[result];
            let nameDuplicateTooltip = Utils.makeDuplicateInputTooltip(state.duplicateComponents[result], "Name");
            let invalid = component.exist ? true : false;
            let cells =
            {
                "cpn":
                {
                    value: component.cpn ? component.cpn : "xxx-xxxxx",
                    tooltip : "",
                    displayValue: component.cpn ? (component.categoryCpn ?      component.cpn :
                                <span className="link">
                                    <a href={`/component/view/${component.cmpId}`} target={"_blank"}>
                                        <CpnField item={component} cpn={component.cpn} />
                                        <InlineIcon >
                                            <LinkIcon/>
                                        </InlineIcon>
                                    </a>
                                </span>) : "xxx-xxxxx",
                    cellClass: 'cpn-col',
                    notLink  : component.cpn ? (component.categoryCpn ? true : false) : true
                },
                "category":
                {
                    value        : component.category,
                    tooltip      : component.category,
                    displayValue : !component.isAlreadyExist ? <Tooltip
                                    placement="right"
                                    overlay={component.className ? "Invalid Category Type" : ""}
                                    destroyTooltipOnHide={true}
                                    mouseLeaveDelay={0}
                                    overlayClassName={`error default-styles ${component.className ? "": "hidden"}`}
                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                    >
                                    <select
                                    className={`select-category ${component.className}`}
                                    value={component.category}
                                    onChange={(e) => this.assignCategoryToInputs(e, i, component.id)}
                                    >
                                        <option value=""></option>
                                        {component.children.length > 0 ? this.getCategories().assemblyType : this.getCategories().options}
                                    </select>
                                    </Tooltip> : <input
                                                type="text"
                                                readOnly={true}
                                                className={`header-input read-only-input disabled`}
                                                value={component.category}
                                                />,
                    cellClass    : 'category-col category-cell',
                    notLink      : true
                },
                "name":
                {
                    value       : component.name,
                    tooltip     : component.name,
                    displayValue: invalid ? <Tooltip
                                    placement="right"
                                    getTooltipContainer={() => document.querySelector(".routes")}
                                    overlay={nameDuplicateTooltip &&
                                            <div>
                                                <p>
                                                    <span className="link-text">{nameDuplicateTooltip.errorMessage}</span>
                                                    <br/>
                                                    <Link
                                                        to={nameDuplicateTooltip.viewLink}
                                                        target="_blank"
                                                        className="open-link-holder white"
                                                        >
                                                        <span className="link-text">{nameDuplicateTooltip.linkMessage}
                                                            <InlineIcon >
                                                                <LinkIcon/>
                                                            </InlineIcon>
                                                        </span>
                                                    </Link>
                                                </p>
                                            </div>}
                                    overlayClassName={`error simple-rc-tip ${invalid ? "": "hidden"}`}
                                    ><div>{component.name}</div></Tooltip> : component.name,
                    cellClass   : `name-col ${invalid ? "invalid" : ""}`,
                    notLink     : true
                },
                "image":
                {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={component.image ? [{src: component.image}] : []} imgIdentifier={component.image ? `img-${component.id}` : ''} isValispaceComponent={true}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                "description":
                {
                    value       : component.description,
                    tooltip     : component.description,
                    displayValue: component.description,
                    cellClass   : 'desc-col',
                    notLink      : true
                },
                "creator":
                {
                    value        : component.creator,
                    tooltip      : "",
                    displayValue : component.creator,
                    notLink      : true
                },
                "documents":
                {
                    value        : component.documents.length,
                    tooltip      : "",
                    displayValue : component.documents.length,
                    notLink      : true
                },
                "lastModified":
                {
                    value       : component.lastModified ? component.lastModified : '',
                    tooltip     : component.lastModified,
                    displayValue: <TimeStampColumn
                                    key={Utils.generateUniqueId()}
                                    format='date-time-with-long-format'
                                    value={component.lastModified}/>,
                    cellClass   : 'lastModified-col',
                    notLink      : true

                },
                object           : component,
                rowId            : component.id,
                notLink          : true,
                targetBlank      : true,
                indexTableEl     : component.hasOwnProperty('isAlreadyExist') && component.isAlreadyExist ?
                                    <Tooltip
                                    placement="top"
                                    getTooltipContainer={() => document.querySelector(".valispace-selected-cmps")}
                                    overlay={
                                            <div>
                                                <p>
                                                    <span className="link-text">{"Component already exists in library."}</span>
                                                    <br/>
                                                </p>
                                            </div>}
                                    overlayClassName={`error valispace-error-icon simple-rc-tip`}
                                    ><div><AlertIcon/></div></Tooltip>
                                        : "",
            }
            return cells
        }) : []

        return rows;
    }

    backButton()
    {
        this.props.setStep("components-list");
    }

    render()
    {
        let state = this.state;
        // let isBulkCategoryAllowed = state.checkedRows.length > 0;
        let projectName = this.props.selectedProject.name;
        let workspaceName = this.props.selectedWorkspace.name;
        // let bulkCategoryClass = `bulk-category ${isBulkCategoryAllowed ? "" : "disabled"}`;
        let continueButtonClass = `btn-continue ${state.disabledContinue ? "disabled" : ""}`;

        if(state.loading)
        {
            return <Loading />
        }

        let rows = this.getRows();

        let markup =
                <div className="valispace-selected-cmps">
                    <header className="valispace-modal-header">
                        <HeaderBanner text="Step 4 of 4: Assign Categories"/>
                        <div className="btn-container">
                            <button
                                className="btn-back"
                                onClick={this.backButton}>
                                Back
                            </button>
                            <SubmitBtn
                                onClick={!state.disabledContinue ? this.onContinue : function(){}}
                                className={continueButtonClass}
                                btnText={"Import"}
                            />
                        </div>
                    </header>
                    <div className="content-wrapper">
                        <div className="modal-heading">
                            <h1> Assign Categories </h1>
                        </div>
                        <div className="details-wrapper">
                            <div className="details-heading">
                                <h3>VALISPACE <span>COMPONENTS</span></h3>
                            </div>
                            <div className="table-wrapper">
                                <div className="heading-holder">
                                    <h3> <span>Workspace: </span> {workspaceName} </h3>
                                    <h3> <span>Project: </span> {projectName} </h3>
                                </div>
                                {/*<div className="bulk-action-block">
                                    <select
                                        className={bulkCategoryClass}
                                        value={state.bulkCategory}
                                        onChange={isBulkCategoryAllowed ? (e) => this.assignBulkCategory(e) : function(){}}
                                        >
                                        <option value="">Assign Category</option>
                                        {this.getCategories().options}
                                    </select>
                                </div>*/}
                                <div className="valispace-components">
                                    <PerfectScrollbar
                                        className="valispace-modal-scroll"
                                    >
                                        <ExtendedTable
                                            wrapperClassName="valispace-review-block"
                                            wrapperSelectorClass="valispace-review-view"
                                            headings={state.headings}
                                            themeDataTableId={"valispace-review-table"}
                                            rows={rows}
                                            stylesName="valispaceReviewScreen"
                                            allowRowSelect={false}
                                            resultText={`${rows.length} components`}
                                            disableDataSorting={true}
                                            onRowSelect={this.onRowSelect}
                                            onSelectAllRows={this.onSelectAllRows}
                                            syncWithParentState={true}
                                            includeToolBar={true}
                                            clearSelectedRows={state.clearSelectedRows}
                                            concateWrapperSelectorClassMasterCheckbox={true}
                                            borderedTable={true}
                                        />
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        return markup
    }
}

export default ReviewScreen
