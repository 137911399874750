import                  "./index.css"
import React            from "react"
import Utils            from "../../../modules/utils"
import ReactTooltip     from 'react-tooltip'

let cache = []

class Icon extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state          = {svg: null}
        this.loadFromCache  = this.loadFromCache.bind(this)
        this.loadFromFile   = this.loadFromFile.bind(this)
        this.onLoadFromFile = this.onLoadFromFile.bind(this)
        this.onClick        = this.onClick.bind(this)
    }

    loadFromCache()
    {
        for(var i=0; i<cache.length; i++)
        {
            let item = cache[i]

            if(item.src === this.props.src)
            {
                this.refs.node.appendChild(item.svg.cloneNode(true))
                return true
            }
        }
    }

    loadFromFile()
    {
        let object       = document.createElement("object")
        object.onload    = this.onLoadFromFile
        object.className = "ui-icon object"
        object.type      = "image/svg+xml"
        object.data      = this.props.src
        object.width     = this.props.width
        object.height    = this.props.height
        document.body.appendChild(object)
    }

    onLoadFromFile(event)
    {
        if(!this.refs.node || this.refs.node.children.length > 0) return
        let svg  = event.target.contentDocument.children[0]
        event.target.parentNode.removeChild(event.target)
        this.refs.node.appendChild(svg)
        cache.push({src: this.props.src, svg: svg.cloneNode(true)})
    }

    onClick(e)
    {
        if (this.props.stopPropagation)
        {
            e.preventDefault()
            e.stopPropagation()
        }
        let event = Utils.getEvent(this.refs.node)
        this.props.onClick && this.props.onClick(event)
    }

    render()
    {
        let markup =

            <div
                key={Math.random()}
                className={"ui-icon " + (this.props.className || '')}
                onClick={this.onClick}
                name={this.props.name}
                data-tip={this.props.tooltip}
                data-type={this.props.tooltipType}
                data-place={this.props.tooltipPlace}
                data-for={this.props.dataFor}
                ref="node"
                >
                {this.state.svg}
            </div>

        return markup
    }

    componentDidMount()
    {
        if(!this.props.src) return
        this.loadFromCache() || this.loadFromFile()
    }

    componentDidUpdate()
    {
        if(!this.props.src) return
        this.loadFromCache() || this.loadFromFile()
        ReactTooltip.rebuild()
    }
}

Icon.defaultProps =
{
    className : "",
    src       : "",
    onClick   : null,
    width     : "1",
    height    : "1",
    name      : ""
}

export default Icon
