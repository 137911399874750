import { Paper, Typography } from "@mui/material";
import React from "react";

export interface PageWrapperProperties {
  isBuildEnabled: boolean;
  isLoading: boolean;
}

export const PageWrapper: React.FC<PageWrapperProperties> = ({
  children,
  isBuildEnabled,
  isLoading,
}) => {
  if (!(isBuildEnabled || isLoading)) {
    return (
      <Paper sx={{ padding: "1rem", margin: "1rem", textAlign: "center" }}>
        <Typography variant="h3">Build functionality is not enabled for this account.</Typography>
      </Paper>);
  }

  return <>{children}</>;
};
