import { PageWrapper } from "build/components/pageWrapper";
import { useIsBuildEnabled } from "build/hooks/isBuildEnabled";
import React from "react";

export function BuildLots() {
  const { isBuildEnabled, loading: isBuildEnabledLoading } = useIsBuildEnabled();

  return (
    <PageWrapper
      isBuildEnabled={isBuildEnabled}
      isLoading={isBuildEnabledLoading}
    >
      This is the Lot Library!
    </PageWrapper>
  );
}
